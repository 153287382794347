import { z } from 'zod';

export const shopManagerItemSchema = z.object({
	id: z.number().int(),
	roleId: z.number().int(),
	userId: z.number().int(),
	shopId: z.number().int().optional(),
});

export interface ShopManagerItemOptions {
	id: number;
	roleId: number;
	userId: number;
	shopId?: number;
}

export class ShopManagerItem {
	readonly id: number;
	readonly roleId: number;
	readonly userId: number;
	readonly shopId?: number;

	constructor(options: ShopManagerItemOptions) {
		this.id = options.id;
		this.roleId = options.roleId;
		this.userId = options.userId;
		if (options.shopId !== undefined) this.shopId = options.shopId;
		shopManagerItemSchema.parse(this);
	}

}