import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	FeatureConfigurationDataItem,
	FeatureConfigurationDataItemOptions,
	featureConfigurationDataItemSchema
} from '../composite';

export const featureConfigurationOneResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: featureConfigurationDataItemSchema.optional(),
	}),
);

export interface FeatureConfigurationOneResponseDtoOptions extends BaseResponseDtoOptions {
	data?: FeatureConfigurationDataItem | FeatureConfigurationDataItemOptions;
}

export class FeatureConfigurationOneResponseDto extends BaseResponseDto {
	readonly data?: FeatureConfigurationDataItem;

	constructor(options: FeatureConfigurationOneResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			if (options.data instanceof FeatureConfigurationDataItem) {
				this.data = options.data;
			} else {
				this.data = new FeatureConfigurationDataItem(options.data);
			}
		}
		featureConfigurationOneResponseDtoSchema.parse(this);
	}
}