import { z } from 'zod';

export const shopManagerRoleItemSchema = z.object({
	id: z.number().int(),
	title: z.string(),
	shortTitle: z.string(),
	engShortTitle: z.string(),
	subLevel: z.number().int(),
	isShopUser: z.boolean().optional(),
});

export interface ShopManagerRoleItemOptions {
	id: number;
	title: string;
	shortTitle: string;
	engShortTitle: string;
	subLevel: number;
	isShopUser?: boolean;
}

export class ShopManagerRoleItem {
	readonly id: number;
	readonly title: string;
	readonly shortTitle: string;
	readonly engShortTitle: string;
	readonly subLevel: number;
	readonly isShopUser?: boolean;

	constructor(options: ShopManagerRoleItemOptions) {
		this.id = options.id;
		this.title = options.title;
		this.shortTitle = options.shortTitle;
		this.engShortTitle = options.engShortTitle;
		this.subLevel = options.subLevel;
		if (options.isShopUser !== undefined) this.isShopUser = options.isShopUser;
		shopManagerRoleItemSchema.parse(this);
	}

}