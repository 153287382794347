import React from 'react';
import { useSelector } from 'react-redux';
import { TaskStatusTitle } from 'common-lib';
import { UiBadge } from '~components';

type Props = {
	data: any,
};

export default function ShopTaskListView(props: Props) {
	const { isShopUser } = useSelector((state: any) => state.me);
	return <>
		{props.data.map(shopTask => {
			const { taskId } = shopTask;
			const linkTo = isShopUser
				? `/tasks/${btoa(taskId)}`
				: `/tasks/shop/${btoa(shopTask.shopId)}/${btoa(taskId)}`;
			return <UiBadge key={shopTask.taskId}
			                bigPaddings
			                linkTo={linkTo}>
				<h1>{shopTask.title}</h1>
				<UiBadge.Values values={shopTask.values} />
				<UiBadge.DueDateAndStatus dueDate={shopTask.dueDate}
				                          status={shopTask.status}
				                          statusText={TaskStatusTitle[shopTask.status]} />
			</UiBadge>;
		})}
	</>;
}
