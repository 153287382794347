import { z } from 'zod';
import { ChecklistTaskStatusEnum } from 'common-lib';

export const checklistTaskListDataItemSchema = z.object({
	id: z.number().int(),
	taskId: z.string(),
	status: z.nativeEnum(ChecklistTaskStatusEnum),
	taskCreatedAt: z.instanceof(Date),
	departmentName: z.string().optional(),
	checkListName: z.string().optional(),
	checkListProcessName: z.string().optional(),
	checkListQuestion: z.string(),
	creatorComment: z.string().optional(),
	taskDeadlineAt: z.instanceof(Date),
	creatorFullname: z.string().optional(),
	taskClosedAt: z.instanceof(Date).optional(),
	executorUserId: z.number().int().optional(),
	isExpired: z.boolean().optional(),
});

export interface ChecklistTaskListDataItemOptions {
	id: number;
	taskId: string;
	status: ChecklistTaskStatusEnum;
	taskCreatedAt: Date | string;
	departmentName?: string;
	checkListName?: string;
	checkListProcessName?: string;
	checkListQuestion: string;
	creatorComment?: string;
	taskDeadlineAt: Date | string;
	creatorFullname?: string;
	taskClosedAt?: Date | string;
	executorUserId?: number;
	isExpired?: boolean;
}

export class ChecklistTaskListDataItem {
	readonly id: number;
	readonly taskId: string;
	readonly status: ChecklistTaskStatusEnum;
	readonly taskCreatedAt: Date;
	readonly departmentName?: string;
	readonly checkListName?: string;
	readonly checkListProcessName?: string;
	readonly checkListQuestion: string;
	readonly creatorComment?: string;
	readonly taskDeadlineAt: Date;
	readonly creatorFullname?: string;
	readonly taskClosedAt?: Date;
	readonly executorUserId?: number;
	readonly isExpired?: boolean;

	constructor(options: ChecklistTaskListDataItemOptions) {
		this.id = options.id;
		this.taskId = options.taskId;
		this.status = options.status;
		if (options.taskCreatedAt instanceof Date) {
			this.taskCreatedAt = options.taskCreatedAt;
		} else {
			this.taskCreatedAt = new Date(options.taskCreatedAt);
		}
		if (options.departmentName !== undefined) this.departmentName = options.departmentName;
		if (options.checkListName !== undefined) this.checkListName = options.checkListName;
		if (options.checkListProcessName !== undefined) this.checkListProcessName = options.checkListProcessName;
		this.checkListQuestion = options.checkListQuestion;
		if (options.creatorComment !== undefined) this.creatorComment = options.creatorComment;
		if (options.taskDeadlineAt instanceof Date) {
			this.taskDeadlineAt = options.taskDeadlineAt;
		} else {
			this.taskDeadlineAt = new Date(options.taskDeadlineAt);
		}
		if (options.creatorFullname !== undefined) this.creatorFullname = options.creatorFullname;
		if (options.taskClosedAt !== undefined) {
			if (options.taskClosedAt instanceof Date) {
				this.taskClosedAt = options.taskClosedAt;
			} else {
				this.taskClosedAt = new Date(options.taskClosedAt);
			}
		}
		if (options.executorUserId !== undefined) this.executorUserId = options.executorUserId;
		if (options.isExpired !== undefined) this.isExpired = options.isExpired;
		checklistTaskListDataItemSchema.parse(this);
	}
}