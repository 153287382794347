/**
 * Выполняет нарезку SQL скрипта на несколько отдельных запросов.
 * Учитываются блоки с BEGIN и END.
 * Для каждого запроса в конце обрезается
 * @param rawSql
 * @param trimComments {boolean}
 * @returns {string[]}
 */
export const splitSqlScript = (rawSql: string, trimComments = false) => {
	const tempSemicolon = '<__TEMP__SEMICOLON__>';
	const tempBreak = ' <__TEMP__BREAK__LINE__> '; // с пробелами по бокам, чтобы BEGIN и END имели границу слова
	const regex = /\bBEGIN\b.+?\bEND\b/ig;
	if (trimComments) rawSql = rawSql.replace(/\s*--.*/gm, '');
	rawSql = rawSql.replace(/[\r\n]+/g, tempBreak);
	rawSql = rawSql.replace(regex, (found) => found.replace(/;/g, tempSemicolon));
	rawSql = rawSql.replace(tempBreak, '\n');
	return rawSql.split(';')
		.map(s => s.replace(tempSemicolon, ';'))
		.map(s => s.trim())
		.filter(s => !!s);
};
