import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'semantic-ui-react';
import UiBadge from '~components/ui/UiBadge';
import UiOverMenu from '~components/ui/UiOverMenu';
import { Spinner, UiPhotoGallery2, UserPhotoPreview } from '~components';
import isMobile from 'is-mobile';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { QuestionnaireQuestionTypeEnum, UserRoles } from 'common-lib';
import generateId from '~tools/generateId';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';
import { QuestionnaireAnswerTestingRequestDto } from 'protocol-lib';

const TestingList = styled.div`
	& > div {
		margin-bottom: 1rem;

		&.invalid {
			background: rgba(255, 220, 220, 0.5);
			border-radius: 8px;
			padding: 8px;
			border: 2px solid pink;
		}
	}

	span.__spark {
		margin-left: 0.3rem;
		color: red;
	}

	div.__radioList {
        display: inline-block;

		div {
			cursor: default;
			padding: 0.3rem 0 0.3rem 2rem;
			background-image: url(/assets/img/ui/radio_empty.svg);
			background-repeat: no-repeat;
			background-size: initial;
			background-position: left center;

			&.active {
				background-image: url(/assets/img/ui/radio_checked.svg);
			}
		}
	}

	div.__checkboxList {
        display: inline-block;

		div {
			cursor: default;
			padding: 0.3rem 0 0.3rem 2rem;
			background-image: url(/assets/img/ui/checkbox_empty.svg);
			background-repeat: no-repeat;
			background-size: initial;
			background-position: left center;

			&.active {
				background-image: url(/assets/img/ui/checkbox_checked.svg);
			}
		}
	}
`;

const InfoStyled = styled.div`
	margin: 18px auto;
	font-size: 16px;
	text-align: center;
	color: #9696A7;
`;

export interface ITestingSettings {
	mixOptionAnswers: boolean;
	showTestResult: boolean;
}

interface IOptionsType {
	index: number;
	value: string;
	correctAnswer: boolean;
}

export interface ITestingQuestion {
	text: string;
	type: string;
	startDate: string;
	startTime: string;
	photos?: any[];
	isInvalid?: boolean;
	options?: IOptionsType[];
	correctAnswer?: string;
	mixed?: IOptionsType[];
}

export function UserTestingSolving(props) {
	const [answers, setAnswers] = useState<any[]>(props.questions.map(() => null));
	const [questions, setQuestions] = useState<ITestingQuestion[]>(props.questions);
	const [issetErrors, setIssetErrors] = useState<boolean>(false);
	const [showQuestionFileId, setShowQuestionFileId] = useState<number | string>();
	const [isSubmited, setSubmited] = useState<boolean>(false);

	const meConfig: any = useSelector<any>(state => state.me.currentUser);

	const questionsSettings = props.questionsSettings;

	const navigate = useNavigate();

	// отключение свайпа на тестах
	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	useEffect(() => {
		if (questionsSettings.mixOptionAnswers) {
			setQuestions(questions?.map((item) => {
				let mixed: any[] = [];
				if (['RADIO', 'CHECKERS'].includes(item.type)) {
					mixed = shuffle(item);
				}
				return { ...item, mixed };
			}));
		}
	}, []);

	useEffect(() => {
		if (isSubmited) {
			submit();
		}
	}, [isSubmited]);

	useEffect(() => {
		if (showQuestionFileId) {
			if (location.hash !== '#preview') {
				location.hash = '#preview';
				navigate(location.pathname + location.hash);
			}
			window.addEventListener('popstate', onBack, false);
		}
	}, [showQuestionFileId]);

	function onBack(e) {
		e.preventDefault();
		setShowQuestionFileId(undefined);
		window.removeEventListener('popstate', onBack);
	}

	function shuffle(item: ITestingQuestion ): IOptionsType[] {
		const result = item.options?.map(i => i) || [];
		for (let i = result.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[result[i], result[j]] = [result[j], result[i]];
		}
		return result;
	}


	function updateAnswers(type: string, index: number, target: any, mixedQuestions?: boolean): void {
		if (mixedQuestions) {
			if (type === QuestionnaireQuestionTypeEnum.CHECKERS) {
				if (!answers[index]) {
					answers[index] = [];
				}
				if (target.checked) {
					answers[index].push(target.value);
				} else {
					const k = answers[index].indexOf(target.value);
					answers[index].splice(k, 1);
				}
			} else {
				answers[index] = target.value;
			}

			setQuestions(questions.map(item => item));

			setAnswers(answers);
		} else {
			if (type === QuestionnaireQuestionTypeEnum.CHECKERS) {
				if (!answers[index]) {
					answers[index] = [];
				}
				if (target.checked) {
					answers[index].push({ ...target });
				} else {
					answers[index] = answers[index].filter(i => i.index !== target.index);
				}
			} else {
				answers[index] = target.value;
			}

			setQuestions(questions.map(item => item));

			setAnswers(answers);
		}
	}

	function checkCorrectAnswers(questList: ITestingQuestion[], answerList: any[], mixQuestions: boolean): number {
		let count: number = 0;
		questList.forEach((quest, questIndex) => {
			if (quest?.correctAnswer === answerList[questIndex]) count++;
			if (quest.type === QuestionnaireQuestionTypeEnum.RADIO) {
				quest.options?.forEach((o, index) => {
					if (o.value === answerList[questIndex] && o.correctAnswer) count++;
				});
			}
			if (quest.type === QuestionnaireQuestionTypeEnum.CHECKERS) {
				if (mixQuestions) {
					const questionCorrectAnswers: string[] = [];
					quest.options?.forEach((o, i) => {
						if (o.correctAnswer) {
							questionCorrectAnswers.push(o.value);
						}
						return questionCorrectAnswers;
					});
					const sortQuests:string[] = questionCorrectAnswers.sort((a, b) => a.localeCompare(b));
					const sortAnswers: string[] = answerList[questIndex].sort((a, b) => a.localeCompare(b));
					if (JSON.stringify(sortQuests) === JSON.stringify(sortAnswers)) count++;
				} else {
					const questionCorrectAnswers: number[] = [];
					quest.options?.forEach((o, i) => {
						if (o.correctAnswer) {
							questionCorrectAnswers.push(o.index);
						}
						return questionCorrectAnswers;
					});
					const sortQuests = questionCorrectAnswers.sort((a, b) => Number(a < b));
					const sortAnswers: number[] = answerList[questIndex].sort((a, b) => a.index - b.index).map(i => i.index);
					if (JSON.stringify(sortQuests) === JSON.stringify(sortAnswers)) count++;
				}

			}
			return count;
		});
		return (Math.floor(count / questList.length * 100));
	}

	function validate(): boolean {
		let isValid: boolean = true;

		answers.map((answer, index) => {
			if (
				answer !== null &&
				answer !== '' &&
				(!Array.isArray(answer) || answer.length)
			) {
				if (questions[index].type === QuestionnaireQuestionTypeEnum.STRING && answer.length > 2000) {
					questions[index].isInvalid = true;
					isValid = false;
				} else {
					questions[index].isInvalid = false;
				}
			} else {
				questions[index].isInvalid = true;
				isValid = false;
			}
		});

		setQuestions([...questions]);

		return isValid;
	}

	function submit(): void {
		if (!validate()) {
			setIssetErrors(true);
			setSubmited(false);
			return;
		}

		setIssetErrors(false);

		const payload: QuestionnaireAnswerTestingRequestDto[] = answers.map((answer, index) => {
			const updateAnswer: any[] = [];
			if (questionsSettings.mixOptionAnswers) {
				if (questions[index].type === QuestionnaireQuestionTypeEnum.CHECKERS) {
					const res = questions[index].options?.filter(i => answer.includes(i.value)) || [];
					if (res.length) {
						res.forEach(i => updateAnswer.push({ index: i.index, value: i.value}));
					}
				}
			}
			return new QuestionnaireAnswerTestingRequestDto({
				questionIndex: index,
				...(answer instanceof Array
						? updateAnswer.length
							? { value: updateAnswer?.map(i => ({ answerIndex: i.index, answerValue: i.value })) }
							: { value: answer?.map(i => ({ answerIndex: i.index, answerValue: i.value })) }
						: { value: answer }
				),
			});
		});

		// Подсчитывает скольпо правильных ответов
		const result = checkCorrectAnswers(questions, answers, questionsSettings.mixOptionAnswers);
		props.submit(payload, result);
	}

	// клик на фото
	function clickQuestionImage(fileId: number | string) {
		setShowQuestionFileId(fileId);
	}

	if (showQuestionFileId) {
		const questionPhotoIds = questions
			.filter(i => i.photos?.includes(showQuestionFileId))
			.map(i => i.photos)
			.flat();
		const questionIndex = questionPhotoIds.findIndex(p => p === showQuestionFileId);

		const questionPhotos = questionPhotoIds.map(p => {
			const day = moment(questions[0].startDate).format('DD.MM');
			const time = questions[0].startTime;
			return {
				fileId: p,
				title: day,
				subTitle: time,
				src: `/api/file/download/${p}?size=middle`,
			};
		});

		return <UserPhotoPreview
			selectedIndex={Number(questionIndex)}
			imageList={questionPhotos}
			isMobile={isMobile()}
			onClose={() => {
				setShowQuestionFileId(undefined);
			}}
		/>;
	}

	return (
		<>
			<UiBadge bigPaddings>
				<TestingList>
					{questions.map((item, index) => {
						const photoSrcs = item.photos?.map(p => {
							return ({
								fileId: p,
								src: `/api/file/download/${p}?size=middle`,
							});
						});

						return (
							<div
								key={item.text}
								className={item.isInvalid ? 'invalid' : ''}
							>
								<h1>{item.text}</h1>
								{item.photos?.length ? (
									<div style={{ marginBottom: '5px' }}>
										<UiPhotoGallery2 photos={photoSrcs || []}
										                 onClickImage={meConfig.role !== UserRoles.ADMIN ? clickQuestionImage : undefined}
										                 readOnly />
									</div>
								) : null}

								{item.type === QuestionnaireQuestionTypeEnum.FLOAT &&
									<Input
										fluid
										type="number"
										defaultValue={answers[index]}
										disabled={isSubmited}
										placeholder="Введите число"
										onChange={event => updateAnswers(item.type, index, event.target)}
									/>
								}

								{item.type === QuestionnaireQuestionTypeEnum.RADIO ? (
									<div className="__radioList">
										{(item.mixed || item.options)?.map(option => {
												const checked = answers[index] === option.value;
												return (
													<div
														key={`${option.value}-${generateId()}`}
														className={checked ? 'active' : ''}
														onClick={() => !isSubmited && updateAnswers(item.type, index, {
															checked: !checked,
															value: option.value.toString(),
														})}
													>{option.value}</div>
												);
											})
										}
									</div>
								) : null}

								{item.type === QuestionnaireQuestionTypeEnum.CHECKERS ? (
									<div className="__checkboxList">
										{(item.mixed || item.options)?.map((option, optIndex) => {
											const indexAnswers = answers[index]?.map(i => i.index);
											const checked = questionsSettings.mixOptionAnswers
												? answers[index]?.includes(option.value)
												: indexAnswers?.includes(option.index);
											return (
												<div
													key={`${option.value}-${generateId()}`}
													className={checked ? 'active' : ''}
													onClick={() => !isSubmited && updateAnswers(item.type, index, {
														checked: !checked,
														index: optIndex,
														value: option.value.toString(),
													}, questionsSettings.mixOptionAnswers)}>{option.value}</div>
											);
										})}
									</div>
								) : null}
							</div>
						);
					})}
				</TestingList>
				{issetErrors &&
					<div style={{ color: 'red' }}>
						Заполните необходимые поля
					</div>
				}
			</UiBadge>
			<UiOverMenu>
				<UiOverMenu.AcceptButton title="Завершить" onClick={() => setSubmited(true)} disabled={isSubmited}/>
			</UiOverMenu>
			{meConfig.role !== UserRoles.ADMIN && isSubmited ? (
				<Spinner onpage text='Сохранение...' />
			) : null}
		</>
	);
}
