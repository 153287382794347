import { z } from 'zod';

export const photoGalleryPayloadRequestDtoSchema = z.object({
	data: z.string().optional(),
});

export interface PhotoGalleryPayloadRequestDtoOptions {
	data?: string;
}

export class PhotoGalleryPayloadRequestDto {
	readonly data?: string;

	constructor(options: PhotoGalleryPayloadRequestDtoOptions) {
		if (options.data !== undefined) {
			if (/^data:image\/(png|jpg|jpeg|gif);base64,/.test(options.data)) {
				this.data = options.data;
			} else {
				// fixme поробовать превратить в base64
				this.data = options.data;
			}
		}
		photoGalleryPayloadRequestDtoSchema.parse(this);
	}
}
