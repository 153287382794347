import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Добавляем хуки в классовые компоненты через Higher Order Component
const HOCWithHooks = (Component) => (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	return <Component {...props} {...{ navigate, location, params }} />;
};
export default HOCWithHooks;
