import { z } from 'zod';
import { ShopManagerRoleItem, ShopManagerRoleItemOptions, shopManagerRoleItemSchema } from './ShopManagerRoleItem';
import { ShopManagerItem, ShopManagerItemOptions, shopManagerItemSchema } from './ShopManagerItem';

export const shopManagerMetaSchema = z.object({
	shopManagerRoleMap: z.map(z.string(), shopManagerRoleItemSchema).optional(),
	shopManagerMap: z.map(z.string(), shopManagerItemSchema).optional(),
});

export interface ShopManagerMetaOptions {
	shopManagerRoleMap?: Record<string, ShopManagerRoleItem | ShopManagerRoleItemOptions>;
	shopManagerMap?: Record<string, ShopManagerItem | ShopManagerItemOptions>;
}

export class ShopManagerMeta {
	readonly shopManagerRoleMap?: Record<string, ShopManagerRoleItem>;
	readonly shopManagerMap?: Record<string, ShopManagerItem>;

	constructor(options: ShopManagerMetaOptions) {
		if (options.shopManagerRoleMap !== undefined) {
			this.shopManagerRoleMap = {};
			for(const [key, value] of Object.entries(options.shopManagerRoleMap)) {
				if (value instanceof ShopManagerRoleItem) {
					this.shopManagerRoleMap[key] = value;
				} else {
					this.shopManagerRoleMap[key] = new ShopManagerRoleItem(value);
				}
			}
		}
		if (options.shopManagerMap !== undefined) {
			this.shopManagerMap = {};
			for(const [key, value] of Object.entries(options.shopManagerMap)) {
				if (value instanceof ShopManagerItem) {
					this.shopManagerMap[key] = value;
				} else {
					this.shopManagerMap[key] = new ShopManagerItem(value);
				}
			}
		}
		shopManagerMetaSchema.parse(this);
	}
}