/*
 * Модуль шлюза JS и Android
 */

export function getFcmToken() {
	return window['AndroidFunction']?.getFcmToken?.();
}

export function setSwipeRefreshLayoutEnabled(flag: boolean) {
	window['AndroidFunction']?.setSwipeRefreshLayoutEnabled?.(flag);
}

export function requestVideoCapturePermission() {
	window['AndroidFunction']?.requestVideoCapturePermission?.();
}
