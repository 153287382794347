import { z } from 'zod';

export const fileTypeDataItemSchema = z.object({
	id: z.number().int(),
	ext: z.string(),
});

export interface FileTypeDataItemOptions {
	id: number;
	ext: string;
}

export class FileTypeDataItem {
	readonly id: number;
	readonly ext: string;

	constructor(options: FileTypeDataItemOptions) {
		this.id = options.id;
		this.ext = options.ext;
		fileTypeDataItemSchema.parse(this);
	}
}