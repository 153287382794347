import { z } from 'zod';
import { InfoMessageToAddresseeEnum } from 'common-lib';

export const infoMessagesToAddresseeDataItemSchema = z.object({
	infoMessageId: z.number().int(),
	addresseeUserId: z.number().int(),
	status: z.nativeEnum(InfoMessageToAddresseeEnum),
	updatedAt: z.instanceof(Date),
});

export interface InfoMessagesToAddresseeDataItemOptions {
	infoMessageId: number;
	addresseeUserId: number;
	status: InfoMessageToAddresseeEnum;
	updatedAt: Date | string;
}

export class InfoMessagesToAddresseeDataItem {
	infoMessageId: number;
	addresseeUserId: number;
	status: InfoMessageToAddresseeEnum;
	updatedAt: Date;

	constructor(options: InfoMessagesToAddresseeDataItemOptions) {
		this.infoMessageId = options.infoMessageId;
		this.addresseeUserId = options.addresseeUserId;
		this.status = options.status;
		if (options.updatedAt instanceof Date) {
			this.updatedAt = options.updatedAt;
		} else {
			this.updatedAt = new Date(options.updatedAt);
		}
		infoMessagesToAddresseeDataItemSchema.parse(this);
	}
}