import { createReducer } from 'redux-act'
import {
	DELETE_SHOP,
	DELETE_USER,
	FETCH_ACCOUNT,
	FETCH_GOOD_LIST,
	FETCH_SHOP,
	FETCH_SHOP_LIST,
	FETCH_USER,
	FETCH_USER_LIST,
	FETCH_SHOP_ROLE_LIST,
	FETCH_REGION_LIST,
} from '../actions/structureActions'

function updateItems(state, values) {
	Object.keys(values)
		.filter(key => values[key])
		.forEach(key => {
			if (!state[key]) state[key] = []
			values[key].forEach(item => {
				if (!item) return;
				const old = state[key].find(i => i.id === item.id)
				if (old) {
					Object.assign(old, item)
				} else {
					state[key].push(item)
				}
			})
		})
	return state
}

function deleteItems(state, values) {
	Object.keys(values)
		.filter(key => values[key])
		.forEach(key => {
			if (!state[key]) return state
			const idsToDelete = values[key]
			state[key] = state[key].filter(i => !idsToDelete.includes(i.id))
			if (!state[key].length) delete state[key]
		})
	return state
}

export default createReducer(
	{
		[FETCH_ACCOUNT]: (state, { data: account }) => ({
			...state,
			account,
		}),
		[FETCH_SHOP_LIST]: (state, { data: shops }) => updateItems(state, { shops }),
		[FETCH_SHOP]: (state, { data: shop, meta}) => updateItems(state, { shops: [{ ...shop, meta }] }),
		[DELETE_SHOP]: (state, shopId) => deleteItems(state, { shops: [shopId] }),
		[FETCH_USER_LIST]: (state, { data: users }) => updateItems(state, { users }),
		[FETCH_SHOP_ROLE_LIST]: (state, { data: shopRoles }) => updateItems(state, { shopRoles }),
		[FETCH_REGION_LIST]: (state, { data: regions }) => updateItems(state, { regions }),
		[FETCH_USER]: (state, { data: user }) => updateItems(state, { users: [user] }),
		[DELETE_USER]: (state, userId) => deleteItems(state, { users: [userId] }),
		[FETCH_GOOD_LIST]: (state, { data: goods }) => updateItems(state, { goods }),
	},
	{
		account: null,
		shops: null,
		users: null,
		shopRoles: null,
		regions: null,
		goods: null,
	},
)
