import { fetchGetThenJson, fetchPostThenJson, fetchPut } from '~tools/fetchBackend';
import { TaskExecRequestDto, TaskRejectRequestDto, TaskExecResponseDto, TaskRejectResponseDto } from 'protocol-lib';

/* create task */
export async function createTask(itemData) {
	return fetchPostThenJson('tasks', itemData);
}

/* read analytics list */
export async function loadTasksAnalyticsData() {
	return fetchGetThenJson('tasks');
}

/* read task list */
export async function getTasksList(shopId) {
	return fetchGetThenJson(shopId ? `tasks?shopId=${shopId}` : 'tasks');
}

/* read task */
export async function loadTaskItemData(taskId, shopId) {
	return fetchGetThenJson(shopId ? `tasks/${taskId}?shopId=${shopId}` : `tasks/${taskId}`);
}

/* update - execute task */
export async function execTask(taskId: number, data: TaskExecRequestDto): Promise<TaskExecResponseDto> {
	return fetchPut(`tasks/execute/${taskId}`, data);
}

/* update - reject task */
export async function rejectTask(taskId: number, data: TaskRejectRequestDto): Promise<TaskRejectResponseDto> {
	return fetchPut(`tasks/reject/${taskId}`, data);
}
