import React, { ReactNode, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { mainTheme } from './brandColors';

type UserLayoutContextType = {
	addEventListener: Function
	removeEventListener: Function
	dispatchEvent: Function
}

export const UserLayout2Context = React.createContext<UserLayoutContextType>({
	addEventListener: () => {
		throw new Error('Method addEventListener not released');
	},
	removeEventListener: () => {
		throw new Error('Method removeEventListener not released');
	},
	dispatchEvent: () => {
		throw new Error('Method dispatchEvent not released');
	},
});

export const EVENT_CHECK_BACK_HANDLE = 'EVENT_CHECK_BACK_HANDLE';

type EventCallbacks = Map<string, Set<Function>>;

type Props = {
	children: ReactNode
}

/**
 * Дочерние ноды могут использовать контекст чтобы управлять событиями:
 * - подписываться / отписываться
 * - вызывать события
 */
export default function UserLayout2(props: Props) {
	const [eventCallbacks] = useState<EventCallbacks>(new Map());

	return (
		<UserLayout2Context.Provider
			value={{
				addEventListener: subscribe.bind(eventCallbacks),
				removeEventListener: unsubscribe.bind(eventCallbacks),
				dispatchEvent: dispatch.bind(eventCallbacks),
			}}
		>
			<ThemeProvider theme={mainTheme}>
				{props.children}
			</ThemeProvider>
		</UserLayout2Context.Provider>
	);
}

/**
 * Подписка на событие
 */
function subscribe(this: EventCallbacks, name: string, cb: Function) {
	if (!this.has(name)) {
		this.set(name, new Set<Function>());
	}
	this.get(name)!.add(cb);
}

/**
 * Отписка от события
 * @param name
 * @param cb
 */
function unsubscribe(this: EventCallbacks, name: string, cb: Function) {
	if (!this.has(name)) {
		return;
	}
	const set: Set<Function> = this.get(name)!;
	set.delete(cb);
	if (!set.size) {
		this.delete(name);
	}
}

/**
 * Исполнение события
 * @param name
 * @param payload
 */
function dispatch(this: EventCallbacks, name: string, payload: any) {
	if (!this.has(name)) {
		return;
	}
	const set: Set<Function> = this.get(name)!;
	return Array.from(set).map(cb => cb(payload));
}
