/**
 * Usage: [1,4,-1,3].sort(sortAscNumeric)
 */
export function sortAscNumeric(a: number, b: number): number {
	return b > a ? -1 : a > b ? 1 : 0;
}

/**
 * Usage: [1,4,-1,3].sort(sortDescNumeric)
 */
export function sortDescNumeric(a: number, b: number): number {
	return b > a ? 1 : a > b ? -1 : 0;
}
