export function checkLocalStorageAccess() {
	try {
		localStorage.getItem('x1');
		return true;
	} catch (e) {
		return false;
	}
}

export function get(key: string, defaultValue?: string): string | undefined {
	try {
		return localStorage.getItem(`CONFIG::${key}`) || defaultValue;
	} catch (e) {
		console.debug('LocalStorage.getItem catched an error:', e);
		return defaultValue;
	}
}

export function set(key: string, value: any) {
	try {
		return localStorage.setItem(`CONFIG::${key}`, value);
	} catch (e) {
		console.debug('LocalStorage.setItem catched an error:', e);
	}
}

export function keys() {
	try {
		const count = localStorage.length;
		return Array(count).fill(0).map((_, index) => localStorage.key(index));
	} catch (e) {
		console.debug('LocalStorage.key catched an error:', e);
	}
}

export function size() {
	try {
		return new Blob(Object.values(localStorage)).size;
	} catch (e) {
		console.debug('LocalStorage size calculation catched an error:', e);
	}
}
