import { z } from 'zod';
import {
	QuestionnaireDataItem,
	QuestionnaireDataItemOptions,
	questionnaireDataItemSchema,
} from '../composite';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';

export const questionnaireWithBaseResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(questionnaireDataItemSchema).optional(),
	}),
);

export interface QuestionnaireListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (QuestionnaireDataItem | QuestionnaireDataItemOptions)[];
}

export class QuestionnaireListResponseDto extends BaseResponseDto {
	readonly data?: QuestionnaireDataItem[];

	constructor(options: QuestionnaireListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof QuestionnaireDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new QuestionnaireDataItem(item));
				}
			}
		}
		questionnaireWithBaseResponseDtoSchema.parse(this);
	}
}
