import errorReducer from './reducers/errorReducer';
import meReducer from './reducers/meReducer';
import structureReducer from './reducers/structureReducer';
import incidentReducer from './reducers/incidentReducer';
import incidentImportReducer from './reducers/incidentImportReducer';
import incidentReportFileReducer from './reducers/incidentReportFileReducer';

export default {
  errors: errorReducer,
  me: meReducer,
  structure: structureReducer,
  incidents: incidentReducer,
  incidentImport: incidentImportReducer,
  incidentReport: incidentReportFileReducer,
};
