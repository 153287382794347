import React, { useEffect, useState } from 'react';
import { Spinner } from '~components';
import { Button, Modal } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import * as QuizApi from '~api/quizApi';
import { UserContentWrapper } from '~containers';
import { ITestingSettings, ITestingQuestion, UserTestingSolving } from './UserTestingSolving';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';
import { QuestionnaireTypeEnum } from 'common-lib';
import { useSelector } from 'react-redux';
import { QuestionnaireAnswerTestingRequestDto } from 'protocol-lib';

export default function UserTestingPage() {
	const params = useParams();
	const quizId: number = Number(params.id);

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	const meConfig: any = useSelector<any>(state => state.me.currentUser);
	const isDmManager = meConfig.shopManagerRoles !== undefined;

	const navigate = useNavigate();

	const [quizName, setTestingName] = useState<string>('');
	const [isShowQuizStopped, setIsShowQuizStopped] = useState<boolean>(false);
	const [questions, setQuestions] = useState<ITestingQuestion[]>([]);
	const [questionsSettings, setQuestionsSettings] = useState<ITestingSettings>();
	const [resultModal, setResultModal] = useState(false);
	const [answeredPercent, setAnsweredPercent] = useState<number>(0);

	async function checkQuizAvailability(): Promise<boolean> {
		return await QuizApi.fetchRunningQuizList().then(res => {
			return res.data?.findIndex(item => item.id === quizId) !== -1;
		}).catch(() => {
			return true;
		});
	}

	function closeModal(): void {
		setResultModal(false);
		navigate('/quiz-list');
	}

	function submit(payload: QuestionnaireAnswerTestingRequestDto[], result: number) {
		const updatedPayload = payload;
		setAnsweredPercent(result);
		QuizApi.answerTest(quizId, updatedPayload, result).then(() => {
			if (questionsSettings?.showTestResult) {
				setResultModal(true);
			} else {
				navigate('/quiz-list', { replace: true });
			}
		}).catch(error => {
			if ([400].includes(error.getStatus())) {
				setIsShowQuizStopped(true);
			} else {
				throw new Error(error);
			}
		});
	}

	useEffect(() => {
		QuizApi.fetchQuiz(quizId).then((res: any) => {
			if (res.data?.questionnaireConfig?.questionsSettings) {
				setTestingName(res.data.title);
				setQuestionsSettings(res.data.questionnaireConfig.questionsSettings);
				setQuestions(res.data?.questionnaireConfig?.questions.map(item => {
					const question: ITestingQuestion = {
						text: item.text,
						type: item.type,
						startDate: res.data.startDate,
						startTime: res.data.startTime,
						photos: item.photos,
						...(item.correctAnswer && { correctAnswer: item.correctAnswer }),
						...(item.options && { options: item.options }),
					};

					return question;
				}));
			}
		});

	}, []);

	if (!quizName || !questions?.length) {
		return <Spinner onpage />;
	}

	return (
		<UserContentWrapper isPreview title={`Тест: ${quizName}`} backLinkTo="/quiz-list">
			<UserTestingSolving questions={questions} quizType={QuestionnaireTypeEnum.TESTING} questionsSettings={questionsSettings} submit={submit} />
			{isShowQuizStopped ? (
				<Modal open>
					<Modal.Content>
						<p>
							Тест был остановлен.
						</p>
						<p>
							Данные не сохранены.
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={() => {
							navigate('/quiz-list', { replace: true });
						}} content="OK" />
					</Modal.Actions>
				</Modal>
			) : null}
			{resultModal ? (
				<Modal open onClose={closeModal} size="small">
					<Modal.Header>
						<h3>Тест завершен</h3>
					</Modal.Header>
					<Modal.Content>
						<p>
							Ваш результат {answeredPercent} %
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={closeModal} content="OK" />
					</Modal.Actions>
				</Modal>
			) : null}
		</UserContentWrapper>
	);
}
