import { z } from 'zod';
import {
	QuestionnaireExecutionConfig,
	questionnaireExecutionConfigSchema,
	QuestionnaireReportConfig,
	questionnaireReportConfigSchema
} from '../composite';
import { QuestionnaireConfig, questionnaireConfigSchema } from '../composite';

export const questionnaireCreateRequestDtoSchema = z.object({
	title: z.string(),
	addresseesShopIds: z.array(z.number().int()).optional(),
	addresseesManagerIds: z.array(z.number().int()).optional(),
	questionnaireConfig: questionnaireConfigSchema,
	executionConfig: questionnaireExecutionConfigSchema,
	reportConfig: questionnaireReportConfigSchema,
});
export interface QuestionnaireCreateRequestDtoOptions {
	title: string;
	addresseesShopIds?: number[];
	addresseesManagerIds?: number[];
	questionnaireConfig: QuestionnaireConfig;
	reportConfig: QuestionnaireReportConfig;
	executionConfig: QuestionnaireExecutionConfig;
}
export class QuestionnaireCreateRequestDto {
	readonly title: string;
	readonly addresseesShopIds?: number[];
	readonly addresseesManagerIds?: number[];
	readonly questionnaireConfig: QuestionnaireConfig;
	readonly reportConfig?: QuestionnaireReportConfig;
	readonly executionConfig: QuestionnaireExecutionConfig;

	constructor(options: QuestionnaireCreateRequestDtoOptions) {
		this.title = options.title;
		if (options.addresseesShopIds !== undefined) this.addresseesShopIds = options.addresseesShopIds;
		if (options.addresseesManagerIds !== undefined) this.addresseesManagerIds = options.addresseesManagerIds;
		this.questionnaireConfig = options.questionnaireConfig;
		if (options.reportConfig !== undefined) this.reportConfig = options.reportConfig;
		this.executionConfig = options.executionConfig;
		questionnaireCreateRequestDtoSchema.parse(this);
	}
}