import { z } from 'zod';

export const featureConfigurationDataItemSchema = z.object({
	id: z.number().int(),
	name: z.string(),
	title: z.string(),
	enabledAll: z.boolean(),
	shopIds: z.array(z.number().int()).nullable(),
});

export interface FeatureConfigurationDataItemOptions {
	id: number;
	name: string;
	title: string;
	enabledAll: boolean;
	shopIds: number[] | null;
}

export class FeatureConfigurationDataItem {
	readonly id: number;
	readonly name: string;
	readonly title: string;
	readonly enabledAll: boolean;
	readonly shopIds: number[] | null;

	constructor(options: FeatureConfigurationDataItemOptions) {
		this.id = options.id;
		this.name = options.name;
		this.title = options.title;
		this.enabledAll = options.enabledAll;
		this.shopIds = options.shopIds;
		featureConfigurationDataItemSchema.parse(this);
	}
}