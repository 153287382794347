import React from 'react'
import { connect } from 'react-redux'
import { getAuthorizedCookie } from '~tools/cookie'
import { fetchMeConfig } from '~store/actions/meActions'
import { addError } from '~store/actions/errorActions'
import { Spinner } from '~components';
import { LoginPage } from '../pages'

type Props = {
	me: any,
	routes: any,
	fetchMeConfig: Function,
	addError: Function,
}

type State = {
	isRequesting: boolean,
	hasRenderError: boolean,
}

class AppContainer extends React.Component<Props, State>
{
	constructor(props: Props) {
		super(props);
		const isAuthorized = getAuthorizedCookie();
		this.state = {
			isRequesting: !!isAuthorized,
			hasRenderError: false,
		};
	}

	componentDidMount() {
		const isAuthorized = getAuthorizedCookie();
		if (isAuthorized) {
			this.props.fetchMeConfig()
				.then(() => this.setState({ isRequesting: false }));
		}
	}

	static getDerivedStateFromError(error) {
		console.error('[AppContainer поймал ошибку во время рендера]', error);
		return { hasRenderError: true };
	}

	render() {
		if (this.state.hasRenderError) {
			return <div>Произошла ошибка во время рендеринга</div>;
		}

		// проверяем доступ пользователя к ресурсам без авторизации
		if (window.location.pathname.startsWith('/noauth-user')) {
			return this.props.routes.NOAUTH_ROUTE;
		}

		// если пользователь не авторизован, выводим форму для авторизации
		const isAuthorized = getAuthorizedCookie();
		if (!isAuthorized) {
			return <LoginPage />;
		}
		// авторизованный пользователь должен получить конфиг пользователя
		if (this.state.isRequesting) {
			return <Spinner />;
		}
		// определяем роут по роли пользователя
		const { currentUser: { role, shopManagerRoles }, isShopUser } = this.props.me || {};
		if (!role) return false;
		if (role === 'ADMIN') return this.props.routes.ADMIN_ROUTE;
		if (role === 'SUPPORT') return this.props.routes.SUPPORT_ROUTE;
		if (role === 'CHIEF') return this.props.routes.SUPERVISOR_ROUTE;
		if (role === 'MANAGER') {
			if (isShopUser) return this.props.routes.DM_ZDM_ROUTE;
			// если сюда дошли, то надо проверить, чтобы у пользователя оставилсь другие роли
			// fixme Что если у пользователя нету роли вообще, зачем ему давать роуты супервизора?
			return this.props.routes.SUPERVISOR_ROUTE;
		}
		// иначе доступ закрыт
		this.props.addError({ error: 'Вам запрещен доступ', canLogout: true });
		return false;
	}
}

export default connect(
	({ me }: any) => ({ me }),
	{ fetchMeConfig, addError },
)(AppContainer);
