import { Colors } from './colors';

export enum ChecklistTaskStatusEnum {
	PLANNED = 'PLANNED',
	STARTED = 'STARTED',
	COMPLETED = 'COMPLETED',
	EXPIRED = 'EXPIRED',
}

export const ChecklistTaskStatusTitle = {
	[ChecklistTaskStatusEnum.PLANNED]: 'Новая',
	[ChecklistTaskStatusEnum.STARTED]: 'В работе',
	[ChecklistTaskStatusEnum.COMPLETED]: 'Выполнено',
	[ChecklistTaskStatusEnum.EXPIRED]: 'Просрочено',
};

export const ChecklistTaskStatusColor = {
	[ChecklistTaskStatusEnum.PLANNED]: Colors.default,
	[ChecklistTaskStatusEnum.STARTED]: Colors.default,
	[ChecklistTaskStatusEnum.COMPLETED]: Colors.success,
	[ChecklistTaskStatusEnum.EXPIRED]: Colors.error,
};
