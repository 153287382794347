import { z } from 'zod';
import {
	QuestionnaireExecDataItem,
	QuestionnaireExecDataItemOptions,
	questionnaireExecDataItemSchema
} from './QuestionnaireExecDataItem';

export const questionnaireReportDataItemSchema = questionnaireExecDataItemSchema.merge(
	z.object({
		attemptUser: z.string().optional(),
		respondingAt: z.instanceof(Date).optional(),
		respondingData: z.string().optional(),
		respondingUserId: z.number().optional(),
	}),
);

export interface QuestionnaireReportDataItemOptions extends QuestionnaireExecDataItemOptions {
	attemptUser?: string;
	respondingAt?: Date | string | null;
	respondingData?: string;
	respondingUserId?: number;
}

export class QuestionnaireReportDataItem extends QuestionnaireExecDataItem {
	readonly attemptUser?: string;
	readonly respondingAt?: Date | null;
	readonly respondingData?: string; // FIXME Приходит JSON типа "[{\"questionIndex\":0,\"value\":\"4\"},{\"questionIndex\":1,\"value\":...
	readonly respondingUserId?: number;

	constructor(options: QuestionnaireReportDataItemOptions) {
		super(options);
		if (options.attemptUser !== undefined) this.attemptUser = options.attemptUser;
		if (options.respondingAt !== undefined) {
			if (options.respondingAt instanceof Date) {
				this.respondingAt = options.respondingAt;
			} else if (options.respondingAt !== null) {
				this.respondingAt = new Date(options.respondingAt);
			} else {
				this.respondingAt = options.respondingAt;
			}
		}
		// todo Разбирать respondingData json здесь
		if (options.respondingData !== undefined) this.respondingData = options.respondingData;
		if (options.respondingUserId !== undefined) this.respondingUserId = options.respondingUserId;
		questionnaireReportDataItemSchema.parse(this);
	}

}