import { z } from 'zod';
import {
	QuestionnaireAnswerPhotoItem,
	QuestionnaireAnswerPhotoItemOptions,
	questionnairePhotoAnswerItemSchema,
	QuestionnaireAnswerDto,
	QuestionnaireAnswerDtoOptions,
	questionnaireAnswerDtoSchema
} from '../composite';

export const questionnaireAnswerQuizRequestDtoSchema = questionnaireAnswerDtoSchema.merge(
	z.object({
		value: z.union([
			z.string(),
			z.array(z.number().int()),
			z.array(questionnairePhotoAnswerItemSchema),
			z.null()
		]),
	}),
);

export interface QuestionnaireAnswerQuizRequestDtoOptions extends QuestionnaireAnswerDtoOptions {
	value: string | null | (QuestionnaireAnswerPhotoItem | QuestionnaireAnswerPhotoItemOptions | number)[];
}

export class QuestionnaireAnswerQuizRequestDto extends QuestionnaireAnswerDto {
	readonly value: string | (QuestionnaireAnswerPhotoItem | number)[] | null;

	constructor(options: QuestionnaireAnswerQuizRequestDtoOptions) {
		super(options);
		if (options.value instanceof Array) {
			this.value = [];
			for (const item of options.value) {
				if (typeof item === 'number') {
					this.value.push(item);
				} else if (item instanceof QuestionnaireAnswerPhotoItem) {
					this.value.push(item);
				} else {
					this.value.push(new QuestionnaireAnswerPhotoItem(item));
				}
			}
		} else {
			this.value = options.value;
		}
		questionnaireAnswerQuizRequestDtoSchema.parse(this);
	}
}