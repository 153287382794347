import { z } from 'zod';
import { QuestionsSettings, questionsSettingsSchema } from './QuestionsSettings';
import {
	QuestionnaireQuizItem,
	QuestionnaireQuizItemOptions,
	questionnaireQuizItemSchema
} from './QuestionnaireQuizItem';

export const questionnaireConfigSchema = z.object({
	questions: z.array(questionnaireQuizItemSchema),
	questionsSettings: questionsSettingsSchema.optional(),
});

export interface QuestionnaireConfigOptions {
	questions: (QuestionnaireQuizItem | QuestionnaireQuizItemOptions)[];
	questionsSettings?: QuestionsSettings;
}

export class QuestionnaireConfig {
	readonly questions: QuestionnaireQuizItem[];
	readonly questionsSettings?: QuestionsSettings;

	constructor(options: QuestionnaireConfigOptions) {
		this.questions = [];
		for (const item of options.questions) {
			if (item instanceof QuestionnaireQuizItem) this.questions.push(item);
			else this.questions.push(new QuestionnaireQuizItem(item));
		}
		if (options.questionsSettings !== undefined) this.questionsSettings = options.questionsSettings;
		questionnaireConfigSchema.parse(this);
	}
}