import { z } from 'zod';
import { ShopDataItem, ShopDataItemOptions, shopDataItemSchema } from '../../shops';
import { UserDataItem, UserDataItemOptions, userDataItemSchema } from '../../users';
import { TaskToShopItem, TaskToShopItemOptions, taskToShopItemSchema } from '../composite';
import { OptionsItem, OptionsItemOptions, optionsItemSchema } from '../../../meta';

export const taskMetaSchema = z.object({
	options: z.map(z.string(), optionsItemSchema).optional(),
	shopMap: z.map(z.string(), shopDataItemSchema).optional(),
	taskToShopMap: z.map(z.string(), taskToShopItemSchema).optional(),
	userMap: z.map(z.string(), userDataItemSchema).optional(),
});

export interface TaskMetaOptions {
	options?: Record<string, OptionsItem | OptionsItemOptions>;
	shopMap?: Record<string, ShopDataItem | ShopDataItemOptions>;
	taskToShopMap?: Record<string, TaskToShopItem | TaskToShopItemOptions>;
	userMap?: Record<string, UserDataItem | UserDataItemOptions>;
}

export class TaskMeta {
	readonly options?: Record<string, OptionsItem>;
	readonly shopMap?: Record<string, ShopDataItem>;
	readonly taskToShopMap?: Record<string, TaskToShopItem>;
	readonly userMap?: Record<string, UserDataItem>;

	constructor(options: TaskMetaOptions) {
		if (options.options !== undefined) {
			this.options = {};
			for (const [key, value] of Object.entries(options.options)) {
				if (value instanceof OptionsItem) {
					this.options[key] = value;
				} else {
					this.options[key] = new OptionsItem(value);
				}
			}
		}
		if (options.shopMap !== undefined) {
			this.shopMap = {};
			for(const [key, value] of Object.entries(options.shopMap)) {
				if (value instanceof ShopDataItem) {
					this.shopMap[key] = value;
				} else {
					this.shopMap[key] = new ShopDataItem(value);
				}
			}
		}
		if (options.taskToShopMap !== undefined) {
			this.taskToShopMap = {};
			for(const [key, value] of Object.entries(options.taskToShopMap)) {
				if (value instanceof TaskToShopItem) {
					this.taskToShopMap[key] = value;
				} else {
					this.taskToShopMap[key] = new TaskToShopItem(value);
				}
			}
		}
		if (options.userMap !== undefined) {
			this.userMap = {};
			for(const [key, value] of Object.entries(options.userMap)) {
				if (value instanceof UserDataItem) {
					this.userMap[key] = value;
				} else {
					this.userMap[key] = new UserDataItem(value);
				}
			}
		}
		taskMetaSchema.parse(this);
	}
}