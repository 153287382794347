import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { ChecklistTaskMeta, ChecklistTaskMetaOptions, checklistTaskMetaSchema } from '../meta';
import { ChecklistTaskDataItem, ChecklistTaskDataItemOptions, checklistTaskDataItemSchema } from '../composite';

export const checklistTaskOneResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: checklistTaskDataItemSchema.optional(),
		meta: checklistTaskMetaSchema.optional(),
	}),
);

export interface ChecklistTaskOneResponseDtoOptions extends BaseResponseDtoOptions {
	data?: ChecklistTaskDataItem | ChecklistTaskDataItemOptions;
	meta?: ChecklistTaskMeta | ChecklistTaskMetaOptions;
}

export class ChecklistTaskOneResponseDto extends BaseResponseDto {
	readonly data?: ChecklistTaskDataItem;
	readonly meta?: ChecklistTaskMeta;

	constructor(options: ChecklistTaskOneResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			if (options.data instanceof ChecklistTaskDataItem) {
				this.data = options.data;
			} else {
				this.data = new ChecklistTaskDataItem(options.data);
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof ChecklistTaskMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new ChecklistTaskMeta(options.meta);
			}
		}
		checklistTaskOneResponseDtoSchema.parse(this);
	}

}