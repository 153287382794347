import { z } from 'zod';
import { UserDataItem, UserDataItemOptions, userDataItemSchema } from '../../users';

export const checklistTaskMetaSchema = z.object({
	userMap: z.map(z.string(), userDataItemSchema).optional(),
});

export interface ChecklistTaskMetaOptions {
	userMap?: Record<string, UserDataItem | UserDataItemOptions>;
}

export class ChecklistTaskMeta {
	readonly userMap?: Record<string, UserDataItem>;

	constructor(options: ChecklistTaskMetaOptions) {
		if (options.userMap !== undefined) {
			this.userMap = {};
			for(const [key, value] of Object.entries(options.userMap)) {
				if (value instanceof UserDataItem) {
					this.userMap[key] = value;
				} else {
					this.userMap[key] = new UserDataItem(value);
				}
			}
		}
		checklistTaskMetaSchema.parse(this);
	}
}