import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	QuestionnaireTestingReportResultItem,
	QuestionnaireTestingReportResultItemOptions,
	questionnaireTestingReportResultItemSchema,
} from '../composite';

export const questionnaireTestingReportResultResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: questionnaireTestingReportResultItemSchema.optional(),
	}),
);

export interface QuestionnaireTestingReportResultResponseDtoOptions extends BaseResponseDtoOptions {
	data?: QuestionnaireTestingReportResultItem | QuestionnaireTestingReportResultItemOptions;
}

export class QuestionnaireTestingReportResultResponseDto extends BaseResponseDto {
	readonly data?: QuestionnaireTestingReportResultItem;

	constructor(options: QuestionnaireTestingReportResultResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			if (options.data instanceof QuestionnaireTestingReportResultItem) {
				this.data = options.data;
			} else {
				this.data = new QuestionnaireTestingReportResultItem(options.data);
			}
		}
		questionnaireTestingReportResultResponseDtoSchema.parse(this);
	}
}