import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	QuestionnaireReportItem,
	QuestionnaireReportItemOptions,
	questionnaireReportItemSchema
} from '../composite';

export const questionnaireReportListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(questionnaireReportItemSchema).optional(),
	}),
);

export interface QuestionnaireReportListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (QuestionnaireReportItem | QuestionnaireReportItemOptions)[];
}

export class QuestionnaireReportListResponseDto  extends BaseResponseDto {
	readonly data?: QuestionnaireReportItem[];

	constructor(options: QuestionnaireReportListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof QuestionnaireReportItem) {
					this.data.push(item);
				} else {
					this.data.push(new QuestionnaireReportItem(item));
				}
			}
		}
		questionnaireReportListResponseDtoSchema.parse(this);
	}

}