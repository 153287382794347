import React, { useEffect, useState, ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { isDev } from 'common-lib';
import initClientsideLogger from '../../lib/initClientsideLogger';
import { requestVideoCapturePermission } from '~tools/androidFunctions';
import { get, set } from '~tools/localConfig';
import { WideBackground } from '~components';

const logger = initClientsideLogger(PhotoShooterV2.name);

const CAMERA_INDEX = `${PhotoShooterV2.name}::cameraIndex`;

const VideoDivStyled = styled.div`
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	display: ${p => p.hidden ? 'none' : 'block'};
`;

const VideoAimBorderStyled = styled.img`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
`;

const ButtonsGroupStyled = styled.div`
	display: grid;
	grid-template-columns: 4fr 1fr 4fr;
	grid-template-rows: 1fr;
	align-items: center;
	width: 100%;
	position: fixed;
	left: 0;
	bottom: 0;
	padding: 0 2rem 2rem 2rem;

	@media (min-width: 500px) {
		width: 500px;
		left: calc(50% - 250px);
	}
`;

const WhiteContainerStyled = styled.div`
	border-radius: 16px;
	background: white;
	padding: 1.5rem;
`;

export interface Props
{
	children: ReactNode;
	maxSquareSideWidth: number;
	onSave: (data: string) => void;
	onCancel: () => void;
	onCannotUseDevice: () => void;
	showAim?: true;
}

interface Store
{
	videoDevices?: any[];
	streamSource?: MediaStream;
	cameraConnectingCount: number;
	videoRef?: HTMLVideoElement;
	videoContainerRef?: HTMLDivElement;
	videoDivRef?: HTMLDivElement;
	canvasRef?: HTMLCanvasElement;
}

interface VideoInfo
{
	videoWidth: number;
	videoHeight: number;
	newVideoWidth: number;
	newVideoHeight: number;
	videoMarginLeft: number;
	videoMarginTop: number;
	blockWidth: number;
	blockHeight: number;
	squareSize: number;
	percent: number;
	hiddenSize: number;
}

const devLogs: string[] = [
	'# dev logs. showed only in development mode #',
];

export function PhotoShooterV2(props: Props) {
	const [canCaptureVideo, setCanCaptureVideo] = useState<boolean>(hasGetUserMedia());
	const [isStreaming, setStreaming] = useState<boolean>(false);
	const [selectedVideoDeviceIndex, setSelectedVideoDeviceIndex] = useState<number | undefined>();
	const [videoInfo, setVideoInfo] = useState<VideoInfo | undefined>();
	const [store] = useState<Store>({ cameraConnectingCount: 0 });
	const [isCapture, setCapture] = useState(false);
	const [, setRandom] = useState(0);

	function addDevLog(s: string): void {
		const time = new Date().toLocaleTimeString();
		devLogs.unshift(`[${time}] ${s}`);
		setRandom(Math.random());
	}

	function clearDevLogs(): void {
		devLogs.length = 0;
		setRandom(Math.random());
	}

	useEffect(() => {
		addDevLog('> effect 1');
		addEventListener('resize', onResize);
		document.addEventListener('visibilitychange', onVisibilityChange);
		// получаем доступ к камере
		if (canCaptureVideo) {
			// получаем список видео устройств
			navigator.mediaDevices.enumerateDevices()
				.then(devices => {
					store.videoDevices = devices.filter(d => d.kind === 'videoinput');
					if (!store.videoDevices?.length) {
						// нет доступа или на клиенте отсутствуют видео источники
						setCanCaptureVideo(false);
						props.onCannotUseDevice();
						return;
					}
					connectToCamera();
				})
				.catch(error => {
					logger.error('Ошибка обработки enumerateDevices', error);
					props.onCannotUseDevice();
				});
		} else {
			props.onCannotUseDevice();
		}
		return () => {
			addDevLog('> effect 1 OFF');
			window.removeEventListener('resize', onResize);
			document.removeEventListener('visibilitychange', onVisibilityChange);
			store.videoRef?.pause();
			stopTracks();
		};
	}, [canCaptureVideo]);

	useEffect(() => {
		addDevLog('> effect 2');
		requestVideoCapturePermission();
	}, []);

	function onResize() {
		addDevLog('> onResize');
		calculateVideoBounds();
	}

	function onVisibilityChange() {
		const { hidden }  =document;
		addDevLog('> onVisibilityChange, hidden = ' + hidden);
		if (hidden) {
			stopTracks();
		} else {
			connectToCamera();
		}
	}

	// чтобы видео стало квадратным его нужно подрезать, для этого определяем границы подрезки
	function calculateVideoBounds() {
		addDevLog('> calculateVideoBounds');
		if (!store.videoRef || !store.videoDivRef) return undefined;
		const { videoWidth, videoHeight } = store.videoRef;
		const { outerHeight: blockHeight, outerWidth: blockWidth } = window;
		const minWidth = Math.min(blockWidth, props.maxSquareSideWidth);
		const minHeight = Math.min(blockHeight, props.maxSquareSideWidth);
		const squareSize = Math.floor(Math.min(minWidth, minHeight));
		const percent = squareSize / Math.min(videoWidth, videoHeight);
		const hiddenSize = Math.floor(Math.abs(videoWidth - videoHeight) / 2 * percent);
		store.videoDivRef.style.width = `${squareSize}px`;
		store.videoDivRef.style.height = `${squareSize}px`;
		store.videoDivRef.style.display = 'block';
		const newVideoWidth = Math.floor(videoWidth * percent);
		const newVideoHeight = Math.floor(videoHeight * percent);
		let videoMarginLeft = 0;
		let videoMarginTop = 0;
		if (videoWidth > videoHeight) {
			videoMarginLeft = -hiddenSize;
		} else {
			videoMarginTop = -hiddenSize;
		}
		store.videoRef.style.margin = `${videoMarginTop}px ${videoMarginLeft}px`;
		store.videoRef.style.width = `${newVideoWidth}px`;
		store.videoRef.style.height = `${newVideoHeight}px`;
		setVideoInfo({ videoWidth, videoHeight, newVideoWidth, newVideoHeight,
			videoMarginLeft, videoMarginTop, blockWidth, blockHeight, squareSize, percent,
			hiddenSize });
	}

	function connectToCamera(): void {
		setSelectedVideoDeviceIndex(undefined);
		selectVideoDevice(Number(get(CAMERA_INDEX, '1')));
	}

	function stopTracks(): void {
		addDevLog('> stopTracks');
		if (store.streamSource) {
			store.streamSource.getTracks().forEach(t => t.stop());
		}
	}

	function selectVideoDevice(selectedVideoDeviceIndex1: number) {
		addDevLog('> selectVideoDevice, index = ' + selectedVideoDeviceIndex1);
		let selectedDevice: any;
		let videoRef: any;
		// защита от выхода за края
		try {
			selectedVideoDeviceIndex1 = store.videoDevices ? selectedVideoDeviceIndex1 % store.videoDevices.length : 0;
			if (store.cameraConnectingCount! > 5) {
				props.onCannotUseDevice(); // не удалось подключиться к камере с 10-ти попыток
				return;
			}
			if (!store.videoRef) {
				return setTimeout(
					() => selectVideoDevice(selectedVideoDeviceIndex1),
					(store.cameraConnectingCount++) * 100);
			}
			store.cameraConnectingCount = 0; // reset
			if (selectedVideoDeviceIndex1 === selectedVideoDeviceIndex) {
				return;
			}
			if (selectedVideoDeviceIndex1 !== selectedVideoDeviceIndex && selectedVideoDeviceIndex !== undefined) {
				stopTracks();
			}
			setVideoInfo(undefined);
			selectedDevice = store.videoDevices?.[selectedVideoDeviceIndex1];
			videoRef = store.videoRef;
			if (!videoRef) throw new Error('videoRef not initialized');
		} catch (error: any) {
			logger.error('Ошибка инициализации камеры', error);
		}
		navigator.mediaDevices.getUserMedia({
			audio: false,
			video: { deviceId: selectedDevice.deviceId },
		})
			.then(streamSource => {
				store.streamSource = streamSource;
				videoRef.srcObject = streamSource; // теперь включаем выбранный девайс
				videoRef.onloadedmetadata = () => {
					videoRef
						.play()
						.then(() => {
							calculateVideoBounds();
						})
						.catch(err => {
							logger.error('Ошибка запуска видеопотока', err);
							props.onCannotUseDevice();
						});
				};
				setSelectedVideoDeviceIndex(selectedVideoDeviceIndex1);
				setCanCaptureVideo(true);
				setStreaming(true);
				set(CAMERA_INDEX, selectedVideoDeviceIndex1);
			})
			.catch(err => {
				const message = err.stack || String(err);
				logger.error(`Ошибка подключения к устройству камеры: ${message}`, err);
				props.onCannotUseDevice();
			});
	}

	function handleNextCamera() {
		addDevLog('> handleNextCamera');
		if (selectedVideoDeviceIndex === undefined) {
			throw new Error('Источник видео не выбран');
		}
		selectVideoDevice(selectedVideoDeviceIndex + 1);
	}

	function handleSnap() {
		addDevLog('> handleSnap');
		if (!store.canvasRef) throw new Error('canvasRef not inited');
		if (!store.videoRef) throw new Error('videoRef not inited');
		const canvas = store.canvasRef;
		const context = canvas.getContext('2d');
		if (!context) throw new Error('canvas context not inited');
		if (!videoInfo) {
			throw new Error('Нет информации о видео источнике');
		}
		// draw to canvas
		canvas.width = videoInfo.squareSize;
		canvas.height = videoInfo.squareSize;
		const { videoMarginLeft, videoMarginTop, newVideoWidth, newVideoHeight } = videoInfo;
		context.drawImage(store.videoRef, videoMarginLeft, videoMarginTop, newVideoWidth, newVideoHeight);
		const data = canvas.toDataURL('image/png');
		setCapture(false);
		props.onSave(data);
	}

	function handleCancel() {
		addDevLog('> handleCancel');
		setCapture(false);
		props.onCancel();
	}

	function handleCanPlay() {
		addDevLog('> handleCanPlay');
		if (!isStreaming) {
			setStreaming(true);
		}
	}

	return <>
		<div onClick={() => setCapture(true)}>
			{props.children}
		</div>
		<WideBackground hidden={!isCapture}>
			<div ref={ref => store.videoContainerRef = ref || undefined}>
				{!videoInfo ? <>
					<WhiteContainerStyled>
						Выполняется подключение к устройству камеры...
					</WhiteContainerStyled>
				</> : null}
				<VideoDivStyled ref={ref => store.videoDivRef = ref || undefined}
				                hidden={!videoInfo}>
					<video ref={ref => store.videoRef = ref || undefined}
					       onCanPlay={handleCanPlay} />
					{props.showAim
						? <VideoAimBorderStyled src="/assets/img/user-photo/aim-border.svg" />
						: null}
					<ButtonsGroupStyled>
						<div>
							{(store.videoDevices?.length || 0) > 1 ? (
								<Button circular
								        size="large"
								        icon="refresh"
								        onClick={handleNextCamera} />
							) : null}
						</div>
						<div>
							<Button positive
							        circular
							        size="massive"
							        icon="camera"
							        style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
							        onClick={handleSnap} />
						</div>
						<div>
							<Button circular size="large" icon="cancel"
							        onClick={handleCancel} />
						</div>
					</ButtonsGroupStyled>
				</VideoDivStyled>
				<canvas ref={ref => store.canvasRef = ref || undefined}
				        style={{ display: 'none' }} />
			</div>
		</WideBackground>
		{isDev ? (
			<div style={{ position: 'fixed', background: 'rgba(255,255,100,0.8)', opacity: 0.9, top: 0, left: 0, right: 0, zIndex: 1111, maxHeight: '100px', overflow: 'auto' }}>
				{devLogs.map(s => <div>{s}</div>)}
				<div>
					<button onClick={() => clearDevLogs()}>clear logs</button>
				</div>
			</div>
		) : null}
	</>;
}

// есть ли у браузера API для работы с камерой
function hasGetUserMedia(): boolean {
	return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}
