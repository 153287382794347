import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { validateNumber, validateString } from 'common-lib';
import { AddresseeSelector, Spinner, UiInputBadge, UiTabSwitch, UiTextareaBadge } from '~components';
import { CheckedSubordinatesValue } from '~components/ui/AddresseeSelector';
import UiOverMenu from '~components/ui/UiOverMenu';
import TasksFetcher from '~api/TasksFetcher';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';

const fetcher = new TasksFetcher();

const CauseStyled = styled.div`
  color: #878FA5;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 12px;
`;

const TITLE_MIN_LENGTH = 5;
const TITLE_MAX_LENGTH = 200;
const DESCRIPTION_MIN_LENGTH = 0;
const DESCRIPTION_MAX_LENGTH = 4000;
const PERIOD_MIN = 1;
const PERIOD_MAX = 15;

type FormFieldBase = {
	error?: string
	hasChanges?: boolean
	touched?: boolean
}

type FormData = {
	fields: {
		title: FormFieldBase & { defaultValue: string, value: string }
		description: FormFieldBase & { defaultValue: string, value: string }
		executionPeriodDays: FormFieldBase & { defaultValue: number, value: number }
	}
	hasError: boolean
	hasChanges: boolean
	waitChanges: boolean
}

type FormDataFieldValue = {
	title?: string
	description?: string
	executionPeriodDays?: number
}

type Props = {
	subordinates: any
	onCreatedSuccess: () => void
}

export default function TaskCreateView(props: Props) {
	const [isSaving, setSaving] = useState<boolean>();
	const [formData, setFormData] = useState<FormData>({
		fields: {
			title: { defaultValue: '', value: '' },
			description: { defaultValue: '', value: '' },
			executionPeriodDays: { defaultValue: 3, value: 3 },
		},
		hasError: false,
		hasChanges: false,
		waitChanges: true,
	}); // по умолчанию есть обязательные поля
	const [tabIndex, setTabIndex] = useState<number>(0);
	const [checkedSubordinatesInfo, setCheckedSubordinates] = useState<CheckedSubordinatesValue>({
		checkedShops: new Set(),
		checkedUsers: new Set(),
		checkedNotallUsers: new Set(),
		openedBranches: new Set(),
		branchSelectedCount: new Map(),
	});

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	/**
	 * Обновляет данные формы по принципу setState
	 */
	const checkErrorsAndUpdateFormData = (formData: FormData) => {
		const { title, description, executionPeriodDays } = formData.fields;
		const { checkedShops } = checkedSubordinatesInfo;
		// фиксируем изменение полей
		title.hasChanges = title.defaultValue !== title.value;
		description.hasChanges = description.defaultValue !== description.value;
		executionPeriodDays.hasChanges = executionPeriodDays.defaultValue !== executionPeriodDays.value;
		formData.hasChanges = title.hasChanges || description.hasChanges || executionPeriodDays.hasChanges
			|| checkedShops.size > 0;
		// было ли изменение в полях
		title.touched = title.touched || title.hasChanges;
		description.touched = description.touched || description.hasChanges;
		executionPeriodDays.touched = executionPeriodDays.touched || executionPeriodDays.hasChanges;
		// проверяем ошибки
		title.error = validateString(TITLE_MIN_LENGTH, TITLE_MAX_LENGTH)(title.value) || undefined;
		description.error = validateString(DESCRIPTION_MIN_LENGTH, DESCRIPTION_MAX_LENGTH)(description.value) || undefined;
		executionPeriodDays.error = validateNumber(PERIOD_MIN, PERIOD_MAX)(executionPeriodDays.value) || undefined;
		formData.hasError = !!title.error || !!description.error || !!executionPeriodDays.error
			|| !checkedShops.size;
		setFormData({ ...formData }); // меняем ссылку на объект
	};

	/**
	 * Таймер с отложенным обновлением стейта нужен для того, чтобы форма не рендерилась при каждом
	 * изменении формы
	 */
	const updateChanges = (fieldValue: FormDataFieldValue) => {
		clearTimeout(updateTimerId);
		Object.keys(fieldValue).forEach(key => {
			formData.fields[key].value = fieldValue[key];
		});
		if (!formData.waitChanges) {
			formData.waitChanges = true;
			checkErrorsAndUpdateFormData(formData);
		}
		updateTimerId = setTimeout(() => {
			formData.waitChanges = false;
			checkErrorsAndUpdateFormData(formData);
		}, 500);
	};

	/**
	 * Меняет состояние для выбранных подчиненных магазинов
	 */
	const changeShopSelection = (checkedSubordinates: CheckedSubordinatesValue) => {
		setCheckedSubordinates({ ...checkedSubordinates }); // обновляем ссылку на объект
		checkErrorsAndUpdateFormData(formData);
	};

	/**
	 * Сохраняет изменения и выполняет возврат назад по истории
	 */
	const createAndGoBack = () => {
		setSaving(true);
		fetcher.createOne({
			title: formData.fields.title.value,
			description: formData.fields.description.value,
			executionPeriodDays: formData.fields.executionPeriodDays.value,
			subordinateShopIds: Array.from(checkedSubordinatesInfo.checkedShops),
		})
			.then(() => props.onCreatedSuccess())
			.finally(() => setSaving(false));
	};

	const isSaveDisabled = formData.waitChanges || formData.hasError || !formData.hasChanges;
	const disabledCause = !formData.hasChanges ? 'Нужно заполнить все данные'
		: !!formData.fields.title.error ? `Название задачи: ${formData.fields.title.error}`
			: !!formData.fields.description.error ? `Описание задачи: ${formData.fields.description.error}`
				: !!formData.fields.executionPeriodDays.error ? `Срок: ${formData.fields.executionPeriodDays.error}`
					: !checkedSubordinatesInfo.checkedShops.size ? 'Нужно выбрать получателей'
						: undefined;

	const checkedSubTabTitle = checkedSubordinatesInfo.checkedShops.size
		? `Получатели (${checkedSubordinatesInfo.checkedShops.size})`
		: 'Получатели';

	return <>
		<UiTabSwitch tabs={['Описание задачи', checkedSubTabTitle]}
		             selectedIndex={tabIndex}
		             onChange={index => setTabIndex(index)} />
		{disabledCause ? <CauseStyled>{disabledCause}</CauseStyled> : null}
		{tabIndex === 0 ? renderForm(formData, updateChanges) : null}
		{tabIndex === 1 ? (
			<AddresseeSelector
				subordinates={props.subordinates}
				treeInfo={checkedSubordinatesInfo}
				onChange={changeShopSelection}
			/>
		) : null}
		{isSaving ? <Spinner onpage text="Сохранение..." /> : null}
		<UiOverMenu>
			<UiOverMenu.AcceptButton title="Сохранить"
			                         onClick={createAndGoBack}
			                         disabled={isSaveDisabled} />
		</UiOverMenu>
	</>;
}

let updateTimerId; // таймер для отложенного обновления данных формы

const renderForm = (formData: FormData, onChange: Function) => {
	const { title, description, executionPeriodDays } = formData.fields;
	return (
		<>
			<UiInputBadge title="Название задачи" type="text" maxLength={TITLE_MAX_LENGTH}
			              placeholder="Введите название"
			              defaultValue={title.value}
			              onChange={e => onChange({ title: e.target.value })}
			              error={!!title.error && title.touched} />
			<UiTextareaBadge title="Описание задачи" maxLength={DESCRIPTION_MAX_LENGTH}
			                 placeholder="Опишите задачу"
			                 defaultValue={description.value}
			                 onChange={e => onChange({ description: e.target.value })}
			                 error={description.touched && !!description.error} />
			<UiInputBadge title="Срок на выполнение" type="number" min={PERIOD_MIN}
			              max={PERIOD_MAX}
			              defaultValue={executionPeriodDays.value}
			              onChange={e => onChange({ executionPeriodDays: Number(e.target.value) })}
			              error={!!executionPeriodDays.error && executionPeriodDays.touched} />
		</>
	);
}
