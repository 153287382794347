import { z } from 'zod';
import { TaskPhotoItem, TaskPhotoItemOptions, taskPhotoItemSchema } from '../composite';

export const taskExecRequestDtoSchema = z.object({
	executionComment: z.string().optional(),
	executionPhotos: z.array(taskPhotoItemSchema).optional(),
});

export interface TaskExecRequestDtoOptions {
	executionComment?: string;
	executionPhotos?: (TaskPhotoItem | TaskPhotoItemOptions)[];
}

export class TaskExecRequestDto {
	readonly executionComment?: string;
	readonly executionPhotos?: TaskPhotoItem[];

	constructor(options: TaskExecRequestDtoOptions) {
		if (options.executionComment !== undefined) this.executionComment = options.executionComment;
		if (options.executionPhotos?.length) {
			this.executionPhotos = [];
			for (const item of options.executionPhotos) {
				if (item instanceof TaskPhotoItem) {
					this.executionPhotos.push(item);
				} else {
					this.executionPhotos.push(new TaskPhotoItem(item));
				}
			}
		}
		taskExecRequestDtoSchema.parse(this);
	}
}