import { z } from 'zod';
import { PageInfoDto, pageInfoDtoSchema } from '../meta';

export const baseResponseDtoSchema = z.object({
	ok: z.boolean(),
	timestamp: z.number().int(),
	pageInfo: pageInfoDtoSchema.optional(),
	error: z.string().optional(),
	errorCode: z.string().optional(),
});

export interface BaseResponseDtoOptions {
	ok: boolean;
	timestamp: number;
	pageInfo?: PageInfoDto;
	error?: string;
	error_code?: string;
}

export abstract class BaseResponseDto {
	readonly ok: boolean;
	readonly timestamp: number;
	readonly pageInfo?: PageInfoDto;
	readonly error?: string;
	readonly errorCode?: string;

	constructor(options: BaseResponseDtoOptions) {
		this.ok = options.ok;
		this.timestamp = options.timestamp;
		if (options.pageInfo !== undefined) this.pageInfo = options.pageInfo;
		if (options.error !== undefined) this.error = options.error;
		if (options.error_code !== undefined) this.errorCode = options.error_code;
		baseResponseDtoSchema.parse(this);
	}
}