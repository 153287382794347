import { z } from 'zod';

export const questionnaireTestingAnswerItemSchema = z.object({
	answerIndex: z.number().int(),
	answerValue: z.string()
});

export interface QuestionnaireTestingAnswerItemOptions {
	answerIndex: number;
	answerValue: string;
}

export class QuestionnaireTestingAnswerItem {
	readonly answerIndex: number;
	readonly answerValue: string;

	constructor(options: QuestionnaireTestingAnswerItemOptions) {
		this.answerIndex = options.answerIndex;
		this.answerValue = options.answerValue;
		questionnaireTestingAnswerItemSchema.parse(this);
	}
}