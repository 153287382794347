import { z } from 'zod';
import { QuestionnaireExecutionStatusEnum } from 'common-lib';

export const questionnaireExecDataItemSchema = z.object({
	shopId: z.number().int(),
	status: z.nativeEnum(QuestionnaireExecutionStatusEnum),
	respondingResult: z.number().int().nullable().optional(),
	quizResultHashCode: z.string(),
});

export interface QuestionnaireExecDataItemOptions {
	shopId: number;
	status: QuestionnaireExecutionStatusEnum;
	respondingResult?: number | null;
	quizResultHashCode: string;
}

export class QuestionnaireExecDataItem {
	readonly shopId: number;
	readonly status: QuestionnaireExecutionStatusEnum;
	readonly respondingResult?: number | null;
	readonly quizResultHashCode: string;

	constructor(options: QuestionnaireExecDataItemOptions) {
		this.shopId = options.shopId;
		this.status = options.status;
		if (options.respondingResult !== undefined) this.respondingResult = options.respondingResult;
		this.quizResultHashCode = options.quizResultHashCode;
		questionnaireExecDataItemSchema.parse(this);
	}

}