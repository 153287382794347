import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { PageInfo } from 'common-lib';
import { get, set } from '~tools/localConfig';
import DiagnosticsFileGalleryFetcher from '~api/DiagnosticsFileGalleryFetcher';
import { Pagination, Spinner } from '~components';
import { DiagnosticsFileGalleryListResponseDto } from 'protocol-lib';

const GalleryStyle = styled.div`
	display: grid;
	grid-template-columns: repeat(${o => o.cols || 5}, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(100px, auto);
	margin: 1rem 0;
`;

const ItemStyle = styled.div`
	position: relative;
	border: 1px solid #ccc;
	border-radius: 2px;

	&:hover {
		box-shadow: 0 1px 3px 0 rgba(50, 50, 0, .3);
	}
`;

const ItemImageStyle = styled.img`
	display: block;
	max-width: 100%;
	max-height: 100%;
`;

const FileInfoStyle = styled.div`
	padding: 5px;

	.index {
		color: gray;
		font-size: 0.8em;
	}

	.size {
		color: gray;
		font-size: 0.8em;
	}
`;

const ImageInfoStyle = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	max-width: 100%;
	max-height: 100%;

	div {
		padding: 0 2px;
		font-size: 0.8em;
		background: black;
		color: white;
	}
`;

const PAGE_SIZE_OPTIONS = [24, 40, 65, 90, 126, 160, 189, 230].map(size => ({
	key: String(size),
	text: String(size),
	value: size,
}));

const FILTER_FILE_TYPE_ALL = 'all';

const FILTER_FILE_TYPES = [{ key: 'all', value: FILTER_FILE_TYPE_ALL, text: 'Все' }]
	.concat(
		['zip', 'csv', 'html', 'xlsx', 'png'].map(ext => ({
			key: ext,
			text: ext,
			value: ext,
		})),
	);

export const DFG_PAGE_INFO = 'DIAGNOSTICS.FILE_GALLERY.PAGE_INFO';

const dfgPageInfo = get(DFG_PAGE_INFO, '');

const fetcher = new DiagnosticsFileGalleryFetcher();

export default function AdminDiagnosticsFileGalleryPage() {
	const [{ items, pageInfo, checkQueryString, isItemsRequesting }, setItems] = useState<any>({});

	const navigate = useNavigate();
	const location = useLocation();
	const curPageInfo = PageInfo.parseFromString(String(location.search || dfgPageInfo),
		{ pageSize: 40, meta: 'fileTypes' });

	if (items === undefined || curPageInfo.toQueryString() !== checkQueryString) {
		if (!isItemsRequesting) {
			setItems({ items, pageInfo, checkQueryString, isItemsRequesting: true });
			fetcher.getList(curPageInfo).then((json: DiagnosticsFileGalleryListResponseDto) => {
				setItems({
					items: fillItems(json),
					pageInfo: json.pageInfo,
					checkQueryString: curPageInfo.toQueryString(),
				});
			});
		}
		return <Spinner />;
	}

	const updatePageInfo = obj => {
		curPageInfo.update(obj);
		const search = curPageInfo.toQueryString();
		set(DFG_PAGE_INFO, search);
		navigate(`${location.pathname}?${search}`);
	};

	const updatePageInfoFileType = fileType => {
		if(fileType === FILTER_FILE_TYPE_ALL) {
			delete curPageInfo.customParams?.fileType;
		} else if (curPageInfo.customParams) {
			curPageInfo.customParams.fileType = fileType;
		}
		const search = curPageInfo.toQueryString();
		set(DFG_PAGE_INFO, search);
		navigate(`${location.pathname}?${search}`);
	};

	// 24, 40, 65, 90, 126, 160, 189, 230
	const cols = pageInfo.pageSize <= 24 ? 3
		: pageInfo.pageSize <= 40 ? 4
			: pageInfo.pageSize <= 65 ? 5
				: pageInfo.pageSize <= 90 ? 6
					: pageInfo.pageSize <= 126 ? 7
						: pageInfo.pageSize <= 160 ? 8
							: pageInfo.pageSize <= 189 ? 9 : 10;

	const imageSize = cols < 5 ? 'middle' : 'small';

	return (
		<>
			<h1>
				<Link to="/admin/diagnostics">
					<Icon name="arrow left" size="small" />
				</Link>
				Галерея файлов
			</h1>
			<Dropdown inline size="small" options={FILTER_FILE_TYPES}
			          defaultValue={curPageInfo.customParams?.fileType || FILTER_FILE_TYPE_ALL}
			          onChange={(_, { value: fileType }) => updatePageInfoFileType(fileType)} />
			<div>Всего: {pageInfo.allCount} файлов, {pageInfo.pageCount} страниц</div>
			<GalleryStyle cols={cols}>
				{items.map(i => renderItem(i, imageSize))}
			</GalleryStyle>
			<Pagination pageInfo={pageInfo}
			            onChangeIndex={pageIndex => updatePageInfo({ pageIndex })}
			            sizeOptions={PAGE_SIZE_OPTIONS}
			            onChangeSize={pageSize => updatePageInfo({ pageSize })} />
		</>
	);
}

function fillItems(json) {
	const { data, meta: { fileTypeMap = undefined } = {} } = json;
	data.forEach(item => {
		item.fileType = fileTypeMap[item.fileTypeId];
		delete item.fileTypeId;
	});
	return data;
}

function renderItem(item, imageSize) {
	const { id, name, size, fileType: { ext } } = item;
	return (
		<ItemStyle key={id}>
			{ext === 'png' ? (
				<>
					<ItemImageStyle src={`/api/file/download/${id}?size=${imageSize}`} alt="" />
					<ImageInfoStyle>
						<div>#{id}</div>
					</ImageInfoStyle>
				</>
			) : (
				<FileInfoStyle>
					<div className="index">#{id}</div>
					<div>{name}</div>
					<div className="size">{size} b</div>
				</FileInfoStyle>
			)}
		</ItemStyle>
	);
}
