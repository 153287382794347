import { validateString } from '../utils';
import { convertDtoToIncidentType, convertIncidentTypeToDto } from './converters';

type IncidentTypeData = {
	separator: string
	description?: string
	enableChangeAfterSave: boolean
	inputData: IncidentTypeInputDataValue[]
	cardTemplate: IncidentTypeCardTemplateValue[]
	solutionData: IncidentTypeSolutionDataValue[]
	outputData: IncidentTypeOutputDataValue[]
}

export enum IncidentTypeValueFormat {
	STRING = 'STRING',
	FLOOR = 'FLOOR',
	DOUBLE = 'DOUBLE',
	HUMAN_DATE = 'HUMAN_DATE',
}

export type IncidentTypeInputDataValue = IncidentTypeInputDataValue1 | IncidentTypeInputDataValue2;

export type IncidentTypeSolutionDataValue =
	IncidentTypeSolutionDataRadioOptionsValue
	| IncidentTypeSolutionDataCentralPhotoValue
	| IncidentTypeSolutionDataDoubleValue;

// тип поля входа
export type IncidentTypeInputDataValue1 = {
	headColumnText: string
	format: IncidentTypeValueFormat
	id: string
	isRequired: boolean // (default: false)
}

// тип поля входа когда в одной строке несколько значений через разделитель
export type IncidentTypeInputDataValue2 = {
	headColumnText: string
	format: 'SPLIT'
	delimiter: string
	ids: IncidentTypeInputDataValue2Id[]
	isRequired: boolean // (default: false)
}

export type IncidentTypeInputDataValue2Id = {
	index: number
	format: IncidentTypeValueFormat
	id: string
}

// тип поля в шаблоне отображения на странице
export type IncidentTypeCardTemplateValue = {
	type: 'VALUE'
	title: string
	from: {
		block: 'inputData' // пока ссылки можно использовать только из блока входа
		field: string
	}
	format: IncidentTypeValueFormat
	decimalSize: number // количество знаков после запятой (default: 0)
	devOnly: boolean // выводится только для разработки (default: false)
}

// тип поля ответа - выбор одной опции
export type IncidentTypeSolutionDataRadioOptionsValue = {
	id: string
	type: 'RADIO_OPTIONS'
	options: {
		value: string
		title: string
		subTitle?: string
		reportValue: string
	}[]
	isRequired: boolean // (default: false)
}

// тип поля ответа - фотография
// если указано requiredFormula, значит условие требования зависит от других полей
export type IncidentTypeSolutionDataCentralPhotoValue = {
	id: string
	type: 'CENTRAL_PHOTO'
	isRequired?: boolean // (default: false)
	requiredFormula?: IncidentTypeSolutionDataRequiredFormula[]
}

// тип поля ответа - вещественное число
// если указано requiredFormula, значит условие требования зависит от других полей
export type IncidentTypeSolutionDataDoubleValue = {
	id: string
	type: 'DOUBLE'
	isRequired?: boolean // (default: false)
	requiredFormula?: IncidentTypeSolutionDataRequiredFormula[]
	visibleFormula?: IncidentTypeSolutionDataVisibleFormula
}

export type IncidentTypeSolutionDataRequiredFormula = {
	isRequired: boolean
	key: string
	value: string
}

export type IncidentTypeSolutionDataVisibleFormula = {
	key: string
	value: string
}

// шаблон результата в отчета
export type IncidentTypeOutputDataValue = {
	id: string
	title: string
	from: {
		block: 'inputData' | 'report'
		field: string
	}
	valueType?: 'PHOTO_EXISTS' // какой формат ответа
}

export type IncidentTypeErrors = {
	hasError: boolean
	name: string | false
	type: string | false
	dataSeparator: string | false
	inputDataEmpty: boolean
	cardTemplateEmpty: boolean
	solutionDataEmpty: boolean
	outputDataEmpty: boolean
}

// интерфейс для передачи DTO
export interface IncidentTypeDto {
	id?: number
	accountId?: number
	version?: number
	name: string
	type: string
	data: IncidentTypeData
}

// класс модели
export class IncidentType implements IncidentTypeDto
{
	id?: number
	accountId?: number
	version?: number
	name: string
	type: string
	data: IncidentTypeData

	constructor() {
		this.name = '';
		this.type = '';
		this.data = {
			separator: '',
			enableChangeAfterSave: true,
			inputData: [],
			cardTemplate: [],
			solutionData: [],
			outputData: [],
		};
	}

	// проверяет наличие ошибок
	validate(): IncidentTypeErrors {
		const errors: IncidentTypeErrors = {
			hasError: false, // будет перезаписано
			name: validateString(1, 100)(this.name) || false,
			type: validateString(1, 50)(this.type) || false,
			dataSeparator: validateString(1, 10)(this.data.separator) || false,
			inputDataEmpty: this.data.inputData.length === 0,
			cardTemplateEmpty: this.data.cardTemplate.length === 0,
			solutionDataEmpty: this.data.solutionData.length === 0,
			outputDataEmpty: this.data.outputData.length === 0,
		}
		errors.hasError = Object.keys(errors)
			.some(key => (errors as any)[key] !== false);
		return errors;
	}

	static fromDto(dto: any): IncidentType {
		return convertDtoToIncidentType(dto);
	}

	toDto(): IncidentTypeDto {
		return convertIncidentTypeToDto(this);
	}
}
