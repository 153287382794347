import merge from 'deepmerge';
import { ModelConvertationError } from '../../errors';
import { IncidentTypeInputDataValue, IncidentTypeCardTemplateValue, IncidentTypeSolutionDataValue,
	IncidentTypeOutputDataValue, IncidentTypeDto, IncidentTypeValueFormat, IncidentType } from '../IncidentType';

/**
 * Конвертирует объект в модель.
 *
 * Может выкинуть ошибку ModelConvertationError.
 *
 * @param dto
 * @throws ModelConvertationError
 */
export function convertDtoToIncidentType(dto: any): IncidentType {
	const item = new IncidentType();
	item.id = Number(dto.id) || undefined;
	item.accountId = Number(dto.accountId) || undefined;
	item.version = Number(dto.version) || undefined;
	item.name = String(dto.name);
	item.type = String(dto.type);
	try {
		const data: any = JSON.parse(dto.data);
		const inputData: IncidentTypeInputDataValue[] = convertInputDataFromData(data);
		const cardTemplate: IncidentTypeCardTemplateValue[] = convertCardTemplateFromData(data);
		const solutionData: IncidentTypeSolutionDataValue[] = convertSolutionDataFromData(data);
		const outputData: IncidentTypeOutputDataValue[] = convertOutputDataFromData(data);
		item.data = {
			separator: String(data.separator),
			enableChangeAfterSave: Boolean(data.enableChangeAfterSave),
			inputData, cardTemplate, solutionData, outputData,
		};
		return item;
	} catch (error) {
		throw new ModelConvertationError('[Ошибка конвертации DTO в IncidentType] Ошибка парсинга поля data: ' + String(error));
	}
}

export function convertIncidentTypeToDto(incidentType: IncidentType): IncidentTypeDto {
	return merge({}, incidentType);
}

function convertInputDataFromData(data: any): IncidentTypeInputDataValue[] {
	const { inputData } = data;
	const res: IncidentTypeInputDataValue[] = [];
	inputData?.forEach((item: any) => {
		try {
			if (item.format === 'SPLIT') {
				res.push({
					headColumnText: item.headColumnText,
					format: 'SPLIT',
					delimiter: item.delimiter,
					ids: item.ids.map((idData: any) => {
						if (!(IncidentTypeValueFormat as any)[idData.format]) {
							throw new Error(`Значение в поле data.inputData.ids.format "${idData.format}" не соответствует типу IncidentTypeValueFormat`);
						}
						return {
							index: Number(idData.index),
							format: idData.format,
							id: idData.id,
						}
					}),
					isRequired: item.isRequired === true,
				});
			} else {
				if (!(IncidentTypeValueFormat as any)[item.format]) {
					throw new Error(`Значение в поле inputData.format "${item.format}" не соответствует типу IncidentTypeValueFormat`);
				}
				res.push({
					headColumnText: item.headColumnText,
					format: item.format,
					id: item.id,
					isRequired: item.isRequired === true,
				});
			}
		} catch (error) {
			throw new Error('Ошибка конвертации data.inputData: ' + String(error));
		}
	});
	return res;
}

function convertCardTemplateFromData(data: any): IncidentTypeCardTemplateValue[] {
	const { cardTemplate } = data;
	const res: IncidentTypeCardTemplateValue[] = [];
	cardTemplate?.forEach((item: any) => {
		try {
			if (!(IncidentTypeValueFormat as any)[item.format]) {
				throw new Error(`Значение в поле cardTemplate.format "${item.format}" не соответствует типу IncidentTypeValueFormat`);
			}
			const [, field] = item.from.split('.');
			res.push({
				type: 'VALUE',
				title: String(item.title).trim(),
				from: {
					block: 'inputData',
					field,
				},
				format: item.format,
				decimalSize: Number(item.decimalSize) || 0,
				devOnly: item.devOnly === true,
			});
		} catch (error) {
			throw new Error('Ошибка конвертации data.cardTemplate: ' + String(error));
		}
	});
	return res;
}

function convertSolutionDataFromData(data: any): IncidentTypeSolutionDataValue[] {
	const { solutionData } = data;
	const res: IncidentTypeSolutionDataValue[] = [];
	solutionData?.forEach((item: any) => {
		try {
			if (item.type === 'RADIO_OPTIONS') {
				res.push({
					id: item.id,
					type: 'RADIO_OPTIONS',
					isRequired: item.isRequired === true,
					options: item.options.map((option: any) => ({
						value: option.value,
						title: option.title,
						subTitle: option.subTitle || undefined,
						reportValue: option.reportValue,
					})),
				});
			} else if (item.type === 'CENTRAL_PHOTO') {
				res.push({
					id: item.id,
					type: 'CENTRAL_PHOTO',
					isRequired: !item.requiredFormula ? item.isRequired === true : undefined,
					requiredFormula: item.requiredFormula ? item.requiredFormula.map((formula: any) => ({
						isRequired: formula.isRequired === true,
						key: formula.key,
						value: formula.value,
					})) : undefined,
				});
			} else if (item.type === 'DOUBLE') {
				res.push({
					id: item.id,
					type: 'DOUBLE',
					isRequired: !item.requiredFormula ? item.isRequired === true : undefined,
					requiredFormula: item.requiredFormula ? item.requiredFormula.map((formula: any) => ({
						isRequired: formula.isRequired === true,
						key: formula.key,
						value: formula.value,
					})) : undefined,
					visibleFormula: item.visibleFormula ? item.visibleFormula.map((formula: any) => ({
						key: formula.key,
						value: formula.value,
					})) : undefined,
				});
			} else {
				throw new Error(`Значение в поле solutionData.type '${item.type}' должно быть 'RADIO_OPTIONS' или 'CENTRAL_PHOTO'`);
			}
		} catch (error) {
			throw new Error('Ошибка конвертации data.cardTemplate: ' + String(error));
		}
	});
	return res;
}

function convertOutputDataFromData(data: any): IncidentTypeOutputDataValue[] {
	const { outputData } = data;
	const res: IncidentTypeOutputDataValue[] = [];
	outputData?.forEach((item: any) => {
		try {
			const [block, field] = item.from.split('.');
			res.push({
				id: item.id,
				title: item.title,
				from: { block, field },
				valueType: item.valueType === 'PHOTO_EXISTS' ? 'PHOTO_EXISTS' : undefined,
			})
		} catch (error) {
			throw new Error('Ошибка конвертации data.outputData: ' + String(error));
		}
	});
	return res;
}
