import { TodoItemStatusEnum, todoItemStatusTitleToEnum, todoItemStatusToWeight } from 'common-lib';
import { TodoDataItem } from 'protocol-lib';

type TodoItem = {
	id: number
	externalId: string
	title: string
	description: string
	status?: TodoItemStatusEnum
	statusText: string
	statusWeight: number
	createdAt: Date
	updatedAt: Date
};

export function fillTodoListFromJson(data: TodoDataItem[]): TodoItem[] {
	return data.map(i => {
		const status = todoItemStatusTitleToEnum(i.status);
		return {
			id: i.id,
			externalId: i.externalId,
			title: i.title,
			description: i.description,
			status,
			statusText: i.status,
			statusWeight: todoItemStatusToWeight(status),
			createdAt: i.createdAt,
			updatedAt: i.updatedAt,
		};
	})
		.sort((a, b) => {
			const k = a.statusWeight - b.statusWeight;
			return (k !== 0) ? k : a.updatedAt.getTime() - b.updatedAt.getTime();
		});
}
