import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';

type DataPageInfo = {
	pageIndex: number
	pageSize: number
	pageCount: number
}

type SizeOption = {
	key: string | number
	text: string
	value: string | number
}

type Props = {
	pageInfo: DataPageInfo
	onChangeIndex: Function
	sizeOptions?: SizeOption[]
	onChangeSize?: Function
}

export default function Pagination(props: Props) {
	const { pageIndex, pageSize, pageCount } = props.pageInfo;
	const lIndex = Math.min(Math.max(0, pageIndex - 3), pageCount - 1);
	const rIndex = Math.min(pageCount - 1, pageIndex + 3);
	const midCount = rIndex - lIndex;
	return (
		<>
			{props.sizeOptions ? (
				<Dropdown
					button
					className="icon"
					labeled
					options={props.sizeOptions}
					defaultValue={pageSize}
					onChange={(_, { value }) => props.onChangeSize?.(value)}
				/>
			) : null}
			<Button.Group>
				{lIndex > 0 &&
                <Button content="1" onClick={() => props.onChangeIndex(0)} />}
				{lIndex > 1 && <Button disabled content="..." />}
				{midCount && Array(rIndex - lIndex + 1).fill(0)
					.map((_, i) => {
						const index = i + lIndex;
						return (
							<Button
								key={index}
								content={index + 1}
								active={index === pageIndex}
								onClick={index !== pageIndex ? () => props.onChangeIndex(index) : undefined}
							/>
						);
					})}
				{rIndex < pageCount - 2 && <Button disabled content="..." />}
				{rIndex < pageCount - 1 && (
					<Button content={pageCount}
					        onClick={() => props.onChangeIndex(pageCount - 1)} />
				)}
			</Button.Group>
		</>
	);
}
