import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import appStore from '~store';
import { AppContainer, ErrorContainer } from './index';
import routes from '../routes/appRoutes';

function App() {
	return (
		<Provider store={appStore}>
			<BrowserRouter basename="/">
				<ErrorContainer />
				<AppContainer routes={routes} />
			</BrowserRouter>
		</Provider>
	);
}

export default App;
