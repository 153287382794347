export enum QuestionnaireExecutionStatusEnum {
	IDLE = 'IDLE',
	SCHEDULED = 'SCHEDULED',
	RUNNING = 'RUNNING',
	STOPPED = 'STOPPED',
	REPORTED = 'REPORTED',
	FINISHED = 'FINISHED',
	CANCELED = 'CANCELED',
}

export const QuestionnaireExecutionStatusTitle = {
	[QuestionnaireExecutionStatusEnum.IDLE]: 'Ожидает',
	[QuestionnaireExecutionStatusEnum.SCHEDULED]: 'Запланирован',
	[QuestionnaireExecutionStatusEnum.RUNNING]: 'Сейчас идет',
	[QuestionnaireExecutionStatusEnum.STOPPED]: 'Остановлен',
	[QuestionnaireExecutionStatusEnum.REPORTED]: 'Сформирован',
	[QuestionnaireExecutionStatusEnum.FINISHED]: 'Завершен',
	[QuestionnaireExecutionStatusEnum.CANCELED]: 'Отменён',
};
