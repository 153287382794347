import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Icon, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { FlexGrid } from '~components';

const ClickableSegmentContainer = styled.div`
  .ui.segment {
    cursor: pointer;
    display: block;
    text-align: center;

    &:hover {
      background: #eff5ff;
    }
  }
`;

export default function DashboardPage() {
	return (
		<ClickableSegmentContainer>
			<FlexGrid width="400">
				<FlexGrid.Item>
					<Segment as={Link} to="/admin/dashboard/incident/import">
						<Header icon>
							<Icon name="list layout" />
							Загрузка инцидентов
						</Header>
					</Segment>
				</FlexGrid.Item>
				<FlexGrid.Item>
					<Segment as={Link} to="/admin/dashboard/structure/import">
						<Header icon>
							<Icon name="users" />
							Загрузка структуры
						</Header>
					</Segment>
				</FlexGrid.Item>
			</FlexGrid>
		</ClickableSegmentContainer>
	);
}
