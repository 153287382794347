import { z } from 'zod';

export const todoDataItemSchema = z.object({
	id: z.number().int(),
	externalId: z.string(),
	title: z.string(),
	description: z.string(),
	status: z.string(),
	statusText: z.string(),
	statusWeight: z.number().int(),
	createdAt: z.instanceof(Date),
	updatedAt: z.instanceof(Date),
});

export interface TodoDataItemOptions {
	id: number;
	externalId: string;
	title: string;
	description: string;
	status: string;
	statusText: string;
	statusWeight: number;
	createdAt: Date | string;
	updatedAt: Date | string;
}

export class TodoDataItem {
	readonly id: number;
	readonly externalId: string;
	readonly title: string;
	readonly description: string;
	readonly status: string;
	readonly statusText: string;
	readonly statusWeight: number;
	readonly createdAt: Date;
	readonly updatedAt: Date;

	constructor(options: TodoDataItemOptions) {
		this.id = options.id;
		this.externalId = options.externalId;
		this.title = options.title;
		this.description = options.description;
		this.status = options.status;
		this.statusText = options.statusText;
		this.statusWeight = options.statusWeight;
		if (options.createdAt instanceof Date) {
			this.createdAt = options.createdAt;
		} else {
			this.createdAt = new Date(options.createdAt);
		}
		if (options.updatedAt instanceof Date) {
			this.updatedAt = options.updatedAt;
		} else {
			this.updatedAt = new Date(options.updatedAt);
		}
		todoDataItemSchema.parse(this);
	}
}