import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Dropdown, Icon, Menu, Popup } from 'semantic-ui-react';
import { UserRoles, isProdServer, isReleaseServer, isTestServer } from 'common-lib';
import { MenuItemLabel } from '~components';
import { logout } from '~store/actions/loginActions';

const dashboardPageRegex = /^\/dash/;
const incidentImportPageRegex = /^\/admin\/incidents/;
const shopsPageRegex = /^\/admin\/shops/;
const staffPageRegex = /^\/admin\/users/;
const infoMessagesPageRegex = /^\/admin\/infomsg/;
const quizPageRegex = /^\/admin\/quiz/;
const featureConfigurationPageRegex = /^\/admin\/configuration/;
const diagnosticsPageRegex = /^\/admin\/diagnostics/;

export default function AdminMenu() {
	const { pathname } = useLocation();
	const { fullName, role } = useSelector((state: any) => state.me.currentUser);

	const isOnAnyDashboard = dashboardPageRegex.test(pathname) || pathname === '/';
	const isOnIncidentsImport = incidentImportPageRegex.test(pathname);
	const isOnShops = shopsPageRegex.test(pathname);
	const isOnStaff = staffPageRegex.test(pathname);
	const isOnInfoMsg = infoMessagesPageRegex.test(pathname);
	const isOnQuiz = quizPageRegex.test(pathname);
	const isOnFeatureConfiguration = featureConfigurationPageRegex.test(pathname);
	const isOnDiagnostics = diagnosticsPageRegex.test(pathname);
	const isAdmin = role === UserRoles.ADMIN;

	return (
		<>
			<Menu id="admin-main-menu" inverted borderless fixed="top">
				<Menu.Item active={isOnAnyDashboard} as={Link} to="/">
					<Icon name="dashboard" />
					<MenuItemLabel>Дашборд</MenuItemLabel>
				</Menu.Item>
				{isAdmin ? (
					<Menu.Item active={isOnIncidentsImport} as={Link} to="/admin/incidents">
						<Icon name="th list" />
						<MenuItemLabel>Инциденты</MenuItemLabel>
					</Menu.Item>
				) : null}
				<Menu.Item active={isOnShops} as={Link} to="/admin/shops">
					<Icon name="warehouse" />
					<MenuItemLabel>Магазины</MenuItemLabel>
				</Menu.Item>
				<Menu.Item active={isOnStaff} as={Link} to="/admin/users">
					<Icon name="users" />
					<MenuItemLabel>Пользователи</MenuItemLabel>
				</Menu.Item>
				<Menu.Item active={isOnInfoMsg} as={Link} to="/admin/infomsg">
					<Icon name="envelope" />
					<MenuItemLabel>Сообщения</MenuItemLabel>
				</Menu.Item>
				<Menu.Item active={isOnQuiz} as={Link} to="/admin/quiz">
					<Icon name="edit" />
					<MenuItemLabel>Опросы и тесты</MenuItemLabel>
				</Menu.Item>
				{isAdmin && !isProdServer && !isReleaseServer && !isTestServer ? (
					<Menu.Item active={isOnFeatureConfiguration}
					           as={Link}
					           to="/admin/configuration">
						<Icon name="setting" />
						<MenuItemLabel>Конфигурации</MenuItemLabel>
					</Menu.Item>
				) : null}
				{isAdmin && !isProdServer && !isReleaseServer ? (
					<Menu.Item active={isOnDiagnostics} as={Link} to="/admin/diagnostics">
						<Icon name="setting" />
						<MenuItemLabel>Диагностика</MenuItemLabel>
					</Menu.Item>
				) : null}
				<Popup
					content={`Вы вошли как ${fullName}`}
					position="bottom right"
					trigger={(
						<Menu.Item position="right" onClick={handleLogout}>
							Выход
						</Menu.Item>
					)}
				/>
			</Menu>
			{!isProdServer ? (
				<div style={{
					position: 'fixed',
					right: '2px',
					bottom: '2px',
					zIndex: 9999,
					opacity: 0.5,
					fontSize: '0.6rem',
					background: 'rgba(255, 255, 255, 0.8)',
				}}>
					{location.hostname}&nbsp;
					<Button icon="refresh" size="tiny"
					        onClick={() => document.location.reload()} />
				</div>
			) : null}
		</>
	);
}

function handleLogout() {
	logout()
		.then(({ redirectUrl }) => {
			// сервер может попросить перейти по другому адресу
			window.location.replace(redirectUrl || '/');
		})
		.catch(err => {
			console.error('[Ошибка разлогина]', err);
			window.location.replace('/');
		});
}
