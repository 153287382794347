import { get, set } from '~tools/localConfig';
import ClientsideLokiBatcher, { LokiBatcherOptions } from './ClientsideLokiBatcher';

type LokiWinstonTransportOptions = LokiBatcherOptions & {
	// минимальный уровень отправки логов
	minLevel: string // error|warn|info|http|verbose|debug|silly
	labels?: any
};

const LEVEL_INDEX = {
	error: 1,
	warn: 2,
	info: 3,
	http: 4,
	verbose: 5,
	debug: 6,
	silly: 7,
}

// когда клиент стартанул приложение
const CLIENT_STARTED = new Date();
// уникальный "вход" клиента
// берем из хранилища или создаем новый
const CLIENT_TRACE_ID = get('clientTraceId', Math.random().toString(36).substr(2, 7));
set('clientTraceId', CLIENT_TRACE_ID);
// когда последний раз была отправлена информация о клиенте
const CLIENT_LAST_SENT = Number(get('clientSend'));

let clientInfoSent = false;

export default class ClientsideLokiTransport
{
	private readonly batcher: ClientsideLokiBatcher;
	private readonly labels: any;
	private readonly minLevel?: number;

	/**
	 * Creates an instance of ClientsideLokiTransport.
	 * @param {*} options
	 * @memberof ClientsideLokiTransport
	 */
	constructor(options: LokiWinstonTransportOptions) {
		// Pass all the given options to batcher
		this.batcher = new ClientsideLokiBatcher({
			basicAuth: options.basicAuth,
			batchingInterval: options.batchingInterval,
			clearOnError: options.clearOnError,
			replaceTimestamp: options.replaceTimestamp,
		});

		this.labels = options.labels;
		this.labels.clientTraceId = CLIENT_TRACE_ID;
		this.minLevel = LEVEL_INDEX[options.minLevel || ''];

		// сначала проверяем, нужно ли отправить данные о клиенте
		this.sendClientInfo();
	}

	log = (logInfo: any) => {
		// Deconstruct the log
		const { timestamp, instance, level, message } = logInfo;

		if (this.minLevel) {
			const levelIndex = LEVEL_INDEX[level];
			// уровень ниже минимального
			if (levelIndex > this.minLevel) return;
		}

		// build custom labels if provided
		let lokiLabels: any = { level };
		if (this.labels) {
			lokiLabels = Object.assign(lokiLabels, this.labels);
		}
		lokiLabels.instance = instance;

		// Make sure all label values are strings
		lokiLabels = Object.fromEntries(
			Object.entries(lokiLabels).map(
				([key, value]: [string, any]) => [key, value ? value.toString() : value]));

		// Construct the log to fit Grafana Loki's accepted format
		const logEntry = {
			labels: lokiLabels,
			entries: [
				{
					ts: timestamp ? timestamp.getTime() : Date.now(),
					line: message,
				},
			],
		};

		// Pushes the log to the batcher
		this.batcher
			.pushLogEntry(logEntry)
			.catch(error => {
				// tslint:disable-next-line:no-console
				console.error('[Ошибка отправки логов в Loki]', error);
			});
	}

	/**
	 * Если нужно, отправляет лог с данными о клиенте.
	 */
	sendClientInfo = () => {
		if (clientInfoSent) {
			return;
		}

		// отправляем инофрмацию о клиенте не чаще чем раз в сутки
		if (CLIENT_LAST_SENT && CLIENT_LAST_SENT > Date.now() - 86400_000) {
			return;
		}

		this.log({
			timestamp: CLIENT_STARTED,
			instance: 'ClientsideLokiTransport',
			level: 'info',
			message: JSON.stringify({
				appVersion: navigator?.appVersion || undefined,
				languages: navigator?.languages || undefined,
				userAgent: navigator?.userAgent || undefined,
			}),
		});

		set('clientSend', Date.now());

		clientInfoSent = true;
	}

	/**
	 * Send batch to loki when clean up
	 */
	close = () => {
		this.batcher.close(undefined);
	}
}
