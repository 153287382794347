import { z } from 'zod';

export const incidentTypeDataItemSchema = z.object({
	id: z.number().int(),
	name: z.string(),
	type: z.string(),
});

export interface IncidentTypeDataItemOptions {
	id: number;
	name: string;
	type: string;
}

export class IncidentTypeDataItem {
	readonly id: number;
	readonly name: string;
	readonly type: string;

	constructor(options: IncidentTypeDataItemOptions) {
		this.id = options.id;
		this.name = options.name;
		this.type = options.type;
		incidentTypeDataItemSchema.parse(this);
	}
}