import React, { useState, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { PhotoShooterV1 } from './PhotoShooterV1';
import { PhotoShooterV2 } from './PhotoShooterV2';
import { FeatureNamesEnum } from 'common-lib';

/**
 * Как работает снималка фотографий.
 * 1. Пробуем получить версию фичи из конфига. По умолчанию будет вторая версия.
 * 2. PhotoShooterV2 отображает кнопку съемки, которая передается в children. Пытается получить
 * доступ к устройству и настроить стрим в <video/> тег. Если доступа к камере нет или возникли
 * другие ошибки, из-за которых приложение не может получить доступ к устройству камеры, то
 * PhotoShooterV2 вызывает метод onCannotUseDevice и компонента переключается не версию 1.
 * 3. Первая версия работает по принципу "capture=user". На девайсах при нажатии на кнопку будет
 * выполнен переход в дефолтное приложение Камеры, а для веба это будет выбор файла из проводника.
 * Для пользователя смена версии со второй на первую не будет заметна. На ios может быть задержка
 * до 500 мс во время определния доступа к устройству. Такой лаг будет проявляться каждый раз
 * при отрисовке нового PhotoShooterV2. Можно исключить этот лаг если запоминать версию в LS.
 */

type ShootMethod = 'v1' | 'v2';

export interface Props
{
	children: ReactNode;
	maxSquareSideWidth: number;
	onSave: (data: string) => void;
	onCancel: () => void;
	showAim?: true;
}

export function PhotoShooter(props: Props) {
	const meConfig: any = useSelector<any>(state => state.me);

	const [shootMethod, setShootMethod] = useState<ShootMethod>(
		meConfig.features?.includes(FeatureNamesEnum.PHOTO_SHOOT_V2) ? 'v2' : 'v1'
	);

	if (shootMethod === 'v1') {
		return <PhotoShooterV1 maxSquareSideWidth={props.maxSquareSideWidth}
		                       onSave={props.onSave}
		                       onCancel={props.onCancel}>
			{props.children}
		</PhotoShooterV1>;
	}

	return <PhotoShooterV2 maxSquareSideWidth={props.maxSquareSideWidth}
	                       onSave={props.onSave}
	                       onCancel={props.onCancel}
	                       showAim={props.showAim}
	                       onCannotUseDevice={() => setShootMethod('v1')}>
		{props.children}
	</PhotoShooterV2>;
}
