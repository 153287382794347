import { QuestionnaireExecutionStatusEnum } from 'common-lib';
import { z } from 'zod';

export const questExecToShopItemSchema = z.object({
	status: z.nativeEnum(QuestionnaireExecutionStatusEnum),
	shopId: z.number().int(),
	respondingUserId: z.number().int().nullable(),
	respondingResult: z.string().nullable()
});

export interface QuestExecToShopItemOptions {
	status: QuestionnaireExecutionStatusEnum;
	shopId: number;
	respondingUserId: number | null;
	respondingResult: string | null;
}

export class QuestExecToShopItem {
	status: QuestionnaireExecutionStatusEnum;
	shopId: number;
	respondingUserId: number | null;
	respondingResult: string | null;

	constructor(options: QuestExecToShopItemOptions) {
		this.status = options.status;
		this.shopId = options.shopId;
		this.respondingUserId = options.respondingUserId;
		this.respondingResult = options.respondingResult;
		questExecToShopItemSchema.parse(this);
	}
}