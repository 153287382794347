import util from 'util';
import ClientsideLokiTransport from './ClientsideLokiTransport';
// Levels:
// error: 0
// warn: 1
// info: 2 ==> TEST level
// http: 3 ==> PROD level
// verbose: 4
// debug: 5 ==> DEV level
// silly: 6
// Уровень лога определяется в следующем порядке:
// 1. env LOG_LEVEL
// 2. config.logLevel
// 3. Для тестового окружения => info
// 4. Для разработки => debug
const logLevel = process.env.LOG_LEVEL || '';
// Проверяем уровень лога.
const availableLogLevels = ['error', 'warn', 'info', 'http', 'verbose', 'debug', 'silly'];
if (!availableLogLevels.includes(logLevel)) {
	// tslint:disable-next-line:no-console
	console.error(`[Ошибка логгера] значение уровня лога может принимать одно значение из ${availableLogLevels}. Для указания уровня лога используется env LOG_LEVEL.\nProcess will exit.`);
}

function explodeLog(error) {
	const {
		message,
		stack,
		original: { stack: originalStack } = {} as any,
	} = error;
	const splat = error[Symbol.for('splat')];
	const { stack: splatStack, ...otherArgs } = splat?.[0] || {};
	return {
		message,
		stack,
		originalStack,
		splatStack,
		otherArgs: Object.keys(otherArgs).length ? otherArgs : undefined,
	};
}

interface LogOptions {
	level: 'debug' | 'info' | 'warn' | 'error';
	message: string;
	error?: Error | string;
}

function formatLog(options: LogOptions): string {
	const { level, message: rawMessage, error = {} } = options;
	const explodedError = explodeLog(error);
	const { message: errorMessage } = explodedError;
	const message = `${rawMessage}${errorMessage ? ` -> ${errorMessage}` : ''}`;
	if (level === 'debug') {
		return message;
	}
	const lines: string[] = [];
	const { stack, originalStack, splatStack, otherArgs } = explodedError;
	if (!stack) {
		lines.push(message);
	} else {
		lines.push(`${rawMessage} -> ${stack}`);
	}
	if (originalStack && originalStack !== stack) {
		lines.push(`ERROR ORIGINAL STACK: ${originalStack}`);
	}
	if (splatStack && splatStack !== stack) {
		lines.push(`ERROR SPLAT STACK: ${splatStack}`);
	}
	if (otherArgs && Object.keys(otherArgs).length) {
		lines.push(`OTHER ERROR ARGS: ${JSON.stringify(otherArgs)}`);
	}
	return lines.join('\n');
}

// добавляем логирование в loki
const batchingInterval: number | false = !process.env.LOKI_BATCH_INTERVAL
	? false
	: Number(process.env.LOKI_BATCH_INTERVAL);
if (batchingInterval !== false) {
	if (Number.isNaN(batchingInterval) || batchingInterval < 5 || batchingInterval > 60) {
		// tslint:disable-next-line:no-console
		console.error(util.format('[Ошибка настройки логгера] Значение для batchingInterval может быть false или целое [5..60]. Сейчас равно %o', batchingInterval));
	}
}

const lokiTransport = new ClientsideLokiTransport({
	basicAuth: [process.env.LOKI_USERNAME, process.env.LOKI_PASSWORD].join(':'),
	minLevel: logLevel,
	labels: {
		platform: 'frontend',
		serverEnv: process.env.SERVER_ENV, // стенд
	},
	batchingInterval: 0, // TODO
});

class ClientsideLogger
{
	private readonly instance: string;

	constructor(instance: string) {
		this.instance = instance;
	}

	private lokiLog = (level, message: string, error?: Error) => {
		lokiTransport.log({
			timestamp: new Date(),
			instance: this.instance,
			level,
			message: formatLog({  level, message, error }),
		});
	}

	debug = (message: string) => {
		console.debug(message);
		this.lokiLog('debug', message);
	}

	info = (message: string) => {
		console.log(message);
		this.lokiLog('info', message);
	}

	warn = (message: string) => {
		console.warn(message);
		this.lokiLog('warn', message);
	}

	error = (message: string, error?: Error) => {
		console.error(message, error);
		this.lokiLog('error', message, error);
	}
}

/**
 * Создает логгер для указанного модуля
 */
export default function initClientsideLogger(instance: string): ClientsideLogger {
	return new ClientsideLogger(instance);
}
