import { createAction } from 'redux-act';
import { ResponseErrorEnum, ResponseError } from 'common-lib';

export const ADD_ERROR = createAction('ADD_ERROR') as Function;
export const REMOVE_ERROR = createAction('REMOVE_ERROR') as Function;

export const addError = (e) => dispatch => {
	console.debug('*** errorActions.addError:', e);
	const { error, mustReload, canLogout, canClose } = e;
	const date = new Date();
	const item: any = {
		message: typeof error === 'string' ? error : error.message,
		name: error.name,
		stack: error.stack,
		mustReload,
		canLogout,
		canClose,
		date,
	};
	// для известных типов ошибок необходимо заменить текст сообщений
	if (error instanceof ResponseError) {
		const status = error.getStatus();
		const json = error.getJson();
		if (json && json.error_code) {
			// сервер в теле передал нам код ошибки, разбираемся с этим кодом
			switch (json?.error_code) {
				case ResponseErrorEnum.ERROR_CODE_DATABASE_UNIQUE_CONSTRAINT_ERROR:
					item.humanMessage = 'Вы пытаетесь добавить данные, которые уже были добавлены.';
					item.mustReload = false;
					break;
				case ResponseErrorEnum.ERROR_CODE_TM_NOT_FOUND:
					item.humanMessage = 'Не найден указанный ТМ';
					item.mustReload = true;
					break;
				case ResponseErrorEnum.ERROR_CODE_UPF_NOT_FOUND:
					item.humanMessage = 'Не найден указанный УПФ';
					item.mustReload = true;
					break;
				case ResponseErrorEnum.ERROR_CODE_SHOP_NOT_FOUND:
					item.humanMessage = 'Не найден указанный магазин'
					item.mustReload = true;
					break;
				case ResponseErrorEnum.ERROR_CODE_VALIDATION_INPUT:
					item.humanMessage = json.error;
					item.mustReload = true;
					break;
				default:
					item.humanMessage = 'Сервер прислал неизвестную ошибку. Пожалуйста, сообщите об этом разработчику.';
			}
		}
		if (!item.humanMessage) {
			if (status === 504) {
				item.humanMessage = 'Сервер не отвечает';
			} else {
				item.humanMessage = 'В приложении возникла исключительная ошибка. Пожалуйста, сообщите об этом администратору.';
			}
		}
		if (status >= 500 && item.mustReload !== false) {
			// в случае серверной ошибки обязываем пользователя обновить страницу
			item.mustReload = true;
		}
	}
	dispatch(ADD_ERROR(item));
}

export const removeError = errorId => (dispatch) => {
	dispatch(REMOVE_ERROR(errorId));
}
