import { z } from 'zod';
import { QuestionnaireExecutionStatusEnum, QuestionnaireTypeEnum } from 'common-lib';
import {
	QuestionnaireExecDataItem,
	QuestionnaireExecDataItemOptions,
	questionnaireExecDataItemSchema
} from './QuestionnaireExecDataItem';
import {
	QuestionnaireReportDataItem,
	QuestionnaireReportDataItemOptions,
	questionnaireReportDataItemSchema
} from './QuestionnaireReportDataItem';

export const questionnaireStatisticReportItemSchema = z.object({
	id: z.number().int(),
	title: z.string(),
	status: z.nativeEnum(QuestionnaireExecutionStatusEnum),
	execData: questionnaireExecDataItemSchema.optional(),
	reportData: questionnaireReportDataItemSchema.optional(),
	shops: z.array(z.number().int()).optional(),
	managers: z.array(z.number().int()).optional(),
	addresseesCount: z.number().int(),
	type: z.nativeEnum(QuestionnaireTypeEnum),
	answeredCount: z.number().int(),
	isSupervisor: z.boolean(),
	startedAt: z.instanceof(Date),
	finishedAt: z.instanceof(Date),
	repeatCount: z.number().int(),
	testResult: z.number().int().optional(),
});

export interface QuestionnaireStatisticReportItemOptions {
	id: number;
	title: string;
	status: QuestionnaireExecutionStatusEnum;
	execData?: (QuestionnaireExecDataItem | QuestionnaireExecDataItemOptions)[];
	reportData?: (QuestionnaireReportDataItem | QuestionnaireReportDataItemOptions)[];
	shops?: number[];
	managers?: number[];
	addresseesCount: number;
	type: QuestionnaireTypeEnum;
	answeredCount: number;
	isSupervisor: boolean;
	startedAt: Date | string;
	finishedAt: Date | string;
	repeatCount: number;
	testResult?: number;
}

export class QuestionnaireStatisticReportItem {
	readonly id: number;
	readonly title: string;
	readonly status: QuestionnaireExecutionStatusEnum;
	readonly execData?: QuestionnaireExecDataItem[];
	readonly reportData?: QuestionnaireReportDataItem[];
	readonly shops?: number[];
	readonly managers?: number[];
	readonly addresseesCount: number;
	readonly type: QuestionnaireTypeEnum;
	readonly answeredCount: number;
	readonly isSupervisor: boolean;
	readonly startedAt: Date;
	readonly finishedAt: Date;
	readonly repeatCount: number;
	readonly testResult?: number;

	constructor(options: QuestionnaireStatisticReportItemOptions) {
		this.id = options.id;
		this.title = options.title;
		this.status = options.status;
		if (options.execData?.length) {
			for (const item of options.execData) {
				this.execData = [];
				if (item instanceof QuestionnaireExecDataItem) {
					this.execData.push(item);
				} else {
					this.execData.push(new QuestionnaireExecDataItem(item));
				}
			}
		}
		if (options.reportData?.length) {
			for (const item of options.reportData) {
				this.reportData = [];
				if (item instanceof QuestionnaireReportDataItem) {
					this.reportData.push(item);
				} else {
					this.reportData.push(new QuestionnaireReportDataItem(item));
				}
			}
		}
		if (options.shops?.length) this.shops = options.shops;
		if (options.managers?.length) this.managers = options.managers;
		this.addresseesCount = options.addresseesCount;
		this.type = options.type;
		this.answeredCount = options.answeredCount;
		this.isSupervisor = options.isSupervisor;
		if (options.startedAt instanceof Date) {
			this.startedAt = options.startedAt;
		} else {
			this.startedAt = new Date(options.startedAt);
		}
		if (options.finishedAt instanceof Date) {
			this.finishedAt = options.finishedAt;
		} else {
			this.finishedAt = new Date(options.finishedAt);
		}
		this.repeatCount = options.repeatCount;
		if (options.testResult !== undefined) this.testResult = options.testResult;
		questionnaireStatisticReportItemSchema.parse(this);
	}
}