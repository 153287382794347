import React, { useRef } from 'react';
import styled from 'styled-components';
import PreloaderImage, { PreloadingImageStyled } from '~components/ui/PreloaderImage';
import isMobile from 'is-mobile';

const ListStyled = styled.div`
	display: grid;
	position: relative;
	grid-template-columns: repeat(auto-fill, 60px);
	grid-template-rows: repeat(1, 1fr);
	grid-column-gap: 1vw;
	grid-row-gap: 1vw;
	justify-items: center;

  	&.add {
	  	width: 60px;
	  	height: 60px;
	    border-radius: 16px;
	    background-position: center;
	    background-repeat: no-repeat;
  		background-color: #7B7FEF;
  		background-image: url(/assets/img/ui/photo-add.svg);
  		background-size: initial;
  	}

  	&:not(.add) {
  		position: relative;
  		background-image: url(${p => p.src});
  	}

	@media (max-width: 600px) {
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: 1.5vw;
		grid-row-gap: 1.5vw;

	  	&.add {
			width: 16vw;
			height: 16vw;
			background-size: 9vw;
        }

		${PreloadingImageStyled} {
			width: 16vw;
			height: 16vw;
		}
	}

	@media (max-width: 450px) {
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 2vw;
		grid-row-gap: 2vw;

		&.add {
			width: 18vw;
			height: 18vw;
        }

		${PreloadingImageStyled} {
			width: 18vw;
			height: 18vw;
		}
	}
`;

const DeletedStyled = styled.div`
	position: absolute;
	right: -10px;
	top: -10px;
	width: 24px;
	height: 24px;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(/assets/img/ui/photo-delete.svg);
`;

const InfoStyled = styled.div`
	color: #8790A6;
	font-size: 12px;
	font-weight: 400;
`;

const SelectImg = styled.div`
	grid-column-start: ${p => p.index};
	grid-column-end: ${p => p.index};
	position: absolute;
	top: -5px;
	right: -5px;
	width: 18px;
	height: 18px;
	border: 1px solid #BFBFC7;
	border-radius: 5px;
	background: #fff ${p => p.checked ? 'url(/assets/img/checker-checked.svg)' : ''} center no-repeat;
`;

interface Props {
	photos: any[];
	checks?: number[];
	infoText?: string;
	onClickImage?: (fileId: number) => void;
	onSelect?: (fileId: number) => void;
	select?: boolean;
	onAddPhoto?: Function;
	onRemovePhoto?: Function;
	readOnly?: boolean;
	isLimit?: boolean;
}

export default function UiPhotoGallery2(props: Props) {
	const listRef = useRef<HTMLDivElement>();

	function onAddPhoto(newPhoto) {
		props.onAddPhoto?.(newPhoto);
		listRef.current?.scroll({
			top: 0,
			left: listRef.current?.scrollWidth,
			behavior: 'auto',
		});
	}

	function onRemove(index) {
		props.onRemovePhoto?.(index);
	}

	// выбор фотографии
	function selectPhoto(isCapture: boolean, callback: (data: string) => void, maxPhotoSize = 1200) {
		const input = document.createElement('input'); // создаем input, сразу кликаем чтобы выбрать фотку
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		if (isCapture) {
			input.setAttribute('capture', 'camera');
		}
		input.style.display = 'none';
		document.body.appendChild(input); // Для IOS в safari тег input обязательно нужно добавить в DOM
		input.addEventListener('change', () => { // пользователь выбрал фотку
			if (input.files && input.files[0]) {
				const reader = new FileReader(); // загружаем картинку через ридер
				reader.onload = e => {
					const image = document.createElement('img'); // рисуем файлик в <img/>
					image.onload = () => {
						const { width: imw, height: imh } = image;
						const maxSizeSide = Math.max(imw, imh); // ищем максимальную сторону картинки
						const ratioForHorizontal = imw / imh // соотношение сторон горизонтальных image
						const ratioForVertical = imh / imw // соотношение сторон вертикальных image
						let outputWidth = imw;
						let outputHeight = imh;
						const dx = 0;
						const dy = 0;
						// если размер меньше допустимого, передаём как есть
						if (imw < maxPhotoSize && imh < maxPhotoSize) {
							outputWidth = imw;
							outputHeight = imh;
						}
						// если размер один к одному
						if ((imw === imh) && imw > maxPhotoSize && imh > maxPhotoSize) {
							outputWidth = maxPhotoSize;
							outputHeight = maxPhotoSize;
						}
						// если стороны разные
						if (imw === maxSizeSide) {
							outputWidth = maxPhotoSize;
							outputHeight = outputWidth / ratioForHorizontal;
						} else if (imh === maxSizeSide) {
							outputHeight = maxPhotoSize;
							outputWidth = outputHeight / ratioForVertical;
						}
						const canvas = document.createElement('canvas');
						canvas.width = outputWidth;
						canvas.height = outputHeight;
						const context = canvas.getContext('2d'); // рисуем картинку на <canvas/>
						context?.drawImage(image, dx, dy, outputWidth, outputHeight);
						const data = canvas.toDataURL('image/png');
						callback(data);
						document.body.removeChild(input);
					};
					image.setAttribute('src', e?.target?.result as string);
				};
				reader.readAsDataURL(input.files[0]);
			}
		});
		input.click();
	}


	return (
		<>
			<ListStyled ref={listRef}>
				{props.photos?.map((photo, index) => (
					<>
						<PreloaderImage
							key={photo.fileId}
							src={photo.src}
							onClick={() => props.onClickImage?.(photo.fileId)} >
							{!props.readOnly || props.isLimit ? (
								<DeletedStyled onClick={e => { e.stopPropagation(); onRemove(index); }} />
							) : null}
						</PreloaderImage>
						{props.select && !isMobile() ? (
							<SelectImg
								index={index + 1}
								checked={(props.checks?.indexOf(photo.fileId) || -1) >= 0}
								onClick={() => props.onSelect?.(photo.fileId)}
							/>
						) : null}
					</>
				))}
				{!props.readOnly ? (
					<ListStyled className="add"
								onClick={() => selectPhoto(true, onAddPhoto)} />
				) : null}
			</ListStyled>
			{props.infoText ? (
				<InfoStyled>{props.infoText}</InfoStyled>
			) : null}
		</>
	);
}
