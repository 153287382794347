import { createAction } from 'redux-act';
import fetchBackend, { fetchGetThenJson } from '~tools/fetchBackend';

/*
 * Account
 */

export const FETCH_ACCOUNT = createAction('FETCH_ACCOUNT');

export const fetchAccount = () => dispatch => fetchBackend('get', 'account')
	.then(response => response.json && response.json())
	.then(data => dispatch(FETCH_ACCOUNT(data)))
	.then();

/*
 * Shops
 */

export const FETCH_SHOP_LIST = createAction('FETCH_SHOP_LIST');
export const FETCH_SHOP = createAction('FETCH_SHOP');
export const DELETE_SHOP = createAction('DELETE_SHOP');

export const createShop = saveData => dispatch => fetchBackend('post', 'shop', saveData)
	.then(response => response.json && response.json())
	.then(data => dispatch(FETCH_SHOP(data)))
	.then();

/**
 * Запрос информации по магазину
 * @param id
 * @param options Опции запроса, может быть { users: Boolean }
 * @returns {function(*): Promise<T>}
 */
export const fetchShop = (id, options = {}) => dispatch => {
	if (!id) {
		throw new Error('Id must be not null in fetchShop action');
	}
	const { users, ...any } = options;
	// предупреждаем о лишних опциях
	if (Object.keys(any).length) {
		throw new Error(`Unknown options '${Object.keys(any)}' in fetchShop action`);
	}
	return fetchBackend('get', `shop/${id}${users ? '?meta=users' : ''}`)
		.then(response => response.json && response.json())
		.then(data => data && dispatch(FETCH_SHOP(data)))
		.then();
};

export const fetchShopList = (fetchAllShops = false) => dispatch =>
	fetchBackend('get', `shops${fetchAllShops ? '?page=0:5000' : ''}`)
		.then(response => response.json && response.json())
		.then(data => dispatch(FETCH_SHOP_LIST(data)))
		.then();

export const updateShop = (id, saveData) => dispatch => fetchBackend('put', `shop/${id}`, saveData)
	.then(response => response.json && response.json())
	.then(data => dispatch(FETCH_SHOP(data)))
	.then();

export const deleteShop = id => dispatch => fetchBackend('delete', `shop/${id}`)
	.then(() => dispatch(DELETE_SHOP(id)))
	.then();

/*
 * Users
 */

export const FETCH_USER_LIST = createAction('FETCH_USER_LIST');
export const FETCH_SHOP_ROLE_LIST = createAction('FETCH_SHOP_ROLE_LIST');
export const FETCH_REGION_LIST = createAction('FETCH_REGION_LIST');
export const FETCH_USER = createAction('FETCH_USER');
export const DELETE_USER = createAction('DELETE_USER');

export const createUser = saveData => dispatch => fetchBackend('post', 'user', saveData)
	.then(response => response.json && response.json())
	.then(data => dispatch(FETCH_USER(data)))
	.then();

export const fetchUser = (id, withCreds) => dispatch => fetchBackend('get', `user/${id}${withCreds ? '?meta=creds' : ''}`)
	.then(response => response.json && response.json())
	.then(data => dispatch(FETCH_USER(data)) && data);

export const fetchUserList = userIds => dispatch => fetchGetThenJson(
	userIds
		? `users/${userIds.join(',')}?page=0:5000`
		: 'users?page=0:5000')
	.then(data => dispatch(FETCH_USER_LIST(data)));

export const fetchShopRoles = () => dispatch => fetchGetThenJson(`shop-roles`)
	.then(data => dispatch(FETCH_SHOP_ROLE_LIST(data)));

export const fetchRegionList = () => dispatch => fetchGetThenJson('regions?page=0:50')
	.then(data => dispatch(FETCH_REGION_LIST(data)));

export const updateUser = (id, saveData) => dispatch => fetchBackend('put', `user/${id}`, saveData)
	.then(response => response.json && response.json())
	.then(data => dispatch(FETCH_USER(data)))
	.then();

export const deleteUser = id => dispatch => fetchBackend('delete', `user/${id}`)
	.then(() => dispatch(DELETE_USER(id)))
	.then();

export const updateUserPassword = (id, newPassword) => dispatch => fetchBackend('put', `user/${id}/password`, { key: newPassword })
	.then(response => response.json && response.json())
	.then(data => dispatch(FETCH_USER(data)))
	.then();

/*
 * Goods
 */

export const FETCH_GOOD_LIST = createAction('FETCH_GOOD_LIST');

export const fetchGoodList = ids => dispatch => fetchBackend('get', `structure/goods/${ids.join(',')}`)
	.then(response => response.json && response.json())
	.then(data => dispatch(FETCH_GOOD_LIST(data)));
