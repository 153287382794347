const CONTENT_TYPE = 'application/json';

export type LokiBatcherOptions = {
	basicAuth?: string // basic authentication credentials to access Loki over HTTP (ex: "username:password")
	batchingInterval: number | false // The interval at which batched logs are sent in seconds (default: 5)
	// If batching is not used, the logs are sent as they come
	clearOnError?: boolean // Discard any logs that result in an error during transport (default: false)
	replaceTimestamp?: boolean // Replace any log timestamps with Date.now() (default: false)
}

export default class ClientsideLokiBatcher
{
	private defaultBatchingInterval: number | false;
	private clearOnError: boolean;
	private replaceTimestamp: boolean;

	private url: string;
	private interval: number;
	private circuitBreakerInterval: number = 60000;
	private batch: { streams: any[] };
	private headers: HeadersInit = {};
	private runLoop?: boolean;

	/**
	 * Creates an instance of Batcher.
	 * Starts the batching loop if enabled.
	 * @param {*} options
	 * @memberof Batcher
	 */
	constructor(options: LokiBatcherOptions) {
		// Load given options to the object
		this.defaultBatchingInterval = options.batchingInterval === false ? options.batchingInterval
			: Number(options.batchingInterval) * 1_000 || 5_000;
		this.clearOnError = options.clearOnError || false;
		this.replaceTimestamp = options.replaceTimestamp || false;

		// Construct Grafana Loki push API url
		this.url = '/loki/api/v1/push';

		// Parse basic auth parameters if given
		if (options.basicAuth) {
			const basicAuth = 'Basic ' + btoa(options.basicAuth);
			this.headers['Authorization'] = basicAuth;
		}

		// Define the batching intervals
		this.interval = this.defaultBatchingInterval || 5_000;

		// Initialize the log batch
		this.batch = {
			streams: [],
		};

		// If batching is enabled, run the loop
		if (this.defaultBatchingInterval) {
			this.run();
		}
	}

	/**
	 * Returns a promise that resolves after the given duration.
	 *
	 * @param {*} duration
	 * @returns {Promise}
	 */
	wait(duration) {
		return new Promise(resolve => {
			setTimeout(resolve, duration);
		});
	}

	/**
	 * Pushes logs into the batch.
	 * If logEntry is given, pushes it straight to this.sendBatchToLoki()
	 *
	 * @param {*} logEntry
	 */
	async pushLogEntry(logEntry) {
		const noTimestamp = logEntry && logEntry.entries && logEntry.entries[0].ts === undefined;
		// If user has decided to replace the given timestamps with a generated one, generate it
		if (this.replaceTimestamp || noTimestamp) {
			logEntry.entries[0].ts = Date.now();
		}

		// If batching is not enabled, push the log immediately to Loki API
		if (!this.defaultBatchingInterval) {
			await this.sendBatchToLoki(logEntry);
		} else {
			this.batch.streams.push(logEntry);
		}
	}

	/**
	 * Clears the batch.
	 */
	clearBatch() {
		this.batch.streams = [];
	}

	/**
	 * Sends a batch to Grafana Loki push endpoint.
	 * If a single logEntry is given, creates a batch first around it.
	 *
	 * @param {*} logEntry
	 * @returns {Promise}
	 */
	sendBatchToLoki(logEntry?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			// If the batch is empty, do nothing
			if (this.batch.streams.length === 0 && !logEntry) {
				resolve(undefined);
			} else {
				let reqBody;

				// The data format is JSON, there's no need to construct a buffer
				let preparedJSONBatch;
				if (logEntry !== undefined) {
					// If a single logEntry is given, wrap it according to the batch format
					preparedJSONBatch = prepareJSONBatch({ streams: [logEntry] });
				} else {
					// Stringify the JSON ready for transport
					preparedJSONBatch = prepareJSONBatch(this.batch);
				}
				reqBody = JSON.stringify(preparedJSONBatch);

				// Send the data to Grafana Loki
				post(this.url, CONTENT_TYPE, this.headers, reqBody)
					.then(() => {
						// No need to clear the batch if batching is disabled
						if (logEntry === undefined) {
							this.clearBatch();
						}
						resolve(undefined);
					})
					.catch(err => {
						// Clear the batch on error if enabled
						if (this.clearOnError) {
							this.clearBatch();
						}
						reject(err);
					});
			}
		});
	}

	/**
	 * Runs the batch push loop.
	 *
	 * Sends the batch to Loki and waits for
	 * the amount of this.interval between requests.
	 */
	async run() {
		this.runLoop = true;
		while (this.runLoop) {
			try {
				await this.sendBatchToLoki();
				if (this.interval === this.circuitBreakerInterval) {
					this.interval = this.defaultBatchingInterval || 5_000;
				}
			} catch (error) {
				this.interval = this.circuitBreakerInterval;
			}
			await this.wait(this.interval);
		}
	}

	/**
	 * Stops the batch push loop
	 *
	 * @param {() => void} [callback]
	 */
	close(callback?: () => void) {
		this.runLoop = false;
		this.sendBatchToLoki()
			.then(() => {
				if (callback) {
					callback();
				}
			}) // maybe should emit something here
			.catch(error => {
				// tslint:disable-next-line:no-console
				console.error('[Ошибка отправки логов в loki]', error);
				if (callback) {
					callback();
				}
			}); // maybe should emit something here
	}
}

async function post(url: string, contentType: string, headers: HeadersInit, data: any) {
	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': contentType,
			'Content-Length': data.length.toString(),
			...headers,
		},
		body: data,
	});
}

function prepareJSONBatch(batch) {
	const streams = batch.streams.map(logStream => ({
		stream: logStream.labels,
		values: logStream.entries.map(entry => {
			const date = new Date(entry.ts);
			return [date.getTime() * 1e6, entry.line];
		}),
	}));
	return { streams };
}
