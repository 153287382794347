import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import { copyText } from '~tools/copyText';
import { Nowrap } from '~components';
import {
	AuthCredentialItem, PageInfoDto,
	ShopManagerItem,
	ShopManagerRoleItem,
	UserListResponseDto,
} from 'protocol-lib';
import { UserRoles } from 'common-lib';

const ErrorText = styled.span`
  color: red;
`;

const RoleSpan = styled.span`
  display: inline-block;
  color: #666;
  font-size: .8em;
  font-weight: 500;
  margin-right: 0.6em;
`;

export interface IUserCompositeRow {
	id: number;
	externalId?: string;
	role: UserRoles;
	firstName: string | null;
	lastName: string;
	middleName?: string;
	fullName: string;
	email?: string;
	phone?: string;
	comment?: string;
	username?: string;
	shopRoles?: Set<string>;
}

export interface IFilledTableData {
	rows: IUserCompositeRow[];
	pageInfo?: PageInfoDto;
}

/**
 * Выполняет обогащение данных для таблицы
 */
export const fillTableData = (json: UserListResponseDto): IFilledTableData => {
	const { data: rows = [], meta, pageInfo } = json;
	const { authCredentialMap, shopManagerMap, shopManagerRoleMap } = meta || {};
	const authCredentials: AuthCredentialItem[] | null = authCredentialMap ? Object.values(authCredentialMap) : null;
	const shopManagers: ShopManagerItem[] | null = shopManagerMap ? Object.values(shopManagerMap) : null;
	const shopManagerRoles: ShopManagerRoleItem[] | null = shopManagerRoleMap ? Object.values(shopManagerRoleMap) : null;
	// заполняем пользователей из meta в rows
	rows.forEach((row: IUserCompositeRow) => {
		const authCredential: AuthCredentialItem | undefined = authCredentials?.find((s: AuthCredentialItem) => s.userId === row.id);
		if (authCredential) {
			row.username = authCredential.username;
		}
		const foundShopManagers: ShopManagerItem[] | undefined = shopManagers?.filter((s: ShopManagerItem) => s.userId === row.id);
		if (foundShopManagers) {
			if (!row.shopRoles) row.shopRoles = new Set<string>();
			for (const foundShopManager of foundShopManagers) {
				const shopManagerRole: ShopManagerRoleItem | undefined = shopManagerRoles?.find((s: ShopManagerRoleItem) => s.id === foundShopManager.roleId);
				if (shopManagerRole) {
					row.shopRoles.add(shopManagerRole.title);
				}
			}
		}
	});
	return { rows, pageInfo };
};

export const TABLE_HEADERS = {
	id: {
		title: '#',
		style: { maxWidth: '150px' },
		viewFunc: (row, hl) => (
			<Popup content="Нажми, чтобы скопировать" trigger={(
				<Nowrap style={{ cursor: 'pointer' }} onClick={event => {
					event.stopPropagation();
					copyText(row.id);
				}}>
					{hl(row.id)}
					<Icon name="copy"
					      style={{ color: '#aaa', fontSize: '0.9em', float: 'right' }} />
				</Nowrap>
			)} />
		),
	},
	lastName: {
		title: 'ФИО',
		viewFunc: (row, hl) => (
			<Link to={`/admin/users/${row.id}`}>
				{row.fullName ? hl(row.fullName) : '(не указано)'}
			</Link>
		),
	},
	role: {
		title: 'Роль',
		viewFunc: (row, hl) => hl(row.role),
	},
	username: {
		title: 'Логин',
		viewFunc: (row, hl) => hl(row.username),
	},
	$contacts: {
		title: 'Контакты',
		viewFunc: (row, hl) => (
			<>
				{row.phone ? row.phone.split(/[,;\n]\s+/).map(s => (
					<div key={s}>
						<Icon name="phone" style={{ color: '#999', fontSize: '0.9em' }} />
						{hl(s)}
					</div>
				)) : null}
				{row.email ? row.email.split(/[,;\n]\s+/).map(s => (
					<div key={s}>
						<Icon name="at" style={{ color: '#999', fontSize: '0.9em' }} />
						{hl(s)}
					</div>
				)) : null}
			</>
		),
	},
	$shopRoles: {
		title: 'Роли магазина',
		viewFunc: (row, hl) => (
			<>
				{row.shopRoles ? Array.from(row.shopRoles).map((s: any) => <div
					key={s}>{hl(s)}</div>) : null}
			</>
		),
	},
};
