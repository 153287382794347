import React from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import ReactQuill from 'react-quill';
import { CancelSaveDeleteButtons } from '~components';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const ScrolledDiv = styled.div`
  padding: 4px 8px;
  border: 1px solid silver;
  border-radius: 4px;
  overflow: auto;
`

function renderError(node) {
	return <Segment inverted color="red" style={{ clear: 'both' }}>{node}</Segment>;
}

type Props = {
	title?: string,
	icon?: string,
	metaFields: any[],
	itemData?: any,
	bottomComponent?: any,
	readOnly?: boolean,
	onChange?: any,
	otherSaveFlag?: boolean,
	onSave?: any,
	onCancel?: any,
	onDelete?: any,
}

type State = {
	error?: string,
	fieldErrors?: any,
	hasErrors?: any,
	hasChanges?: boolean,
	itemInitData?: any,
	touchedFields?: any,
}

export default class EditForm extends React.Component<Props, State>
{
	private fieldValues = {};

	constructor(props) {
		super(props);
		// проверяем и иничим данные
		if (!props.metaFields || !props.metaFields.length) {
			this.state = { error: 'Не указано поле metaFields' };
			return;
		}
		const values = Object.assign({}, props.itemData);
		// first error check
		const fieldErrors = {};
		let hasErrors = false;
		props.metaFields.forEach(metaField => {
			const { validate, condition } = metaField;
			if (validate) {
				let error = validate(values[metaField.key]);
				if (condition) {
					/* Обязательно передаем новый объект, чтобы данные не удалось подменить */
					if (!condition(values)) error = null;
				}
				if (error) {
					fieldErrors[metaField.key] = error;
					hasErrors = true;
				}
			}
		});

		this.state = {
			itemInitData: values,
			fieldErrors,
			hasErrors,
			hasChanges: false,
			touchedFields: new Set(),
		};
	}

	handleChange = (metaField, newValue) => {
		const { key, type, onChangeFieldValue } = metaField;
		if (type === 'number') {
			newValue = (newValue === null || newValue === '') ? null : Number(newValue);
		}
		const oldState = JSON.stringify({
			hasErrors: this.state.hasErrors,
			fieldErrors: this.state.fieldErrors,
			hasChanges: this.state.hasChanges,
			touchedFields: this.state.touchedFields,
		});
		const oldValue = this.fieldValues[key];
		const {
			itemInitData: { [key]: defaultValue },
			touchedFields,
		} = this.state;
		const fieldErrors = {};
		if (newValue === oldValue) return undefined;
		touchedFields.add(key);

		if (defaultValue !== newValue) {
			this.fieldValues[key] = newValue;
		} else {
			delete this.fieldValues[key];
		}

		if (onChangeFieldValue) {
			this.fieldValues = onChangeFieldValue(this.fieldValues);
		}

		const hasChanges = Object.keys(this.fieldValues).length > 0;

		const validateValues = Object.assign({}, this.state.itemInitData, this.fieldValues);
		let hasErrors = false;
		this.props.metaFields.forEach(mf => {
			const { validate, condition: mfCondition } = mf;
			if (validate) {
				let error = validate(validateValues[mf.key]);
				if (mfCondition) {
					/* Обязательно передаем новый объект, чтобы данные не удалось подменить */
					if (!mfCondition(validateValues)) error = null;
				}
				if (error) {
					fieldErrors[mf.key] = error;
					hasErrors = true;
				}
			}
		});

		const { onChange } = this.props;
		if (onChange) {
			/* Даем возможность обновить данные */
			onChange(this.fieldValues, metaField, newValue, hasErrors, fieldErrors, hasChanges, touchedFields);
		}

		const newState = {
			hasErrors,
			fieldErrors,
			hasChanges,
			touchedFields,
		}
		console.debug('%c*** newState=', 'background: #eee; color: blue', newState);
		if (JSON.stringify(newState) !== oldState) {
			console.debug('%c*** update=', 'background: #eee; color: blue');
			this.setState(newState);
		}
	};

	renderField = (metaField) => {
		const { key, title, condition, readOnly, icon, type = 'text', options, placeholder, formats, toolbar } = metaField;
		if (condition) {
			/* Проверяем условие отображения поля */
			const { itemInitData } = this.state;
			/* Обязательно передаем новый объект, чтобы данные не удалось подменить */
			if (!condition(Object.assign({}, itemInitData, this.fieldValues ))) return undefined;
		}
		const {
			itemInitData: { [key]: defaultValue },
			fieldErrors: { [key]: error },
			touchedFields,
		} = this.state;
		// simple button
		if (type === 'button') {
			return (
				<Form.Button
					key={key}
					primary
					content={metaField.content || key}
					onClick={metaField.onClick}
				/>
			);
		}
		// toggable checkbox
		if (type === 'checkbox') {
			return (
				<Form.Checkbox
					key={key}
					readOnly={readOnly}
					toggle
					label={title || key}
					defaultChecked={defaultValue}
					onChange={(_, { checked }) => this.handleChange(metaField, checked)}
				/>
			);
		}
		// select
		if (type === 'select') {
			const { clearable = false } = metaField;
			return (
				<Form.Select
					search
					clearable={clearable}
					key={key}
					disabled={readOnly}
					label={title || key}
					options={options}
					error={error && touchedFields.has(key) ? error : undefined}
					defaultValue={defaultValue || ''}
					onChange={(_, { value }) => this.handleChange(metaField, value)}
				/>
			);
		}
		// string values
		if (type === 'text' || type === 'number' || type === 'password') {
			return (
				<Form.Input
					key={key}
					placeholder={placeholder}
					readOnly={readOnly}
					label={title || key}
					icon={icon || undefined}
					iconPosition={icon ? 'left' : undefined}
					type={type}
					error={error && touchedFields.has(key) ? error : undefined}
					defaultValue={defaultValue || ''}
					onChange={(_, { value }) => this.handleChange(metaField, value)}
				/>
			);
		}
		// text-editor
		if (type === 'text-editor') {
			return (
				<Form.TextArea
					key={key}
					placeholder={placeholder}
					readOnly={readOnly}
					label={title || key}
					icon={icon || undefined}
					type={type}
					style={{ minHeight: Math.min(600, Math.max(100, (defaultValue || '').length / 2)) + 'px' }}
					error={error && touchedFields.has(key) ? error : undefined}
					defaultValue={defaultValue || ''}
					onChange={(_, { value }) => this.handleChange(metaField, value)}
				/>
			);
		}
		// quill wysisyg text editor
		if (type === 'wysiwyg') {
			const modules = {
				toolbar: toolbar || false,
				clipboard: { matchVisual: false },
			};
			return (
				<Form.Field key={key}>
					<label>{title || key}</label>
					<ReactQuill
						theme="snow"
						placeholder={placeholder}
						readOnly={readOnly}
						// FIXME почему-то нет поля --> error={error && touchedFields.has(key) ? error : undefined}
						defaultValue={defaultValue || ''}
						onChange={value => this.handleChange(metaField, value)}
						modules={modules}
						formats={formats}
					/>
				</Form.Field>
			);
		}
		if (type === 'html') {
			return (
				<Form.Field key={key}>
					<label>{title || key}</label>
					<ScrolledDiv>
						<div dangerouslySetInnerHTML={{ __html: defaultValue }} />
					</ScrolledDiv>
				</Form.Field>
			)
		}
		// else unknown field type
		this.setState({ error: `Неизвестный тип поля '${type}'` });
	};

	renderForm = () => {
		const { metaFields } = this.props;
		return (
			<Form style={{ margin: '2rem auto' }} className="editform">
				{metaFields.map(this.renderField)}
			</Form>
		);
	}

	render() {
		const hasError = !!this.state.error;
		const { bottomComponent, readOnly, onSave, onCancel, onDelete } = this.props;
		if (hasError) {
			return (
				<>
					<Header as="h3" icon={this.props.icon} content={this.props.title} />
					{hasError && renderError(this.state.error)}
				</>
			);
		}
		return (
			<>
				<div>
					<Header
						as="h3"
						icon={this.props.icon}
						content={this.props.title}
					/>
				</div>
				{this.renderForm()}
				{bottomComponent}
				{(onCancel || onSave || onDelete) && (
					<CancelSaveDeleteButtons
						readOnly={readOnly}
						canSave={!this.state.hasErrors && (this.state.hasChanges || this.props.otherSaveFlag)}
						onCancel={onCancel}
						onSave={onSave ? () => onSave(this.fieldValues) : null}
						onDelete={onDelete}
					/>
				)}
			</>
		);
	}
}
