import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions } from '../../../common';

export const questionnaireReportByShopsResponseDtoSchema = z.object({
	fileId: z.number().int().optional(),
});

export interface QuestionnaireReportByShopsResponseDtoOptions extends BaseResponseDtoOptions {
	fileId?: number;
}

export class QuestionnaireReportByShopsResponseDto extends BaseResponseDto {
	readonly fileId?: number;

	constructor(options: QuestionnaireReportByShopsResponseDtoOptions) {
		super(options);
		if (options.fileId !== undefined) this.fileId = options.fileId;
		questionnaireReportByShopsResponseDtoSchema.parse(this);
	}
}