import { z } from 'zod';
import { InfoMessagesDataItem, InfoMessagesDataItemOptions, infoMessagesDataItemSchema } from '../composite';


export const infoMessagesDataSchema = z.object({
	dataItems: z.array(infoMessagesDataItemSchema).optional(),
	isNotShopManager: z.boolean().optional(),
});

export interface InfoMessagesDataOptions {
	dataItems?: (InfoMessagesDataItem | InfoMessagesDataItemOptions)[];
	isNotShopManager?: boolean;
}

export class InfoMessagesData {
	readonly dataItems?: InfoMessagesDataItem[];
	readonly isNotShopManager?: boolean;

	constructor(options: InfoMessagesDataOptions) {
		if (options.dataItems?.length) {
			this.dataItems = [];
			for (const item of options.dataItems) {
				if (item instanceof InfoMessagesDataItem) {
					this.dataItems.push(item);
				} else {
					this.dataItems.push(new InfoMessagesDataItem(item));
				}
			}
		}
		if (options.isNotShopManager !== undefined) this.isNotShopManager = options.isNotShopManager;
		infoMessagesDataSchema.parse(this);
	}
}