import { z } from 'zod';

export const logDataItemSchema = z.object({
	id: z.number().int(),
	message: z.string(),
});

export interface LogDataItemOptions {
	id: number;
	message: string;
}

export class LogDataItem {
	readonly id: number;
	readonly message: string;

	constructor(options: LogDataItemOptions) {
		this.id = options.id;
		this.message = options.message;
		logDataItemSchema.parse(this);
	}
}