import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { ChecklistTaskStatusEnum, ChecklistTaskStatusTitle, validateString } from 'common-lib';
import { Spinner, UiBadge2, UiTextareaBadge2, UserPhotoPreview, UiPhotoGallery2 } from '~components';
import UiOverMenu from '~components/ui/UiOverMenu';
import { nl2br } from '~tools/html';
import { execChecklistTask } from '~api/checklistTasksApi';
import { ChecklistTaskFormData, ChecklistTaskFormDataFieldValue } from '../checklistTasksAttrs';
import isMobile from 'is-mobile';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';
import { UserDataItem } from 'protocol-lib';

const COMMENT_MIN_LENGTH = 5;
const COMMENT_MAX_LENGTH = 2000;

interface IChecklistPropsData {
	checklistTaskId: number;
	status: ChecklistTaskStatusEnum;
	department: string;
	checkListName: string;
	taskId: string;
	taskCreatedAt: Date;
	taskDeadlineAt: Date;
	checkListProcessName: string;
	checkListQuestion: string;
	creatorComment: string;
	questionPhotos: string;
	creatorFullname: string;
	executorUser: UserDataItem;
	isExpired: boolean;
	executorComment: string;
	isPhotoRequired: boolean;
	isCommentRequired: boolean;
	taskClosedAt: Date;
	error: string;
}

let updateTimerId: NodeJS.Timeout;

type Props = {
	data: IChecklistPropsData
	onExecuteSuccess: () => void
	onRejectSuccess: () => void
};

export default function ChecklistTaskView(props: Props) {
	const [isSaving, setSaving] = useState<boolean>();
	const [formData, setFormData] = useState<ChecklistTaskFormData>({
		fields: {
			executorComment: { defaultValue: undefined, value: undefined },
			photos: { defaultValue: [], value: [] },
		},
		initialized: false,
		hasError: false,
		hasChanges: false,
	});
	const [execComment, setExecComment] = useState<string | undefined>(formData.fields.executorComment.value);
	const [showFileId, setShowFileId] = useState<number | string>();
	const { isShopUser } = useSelector((state: any) => state.me);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	useEffect(() => {
		if (showFileId) {
			if (location.hash !== '#preview') {
				location.hash = '#preview';
				navigate(location.pathname + location.hash);
			}
			window.addEventListener('popstate', onBack, false);
		}
	}, [showFileId]);

	function onBack(e) {
		e.preventDefault();
		setShowFileId(undefined);
		window.removeEventListener('popstate', onBack);
	}

	if (!formData.initialized) {
		setFormData(fillFormDataFromChecklistTask(props.data));
		return null;
	}

	const {
		checklistTaskId,
		status,
		department,
		checkListName,
		taskId,
		taskCreatedAt,
		taskDeadlineAt,
		checkListProcessName,
		checkListQuestion,
		creatorComment,
		questionPhotos,
		creatorFullname,
		executorUser,
		isExpired,
		executorComment,
		isPhotoRequired,
		isCommentRequired,
		taskClosedAt,
		error,
	}: IChecklistPropsData = props.data;

	if (error) {
		throw new Error(error);
	}

	const isSaveDisabled = (!isPhotoRequired && !isCommentRequired) ? false : (formData.hasError || !formData.hasChanges);

	// текущий пользователь это ДМ или ЗДМ и он может добавить решение по задаче
	const shopUserCanExec = isShopUser && [ChecklistTaskStatusEnum.PLANNED, ChecklistTaskStatusEnum.STARTED].includes(status);
	// текущий пользователь это менеджер и он может отклонить решение по задаче
	const expiredStatus = isExpired && ChecklistTaskStatusEnum.EXPIRED;

	// нужна ли кнопка сохранения
	let overMenu;
	if (shopUserCanExec) {
		overMenu = <UiOverMenu.AcceptButton title="Сохранить"
		                                    onClick={onExecuteChecklistTask}
		                                    disabled={isSaveDisabled || isSaving} />;
	}

	// выбираем фотки для вывода в галерее
	const PHOTO_LIMIT_UPLOAD = 5;
	const nowDate = moment().parseZone().utc().format();
	const photoSrcs = formData.fields.photos.value.filter(i => !i.isDeleted).map(i => (
		// createdAt: 	nowDate,
		i.src
	));
	const photoSrcs2 = formData.fields.photos.value.filter(f => !f.isDeleted).map((f, i) => ({
            createdAt: taskClosedAt || nowDate,
		    fileId: f.id || `photo-${i}-item`,
		    src: f.src
	}));
	const questionPhotoSrcs = JSON.parse(questionPhotos)?.map((i: string) => ({
		createdAt: taskCreatedAt,
		fileId: i,
		src: `/api/file/download/external/${i}?size=middle`,
	}));
	const isLimitPhoto = photoSrcs.length < PHOTO_LIMIT_UPLOAD;
	const values = [
		{ key: 'Вопрос:', value: checkListQuestion },
		{ key: 'Автор:', value: creatorFullname || 'Не указано' },
		{ key: 'Коментарий:', value: creatorComment || 'Отсутствует' },
	];
	if (checkListProcessName) {
		values.unshift({ key: 'Процесс:', value: checkListProcessName });
	}
	if (executorUser) {
		values.splice(-1, 0, { key: 'Исполнитель:', value: executorUser.fullName });
	}
	if (questionPhotos) {
		values.push({ key: 'Фото:', value: ''});
	}
	const requiredFields = {
		comment: 'Коментарий обязателен для заполнения',
		photos: 'Необходимо добавить хотя бы одну фотографию',
	};
	const checkPhotoField = isPhotoRequired && photoSrcs.length === 0 && requiredFields.photos;
	const checkCommentField = isCommentRequired && Object.keys(formData.fields.executorComment).length <= 1 && requiredFields.comment;

	const closedDate = moment(taskClosedAt).format('DD.MM.YYYY');

    function parsePhotoSrcs(arr1, arr2, fileId) {
		let selectedIndex = arr1?.findIndex(f => f.fileId === fileId);
		let resArr: any[] | undefined;
		if (selectedIndex > -1) {
			resArr = arr1;
		} else {
			selectedIndex = arr2.findIndex(f => f.fileId === fileId);
			if (selectedIndex > -1) {
				resArr = arr2;
			}
		}
		if (selectedIndex !== -1 && resArr) {
			const list = resArr.map(f => {
				const date = moment(f.createdAt);
				const day = date.format('DD.MM');
				const time = date.format('HH:mm');
				return {
					fileId: f.fileId,
					title: day,
					subTitle: time,
					src: f.src
				};
			});
			return { selectedIndex: String(selectedIndex), list };
		}
		return null;
    }

	if (showFileId) {
		const photoSrcs = parsePhotoSrcs(questionPhotoSrcs, photoSrcs2, showFileId);
		if (!photoSrcs) return null;
		return <UserPhotoPreview
			selectedIndex={Number(photoSrcs.selectedIndex)}
			imageList={photoSrcs.list}
			isMobile={isMobile()}
			onClose={() => {
				setShowFileId(undefined);
				navigate(location.pathname);
			}}
		/>;
	}


	return <>
		<UiBadge2 bigPaddings title="Обязательный заголовок 1">
			<UiBadge2.TitleAndStatus title={`№ ${taskId}`}
			                         checkListName={checkListName}
			                         department={department}
			                         status={status}
			                         expiredStatus={expiredStatus ? expiredStatus : undefined}
			                         expiredText={expiredStatus ? ChecklistTaskStatusTitle[expiredStatus] : undefined}
			                         taskCreatedAt={taskCreatedAt}
			                         taskDeadlineAt={taskDeadlineAt}
			                         statusText={ChecklistTaskStatusTitle[status]} />
			<UiBadge2.Values values={values} />
			{questionPhotos ? (
				<UiPhotoGallery2 photos={questionPhotoSrcs} onClickImage={clickImage} readOnly />
			) : null }
		</UiBadge2>
		{shopUserCanExec ? (
			<UiTextareaBadge2
				title="Выполнение задачи:"
				placeholder="Введите комментарий"
				value={execComment}
				maxLength={COMMENT_MAX_LENGTH}
				error={isCommentRequired && formData.fields.executorComment.touched && !!formData.fields.executorComment.error}
				infoText={checkCommentField || (formData.fields.executorComment.touched ? formData.fields.executorComment.error : undefined)}
				onChange={e => !isSaving && updateFormData({ executorComment: e.target.value })}>
					<h1>Фото:</h1>
					<UiPhotoGallery2 photos={photoSrcs2}
									 onClickImage={clickImage}
									 infoText={checkPhotoField || formData.fields.photos.error}
									 onAddPhoto={isLimitPhoto && !isSaving ? onAddPhoto : undefined}
									 readOnly={!isLimitPhoto}
									 isLimit={!isLimitPhoto}
									 onRemovePhoto={!isSaving ? onRemovePhoto : undefined} />
			</UiTextareaBadge2>
		) : (
			<>
				{executorUser ? (
					<UiBadge2 bigPaddings title="Обязательный заголовок 2">
						<h1>Задача выполнена</h1>
						<UiBadge2.Values values={[{
							key: 'Выполнил:',
							value: `${executorUser.fullName} ${closedDate}`,
						}]} />
						{
							<UiBadge2.Values values={[{
								key: 'Комментарий:',
								value: executorComment ? nl2br(executorComment) : 'Отсутствует',
							}]} />
						}
						<UiPhotoGallery2 photos={photoSrcs2} onClickImage={clickImage} readOnly />
					</UiBadge2>
				) : null}
			</>
		)}
		{isSaving ? <Spinner onpage text="Сохранение..." /> : null}
		{overMenu ? <UiOverMenu>{overMenu}</UiOverMenu> : null}
	</>;

	/**
	 * Обновляет данные формы по принципу setState.
	 * Чтобы не ререндерить форму при вводе текста, будет обновлять стейт с задержкой.
	 */
	function updateFormData(inputValue: ChecklistTaskFormDataFieldValue) {
		const { executorComment, photos } = inputValue;
		clearTimeout(updateTimerId);
		const { fields } = formData;
		let waitChanges = false;
		if (executorComment !== undefined) {
			const o = fields.executorComment;
			o.value = executorComment;
			o.touched = true;
			o.hasChanges = o.value !== o.defaultValue;
			waitChanges = true;
		}
		if (photos !== undefined) {
			const o = fields.photos;
			// убираем новые фото, которые потом удалились
			o.value = photos.filter(p => !(p.isNew && p.isDeleted));
			o.touched = true;
			o.hasChanges = o.value.some(i => i.isNew || i.isDeleted);
			waitChanges = false;
		}
		setExecComment(formData.fields.executorComment.value);
		// check errors
		fields.executorComment.error = shopUserCanExec
			? validateString(COMMENT_MIN_LENGTH, COMMENT_MAX_LENGTH)(fields.executorComment.value || '') || undefined : undefined;
		fields.photos.error = shopUserCanExec && fields.photos.value.filter(i => !i.isDeleted).length === 0
			? 'Добавьте фото' : undefined;
		const hasChanges = fields.executorComment.hasChanges || fields.photos.hasChanges;
		formData.hasError = isCommentRequired && !!fields.executorComment.error || isPhotoRequired && !!fields.photos.error;
		const newFormData = Object.assign({}, formData, { hasChanges });
		if (!waitChanges) {
			setFormData(newFormData);
		} else {
			updateTimerId = setTimeout(() => setFormData(newFormData), 500);
		}
	}

	// сохраняет решение задачи и выполняет возврат назад по истории
	function onExecuteChecklistTask() {
		setSaving(true);
		const executionPhotoIds = formData.fields.photos.value
			.filter(p => p.isNew || p.isDeleted)
			.map(photo => {
				if (photo.isDeleted) {
					return { id: photo.id, isDeleted: true };
				}
				if (photo.isNew) {
					return { data: photo.src };
				}
				console.error('Ошибка фотографии', { photo, photos: formData.fields.photos });
				throw new Error('Фотография сформирована с ошибкой');
			});
		execChecklistTask(checklistTaskId, {
			executorComment: formData.fields.executorComment.value,
			taskDeadlineAt,
			executionPhotoIds,
			isPhotoRequired,
			isCommentRequired,
		})
			.then(() => props.onExecuteSuccess())
			.finally(() => setSaving(false));
	}

	// добавление фото
	function onAddPhoto(newPhoto) {
		updateFormData({
			photos: [...formData.fields.photos.value, { src: newPhoto, isNew: true }],
		});
	}

	// удаление фото
	function onRemovePhoto(index) {
		const photo = formData.fields.photos.value.filter(i => !i.isDeleted)[index];
		photo.isDeleted = true;
		updateFormData({ photos: formData.fields.photos.value });
	}

	// клик на фото
	function clickImage(fileId: number | string) {
		setShowFileId(fileId);
	}

}

export function fillFormDataFromChecklistTask(checklistTaskData): ChecklistTaskFormData {
	const photos: any[] = checklistTaskData.executionPhotoIds?.photos.map((photoId: number) => ({
		id: photoId,
		src: `/api/file/download/${photoId}?size=middle`,
	})) || [];
	return {
		fields: {
			executorComment: {},
			photos: {
				defaultValue: photos.map(p => ({ id: p.id, src: p.src })),
				value: photos,
			},
		},
		initialized: true,
		hasError: false,
		hasChanges: false,
	};
}
