import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import { Nowrap } from '~components';
import { copyText } from '~tools/copyText';
import { arrayToMapById } from 'common-lib';

export interface IncidentShop {
	id: number;
	name: string;
	stats: IncidentShopStats;
}

export interface IncidentShopStats {
	all: number;
	done: number;
}

export type UserTreeShop = {
	all: number
	completed: number
	shop: any
}

export type UserTree = {
	all: number
	completed: number
	users?: UserTree[]
	shops?: UserTreeShop[]
	user?: any
}

export type ManagerStatisticsData = {
	stats?: UserTree
	error?: string
}

export type IncidentTypeStat = {
	key: string
	id: number
	name: string
	completed: number
	all: number
}

export type IncidentTypeData = {
	stats?: IncidentTypeStat[]
	error?: string
}

export type IncidentTypeRenderItemData = {
	id: number
	shopId: number
	incidentTypeId: number
	incidentType: any
	goodExternalId: string
	goodName: string
	state: string
	inputData: any
	report: any
}

export type IncidentTypeRenderData = {
	incidentType: any
	incidents: IncidentTypeRenderItemData[]
}

export function fillIncidents(json: any) {
	const {incidents, incidentTypes,goods} = json;
	// фильтр магазинов без инцидентов
	const shopIds = Array.from(new Set(incidents.map(i => i.shopId)));
	const shops = json.shops.filter(s => shopIds.includes(s.id));
	// составление хешей для списков
	const shopMap = arrayToMapById(shops);
	const incTypeMap = arrayToMapById(incidentTypes);
	const goodMap = arrayToMapById(goods);
	// парсинг объектов
	incidentTypes.forEach(it => it.data = JSON.parse(it.data));
	incidents.forEach((i) => {
		i.inputData = JSON.parse(i.inputData);
		i.report = JSON.parse(i.report);
	});
	// обогащение объектов: магазины -> типы инцидентов -> инциденты -> тотвары
	incidents.forEach((i) => {
		const shop = shopMap[i.shopId];
		if (!shop.incidentTypes) shop.incidentTypes = [];
		// поиск типа инцидента в магазине либо добавление нового типа инцидента в магазин
		let incidentType = shop.incidentTypes.find(it => it.id === i.incidentTypeId);
		if (!incidentType) {
			incidentType = Object.assign({ incidents: [] }, incTypeMap[i.incidentTypeId]);
			shop.incidentTypes.push(incidentType);
		}
		incidentType.incidents.push(i);
		delete i.shopId;
		delete i.incidentTypeId;
		i.good = goodMap[i.goodId];
		delete i.goodId;
	});
	// сортировка магазинов по алфавиту
	shops.sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1);
	// для каждого магазина добавляем метод updateStats для обновления статистики
	shops.forEach((shop) => {
		shop.stats = {};
		shop.incidentTypes.forEach(it => it.stats = {});
		let shopAll = 0;
		shop.incidentTypes.forEach(it => {
			it.incidents.forEach(i => {
				it.stats[i.state] = (it.stats[i.state] || 0) + 1;
				shop.stats[i.state] = (shop.stats[i.state] || 0) + 1;
				shopAll++;
			});
			it.stats.all = it.incidents.length;
		});
		// всего
		shop.stats.all = shopAll;

	});
	return {
		incidents: null,
	};
}

export function fillIncidentTypes(json) {
	console.debug('%c*** json=', 'background: #eee; color: blue', json);
	return json;
}

export const INCIDENT_TYPES_TABLE_HEADERS = {
	id: {
		title: '#',
		style: { maxWidth: '150px' },
		viewFunc: (row, hl) => (
			<Popup content="Нажми, чтобы скопировать" trigger={(
				<Nowrap style={{ cursor: 'pointer' }} onClick={event => {
					event.stopPropagation();
					copyText(row.id);
				}}>
					{hl(row.id)}
					<Icon name="copy"
					      style={{ color: '#aaa', fontSize: '0.9em', float: 'right' }} />
				</Nowrap>
			)} />
		),
	},
	name: {
		title: 'Название',
		viewFunc: (row, hl) => (
			<Link to={`/admin/incidents/types/${row.id}`}>
				{row.name ? hl(row.name) : '(не указано)'}
			</Link>
		),
	},
	type: {
		title: 'Тип',
		viewFunc: (row, hl) => row.type ? hl(row.type) : '(не указано)',
	},
	version: 'Версия',
};
