import React, { useEffect, useState } from 'react';
import { ListIsEmpty, Spinner, UiBadge } from '~components';
import * as QuizApi from '~api/quizApi';
import { UserContentWrapper } from '~containers';
import styled from 'styled-components';
import { getServerUrl, QuestionnaireTypeTitle } from 'common-lib';
import { Link, useParams } from 'react-router-dom';
import { UiContainerContentBreadCrumbs } from '~components/ui/UiContainer';

const TitleAndQuizTypeStyle = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	h1 {
        margin-right: 0.3rem;
	}

    .quiz_type {
        font-size: 10px;
        font-weight: bold;
        padding: 2px 8px;
        border-radius: 16px;
        color: gray;
        background-color: #ddd;
        text-transform: uppercase;
    }
`;

const ButtonStyled = styled.button`
    flex: 1;
    margin: 0 6px;
    height: 60px;
    border: 0;
    border-radius: 16px;
    background-color: ${p => p.active ? '#696969' : '#e0e1e2'};
    color: ${p => p.active ? '#fff' : '#333232'};
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    max-width: 400px;

    // для веба разрешаем менять цвет текста и иконок при наведении
    @media (min-width: 900px) {
        &:hover {
	        color: #ffffff;
            background-color: #80878bcc;
        }
    }
`;


const MenuManagerStyled = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 10px 10px;
	background: #fff;
	border-radius: 20px 20px 0 0;
	box-shadow: 0 -2px 7px rgba(0, 0, 0, 0.1);
	z-index: 4;

    a {
        display: contents;
    }

	@media (max-width: 450px), (max-height: 450px) {
        ${ButtonStyled} {
            height: 55px;
		}
	}
	@media (max-width: 380px), (max-height: 380px) {
        ${ButtonStyled} {
            height: 40px;
            font-size: 15px;
        }
	}
`;

export const ResultStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
	margin-top: 10px;

	.result_title {
		margin-right: 0.3rem;
	}

	div {
        display: flex;

		.result_link {
	        margin-right: 0.3rem;

			&:last-child {
				margin-right: 0;
			}
		}
	}

    .divider {
        margin-right: 0.3rem;
    }
`;

export default function UserQuizSubShopPage(props) {
	const [quizList, setQuizList] = useState<any[] | undefined>(undefined);
	const [breadCrumbs, setBreadCrumbs] = useState<UiContainerContentBreadCrumbs | undefined>(undefined);

	const params = useParams();
	const shopId: number = Number(params.shopId);

	useEffect(() => {
		updateListForSubShop();
	}, []);

	function updateListForSubShop() {
		QuizApi.fetchRunningSubShopList(shopId)
			.then(res => {
			if (res.data) {
				setQuizList(res.data.questionList);
				const resultBreadCrumbs: UiContainerContentBreadCrumbs = [];
				resultBreadCrumbs.push({
					title: 'Все менеджеры',
					linkTo: '/quiz-managers'
				});
				const managerIds: number[] = [];
				res.data.breadCrumbs?.forEach(i => {
					if (i.firstName) {
						managerIds.push(i.userId);
						resultBreadCrumbs.push({
							title: `${i.shortTitle} ${i.firstName} ${i.lastName} ${i.middleName}`,
							linkTo: `/quiz-managers/${managerIds}`
						});
					}
					if (i.shopAddress) {
						resultBreadCrumbs.push({
							title: i.shopAddress
						});
					}
				});
				setBreadCrumbs(resultBreadCrumbs);
			}
		});
	}

	if (!quizList) {
		return <Spinner onpage text={<div>Загрузка<br />списка опросов...</div>} />;
	}

	return (
		<UserContentWrapper title="Опросы и тесты" breadCrumbs={breadCrumbs}>
			<>
				{quizList.length ? quizList.map(item => {
					const serverUrl = getServerUrl();
					const pathToQuiz = item.type.toLowerCase(); // quiz | testing
					return (
						<UiBadge bigPaddings key={item.id}>
							<TitleAndQuizTypeStyle>
								<h1>{item.title}</h1>
								<div className="quiz_type">{QuestionnaireTypeTitle[item.type]}</div>
							</TitleAndQuizTypeStyle>
							<UiBadge.DateTimePeriod dateStart={new Date(item.startAt)} dateFinish={new Date(item.finishAt)} />
							{item.repeatCount > 1 ? (
								<UiBadge.QuizCount leftCount={item.repeatCount - item.finishedCount} repeatCount={item.repeatCount} />
							) : null}
							{item.quizResultHashCodeList.length ? (
								<ResultStyle>
									<div className="result_title">Результат:</div>
									{item.quizResultHashCodeList.map((hash: string, hashIndex: number) => {
										return (
											<div key={hash}>
												<div className="result_link">
													<a href={`${serverUrl}/noauth-user/${pathToQuiz}-reports/${hash}/shop/${shopId}`}>
														Сотрудник {hashIndex + 1}
													</a>
												</div>
												{hashIndex !== item.quizResultHashCodeList.length - 1 ? (<div className="divider">|</div>) : null}
											</div>
										);
									})}
								</ResultStyle>
							) : null}
						</UiBadge>
					);
				}) : <ListIsEmpty />}
			</>

			<MenuManagerStyled>
				<ButtonStyled active>
					Опросы магазинов
				</ButtonStyled>
				<Link to="/quiz-list">
					<ButtonStyled>
						Мои опросы
					</ButtonStyled>
				</Link>
			</MenuManagerStyled>
		</UserContentWrapper>
	);
}
