import React from 'react';
import { useSelector } from 'react-redux';
import { ChecklistTaskStatusEnum, ChecklistTaskStatusTitle } from 'common-lib';
import { UiBadge2 } from '~components';

type Props = {
	data: any,
};

export default function ShopChecklistTaskListView(props: Props) {
	const { isShopUser } = useSelector((state: any) => state.me);
	return <>
		{props.data.map(shopChecklistTask => {
			const { checklistTaskId } = shopChecklistTask;
			const expiredStatus = shopChecklistTask.isExpired && ChecklistTaskStatusEnum.EXPIRED;
			const linkTo = isShopUser
				? `/checklist_tasks/${btoa(checklistTaskId)}`
				: `/checklist_tasks/shop/${btoa(shopChecklistTask.shopId)}/${btoa(checklistTaskId)}`;
			return <UiBadge2 key={checklistTaskId}
			                 bigPaddings
			                 linkTo={linkTo}
			                 title="Обязательный заголовок 3">
				<UiBadge2.TitleAndStatus title={shopChecklistTask.taskId}
				                         checkListName={shopChecklistTask.checkListName}
				                         taskCreatedAt={shopChecklistTask.taskCreatedAt}
				                         taskDeadlineAt={shopChecklistTask.taskDeadlineAt}
				                         expiredStatus={expiredStatus}
				                         status={shopChecklistTask.status}
				                         department={shopChecklistTask.department}
				                         expiredText={ChecklistTaskStatusTitle[expiredStatus]}
				                         statusText={ChecklistTaskStatusTitle[shopChecklistTask.status]} />
				<UiBadge2.Values values={shopChecklistTask.values} />
			</UiBadge2>;
		})}
	</>;
}
