import { z } from 'zod';

export const questionnaireReportConfigSchema = z.object({
	messageTitle: z.string(),
	addressees: z.array(z.string()),
});

export interface QuestionnaireReportConfigOptions {
	messageTitle: string;
	addressees: string[];
}

export class QuestionnaireReportConfig {
	readonly messageTitle: string;
	readonly addressees: string[];

	constructor(options: QuestionnaireReportConfigOptions) {
		this.messageTitle = options.messageTitle;
		this.addressees = options.addressees;
		questionnaireReportConfigSchema.parse(this);
	}
}
