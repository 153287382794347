import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions } from '../../../common';

export const questionnaireReportByManagersResponseDtoSchema = z.object({
	fileId: z.number().int().optional(),
});

export interface QuestionnaireReportByManagersResponseDtoOptions extends BaseResponseDtoOptions {
	fileId?: number;
}

export class QuestionnaireReportByManagersResponseDto extends BaseResponseDto {
	readonly fileId?: number;

	constructor(options: QuestionnaireReportByManagersResponseDtoOptions) {
		super(options);
		if (options.fileId !== undefined) this.fileId = options.fileId;
		questionnaireReportByManagersResponseDtoSchema.parse(this);
	}
}