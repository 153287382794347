import React from 'react';
import { Button, Icon, Progress, SemanticCOLORS } from 'semantic-ui-react';
import moment from 'moment';
import styled from 'styled-components';
import {
	QuestionnaireExecutionStatusEnum,
	QuestionnaireExecutionStatusTitle,
	QuestionnaireTypeTitle
} from 'common-lib';
import { Link } from 'react-router-dom';

const ProgressStyle = styled.div`
	display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 5px;
    align-items: center;

	.progress_bar_style {
		.label {
		    top: 0;
    		width: 100%;
		}
	}

	.progress_count {
		display: flex;
		justify-content: center;
		white-space: nowrap;
	}
`;

function getRoles(role: string) {
	if (role === 'shop') return 'магазинов';
	if (role === 'manager') return 'менеджеров';
}
export const TABLE_HEADERS = {
	$file: {
		title: 'Отчет',
		style: { width: '150px' },
		viewFunc: (row: any) => row.status === QuestionnaireExecutionStatusEnum.FINISHED ? (
			<Button icon labelPosition="left" size="tiny" onClick={() => downloadFile(row.id)}>
				<Icon name="file" />
				Скачать
			</Button>
		) : (
			<Button icon labelPosition="left" size="tiny" disabled>
				<Icon name="file" />
				В работе
			</Button>
		),
	},
	$type: {
		title: 'Тип',
		style: { width: '160px' },
		viewFunc: (row: any) => `${QuestionnaireTypeTitle[row.type]} для ${getRoles(row.role)}`,
	},
	$title: {
		title: 'Название опроса / теста',
		viewFunc: (row: any) => (
			<Link to={`/admin/quiz/statistic/${row.id}`} >
				{row.title}
			</Link>
		),
	},
	startAt: {
		title: 'Начало',
		style: { width: '150px' },
		viewFunc: (row: any) => moment(row.startedAt).format('DD.MM.YYYY HH:mm'),
	},
	finishAt: {
		title: 'Конец',
		style: { width: '150px' },
		viewFunc: (row: any) => moment(row.finishedAt).format('DD.MM.YYYY HH:mm'),
	},
	$status: {
		title: 'Статус',
		style: { width: '150px' },
		viewFunc: (row: any) => QuestionnaireExecutionStatusTitle[row.status],
	},
	$stats: {
		title: 'Выполнение',
		style: { width: '150px' },
		viewFunc: (row: any) => (
			<ProgressStyle>
				<Progress
					className='progress_bar_style'
					percent={Math.floor(100 / (row.addresseesCount * row.repeatCount) * row.answeredCount)}
					color={identifyColorProgress(Math.floor(100 / (row.addresseesCount * row.repeatCount) * row.answeredCount))}
					style={{ marginBottom: 0 }}
				>
					{Math.floor(100 / (row.addresseesCount * row.repeatCount) * row.answeredCount)} %
				</Progress>
				<div className='progress_count'>
					{row.answeredCount} / {row.addresseesCount * row.repeatCount}
				</div>
			</ProgressStyle>
		),
	},
};

export const downloadFile = (questExecId: number) => {
	const a = document.createElement('a');
	a.setAttribute('style', 'display: none');
	document.body.appendChild(a);
	a.setAttribute('href', `/api/questionnaires/reports/download/${questExecId}`);
	a.click();
	document.body.removeChild(a);
};

// Определяем цвет для прогресс бара по процентам
export const identifyColorProgress = (percent): SemanticCOLORS =>  {
	if (percent < 20   ) {
		return 'red';
	} else if (percent <= 89) {
		return 'yellow';
	} else if (percent >= 90 ) {
		return 'green';
	} else {
		return 'grey';
	}
};
