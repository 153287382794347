import { Colors } from './colors';

export enum TaskStatusEnum {
	IN_PROGRESS = 'IN_PROGRESS',
	DONE = 'DONE',
	REJECTED = 'REJECTED',
	EXPIRED = 'EXPIRED',
}

export const TaskStatusTitle = {
	[TaskStatusEnum.IN_PROGRESS]: 'В работе',
	[TaskStatusEnum.DONE]: 'Выполнено',
	[TaskStatusEnum.REJECTED]: 'Отклонено',
	[TaskStatusEnum.EXPIRED]: 'Просрочено',
};

export const TaskStatusColor = {
	[TaskStatusEnum.IN_PROGRESS]: Colors.warn,
	[TaskStatusEnum.DONE]: Colors.success,
	[TaskStatusEnum.REJECTED]: Colors.error,
	[TaskStatusEnum.EXPIRED]: Colors.default,
};
