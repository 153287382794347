import { z } from 'zod';
import { StructureImportStateEnum } from 'common-lib';

export const dashboardStructureImportDateItemSchema = z.object({
	id: z.number().int(),
	fileId: z.number().int(),
	logId: z.number().int(),
	status: z.nativeEnum(StructureImportStateEnum),
	createdUsersCount: z.number().int(),
	updatedUsersCount: z.number().int(),
	deletedUsersCount: z.number().int(),
	createdShopsCount: z.number().int(),
	updatedShopsCount: z.number().int(),
	deletedShopsCount: z.number().int(),
	warnCount: z.number().int(),
	ownerId: z.number().int(),
	createdAt: z.instanceof(Date),
});

export interface DashboardStructureImportDataItemOptions {
	id: number;
	fileId: number;
	logId: number;
	status: StructureImportStateEnum;
	createdUsersCount: number;
	updatedUsersCount: number;
	deletedUsersCount: number;
	createdShopsCount: number;
	updatedShopsCount: number;
	deletedShopsCount: number;
	warnCount: number;
	ownerId: number;
	createdAt: Date | string;
}

export class DashboardStructureImportDataItem {
	readonly id: number;
	readonly fileId: number;
	readonly logId: number;
	readonly status: StructureImportStateEnum;
	readonly createdUsersCount: number;
	readonly updatedUsersCount: number;
	readonly deletedUsersCount: number;
	readonly createdShopsCount: number;
	readonly updatedShopsCount: number;
	readonly deletedShopsCount: number;
	readonly warnCount: number;
	readonly ownerId: number;
	readonly createdAt: Date;

	constructor(options: DashboardStructureImportDataItemOptions) {
		this.id = options.id;
		this.fileId = options.fileId;
		this.logId = options.logId;
		this.status = options.status;
		this.createdUsersCount = options.createdUsersCount;
		this.updatedUsersCount = options.updatedUsersCount;
		this.deletedUsersCount = options.deletedUsersCount;
		this.createdShopsCount = options.createdShopsCount;
		this.updatedShopsCount = options.updatedShopsCount;
		this.deletedShopsCount = options.deletedShopsCount;
		this.warnCount = options.warnCount;
		this.ownerId = options.ownerId;
		if (options.createdAt instanceof Date) {
			this.createdAt = options.createdAt;
		} else {
			this.createdAt = new Date(options.createdAt);
		}
		dashboardStructureImportDateItemSchema.parse(this);
	}
}