import { PageInfo } from 'common-lib';
import {
	QuestionnaireCreateRequestDto,
	QuestionnaireCreateResponseDto,
	QuestionnaireListResponseDto,
	QuestionnaireOneResponseDto,
	QuestionnaireDuplicateResponseDto,
	QuestionnaireReportListResponseDto,
	QuestionnaireRunningListResponseDto,
	QuestionnaireManagerStatsListResponseDto,
	QuestionnaireRunningSubShopListResponseDto,
	QuestionnaireQuizReportResultResponseDto,
	QuestionnaireTestingReportResultResponseDto,
	QuestionnaireAnswerQuizRequestDto,
	QuestionnaireAnswerTestingRequestDto,
	QuestionnaireStatisticReportResponseDto,
	QuestionnaireReportByShopsResponseDto,
	QuestionnaireReportByManagersResponseDto,
	QuestionnairePhotoApplicationResponseDto,
} from 'protocol-lib';
import { fetchDelete, fetchGetThenJson, fetchPost, fetchPostThenJson } from '~tools/fetchBackend';

export async function fetchQuizList(page: number = 1, showPerPage: number = 10, order: string = 'id:desc'): Promise<QuestionnaireListResponseDto> {
	return await fetchGetThenJson(`questionnaires/list?page=${page}:${showPerPage}&order=${order}`);
}

export async function fetchQuiz(id: number): Promise<QuestionnaireOneResponseDto> {
	return await fetchGetThenJson(`questionnaires/one/${id}`);
}

export async function createQuiz(payload: QuestionnaireCreateRequestDto): Promise<QuestionnaireCreateResponseDto> {
	return await fetchPostThenJson('questionnaires/create', payload);
}

export async function createQuizDuplicate(id: number): Promise<QuestionnaireDuplicateResponseDto> {
	return await fetchPostThenJson(`questionnaires/duplicate/${id}`);
}

export async function enableQuiz(id: number): Promise<Response> {
	return await fetchPost(`questionnaires/enable/${id}`);
}

export async function disableQuiz(id: number): Promise<Response> {
	return await fetchPost(`questionnaires/disable/${id}`);
}

export async function deleteQuiz(id: number): Promise<Response> {
	return await fetchDelete(`questionnaires/one/${id}`);
}

export async function fetchReportsList(pageInfo: PageInfo): Promise<QuestionnaireReportListResponseDto> {
	const query = pageInfo.toQueryString();
	return await fetchGetThenJson(`questionnaires/reports/list${query ? `?${query}` : ''}`);
}

export async function fetchRunningQuizList(): Promise<QuestionnaireRunningListResponseDto> {
	return await fetchGetThenJson('questionnaires/running');
}
export async function fetchRunningQuizListForManager(): Promise<QuestionnaireRunningListResponseDto> {
	return await fetchGetThenJson('questionnaires_for_manager/running');
}

/**
 * Запрос данных статистики по опросам для текущего менеджера
 */
export async function fetchQuizCurrentManagerStats(pageInfo?: PageInfo): Promise<QuestionnaireManagerStatsListResponseDto> {
	return await fetchGetThenJson(`questionnaires/manager-stats`);
}

/**
 * Запрос списка опросов подчиненного магазина из ЛК менеджера
 */

export async function fetchRunningSubShopList(shopId: number): Promise<QuestionnaireRunningSubShopListResponseDto> {
	return await fetchGetThenJson(`questionnaires/sub-shop/${shopId}`);
}

/**
 * Запрос списка опросов подчиненного менеджера todo: Не используется
 */
export async function fetchQuizListForSelectedManager(managerId: number): Promise<any> {
	return await fetchGetThenJson(`questionnaires/sub-user/${managerId}`);
}

export async function answerQuiz(id: number, payload: QuestionnaireAnswerQuizRequestDto[]): Promise<Response> {
	return await fetchPost(`questionnaires/answer/${id}`, payload);
}

export async function answerTest(id: number, payload: QuestionnaireAnswerTestingRequestDto[], result: number): Promise<Response> {
	return await fetchPost(`testing/answer/${id}`, { payload, result });
}

export async function getPublicQuizReportResult(quizHash: string): Promise<QuestionnaireQuizReportResultResponseDto | QuestionnaireTestingReportResultResponseDto> {
	return await fetchGetThenJson(`public/questionnaires/result/${quizHash}`);
}

export async function getStatReportId(id: number): Promise<QuestionnaireStatisticReportResponseDto> {
	return await fetchGetThenJson(`questionnaires/statistic/one/${id}`);
}

export async function createReportByShops(execId: number, shops: number[], selectedFilter: number): Promise<QuestionnaireReportByShopsResponseDto> {
	return await fetchPostThenJson(`questionnaires/create_report/${execId}`, { shopIds: shops, selectedFilter});
}

export async function createReportByManagers(execId: number, managers: number[], selectedFilter: number): Promise<QuestionnaireReportByManagersResponseDto> {
	return await fetchPostThenJson(`questionnaires/create_report_by_managers/${execId}`, { managerIds: managers, selectedFilter});
}

export async function fetchPhotoApplication(data: any): Promise<QuestionnairePhotoApplicationResponseDto> {
	return await fetchPostThenJson('questionnaires/photo_application', data);
}