import { z } from 'zod';
import { QuestionItem, QuestionItemOptions, questionItemSchema } from './QuestionItem';
import {
	BreadCrumbItem,
	BreadCrumbItemOptions,
	breadCrumbItemSchema,
} from '../../../meta';

export const questionnaireRunningSubShopItemSchema = z.object({
	questionList: z.array(questionItemSchema).optional(),
	breadCrumbs: z.array(breadCrumbItemSchema).optional()
});

export interface QuestionnaireRunningSubShopItemOptions {
	questionList?: (QuestionItem | QuestionItemOptions)[];
	breadCrumbs?: (BreadCrumbItem | BreadCrumbItemOptions)[];
}

export class QuestionnaireRunningSubShopItem {
	readonly questionList?: QuestionItem[];
	readonly breadCrumbs?: BreadCrumbItem[];

	constructor(options: QuestionnaireRunningSubShopItemOptions) {
		if (options.questionList?.length) {
			this.questionList = [];
			for (const item of options.questionList) {
				if (item instanceof QuestionItem) {
					this.questionList.push(item);
				} else {
					this.questionList.push(new QuestionItem(item));
				}
			}
		}
		if (options.breadCrumbs?.length) {
			this.breadCrumbs = [];
			for (const item of options.breadCrumbs) {
				if (item instanceof BreadCrumbItem) {
					this.breadCrumbs.push(item);
				} else {
					this.breadCrumbs.push(new BreadCrumbItem(item));
				}
			}
		}
		questionnaireRunningSubShopItemSchema.parse(this);
	}
}