import { z } from 'zod';

export const breadCrumbItemSchema = z.object({
	userId: z.number().int(),
	shortTitle: z.string(),
	subLevel: z.number().int(),
	firstName: z.string(),
	lastName: z.string(),
	middleName: z.string(),
	shopAddress: z.string().optional()
});

export interface BreadCrumbItemOptions {
	userId: number;
	shortTitle: string;
	subLevel: number;
	firstName: string;
	lastName: string;
	middleName: string;
	shopAddress?: string;
}

export class BreadCrumbItem {
	readonly userId: number;
	readonly shortTitle: string;
	readonly subLevel: number;
	readonly firstName: string;
	readonly lastName: string;
	readonly middleName: string;
	readonly shopAddress?: string;

	constructor(options: BreadCrumbItemOptions) {
		this.userId = options.userId;
		this.shortTitle = options.shortTitle;
		this.subLevel = options.subLevel;
		this.firstName = options.firstName;
		this.lastName = options.lastName;
		this.middleName = options.middleName;
		if (options.shopAddress !== undefined) this.shopAddress = options.shopAddress;
		breadCrumbItemSchema.parse(this);
	}
}