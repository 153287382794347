import { z } from 'zod';
import { IncidentImportStateEnum } from 'common-lib';

export const dashboardIncidentImportDataItemSchema = z.object({
	id: z.number().int(),
	fileId: z.number().int(),
	date: z.string(),
	incidentTypeId: z.number().int(),
	state: z.nativeEnum(IncidentImportStateEnum),
	addedCount: z.number().int(),
	warnCount: z.number().int(),
	ownerId: z.number().int(),
	createdAt: z.instanceof(Date),
});

export interface DashboardIncidentImportDataItemOptions {
	id: number;
	fileId: number;
	date: string;
	incidentTypeId: number;
	state: IncidentImportStateEnum;
	addedCount: number;
	warnCount: number;
	ownerId: number;
	createdAt: Date | string;
}

export class DashboardIncidentImportDataItem {
	readonly id: number;
	readonly fileId: number;
	readonly date: string;
	readonly incidentTypeId: number;
	readonly state: IncidentImportStateEnum;
	readonly addedCount: number;
	readonly warnCount: number;
	readonly ownerId: number;
	readonly createdAt: Date;

	constructor(options: DashboardIncidentImportDataItemOptions) {
		this.id = options.id;
		this.fileId = options.fileId;
		this.date = options.date;
		this.incidentTypeId = options.incidentTypeId;
		this.state = options.state;
		this.addedCount = options.addedCount;
		this.warnCount = options.warnCount;
		this.ownerId = options.ownerId;
		if (options.createdAt instanceof Date) {
			this.createdAt = options.createdAt;
		} else {
			this.createdAt = new Date(options.createdAt);
		}
		dashboardIncidentImportDataItemSchema.parse(this);
	}
}