import React from 'react';
import PropTypes from 'prop-types';
import { ErrorPage } from './index';

interface IPageNotFoundProps {
	children?: React.ReactNode;
}

class PageNotFound extends React.Component<IPageNotFoundProps>
{
	static propTypes = {
		children: PropTypes.node,
	};

	render() {
		return (
			<ErrorPage title="404: Страница не найдена">
				{this.props.children}
			</ErrorPage>
		);
	}
}


export default PageNotFound;
