import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { UserDataItem, UserDataItemOptions, userDataItemSchema } from '../../users';

export const infoMessagesSearchUserListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(userDataItemSchema).optional(),
	}),
);

export interface InfoMessagesSearchUserListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (UserDataItem | UserDataItemOptions)[];
}

export class InfoMessagesSearchUserListResponseDto extends BaseResponseDto {
	data?: UserDataItem[];

	constructor(options: InfoMessagesSearchUserListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof UserDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new UserDataItem(item));
				}
			}
		}
		infoMessagesSearchUserListResponseDtoSchema.parse(this);
	}
}