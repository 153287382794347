import fetchBackend from '~tools/fetchBackend';

export const login = credentials => fetchBackend('post', 'login', credentials);

export const relogin = () => fetchBackend('post', 'relogin');

export const logout = () => fetchBackend('get', 'logout')
  .then(response => response.json && response.json());

export const signAsUser = bodyData => () => fetchBackend('post', 'sign-as-user', bodyData);
