import React, { CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import { getRoleName, UserRoles } from 'common-lib';
import { TRANSPARENT_1PX } from '~components/ui/images';
import { UiBadge } from '~components/index';

type SelectSubordinatesProps = {
	style?: CSSProperties,
	subordinates: Subordinates,
	treeInfo: CheckedSubordinatesValue,
	onChange: (data: any) => void,
	filter?: number,
};

export type Subordinates = {
	subordinateUsers?: SubordinateUser[],
	subordinateShops?: SubordinateShop[],
	userMap: Map<number, SubordinateUser>,
	shopMap: Map<number, SubordinateShop>,
};

export type SubordinateUser = {
	id: number,
	shopCount: number,
	role: UserRoles,
	lastName: string,
	firstName: string,
	middleName: string,
	fullName: string,
	shortName: string,
	parent?: SubordinateUser,
	subordinateUsers?: SubordinateUser[],
	subordinateShops?: SubordinateShop[],
	shopManagerRole?: any,
};

export type SubordinateShop = {
	id: number,
	city: string,
	address: string,
	upfId?: number,
	tmId?: number,
	dfId?: number,
	supervisorUser: SubordinateUser,
};

export type CheckedSubordinatesValue = {
	checkedShops: Set<number>,
	checkedUsers: Set<number>,
	checkedNotallUsers: Set<number>,
	openedBranches: Set<number>,
	branchSelectedCount: Map<number, number>,
	checkedManagers?: Set<number>,
};

export default function AddresseeSelector(props: SelectSubordinatesProps) {
	const { subordinates, treeInfo, onChange, filter } = props;

	useEffect(() => {
		// обновляем галки если в списке есть выбранные
		recountTree(subordinates, treeInfo, filter);
		onCollapseChange();
	}, []);

	const onCheckedChange = () => {
		recountTree(subordinates, treeInfo, filter);
		onChange(treeInfo);
	};

	const onCollapseChange = () => {
		onChange(treeInfo);
	};

	return (
		<div style={props.style}>
			{filter ? (
				<>
					{subordinates.subordinateUsers?.map(user => {
						return (
							<UiBadge key={`user_${user.id}`}>
								{renderSubLevel(user, treeInfo, onCheckedChange, onCollapseChange, filter)}
							</UiBadge>
						);
					})}
				</>
			) : (
				<>
					{subordinates.subordinateUsers?.map(user => {
						return (
							<UiBadge key={`user_${user.id}`}>
								{renderSubLevel(user, treeInfo, onCheckedChange, onCollapseChange)}
							</UiBadge>
						);
					})}
					{subordinates.subordinateShops?.map(shop => {
						return (
							<UiBadge key={`shop_${shop.id}`}>
								{renderSubShop(shop, treeInfo, onCheckedChange)}
							</UiBadge>
						);
					})}
				</>
			)}
		</div>
	);
}

const ItemLevelStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 8px;

  .info {
    flex: 1;
    overflow: hidden;
    padding: 0 16px;
  }

  .fio {
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .counter {
    font-size: 11px;
    color: #9696A6;
  }
`;

const CheckerImg = styled.img`
  width: 24px;
  height: 24px;
  background-image: ${p => !p.empty ? `url(/assets/img/checker-${p.checked ? 'checked' : p.notall ? 'notall' : 'empty'}.svg);` : 'none'};
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const CollapseImg = styled.img`
  width: 24px;
  height: 24px;
  background-image: url(/assets/img/collapse-${p => p.opened ? 'open' : 'close'}.svg);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const SubLevelStyled = styled.div`
  padding-left: 16px;
`;

function recountTree(subordinateItems, treeInfo: CheckedSubordinatesValue, filter?) {
	const { checkedUsers, checkedShops, checkedNotallUsers, branchSelectedCount } = treeInfo;
	checkedUsers.clear();
	checkedNotallUsers.clear();
	branchSelectedCount.clear();
	if (filter) {
		const foo = user => {
			let managerCount = 0;
			if (treeInfo.checkedManagers?.has(user.id)) {
				treeInfo.checkedUsers.add(user.id);
			} else {
				treeInfo.checkedUsers.delete(user.id);
			}
			user.subordinateUsers?.forEach(subUser => {
				foo(subUser);
				if (treeInfo.checkedManagers?.has(subUser.id)) managerCount++;
			});
			if (managerCount > 0) {
				branchSelectedCount.set(user.id, managerCount);
			}
			return managerCount;
		};
		subordinateItems.subordinateUsers?.forEach(foo);
	} else {
		const foo = user => {
			let subCount = 0;
			user.subordinateUsers?.forEach(subUser => {
				subCount += foo(subUser);
			});
			user.subordinateShops?.forEach(subShop => {
				if (checkedShops.has(subShop.id)) subCount++;
			});
			if (subCount > 0) {
				if (user.shopCount === subCount) {
					checkedUsers.add(user.id);
				} else {
					checkedNotallUsers.add(user.id);
				}
				branchSelectedCount.set(user.id, subCount);
			}
			return subCount;
		};
		subordinateItems.subordinateUsers?.forEach(foo);
	}
}

/**
 * Меняется отметка чекера менеджера
 */
function checkUser(user, treeInfo: CheckedSubordinatesValue, onChange: () => void, filter?) {
	if (filter) {
		const { checkedManagers } = treeInfo;
		if (checkedManagers) {
			if (checkedManagers.has(user.id)) {
				checkedManagers.delete(user.id);
			} else {
				checkedManagers.add(user.id);
			}
			onChange();
		}

	} else {
		if (!user.shopCount) return; // fool check
		const flag = !treeInfo.checkedUsers.has(user.id);
		// ставим или снимаем отметки в дереве для подчиненных магазинов
		const foo = subUser => {
			subUser.subordinateShops?.forEach(shop => {
				treeInfo.checkedShops[flag ? 'add' : 'delete'](shop.id);
			});
			subUser.subordinateUsers?.forEach(foo);
		};
		foo(user);
		onChange();
	}
}

/**
 * Меняется отметка чекера магазина
 */
function checkShop(shop, treeInfo: CheckedSubordinatesValue, onChange: () => void) {
	const { checkedShops } = treeInfo;
	checkedShops[checkedShops.has(shop.id) ? 'delete' : 'add'](shop.id);
	onChange();
}

function renderSubLevel(user, treeInfo: CheckedSubordinatesValue, onCheckedChange, onCollapseChange, filter?) {
	const { subordinateUsers, subordinateShops } = user;
	const isOpened = treeInfo.openedBranches.has(user.id);
	return (
		<>
			{filter ? (
				<div key={user.id}>
					<ItemLevelStyled>
						<CheckerImg src={TRANSPARENT_1PX}
						            checked={treeInfo.checkedUsers.has(user.id)}
						            notall={treeInfo.checkedNotallUsers.has(user.id)}
						            onClick={() => checkUser(user, treeInfo, onCheckedChange, filter)} />
						<div className="info">
							<div className="fio">{user.shopManagerRole?.shortTitle || getRoleName(user.role)} {user.fullName}</div>
							{treeInfo.branchSelectedCount.has(user.id)  && user.userCount > 0 ? (
								<div className="counter">
									Выбрано
									подчиненных: {treeInfo.branchSelectedCount.get(user.id)} из {user.userCount}
								</div>
							) : (
								<div className="counter">
									{user.userCount > 0 ? `Подчиненных: ${user.userCount}` : 'Нет подчиненных'}
								</div>
							)}
						</div>
						{subordinateUsers?.length ? (
							<CollapseImg src={TRANSPARENT_1PX} opened={isOpened} onClick={() => {
								if (isOpened) {
									treeInfo.openedBranches.delete(user.id);
								} else {
									treeInfo.openedBranches.add(user.id);
								}
								onCollapseChange();
							}} />
						) : null}
					</ItemLevelStyled>
					{isOpened && subordinateUsers?.length ? (
						<SubLevelStyled>
							{subordinateUsers.map(subUser => renderSubLevel(subUser, treeInfo, onCheckedChange, onCollapseChange, filter))}
						</SubLevelStyled>
					) : null}
				</div>
			) : (
				<div key={user.id}>
					<ItemLevelStyled>
						<CheckerImg src={TRANSPARENT_1PX}
						            empty={!user.shopCount}
						            checked={treeInfo.checkedUsers.has(user.id)}
						            notall={treeInfo.checkedNotallUsers.has(user.id)}
						            onClick={() => checkUser(user, treeInfo, onCheckedChange)} />
						<div className="info">
							<div className="fio">{user.shopManagerRole?.shortTitle || getRoleName(user.role)} {user.fullName}</div>
							{treeInfo.branchSelectedCount.has(user.id) ? (
								<div className="counter">
									Выбрано
									магазинов: {treeInfo.branchSelectedCount.get(user.id)} из {user.shopCount}
								</div>
							) : (
								<div className="counter">
									{user.shopCount ? `Магазинов: ${user.shopCount}` : 'Нет подчиненных магазинов'}
								</div>
							)}
						</div>
						{subordinateUsers?.length || subordinateShops?.length ? (
							<CollapseImg src={TRANSPARENT_1PX} opened={isOpened} onClick={() => {
								if (isOpened) {
									treeInfo.openedBranches.delete(user.id);
								} else {
									treeInfo.openedBranches.add(user.id);
								}
								onCollapseChange();
							}} />
						) : null}
					</ItemLevelStyled>
					{isOpened && subordinateUsers?.length ? (
						<SubLevelStyled>
							{subordinateUsers.map(subUser => renderSubLevel(subUser, treeInfo, onCheckedChange, onCollapseChange))}
						</SubLevelStyled>
					) : null}
					{isOpened && subordinateShops?.length ? (
						<SubLevelStyled>
							{subordinateShops.map(shop => renderSubShop(shop, treeInfo, onCheckedChange))}
						</SubLevelStyled>
					) : null}
				</div>
			)}
		</>
	);
}

function renderSubShop(shop: SubordinateShop, treeInfo: CheckedSubordinatesValue, onCheckedChange) {
	const isChecked = treeInfo.checkedShops.has(shop.id);
	return (
		<ItemLevelStyled key={shop.id}>
			<CheckerImg src={TRANSPARENT_1PX} checked={isChecked}
			            onClick={() => checkShop(shop, treeInfo, onCheckedChange)} />
			<div className="info">
				<div className="fio">{shop.address || 'Адрес не указан'}</div>
				<div className="counter">{shop.city || 'Без города'}</div>
			</div>
		</ItemLevelStyled>
	);
}
