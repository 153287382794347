import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import UiBadge from './UiBadge';

const BadgeStyle = styled.div`
  padding: 8px;
`;

const TextareaStyle = styled.textarea`
  width: 100%;
  resize: vertical;
  min-height: 70px;
  max-height: 300px;
  margin: 5px auto 0 auto;
  padding: 8px;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  background-color: #F7F8F9;
  color: #7F7F87;
  font-size: 16px;

  &.error {
    border-color: #C66C6D;
    border-width: 2px;
  }

  &::placeholder {
    color: #BFBFC7;
  }
`;

const InfoStyled = styled.div`
  color: #8790A6;
  font-size: 12px;
  font-weight: 400;
`;

type Props = {
	title: string         // заголовок
	maxLength?: number
	defaultValue?: string
	placeholder?: string
	onChange?: Function   // обработчик клика
	error?: boolean
	infoText?: string
}

export default function UiTextareaBadge(props: Props) {
	const ref = useRef<HTMLTextAreaElement>();
	useEffect(() => {
		ref.current!!.addEventListener('focus', () => {
			// выполняем скролл текстового поля в видимую область экрана
			// на разных телефонах разная реакция, поэтому скроллим несколько раз
			scrollTextarea(300);
			scrollTextarea(600);
			scrollTextarea(1000);
		});
	});

	function scrollTextarea(ms) {
		setTimeout(() => {
			const current = ref.current;
			if (current) {
				const toTop = current.offsetTop + current.clientHeight - window.innerHeight + 120;
				window.scroll({ top: toTop, behavior: 'auto' });
			}
		}, ms);
	}

	return (
		<UiBadge>
			<BadgeStyle>
				<h1>{props.title}</h1>
				<TextareaStyle
					ref={ref}
					defaultValue={props.defaultValue || ''}
					maxLength={props.maxLength}
					placeholder={props.placeholder || ''}
					onChange={props.onChange}
					className={props.error ? 'error' : undefined}
				/>
				{props.infoText ? <InfoStyled>{props.infoText}</InfoStyled> : null}
			</BadgeStyle>
		</UiBadge>
	);
}
