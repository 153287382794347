import querystring from 'querystring';

const PAGE_INFO_FIELDS = ['pageIndex', 'pageSize', 'orderColumn', 'orderDirection', 'meta', 'search'];

type OrderDirection = 'desc' | 'asc';

type Meta = Set<string>;

type Primitive = { [key: string]: any };

type CustomParams = Primitive;

/**
 * Класс используется для парсинга строки location.search и объекта request.query.
 * На входе подается строка в формате:
 * `url?page=<pageIndex>:<pageSize>&order=<col>:<dir>&meta=<meta1>,<metaN>&search=<text>`
 * Также используется для формирования query строки.
 */
export class PageInfo
{
	private _pageIndex?: number;
	private _pageSize?: number;
	private _orderColumn?: string | string[];
	private _orderDirection?: OrderDirection;
	private _meta?: Meta;
	private _search?: string;
	private _searchParts?: string[];
	private _customParams?: CustomParams;

	constructor(pageIndex: number, pageSize: number, orderColumn: string,
	            orderDirection: OrderDirection, meta: Meta, search: string, customParams: CustomParams) {
		// парсим строку поиска
		// строка поиска может содержать несколько фраз разделенных подстрокой " OR "
		search = search?.trim();
		this.search = search;
		Object.assign(this, {
			pageIndex,
			pageSize,
			orderColumn,
			orderDirection,
			meta,
			customParams,
		})
		Object.seal(this); // запечатаем набор полей в объекте
	}

	get pageIndex(): number | undefined {
		return this._pageIndex;
	}

	set pageIndex(value: number | undefined) {
		this._pageIndex = (value !== undefined && Number.isFinite(value) && value >= 0)
			? Math.max(0, Number(value))
			: undefined
	}

	get pageSize(): number | undefined {
		return this._pageSize;
	}

	set pageSize(value: number | undefined) {
		this._pageSize = (value !== undefined && Number.isFinite(value) && value >= 0)
			? Math.max(1, Number(value))
			: undefined
	}

	get orderColumn(): string | string[] | undefined {
		return this._orderColumn;
	}

	set orderColumn(value: string | string[] | undefined) {
		this._orderColumn = value || undefined;
	}

	get orderDirection(): OrderDirection | undefined {
		return this._orderDirection;
	}

	set orderDirection(value: OrderDirection | undefined) {
		this._orderDirection = value && value.toLowerCase() === 'desc' ? 'desc' : 'asc';
	}

	get meta(): Meta | undefined {
		return this._meta;
	}

	/**
	 * Установка meta.
	 * @param value {Set<string>}
	 */
	set meta(value) {
		this._meta = value && value.size ? value : undefined
	}

	get search(): string | undefined {
		return this._search;
	}

	set search(value) {
		this._search = value || undefined;
		let searchParts: string[] | undefined = String(value || '')
			.split(' OR ')
			.map(s => s.trim())
			.filter(Boolean);
		if (!searchParts.length) {
			searchParts = undefined;
		}
		this._searchParts = searchParts;
	}

	get searchParts(): string[] | undefined {
		return this._searchParts;
	}

	get customParams(): CustomParams | undefined {
		return this._customParams;
	}

	set customParams(value) {
		this._customParams = value || undefined;
	}

	/**
	 * Обновляет поля
	 * @param obj
	 */
	update = (obj: Primitive): void => {
		Object.keys(obj).forEach((key: string) => {
			if (PAGE_INFO_FIELDS.includes(key)) {
				// @ts-ignore
				this[key] = obj[key];
			} else {
				if (obj[key] === undefined) {
					if (this._customParams && key in this._customParams) {
						delete this._customParams[key];
					}
				} else {
					if (!this._customParams) {
						this._customParams = {};
					}
					this._customParams[key] = obj[key];
				}
			}
		})
	}

	toQueryObject = (): Primitive => {
		return {
			page: `${this.pageIndex}:${this.pageSize}`,
			order: `${this.orderColumn}:${this.orderDirection}`,
			search: this.search || undefined,
			meta: this._meta?.size ? Array.from(this._meta).toString() : undefined,
			...this.customParams,
		}
	}

	toQueryString = () => {
		const obj = this.toQueryObject()
		return Object.keys(obj)
			.filter(key => obj[key])
			.map(key => `${key}=${encodeURI(obj[key])}`)
			.join('&')
	}

	toDto = () => {
		return PAGE_INFO_FIELDS.reduce((dto, key) => {
			// @ts-ignore
			if (this[key] !== undefined) {
				// @ts-ignore
				dto[key] = this[key];
			}
			return dto
		}, {} as Primitive)
	}

	/**
	 * На входе получает строку из location.search. Парсит по формату:
	 * `url?page=<pageIndex>:<pageSize>&order=<col>:<dir>&meta=<meta1>,<metaN>&search=<string>`
	 * @param str
	 * @param defaults {any | undefined}
	 * @returns {PageInfo}
	 */
	static parseFromString(str: string, defaults?: any): PageInfo {
		if (str.substr(0, 1) === '?') {
			str = (str || '').substr(1)
		}
		return PageInfo.parseFromObject(querystring.parse(str), defaults);
	}

	/**
	 * На входе получает объект, сформированный из req.query (Node) или location.search (Web).
	 * @param obj
	 * @param defaults {any | undefined}
	 * @returns {PageInfo}
	 */
	static parseFromObject(obj: any, defaults?: any): PageInfo {
		const {
			page: defPage,
			pageIndex: defPageIndex,
			pageSize: defPageSize,
			order: defOrder,
			meta: defMeta,
			search: __unused_defSearch, // нужно выпилить из defCustomParams
			...defCustomParams
		} = defaults || {} as any;
		const {
			page = defPage || undefined,
			order = defOrder || 'id',
			meta: metaS = defMeta || undefined,
			search,
			...customParams
		} = obj;
		const [pageIndex = defPageIndex || 0, pageSize = defPageSize || 50] = String(page || '')
			.split(':')
			.map((s: string) => Number.isNaN(s) ? undefined : +s);
		const [orderColumn = 'id', orderDirection = 'asc'] = String(order || '').split(':');
		const meta = !metaS
			? null
			: metaS
				.split(',')
				.map((s: string) => s.toLowerCase())
				.reduce((set: Set<string>, s: string) => set.add(s), new Set<string>());
		// из дефолтных тоже что-то может попасть в customParams
		Object.keys(defCustomParams).forEach(key => {
			if (!customParams.hasOwnProperty(key)) {
				customParams[key] = defCustomParams[key];
			}
		});
		return new PageInfo(pageIndex, pageSize, orderColumn, orderDirection as OrderDirection,
			meta, search as string, customParams);
	}
}
