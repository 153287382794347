import styled from 'styled-components';

export const ContainerStyled = styled.div`
	background-color: ${p => p.theme.containerBg};
	min-height: 100%;
`;

export const HeaderStyled = styled.div`
	position: fixed;
	z-index: 2;
	left: 0;
	right: 0;
	top: 0;
	background-color: ${p => p.theme.headerBg};
	color: ${p => p.theme.headerColor};
	font-size: ${p => p.theme.headerFontSize};

	& > table {
		width: 100%;
		height: 68px;
		padding: 15px 6px 0 12px;

		td {
			padding: 4px 0;
			vertical-align: bottom;
		}

		td.backArrow {
			width: 0;
			padding: 0 3px 12px 0;
			line-height: 0;

			a {
				display: block;
			}

			// для веба разрешаем менять цвет текста и иконок при наведении
			@media (min-width: 900px) {
				a:hover {
					opacity: 0.5;
				}
			}

			img {
				width: 24px;
				height: 24px;
			}
		}

		td.title {
			padding: 0 0 0 4px;
			vertical-align: middle;
			font-weight: bold;
			line-height: 24px;

			div {
				max-height: 50px;
				overflow: hidden;
				// меняем размер шрифта под разную ширину экрана, чтобы текст влез в одну строку
				@media (max-width: 720px) {
					font-size: ${p => p.titleLength > 50 ? 14 : p.titleLength > 40 ? 16 : p => p.titleLength > 30 ? 20 : 24}px;
				}
				@media (max-width: 570px) {
					font-size: ${p => p.titleLength > 35 ? 14 : p.titleLength > 26 ? 16 : p => p.titleLength > 22 ? 20 : 24}px;
				}
				@media (max-width: 430px) {
					font-size: ${p => p.titleLength > 25 ? 14 : p.titleLength > 22 ? 16 : p => p.titleLength > 18 ? 20 : 24}px;
				}

				div.subTitle {
					font-size: 16px;
					color: #eee;
					font-weight: 500;
				}
			}
		}

		td.ringbell {
			width: 30px;
			padding: 0 0 10px 12px;
			cursor: pointer;
		}

		td.hamburger {
			width: 30px;
			padding: 0 0 6px 12px;
		}
		td.copy_link {
			width: 30px;
		  	div {
				display: flex;
    			flex-direction: column;
    			align-items: center;
			  	padding-right: 10px;
			  	cursor: pointer;

		  		p {
					font-size: 12px;
					white-space: nowrap;
		    	}
		    }

			@media(max-width: 720px) {
				display: none;
			}
		}
	}

	@media (min-width: 1000px) {
		& > table {
			margin-left: 5%;
			width: 95%;
		}
	}

	.corners {
		position: relative;
		height: 16px;
	}

	.corner {
		position: absolute;
		top: 100%;
		width: 16px;
		height: 16px;
	}

	.left {
		left: 0;
		background-image: url(/assets/img/ui/header-left.png);
	}

	.right {
		right: 0;
		background-image: url(/assets/img/ui/header-right.png);
	}
`;

export const HeaderBackArrowImg = styled.img`
	width: 30px;
	height: 30px;
	background-image: url(/assets/img/back-arrow.svg);
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
`;

export const HeaderRingBellImg = styled.img`
	width: 24px;
	height: 24px;
`;

export const HeaderHamburgerImg = styled.img`
	width: 30px;
	height: 30px;
	background-image: url(/assets/img/hamburg.svg);
	background-position: center;
	background-repeat: no-repeat;

	&.notified {
		background-image: url(/assets/img/hamburg-notified.svg);
	}
`;

// тут padding-bottom сделан больше, чтобы была возможность промотать список вниз,
// если плюс закрывает элемент
export const ContentStyled = styled.div`
	padding: 84px 16px 110px 16px;

	& > .content-box {
		max-width: 900px;
		margin: 0 auto;
	}
`;

export const BreadCrumbsStyled = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 19px;
	overflow-y: hidden;
	overflow-x: auto;

	scrollbar-height: 5px; /* Firefox */

	&::-webkit-scrollbar {
		height: 5px;
	}

	// скрываем скроллбар (только на девайсах), но оставляем возможность скроллить контейнер
	@media only screen and (max-device-width: 736px) {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-height: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
	}

	div {
		padding: 5px 9px;
		text-align: center;
		background-color: ${p => p.theme.breadCrumbsBg};
		border-radius: 8px;
		font-size: 11px;
		line-height: 13px;
		white-space: nowrap;
		//text-overflow: ellipsis;
		//overflow: hidden;

		a {
			color: inherit;
		}

		&.active {
			background-color: ${p => p.theme.breadCrumbsActiveBg};
			color: ${p => p.theme.breadCrumbsActiveColor};
			overflow: visible;
		}
	}

	img {
		width: 8px;
		margin: 0 4px;
	}
`;

export const PlusButtonStyled = styled.img`
	position: fixed;
	right: 16px;
	bottom: 16px;
	width: 60px;
	height: 60px;
	background-size: 100%;
	background-image: url(/assets/img/user-menu/plus-button.svg);
	transition: transform 0.3s;
	cursor: pointer;

	// для веба разрешаем менять цвет текста и иконок при наведении
	@media (min-width: 900px) {
		&:hover {
			background-image: url(/assets/img/user-menu/plus-button-active.svg);
			transform: scale(1.2);
		}
	}

	&.hidden {
		transform: translateY(120px);
	}
`;

export const HamburgerContentDimmerStyled = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(20, 20, 20, 0.8);
	z-index: 3;
`;

export const HamburgerContentStyled = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	max-width: 50%;
	min-width: 450px;
	padding-bottom: 70px;
	background-color: #FFF;
	overflow-y: auto;

	@media (max-width: 700px) {
		max-width: 70%;
		min-width: 420px;
	}

	@media (max-width: 500px) {
		max-width: 80%;
		min-width: 360px;
	}

	@media (max-width: 380px) {
		max-width: 90%;
		min-width: 300px;
	}
`;
