import { z } from 'zod';

export const diagnosticsFileGalleryItemSchema = z.object({
	id: z.number().int(),
	name: z.string(),
	fileTypeId: z.number().int(),
	size: z.number().int(),
	path: z.string(),
	downloadCount: z.number().int(),
});

export interface DiagnosticsFileGalleryItemOptions {
	id: number;
	name: string;
	fileTypeId: number;
	size: number;
	path: string;
	downloadCount: number;
}

export class DiagnosticsFileGalleryItem {
	readonly id: number;
	readonly name: string;
	readonly fileTypeId: number;
	readonly size: number;
	readonly path: string;
	readonly downloadCount: number;

	constructor(options: DiagnosticsFileGalleryItemOptions) {
		this.id = options.id;
		this.name = options.name;
		this.fileTypeId = options.fileTypeId;
		this.size = options.size;
		this.path = options.path;
		this.downloadCount = options.downloadCount;
		diagnosticsFileGalleryItemSchema.parse(this);
	}
}