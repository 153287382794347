import React from 'react';
import { connect } from 'react-redux';
import { Button, Message } from 'semantic-ui-react';
import { addError, removeError } from '~store/actions/errorActions';
import { logout } from '~store/actions/loginActions';
import initClientsideLogger from '../lib/initClientsideLogger';

const logger = initClientsideLogger('ErrorContainer');

interface Props {
	errors: any[];
	addError: (error: any) => void;
	removeError: (errorId: number) => void;
}

class ErrorContainer extends React.PureComponent<Props>
{
	componentDidMount() {
		window.addEventListener('error', this.onError)
		window.addEventListener('unhandledrejection', this.onUnhandledRejection)
		window.addEventListener('onrejectionhandled', this.onRejectionHandled)
	}

	componentWillUnmount() {
		window.removeEventListener('error', this.onError)
		window.removeEventListener('unhandledrejection', this.onUnhandledRejection)
		window.removeEventListener('onrejectionhandled', this.onRejectionHandled)
	}

	onError = ({ error }) => {
		logger.error('[Произошла ошибка на странице]', error)
		this.props.addError({ error, mustReload: true });
		return false;
	}

	onUnhandledRejection = error => {
		logger.error('[Unhandled Rejection]', error.reason);
		this.props.addError({ error: error.reason, mustReload: true });
		return false;
	}

	onRejectionHandled = error => {
		logger.error('[Rejection Handled]', error.reason);
		this.props.addError({ error: error.reason, mustReload: true });
		return false;
	}

	renderError = ({ id, name, message, humanMessage, canClose }) => (
		<div key={`error-item-${id}`}>
			<div style={{ display: 'inline-block', marginBottom: '1rem' }}>
				<Message
					error
					compact
					size="small"
					onDismiss={canClose ? () => this.handleDismiss(id) : undefined}
				>
					{humanMessage || (
						<p>
							{!!name && <b>{name}:&nbsp;</b>}
							{message}
						</p>
					)}
				</Message>
			</div>
		</div>
	)

	handleReload = () => {
		window.location.reload()
	}

	handleLogout = () => {
		logout()
			.then(({ redirectUrl }) => {
				// сервер может попросить перейти по другому адресу
				window.location.replace(redirectUrl || '/')
			})
			.catch(err => {
				console.error('[Ошибка при разлогине]', err)
				window.location.replace('/')
			})
	}

	handleDismiss = errorId => {
		this.props.removeError(errorId)
	}

	render() {
		const { errors } = this.props;
		if (!errors?.length) return false;
		// может быть хоть одна ошибка требует ребута
		const isMustReload = errors.some(e => e.mustReload);
		const isCanLogout = errors.some(e => e.canLogout);
		return (
			<div
				id="error-container"
				style={{
					position: 'fixed',
					background: 'rgba(255, 255, 255, .7)',
					width: '100%',
					height: '100%',
					left: 0,
					top: 0,
					padding: `${errors.length < 3 ? '40vh' : '2rem'} 2rem 1rem 2rem`,
					textAlign: 'center',
					zIndex: 1001,
					overflow: 'auto',
				}}
			>
				{errors.map(this.renderError)}
				{!!isMustReload && (
					<div style={{ marginTop: errors.length < 3 ? '5rem' : 0 }}>
						<Button icon="redo"
						        content="Перезапустить приложение"
						        onClick={this.handleReload} />
					</div>
				)}
				{!!isCanLogout && (
					<div style={{ marginTop: errors.length < 3 ? '5rem' : 0 }}>
						<Button icon="sign-out"
						        content="Завершить авторизацию"
						        onClick={this.handleLogout} />
					</div>
				)}
			</div>
		)
	}
}

export default connect(
	(state: any) => ({ errors: state.errors }),
	{ addError, removeError },
)(ErrorContainer)
