import { z } from 'zod';

export const userItemSchema = z.object({
	id: z.number().int(),
	firsName: z.number().int(),
	middleName: z.string(),
	lastName: z.string(),
	fullName: z.string(),
});

export interface UserItemOptions {
	id: number;
	firsName: number;
	middleName: string;
	lastName: string;
	fullName: string;
}

export class UserItem {
	readonly id: number;
	readonly firsName: number;
	readonly middleName: string;
	readonly lastName: string;
	readonly fullName: string;

	constructor(options: UserItemOptions) {
		this.id = options.id;
		this.firsName = options.firsName;
		this.middleName = options.middleName;
		this.lastName = options.lastName;
		this.fullName = options.fullName;
		userItemSchema.parse(this);
	}

}