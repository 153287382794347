import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup, Progress } from 'semantic-ui-react';
import { arrayToMapByField, validateString } from 'common-lib';
import { copyText } from '~tools/copyText';
import { Nowrap } from '~components';
import { InfoMessagesDataItem } from 'protocol-lib';

/**
 * Выполняет обогащение данных для таблицы.
 */
export const fillTableData = ({ data: rows = [], pageInfo }) => {
	return { rows, pageInfo };
}

/**
 * Обогащает данные по информационным сообщениям.
 */
export function fillInfoMessagesListFromJson(json) {
	const { dataItems: infoMessages = [], meta } = json;
	if (!infoMessages.length) {
		return infoMessages;
	}
	const links = arrayToMapByField(Object.values(meta.infoMessageToAddresseeMap), 'infoMessageId') as any;
	infoMessages.forEach((item: any) => {
		item.visibleDate = new Date(item.externalMessageDate || item.createdAt);
		item.status = links.get(item.id)?.status;
	});
	infoMessages.sort((a: any, b: any) => b.visibleDate.getTime() - a.visibleDate.getTime());
	return infoMessages;
}

export function fillInfoMessageForAdminEdit(data: any) {
	const { data: infoMessage = [], meta } = data;
	if (!infoMessage) {
		return infoMessage;
	}
	const links = Object.values(meta?.infoMessageToAddresseeMap || {});
	infoMessage.visibleDate = new Date(infoMessage.externalMessageDate || infoMessage.createdAt);
	infoMessage.infoMessageToAddressees = links.filter((i: any) => i.infoMessageId === infoMessage.id);
	links.forEach((link: any) => {
		link.addressee = meta?.userMap[link.addresseeUserId];
	})
	return infoMessage;
}

export const TABLE_HEADERS = {
	id: {
		title: '#',
		style: { maxWidth: '150px' },
		viewFunc: (row, hl) => (
			<Popup content="Нажми, чтобы скопировать" trigger={(
				<Nowrap style={{ cursor: 'pointer' }} onClick={event => {
					event.stopPropagation()
					copyText(row.id)
				}}>
					{hl(row.id)}
					<Icon name="copy" style={{ color: '#aaa', fontSize: '0.9em', float: 'right' }} />
				</Nowrap>
			)} />
		),
	},
	subject: {
		title: 'Тема',
		viewFunc: (row, hl) => (
			<Link to={`/admin/infomsg/${row.id}`}>
				{row.subject ? hl(row.subject) : '(не указано)'}
			</Link>
		),
	},
	isImportant: {
		title: 'Важное',
		style: { maxWidth: '150px' },
		viewFunc: row => (
			<Nowrap>
				{row.isImportant ? 'Важно' : '-'}
			</Nowrap>
		),
	},
	externalMessageId: {
		title: 'Номер сообщения',
		viewFunc: (row, hl) =>
			row.externalMessageId
				? (
					<div>
						{hl(row.externalMessageId)}
						<div style={{ fontSize: '0.8em', color: '#666' }}>
							{new Date(row.externalMessageDate).toLocaleString()}
						</div>
					</div>
				)
				: '-',
	},
	$counter: {
		title: 'Прочитано',
		style: { maxWidth: '200px' },
		viewFunc: row => {
			const { viewed: vk = 0, created: ck = 0 } = row.stats
			const sum = vk + ck
			const percent = Math.floor(vk / sum * 100)
			return !sum ? 'Не назначено' : (
				<>
					{vk} из {sum}
					{percent ? <Progress percent={percent} size="tiny" autoSuccess
					                     style={{ margin: 0 }} /> : false}
				</>
			)
		},
	},
}

/**
 * Готовит поля для формы.
 */
export const getFieldsInfo = isNew => {
	const fieldsInfo: any[] = []
	if (!isNew) {
		fieldsInfo.push({
			key: 'id',
			title: 'Id (только чтение)',
			readOnly: true,
		})
	}
	return [
		...fieldsInfo,
		{
			key: 'subject',
			title: 'Заголовок',
			validate: validateString(1, 300),
			placeholder: 'Длина от 1 до 300',
		},
		{
			key: 'isImportant',
			title: 'Важное',
			type: 'checkbox',
		},
		{
			key: 'text',
			condition: ({ readOnlyHtml }) => readOnlyHtml === undefined,
			title: 'Текст сообщения',
			validate: validateString(1, 10000),
			placeholder: 'Длина от 1 до 10.000',
			type: 'wysiwyg',
			formats: ['bold', 'italic', 'underline', 'list'],
			toolbar: ['bold', 'italic', 'underline', { list: 'ordered' }],
		},
		{
			key: 'readOnlyHtml',
			condition: ({ readOnlyHtml }) => readOnlyHtml !== undefined,
			title: 'Текст сообщения',
			type: 'html',
		},
	]
}
