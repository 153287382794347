import BaseFetcher from './BaseFetcher';

export default class InfoMessagesFetcher extends BaseFetcher
{
	constructor() {
		super('infomessages');
	}

	async setStatusToView(infoMessageId: number): Promise<Response> {
		if (!infoMessageId) {
			throw new Error(`Параметр infoMessageId не может быть пустым при вызове ${this.constructor.name}.setStatusToView`);
		}
		return await this.fetchPut(`${this.basePath}/one/${infoMessageId}/set-viewed`);
	}
}
