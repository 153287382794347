import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TRANSPARENT_1PX } from '~components/ui/images';

const ImageStyled = styled.img`
`;

type Props = {
	width: number
	height: number
	value: number
	max: number
}

const COLORS = [
	['#F0B2B1', '#E24942'],
	['#F9D7A1', '#FF9900'],
	['#B3E0B9', '#63B655'],
]

function speedometer(img, value, max) {
	const canvas = document.createElement('canvas');
	canvas.setAttribute('style', 'display: none');
	canvas.width = img.width * 2;
	canvas.height = img.height * 2;
	document.body.appendChild(canvas);

	value = Math.floor(value);
	max = Math.floor(max);
	const ctx = canvas.getContext('2d')!;
	ctx.imageSmoothingEnabled = false;
	ctx.clearRect(0, 0, canvas.width, canvas.height);
	ctx.save();
	const squareSize = Math.min(canvas.width, canvas.height);
	const radius = Math.floor(squareSize * 0.35);
	const centerX = Math.floor(canvas.width * 0.5);
	const centerY = Math.floor(canvas.height * 0.4);
	const percent = Math.floor(value / max * 100 || 0);
	const percentText = String(`${percent}%`);
	const colors = percent < 30 ? COLORS[0] : percent < 80 ? COLORS[1] : COLORS[2];
	const fontSize = Math.floor(1 + squareSize / 4.5);
	const fontSizeBtm = Math.floor(5 + squareSize / 6);
	const labely = centerY + radius + fontSize * 0.85;
	const strokeThin = Math.floor(4 + squareSize / 16);
	const maxRad = Math.min(value / max * Math.PI * 2, Math.PI * 2);

	ctx.translate(centerX, centerY);
	ctx.rotate(-Math.PI / 2);

	//background
	ctx.beginPath();
	ctx.arc(0, 0, radius, 0, 2 * Math.PI);
	ctx.lineWidth = strokeThin;
	ctx.strokeStyle = colors[0];
	ctx.stroke();

	//foreground
	ctx.beginPath();
	ctx.arc(0, 0, radius, 0, maxRad);
	ctx.lineWidth = strokeThin;
	ctx.lineCap = 'round';
	ctx.strokeStyle = colors[1];
	ctx.stroke();

	ctx.restore();

	// center text
	ctx.textAlign = 'center';
	ctx.textBaseline = 'middle';
	ctx.font = `${percentText.length > 3 ? fontSize * 0.8 : percentText.length > 2 ? fontSize * 0.85 : fontSize * 0.9}px Arial`;
	ctx.fillStyle = '#000';
	ctx.fillText(percentText, centerX, centerY + 1);

	// bottom text
	const counts = `${value} из ${max}`;
	ctx.font = `${fontSizeBtm - (counts.length > 13 ? 3 : counts.length > 10 ? 2 : 1)}px Arial`;
	ctx.fillText(`${value} из ${max}`, centerX, labely);

	img.src = canvas.toDataURL('image/png');

	document.body.removeChild(canvas);
}

export default function Speedometer(props: Props) {
	const ref = useRef<HTMLImageElement>();
	useEffect(() => speedometer(ref.current, props.value, props.max), [props.value, props.max]);

	return (
		<>
			<ImageStyled
				ref={ref}
				src={TRANSPARENT_1PX}
				width={`${props.width}px`}
				height={`${props.height}px`}
			/>
		</>
	);
}
