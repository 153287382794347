import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { PreloaderImage, UiBadge } from '~components';
import { PreloadingImageStyled } from '~components/ui/PreloaderImage';

const ListStyled = styled.div`
	&.main {
		margin: 1vh 0;
	}

    display: grid;
    position: relative;
    grid-template-columns: repeat(auto-fill, 60px);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
    justify-items: center;

    &.add {
        width: 60px;
        height: 60px;
        border-radius: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #7B7FEF;
        background-image: url(/assets/img/ui/photo-add.svg);
        background-size: initial;
    }

    &:not(.add) {
        position: relative;
        background-image: url(${p => p.src});
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 1.5vw;
        grid-row-gap: 1.5vw;

        &.add {
            width: 16vw;
            height: 16vw;
            background-size: 9vw;
        }

        ${PreloadingImageStyled} {
            width: 16vw;
            height: 16vw;
        }
    }

    @media (max-width: 450px) {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 2vw;
        grid-row-gap: 2vw;

        &.add {
            width: 18vw;
            height: 18vw;
        }

        ${PreloadingImageStyled} {
            width: 18vw;
            height: 18vw;
        }
    }
`;

const DeletedStyled = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/assets/img/ui/photo-delete.svg);
`;

const InfoStyled = styled.div`
  color: #8790A6;
  font-size: 12px;
  font-weight: 400;
`;

const BackStyled = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 16px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowImageStyled = styled.div`
  position: relative;

  img {
    min-width: 300px;
    max-width: 100%;
    min-height: 200px;
    border: 1px solid #BFBFBF;
    border-radius: 16px;
  }
`;

const CloseStyled = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
  background-image: url(/assets/img/ui/photo-close.svg);
  cursor: pointer;
`;

const ButtonsStyled = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
`;

const ArrowButtonStyled = styled.button`
  background-color: ${p => p.theme.buttonAcceptBg};
  background-repeat: no-repeat;
  background-position: center;
  height: 60px;
  min-width: 70px;
  max-width: 170px;
  width: 100%;
  border: 0;
  border-radius: 16px;
  cursor: pointer;

  &:disabled {
    background-color: silver;
  }

  &.left {
    background-image: url(/assets/img/ui/gallery-left-arrow.svg);
    margin-right: 8px;
  }

  &.right {
    background-image: url(/assets/img/ui/gallery-right-arrow.svg);
    margin-left: 8px;
  }
`;

type Props = {
	photos: string[]
	infoText?: string
	readOnly?: boolean
	isLimit?: boolean
	onAddPhoto?: (photo: string) => void;
	onRemovePhoto?: (id: number) => void;
};

export default function UiPhotoGallery(props: Props) {
	const [showIndex, setShowIndex] = useState<number>();
	const listRef = useRef<HTMLDivElement>();

	function onShowPhoto(index) {
		setShowIndex(index);
	}

	function onAddPhoto(newPhoto) {
		props.onAddPhoto?.(newPhoto);
		listRef.current?.scroll({
			top: 0,
			left: listRef.current?.scrollWidth,
			behavior: 'auto',
		});
	}

	function onRemove(index) {
		props.onRemovePhoto?.(index);
	}

	return (
		<>
			<ListStyled ref={listRef} className="main">
				{props.photos.map((photoSrc, index) => (
					<PreloaderImage key={`photo-${index}`} src={photoSrc}
					                onClick={() => onShowPhoto(index)}>
						{!props.readOnly || props.isLimit ? (
							<DeletedStyled onClick={e => { e.stopPropagation(); onRemove(index); }} />
						) : null}
					</PreloaderImage>
				))}
				{!props.readOnly ? (
					<ListStyled className="add"
					                onClick={() => selectPhoto(true, onAddPhoto)} />
				) : null}
			</ListStyled>
			{props.infoText ? <InfoStyled>{props.infoText}</InfoStyled> : null}
			{showIndex !== undefined ? (
				<BackStyled onClick={() => onShowPhoto(undefined)}>
					<div onClick={e => e.stopPropagation()}>
						<UiBadge bigPaddings>
							<ShowImageStyled>
								<img src={props.photos[showIndex]} alt="" />
								<CloseStyled onClick={() => onShowPhoto(undefined)} />
							</ShowImageStyled>
							<ButtonsStyled>
								<ArrowButtonStyled className="left" disabled={showIndex === 0}
								                   onClick={() => onShowPhoto(showIndex - 1)} />
								<ArrowButtonStyled className="right"
								                   disabled={showIndex === props.photos.length - 1}
								                   onClick={() => onShowPhoto(showIndex + 1)} />
							</ButtonsStyled>
						</UiBadge>
					</div>
				</BackStyled>
			) : null}
		</>
	);
}

function selectPhoto(isCapture, callback, maxSquareSize = 500) {
	const input = document.createElement('input'); // создаем input, сразу кликаем чтобы выбрать фотку
	input.setAttribute('type', 'file');
	input.setAttribute('accept', 'image/*');
	if (isCapture) {
		input.setAttribute('capture', 'camera');
	}
	input.style.display = 'none';
	document.body.appendChild(input); // в safari тег input обязательно нужно добавить в DOM
	input.addEventListener('change', () => { // пользователь выбрал фотку
		if (input.files && input.files[0]) {
			const reader = new FileReader(); // загружаем картинку через ридер
			reader.onload = e => {
				const image = document.createElement('img'); // рисуем файлик в <img/>
				image.onload = () => {
					const { width: imw, height: imh } = image;
					const squareSize = Math.min(imw, imh); // будем обрезать картинку до квадрата
					const dx = Math.abs(imw - squareSize) / 2;
					const dy = Math.abs(imh - squareSize) / 2;
					const distSquareSize = Math.min(squareSize, maxSquareSize); // макс размер картинки
					const canvas = document.createElement('canvas');
					canvas.width = distSquareSize;
					canvas.height = distSquareSize;
					const context = canvas.getContext('2d'); // скейлим и рисуем картинку на <canvas/>
					context?.drawImage(image, dx, dy, squareSize, squareSize, 0, 0, distSquareSize, distSquareSize);
					const data = canvas.toDataURL('image/png');
					callback(data);
					document.body.appendChild(input);
				};
				image.setAttribute('src', e?.target?.result as string);
			};
			reader.readAsDataURL(input.files[0]);
		}
	});
	input.click();
}
