import React, { ReactNode } from 'react';

type Props = {
	title: string
	message?: string
	children: ReactNode
}

class ErrorPage extends React.Component<Props>
{
	render() {
		const { title = 'Неизвестная ошибка', message } = this.props;
		return (
			<>
				<h1>{title}</h1>
				<blockquote>
					<p>
						{message || <i>Подробностей по ошибке нет</i>}
					</p>
					{this.props.children}
				</blockquote>
			</>
		);
	}
}


export default ErrorPage;
