import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { PhotoGalleryItem, PhotoGalleryItemOptions, photoGalleryItemSchema } from '../composite';

export const photoGalleryListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(photoGalleryItemSchema).optional(),
	}),
);

export interface PhotoGalleryListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (PhotoGalleryItem | PhotoGalleryItemOptions)[];
}

export class PhotoGalleryListResponseDto extends BaseResponseDto {
	readonly data?: PhotoGalleryItem[];

	constructor(options: PhotoGalleryListResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof PhotoGalleryItem) {
					this.data.push(item);
				} else {
					this.data.push(new PhotoGalleryItem(item));
				}
			}
		}
		photoGalleryListResponseDtoSchema.parse(this);
	}
}
