import { z } from 'zod';
import { QuestionnaireVisible, questionnaireVisibleSchema } from './QuestionnaireVisible';
import {
	QuestionnaireConfigQuestionsItem,
	questionnaireConfigQuestionsItemSchema,
	QuestionnaireConfigQuestionsItemOptions
} from './QuestionnaireConfigQuestionsItem';


export const questionnaireQuizItemSchema = questionnaireConfigQuestionsItemSchema.merge(
	z.object({
		options: z.array(z.string()).optional(),
		isRequired: z.boolean().optional(),
		visibleOptions: questionnaireVisibleSchema.optional(),
		isHidden: z.boolean().optional(),
	}),
);

export interface QuestionnaireQuizItemOptions extends QuestionnaireConfigQuestionsItemOptions {
	options: string[];
	isRequired?: boolean;
	visibleOptions?: QuestionnaireVisible;
	isHidden?: boolean;
}

export class QuestionnaireQuizItem extends QuestionnaireConfigQuestionsItem {
	readonly options?: string[];
	readonly isRequired?: boolean;
	readonly visibleOptions?: QuestionnaireVisible;
	readonly isHidden?: boolean;

	constructor(options: QuestionnaireQuizItemOptions) {
		super(options);
		if (options.options !== undefined) this.options = options.options;
		if (options.isRequired !== undefined) this.isRequired = options.isRequired;
		if (options.visibleOptions !== undefined) this.visibleOptions = options.visibleOptions;
		if (options.isHidden !== undefined) this.isHidden = options.isHidden;
		questionnaireQuizItemSchema.parse(this);
	}
}