import { fetchGetThenJson, fetchPostThenJson } from '~tools/fetchBackend';
import { throwIfExcessKeys } from 'common-lib';
import {
	FeatureConfigurationListResponseDto,
	FeatureConfigurationOneResponseDto,
	FeatureConfigurationUpdateResponseDto,
	FeatureConfigurationPayloadRequestDto
} from 'protocol-lib';

const LIST_AVAIL_KEYS = ['page', 'order', 'search', 'meta'];

export async function fetchFeatureConfigurationList(pageInfo): Promise<FeatureConfigurationListResponseDto> {
	throwIfExcessKeys(pageInfo.toQueryObject(), LIST_AVAIL_KEYS, 'fetchConfigList.pageInfo');
	const query = pageInfo.toQueryString();
	return await fetchGetThenJson(`feature_configurations/list${query ? `?${query}` : ''}`);
}

export async function fetchFeatureConfiguration(id: number): Promise<FeatureConfigurationOneResponseDto> {
	return await fetchGetThenJson(`feature_configurations/one/${id}`);
}

export async function updateFeatureConfiguration(id: number, payload: FeatureConfigurationPayloadRequestDto): Promise<FeatureConfigurationUpdateResponseDto> {
	return await fetchPostThenJson(`feature_configurations/edit/${id}`, payload);
}
