import { z } from 'zod';
import { ShopManagerMeta, ShopManagerMetaOptions, shopManagerMetaSchema } from '../../../meta';
import { userMetaSchema } from '../../users';
import { UserItem, UserItemOptions } from './UserItem';

export const shopMetaSchema = shopManagerMetaSchema.merge(
	z.object({
		userMap: z.map(z.string(), userMetaSchema).optional(),
	}),
);

export interface ShopMetaOptions extends ShopManagerMetaOptions{
	userMap?: Record<string, UserItem | UserItemOptions>;
}

export class ShopMeta extends ShopManagerMeta {
	readonly userMap?: Record<string, UserItem>;

	constructor(options: ShopMetaOptions) {
		super(options);
		if (options.userMap !== undefined) {
			this.userMap = {};
			for(const [key, value] of Object.entries(options.userMap)) {
				if (value instanceof UserItem) {
					this.userMap[key] = value;
				} else {
					this.userMap[key] = new UserItem(value);
				}
			}
		}
		shopMetaSchema.parse(this);
	}
}