import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { isProdServer, isReleaseServer, FeatureNamesEnum } from 'common-lib';
import { TRANSPARENT_1PX } from '../ui/images';

const MenuListStyled = styled.div`
	padding: 0 1.5rem;
`;

const LinkLabel = styled.div`
	flex: 1;
	padding-left: 1.5rem;
	font-size: 1.1rem;
	font-weight: 400;
	color: #474764;
`;

const MenuItemStyled = styled.div`
	display: flex;
	align-items: center;
	padding: 0.7rem;

	&:hover {
		${LinkLabel} {
			color: #BFBFC7;
		}
	}

	img {
		width: 26px;
		height: 26px;
		background-size: 100%;
	}

	.ico0 {
		background-image: url(/assets/img/user-menu/ico0.svg);
	}

	.ico1 {
		background-image: url(/assets/img/user-menu/ico1.svg);
	}

	.ico2 {
		background-image: url(/assets/img/user-menu/ico2.svg);
	}

	.ico3 {
		background-image: url(/assets/img/user-menu/ico3.svg);
	}

	.ico4 {
		background-image: url(/assets/img/user-menu/ico4.svg);
	}

	.ico5 {
		background-image: url(/assets/img/user-menu/ico5.svg);
	}

	.ico6 {
		background-image: url(/assets/img/user-menu/ico6.svg);
	}

	// для веба разрешаем менять цвет текста и иконок при наведении
	@media (min-width: 900px) {
		&:hover {
			${LinkLabel} {
				color: #ADAED9;
			}

			.ico0 {
				background-image: url(/assets/img/user-menu/ico0-hover.svg);
			}

			.ico1 {
				background-image: url(/assets/img/user-menu/ico1-hover.svg);
			}

			.ico2 {
				background-image: url(/assets/img/user-menu/ico2-hover.svg);
			}

			.ico3 {
				background-image: url(/assets/img/user-menu/ico3-hover.svg);
			}

			.ico4 {
				background-image: url(/assets/img/user-menu/ico4-hover.svg);
			}

			.ico5 {
				background-image: url(/assets/img/user-menu/ico5-hover.svg);
			}

            .ico6 {
                background-image: url(/assets/img/user-menu/ico6-hover.svg);
            }
		}
	}

	&.active {
		${LinkLabel} {
			color: #7A7CF2;
		}

		.ico0 {
			background-image: url(/assets/img/user-menu/ico0-active.svg);
		}

		.ico1 {
			background-image: url(/assets/img/user-menu/ico1-active.svg);
		}

		.ico2 {
			background-image: url(/assets/img/user-menu/ico2-active.svg);
		}

		.ico3 {
			background-image: url(/assets/img/user-menu/ico3-active.svg);
		}

		.ico4 {
			background-image: url(/assets/img/user-menu/ico4-active.svg);
		}

		.ico5 {
			background-image: url(/assets/img/user-menu/ico5-active.svg);
		}

		.ico6 {
			background-image: url(/assets/img/user-menu/ico6-active.svg);
		}
	}

	@media (max-width: 450px), (max-height: 550px) {
		img {
			width: 20px;
			height: 20px;
		}
	}
`;

const INCIDENTS_PATH = '/incidents';
const TASKS_PATH = '/tasks';
const CHECKLIST_TASKS_PATH = '/checklist_tasks';
const TODOS_PATH = '/todos';
const MESSAGES_PATH = '/infomsg';
const GALLERY_PATH = '/photo-gallery';
const QUIZ_PATH = '/quiz-list';
const QUIZ_MANAGER_PATH = '/quiz-managers';

export default function UserMenu3() {
	const meConfig: any = useSelector<any>(state => state.me);
	const isDmManager = meConfig.currentUser.shopManagerRoles !== undefined;
	const location = useLocation();
	const { isShopUser } = useSelector((state: any) => state.me);
	const { pathname } = location;
	const onIncidentsRoot = pathname === INCIDENTS_PATH || pathname === '/';
	const onIncidents = onIncidentsRoot || pathname.startsWith(INCIDENTS_PATH);
	const onTasksRoot = pathname === TASKS_PATH;
	const onTasks = onTasksRoot || pathname.startsWith(TASKS_PATH);
	const onChecklistTasksRoot = pathname === CHECKLIST_TASKS_PATH;
	const onChecklistTasks = onChecklistTasksRoot || pathname.startsWith(CHECKLIST_TASKS_PATH);
	const onTodosRoot = pathname === TODOS_PATH;
	const onTodos = onTodosRoot || pathname.startsWith(TODOS_PATH);
	const onMessagesRoot = pathname === MESSAGES_PATH;
	const onMessages = onMessagesRoot || pathname.startsWith(MESSAGES_PATH);
	const onPhotoGalleryRoot = pathname === GALLERY_PATH;
	const onPhotoGallery = onPhotoGalleryRoot || pathname.startsWith(GALLERY_PATH);
	const onQuizListRoot = pathname === QUIZ_PATH || pathname === QUIZ_MANAGER_PATH;
	const onQuizList = onQuizListRoot || pathname.startsWith(QUIZ_PATH);

	const isShowChecklistTasksMenu = meConfig.features?.includes(FeatureNamesEnum.CHECKLIST_TASKS);

	return (
		<MenuListStyled>
			<MenuItemStyled as={Link}
			                to="/incidents"
			                className={onIncidents ? 'active' : undefined}>
				<img src={TRANSPARENT_1PX} className="ico1" alt="" />
				<LinkLabel className="active">Инциденты</LinkLabel>
			</MenuItemStyled>
			<MenuItemStyled as={Link}
			                to="/tasks"
			                className={onTasks ? 'active' : undefined}>
				<img src={TRANSPARENT_1PX} className="ico2" alt="" />
				<LinkLabel>Задачи</LinkLabel>
			</MenuItemStyled>
			{isShopUser ? (
				<MenuItemStyled as={Link}
				                to="/todos"
				                className={onTodos ? 'active' : undefined}>
					<img src={TRANSPARENT_1PX} className="ico3" alt="" />
					<LinkLabel>Список дел</LinkLabel>
				</MenuItemStyled>
			) : null}
			<MenuItemStyled as={Link}
			                to="/infomsg"
			                className={onMessages ? 'active' : undefined}>
				<img src={TRANSPARENT_1PX} className="ico4" alt="" />
				<LinkLabel>Сообщения</LinkLabel>
			</MenuItemStyled>
			<MenuItemStyled as={Link}
			                to={isDmManager ? '/quiz-list' : '/quiz-managers'}
			                className={onQuizList ? 'active' : undefined}>
				<img src={TRANSPARENT_1PX} className="ico6" alt="" />
				<LinkLabel>Опросы и тесты</LinkLabel>
			</MenuItemStyled>
			{(isShopUser && isShowChecklistTasksMenu) ? (
				<MenuItemStyled as={Link}
				                to="/checklist_tasks"
				                className={onChecklistTasks ? 'active' : undefined}>
					<img src={TRANSPARENT_1PX} className="ico0" alt="" />
					<LinkLabel>Задачи по ЧЛ</LinkLabel>
				</MenuItemStyled>
			) : null}
			{isShopUser ? (
				<MenuItemStyled as={Link}
				                to="/photo-gallery"
				                className={onPhotoGallery ? 'active' : undefined}>
					<img src={TRANSPARENT_1PX} className="ico5" alt="" />
					<LinkLabel>Галерея</LinkLabel>
				</MenuItemStyled>
			) : null}
		</MenuListStyled>
	);
}
