import { z } from 'zod';

export const incidentGoodMapItemSchema = z.object({
	id: z.number().int(),
	externalId: z.string(),
	name: z.string(),
});

export interface IncidentGoodMapItemOptions {
	id: number;
	externalId: string;
	name: string;
}


export class IncidentGoodMapItem {
	readonly id: number;
	readonly externalId: string;
	readonly name: string;

	constructor(options: IncidentGoodMapItemOptions) {
		this.id = options.id;
		this.externalId = options.externalId;
		this.name = options.name;
		incidentGoodMapItemSchema.parse(this);
	}
}
