import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dropdown, Header, Icon, Input, Segment, Table } from 'semantic-ui-react';
import { hashCode } from 'common-lib';
import { PagePaginator } from '~components/smallComponents';

const FloatLeft = styled.div`
  float: left;
  margin-right: 1rem;
`;

const FloatRight = styled.div`
  float: right;
  margin-left: 1rem;
`;

const MarkedText = styled.span`
  background-color: aquamarine;
  display: inline-block;
`;

const HeaderPane = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PAGE_SIZE_OPTIONS = [
	{ key: '10', text: '10', value: 10 },
	{ key: '20', text: '20', value: 20 },
	{ key: '50', text: '50', value: 50 },
	{ key: '100', text: '100', value: 100 },
	{ key: '200', text: '200', value: 200 },
];

function renderError(node) {
	return <Segment inverted color="red" style={{ clear: 'both' }}>{node}</Segment>;
}

function renderInfo(node) {
	return <Segment secondary style={{ clear: 'both' }}>{node}</Segment>;
}

/**
 * Компонент строки поика
 */
class SearchInput extends React.Component<{ onChange: (s: string) => void, value?: string }>
{
	static propTypes = {
		value: PropTypes.string,
		onChange: PropTypes.func.isRequired,
	};

	state = { isSearchStringEditing: false };

	private editingSearchStringTimerId?: NodeJS.Timeout;

	handleChangeSearchString = (_, { value }) => {
		clearTimeout(this.editingSearchStringTimerId!);
		if (!value || value.length > 2) {
			this.editingSearchStringTimerId = setTimeout(() => {
				this.props.onChange(value);
				this.setState({ isSearchStringEditing: false });
			}, 2e3);
			if (!this.state.isSearchStringEditing) {
				this.setState({ isSearchStringEditing: true });
			}
		}
	};

	render() {
		return (
			<Input
				size="small"
				icon={<Icon name="search" circular link onClick={this.handleChangeSearchString} />}
				defaultValue={this.props.value || ''}
				placeholder="Поиск..."
				loading={this.state.isSearchStringEditing}
				onChange={this.handleChangeSearchString}
				focus={true}
			/>
		);
	}
}

interface Props {
	icon?: string;
	title: string;
	headers: object;
	searchable?: true;
	callbacks?: object;
	rows: any[];
	pageInfo?: any;
	filterOptions?: any[];
	onClickItem?: any; // FIXME этот пропс нужен?
	onAddItem?: any;
	onChangePageOptions: any;
}

interface State {
	filterValues: any;
	error: string;
}

export default class DataTableWithPageInfo extends React.Component<Props, State>
{
	state = {} as State;

	/**
	 * Прилетели новые пропсы. Сравнивает хеш существующих rows и pageInfo и новых.
	 * Обновляет данные при необходимости.
	 * @param newProps
	 * @param oldState
	 * @returns {*}
	 */
	static getDerivedStateFromProps(newProps, oldState) {
		// checks
		if (!newProps.title) return { error: 'Не указано поле title' };
		if (!newProps.headers) return { error: 'Не указано поле headers' };
		if (!newProps.rows) return { error: 'Не указано поле rows' };
		// check hashes
		const newRowsHash = hashCode(JSON.stringify(newProps.rows));
		let newPageInfoHash;
		if (newProps.pageInfo) {
			newPageInfoHash = hashCode(JSON.stringify(newProps.pageInfo));
		}
		if (newRowsHash === oldState._rowsHash && newPageInfoHash === oldState._pageInfoHash) {
			return oldState;
		}
		return { // собираем новые данные
			_rowsHash: newRowsHash,
			_pageInfoHash: newPageInfoHash,
		};
	}

	handleSort = clickedColumn => {
		if (clickedColumn.substring(0, 1) === '$') return undefined;
		const { pageInfo: { orderColumn, orderDirection }, onChangePageOptions } = this.props;
		const newDirection = (clickedColumn === orderColumn) ? (orderDirection === 'desc' ? 'asc' : 'desc') : 'asc';
		onChangePageOptions({ orderColumn: clickedColumn, orderDirection: newDirection });
	}

	handleChangeSearchString = searchString => {
		this.props.onChangePageOptions({ pageIndex: 0, search: searchString });
	}

	handleFilterChange = (key, value, content) => {
		const { [key]: filterValue } = this.state.filterValues;
		alert('filter');
		if (value !== filterValue) {
			this.setState(({ filterValues }) => {
				filterValues = Object.assign({}, filterValues);
				if (!value) {
					delete filterValues[key];
				} else {
					filterValues[key] = value;
					filterValues[`_content_${key}`] = content;
				}
				return { filterValues };
			});
		}
	}

	handleChangePageIndex = pageIndex => {
		this.props.onChangePageOptions({ pageIndex });
	}

	handleChangePageSize = (_, { value: pageSize }: any) => {
		// при изменении размера страницы можем вылезти за границы списка, поэтому переходим на первую страницу
		this.props.onChangePageOptions({ pageIndex: 0, pageSize });
	}

	renderFilter = () => {
		if (this.props.filterOptions) {
			return (
				<FloatLeft>
					<Dropdown
						text="Фильтр"
						icon="filter"
						labeled
						button
						className="icon"
					>
						<Dropdown.Menu>
							{this.props.filterOptions.map(o => (
								<Dropdown.Item
									key={o.field}
									onClick={e => {
										e.stopPropagation();
										o._ref.open(); /* открываем подменю */
									}}
								>
									<Dropdown
										text={o.label}
										scrolling
										ref={ref => o._ref = ref /* запоминаем ссылку чтобы при клике в пусто месте элемента открыть подменю */}
									>
										<Dropdown.Menu>
											{o.options.map(o1 => (
												<Dropdown.Item
													{...o1}
													onClick={() => this.handleFilterChange(o.field, o1.value, o1.content) /* контент передаем чтобы отобразить его в кнопке фильтра */}
													// selected={o1.value === filterValues[o.field]}
												/>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</FloatLeft>
			);
		}
	}

	renderSearchInput = () => (
		<FloatRight>
			<SearchInput value={(this.props.pageInfo || {}).search || ''} onChange={this.handleChangeSearchString} />
		</FloatRight>
	)

	renderCreateOne = () => {
		if (this.props.onAddItem) {
			return (
				<FloatRight>
					<Button
						primary
						size="small"
						icon="add"
						labelPosition="left"
						content="Создать"
						onClick={() => this.props.onAddItem()}
					/>
				</FloatRight>
			);
		}
	}

	renderHeader = () => {
		const { headers, pageInfo } = this.props;
		const { orderColumn, orderDirection = 'asc' } = (pageInfo || {});
		const headerKeys = Object.keys(headers);

		return (
			<Table.Header>
				<Table.Row>
					{headerKeys.map(headerKey => {
						let headOpt = headers[headerKey];
						if (typeof headers[headerKey] === 'string') headOpt = { title: headers[headerKey] };
						return (
							<Table.HeaderCell
								key={headerKey}
								sorted={orderColumn === headerKey ? (orderDirection === 'desc' ? 'descending' : 'ascending') : undefined}
								onClick={() => this.handleSort(headerKey)}
								content={headOpt.title || undefined}
								style={headOpt.style || undefined}
							/>
						);
					})}
				</Table.Row>
			</Table.Header>
		);
	};

	/**
	 * Подкрашивает куски в строке.
	 * @param searchStrings
	 */
	highlight = searchStrings => {
		return str => {
			if (!searchStrings?.length || !str) return str;
			str = String(str);
			const regExp = RegExp(`(${searchStrings.join('|')})`, 'ig');
			const parts: ReactNode[] = [];
			let lasti = 0;
			let match;
			let k = 0;
			while ((match = regExp.exec(str)) && k++ < 100) {
				if (match.index) {
					parts.push(str.substring(lasti, match.index));
				}
				parts.push(<MarkedText key={`${match[0]}-${parts.length}`}>{match[0]}</MarkedText>);
				lasti = match.index + match[0].length;
			}
			if (lasti < str.length) parts.push(str.substr(lasti));
			return parts.map((part) => {
				if (!part) {
					return null;
				} else if (typeof part === 'string') {
					return <span key={`${part}-index`}>{part}</span>;
				} else {
					return part;
				}
			});
		};
	}

	renderBody = () => {
		const { headers, rows, pageInfo: { search }, callbacks } = this.props;
		const searchStrings = search ? String(search).split(' OR ').map(s => s.trim()).filter(s => !!s) : null;
		const hl = this.highlight(searchStrings);
		const headerKeys = Object.keys(headers);
		return (
			<Table.Body>
				{rows.map(row => (
					<Table.Row key={row.id}>
						{headerKeys.map(headerKey => (
							<Table.Cell key={headerKey}>
								{headers[headerKey].viewFunc ? headers[headerKey].viewFunc(row, hl, callbacks) : row[headerKey]}
							</Table.Cell>
						))}
					</Table.Row>
				))}
			</Table.Body>
		);
	}

	renderPagination = () => {
		const { pageIndex, pageSize, pageCount } = this.props.pageInfo;
		return (
			<PagePaginator
				pageIndex={pageIndex}
				pageSize={pageSize}
				pageCount={pageCount}
				sizeOptions={PAGE_SIZE_OPTIONS}
				onChangePageSize={this.handleChangePageSize}
				onChangePageIndex={this.handleChangePageIndex}
			/>
		);
	}

	render() {
		const { error } = this.state;
		const { rows, pageInfo } = this.props;
		const hasError = !!error;
		const isEmpty = !rows || !rows.length;
		if (hasError) {
			return (
				<>
					<Header as="h3" icon={this.props.icon} content={this.props.title} />
					{hasError && renderError(error)}
					{!hasError && isEmpty && renderInfo('Нет записей')}
				</>
			);
		}
		const rowsCount = rows.length;
		const { allCount = rowsCount } = pageInfo;
		const countLabel = !rowsCount ? null : `Записей: ${rowsCount === allCount ? rowsCount : `${rowsCount} / ${allCount}`}`;
		return (
			<>
				<HeaderPane>
					<div>
						<Header
							as="h3"
							icon={this.props.icon}
							content={this.props.title}
							subheader={countLabel}
						/>
					</div>
					<div>
						{this.renderFilter()}
						{this.props.onAddItem ? this.renderCreateOne() : null}
						{this.props.searchable ? this.renderSearchInput() : null}
					</div>
				</HeaderPane>
				{isEmpty
					? renderInfo('Нет записей')
					: (
						<>
							<Table sortable celled compact="very" selectable>
								{this.renderHeader()}
								{this.renderBody()}
							</Table>
							{this.renderPagination()}
						</>
					)}
			</>
		);
	}
}
