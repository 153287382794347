import { z } from 'zod';
import { UserRoles } from 'common-lib';

export const userDataItemSchema = z.object({
	id: z.number().int(),
	externalId: z.string(),
	role: z.nativeEnum(UserRoles),
	firstName: z.string().nullable(),
	lastName: z.string(),
	middleName: z.string().optional(),
	fullName: z.string(),
	email: z.string().optional(),
	phone: z.string().optional(),
	comment: z.string().optional(),
});


export interface UserDataItemOptions {
	id: number;
	externalId?: string;
	role: UserRoles;
	firstName: string | null;
	lastName: string;
	middleName?: string;
	fullName: string;
	email?: string;
	phone?: string;
	comment?: string;
}

export class UserDataItem {
	readonly id: number;
	readonly externalId?: string;
	readonly role: UserRoles;
	readonly firstName: string | null;
	readonly lastName: string;
	readonly middleName?: string;
	readonly fullName: string;
	readonly email?: string;
	readonly phone?: string;
	readonly comment?: string;

	constructor(options: UserDataItemOptions) {
		this.id = options.id;
		if (options.externalId !== undefined) this.externalId = options.externalId;
		this.role = options.role;
		this.firstName = options.firstName;
		this.lastName = options.lastName;
		if (options.middleName !== undefined) this.middleName = options.middleName;
		this.fullName = options.fullName;
		if (options.email !== undefined) this.email = options.email;
		if (options.phone !== undefined) this.phone = options.phone;
		if (options.comment !== undefined) this.comment = options.comment;
		userDataItemSchema.parse(this);
	}
}