import { z } from 'zod';
import { QuestionnaireExecutionStatusEnum, QuestionnaireTypeEnum } from 'common-lib';

export const questionnaireReportItemSchema = z.object({
	id: z.number().int(),
	type: z.nativeEnum(QuestionnaireTypeEnum),
	title: z.string(),
	status: z.nativeEnum(QuestionnaireExecutionStatusEnum),
	role: z.enum(['manager', 'shop']),
	repeatCount: z.number().int(),
	addresseesCount: z.number().int(),
	answeredCount: z.number().int(),
	startedAt: z.instanceof(Date),
	finishedAt: z.instanceof(Date),
});

export interface QuestionnaireReportItemOptions {
	id: number;
	type: QuestionnaireTypeEnum;
	title: string;
	status: QuestionnaireExecutionStatusEnum;
	role: 'manager' | 'shop';
	repeatCount: number;
	addresseesCount: number;
	answeredCount: number;
	startedAt: Date | string;
	finishedAt: Date | string;
}

export class QuestionnaireReportItem {
	readonly id: number;
	readonly type: QuestionnaireTypeEnum;
	readonly title: string;
	readonly status: QuestionnaireExecutionStatusEnum;
	readonly role: 'manager' | 'shop';
	readonly repeatCount: number;
	readonly addresseesCount: number;
	readonly answeredCount: number;
	readonly startedAt: Date;
	readonly finishedAt: Date;

	constructor(options: QuestionnaireReportItemOptions) {
		this.id = options.id;
		this.type = options.type;
		this.title = options.title;
		this.status = options.status;
		this.role = options.role;
		this.repeatCount = options.repeatCount;
		this.addresseesCount = options.addresseesCount;
		this.answeredCount = options.answeredCount;
		if (options.startedAt instanceof Date) {
			this.startedAt = options.startedAt;
		} else {
			this.startedAt = new Date(options.startedAt);
		}
		if (options.finishedAt instanceof Date) {
			this.finishedAt = options.finishedAt;
		} else {
			this.finishedAt = new Date(options.finishedAt);
		}
		questionnaireReportItemSchema.parse(this);
	}
}