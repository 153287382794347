import { createAction } from 'redux-act';
import fetchBackend from '~tools/fetchBackend';

export const FETCH_INCIDENT_DATA = createAction('FETCH_INCIDENT_DATA');

export const FETCH_INCIDENT_ITEM = createAction('FETCH_INCIDENT_ITEM');

export const FETCH_INCIDENT_TYPES = createAction('FETCH_INCIDENT_TYPES');

export const fetchIncidentData = () => dispatch => fetchBackend('get', 'incident/work-data')
  .then(response => response.json && response.json())
  .then(data => dispatch(FETCH_INCIDENT_DATA(data)));

export const fetchIncidentItem = incidentId => dispatch => fetchBackend('get', `incident/item/${incidentId}`)
  .then(response => response.json && response.json())
  .then(data => dispatch(FETCH_INCIDENT_ITEM(data)));

export const updateIncidentData = (incidentId, state, report) => fetchBackend('put', `incident/item/${incidentId}`, { state, report });

export const fetchIncidentTypes = ids => dispatch => fetchBackend('get', `incident_OLD/types/${ids.join(',')}`)
  .then(response => response.json && response.json())
  .then(data => dispatch(FETCH_INCIDENT_TYPES(data)));
