import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	QuestionnaireRunningSubShopItem,
	QuestionnaireRunningSubShopItemOptions,
	questionnaireRunningSubShopItemSchema
} from '../composite';


export const questionnaireRunningSubShopListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: questionnaireRunningSubShopItemSchema.optional(),
	}),
);
export interface QuestionnaireRunningSubShopListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: QuestionnaireRunningSubShopItem | QuestionnaireRunningSubShopItemOptions;
}

export class QuestionnaireRunningSubShopListResponseDto extends BaseResponseDto {
	readonly data?: QuestionnaireRunningSubShopItem;

	constructor(options: QuestionnaireRunningSubShopListResponseDtoOptions) {
		super(options);
		if (options.data instanceof QuestionnaireRunningSubShopItem) {
			this.data = options.data;
		} else if (options.data !== undefined) {
			this.data = new QuestionnaireRunningSubShopItem(options.data);
		}
		questionnaireRunningSubShopListResponseDtoSchema.parse(this);
	}
}