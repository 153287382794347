import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { DashboardMeta, DashboardMetaOptions, dashboardMetaSchema } from '../../../meta';
import {
	DashboardStructureImportDataItem,
	DashboardStructureImportDataItemOptions,
	dashboardStructureImportDateItemSchema
} from '../composite';

export const dashboardStructureImportListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(dashboardStructureImportDateItemSchema).optional(),
		meta: dashboardMetaSchema.optional(),
	}),
);

export interface DashboardStructureImportListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (DashboardStructureImportDataItem | DashboardStructureImportDataItemOptions)[];
	meta?: DashboardMeta | DashboardMetaOptions;
}

export class DashboardStructureImportListResponseDto extends BaseResponseDto {
	readonly data?: DashboardStructureImportDataItem[];
	readonly meta?: DashboardMeta;

	constructor(options: DashboardStructureImportListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof DashboardStructureImportDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new DashboardStructureImportDataItem(item));
				}
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof DashboardMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new DashboardMeta(options.meta);
			}
		}
		dashboardStructureImportListResponseDtoSchema.parse(this);
	}

}