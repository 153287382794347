import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	QuestionnaireManagerStatsData,
	QuestionnaireManagerStatsDataOptions,
	questionnaireManagerStatsDataSchema
} from '../composite';
import { QuestionnaireMeta, QuestionnaireMetaOptions, questionnaireMetaSchema } from '../meta/QuestionnaireMeta';


export const questionnaireManagerStatsListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: questionnaireManagerStatsDataSchema.optional(),
		meta: questionnaireMetaSchema.optional(),
	}),
);
export interface QuestionnaireManagerStatsListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: QuestionnaireManagerStatsData | QuestionnaireManagerStatsDataOptions;
	meta?: QuestionnaireMeta | QuestionnaireMetaOptions;
}

export class QuestionnaireManagerStatsListResponseDto extends BaseResponseDto {
	readonly data?: QuestionnaireManagerStatsData;
	meta?: QuestionnaireMeta;

	constructor(options: QuestionnaireManagerStatsListResponseDtoOptions) {
		super(options);
		if (options.data instanceof QuestionnaireManagerStatsData) {
			this.data = options.data;
		} else if (options.data !== undefined) {
			this.data = new QuestionnaireManagerStatsData(options.data);
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof QuestionnaireMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new QuestionnaireMeta(options.meta);
			}
		}
		questionnaireManagerStatsListResponseDtoSchema.parse(this);
	}
}