import util from 'util';

/**
 * Создает новую ошибку с сохранением стека из оригинальной ошибки
 * @param ErrorCls
 * @param message
 * @param parentError
 */
export function fillError(ErrorCls: (new (s: string) => Error), message: string, parentError?: Error): Error {
	const newError = new ErrorCls(message) as any;
	if (parentError !== undefined) newError.parentError = parentError;
	return newError;
}

/**
 * Формирует текс из ошибки с учетом родителей и splat объекта
 * @param error
 */
export function parseError(error: any): string {
	const lines: string[] = [];
	while (error) {
		const parts: string[] = [];
		const { stack, message, name, parentError, original, ...other } = error;
		// original - это аргумент из ошибок Sequelize, это копия parent
		const line = stack || message || name;
		parts.push(line);
		Object.keys(other).forEach(key => {
			const value = other[key];
			if (value !== undefined) {
				parts.push(util.format('  • %s: %o', key, value));
			}
		});
		lines.push(parts.join('\n').split('\n').join('\n'));
		error = error.parentError;
		if (error) {
			lines.push('\n' + '-'.repeat(8) + '\nthrowed by ');
		}
	}
	return lines.join('');
}
