import { z } from 'zod';
import {
	QuestionnaireRunningItem,
	QuestionnaireRunningItemOptions,
	questionnaireRunningItemSchema
} from './QuestionnaireRunningItem';

export const questionItemSchema = questionnaireRunningItemSchema.merge(
	z.object({
		quizResultHashCodeList: z.array(z.string()),
	})
);

export interface QuestionItemOptions extends QuestionnaireRunningItemOptions {
	quizResultHashCodeList: string[];
}

export class QuestionItem extends  QuestionnaireRunningItem {
	readonly quizResultHashCodeList: string[];

	constructor(options: QuestionItemOptions) {
		super(options);
		this.quizResultHashCodeList = options.quizResultHashCodeList;
	}
}