import React, { useState, useEffect } from 'react';
import moment from 'moment';

const logs: string[] = [];

export default function AdminDiagnosticsRequestTestingPage() {
	const [interval, setInterval] = useState(0);
	const [, setRand] = useState(0);

	useEffect(() => stopTesting, []);

	useEffect(() => {
		if (interval > 0) {
			startTesting(interval, addLog);
		} else {
			stopTesting();
		}
	}, [interval]);

	function start(n: number): void {
		setInterval(n);
	}

	function stop(): void {
		setInterval(0);
	}

	function clearLogs(): void {
		logs.length = 0;
		setRand(Math.random()); // чтобы сработал рендер
	}

	function addLog(s: string): void {
		const now = moment().format('HH:mm:ss');
		logs.push(`[${now}] ${s}`);
		setRand(Math.random()); // чтобы сработал рендер
	}



	return <>
		<h1>Тестирование запросов к бэку</h1>
		<p>
			Выберите, с какой периодичностью отправлять запросы.<br/>
			<s>Время задержки между запросами и время исполнения запроса бэком будет одинаковое.</s>
		</p>
		<p>
			<button disabled={interval > 0} onClick={() => start(5)}>5 сек.</button>
			<button disabled={interval > 0} onClick={() => start(15)}>15 сек.</button>
			<button disabled={interval > 0} onClick={() => start(30)}>30 сек.</button>
			<button disabled={interval > 0} onClick={() => start(60)}>60 сек.</button>
			<button disabled={interval > 0} onClick={() => start(120)}>120 сек.</button>
			<button disabled={!interval} onClick={() => stop()}>Стоп</button>
			<button disabled={logs.length === 0} onClick={() => clearLogs()}>Чистка логов</button>
		</p>
		<h1>Логи</h1>
		<p key={logs.length}>
			{logs.map((s, index) => <div><code key={index}>{s}</code></div>)}
		</p>
	</>;
}

let requestIndex = 0;
let testingInterval = 0;
let nextTimer: NodeJS.Timeout;

function startTesting(n: number, addLog: (s: string) => void): void {
	testingInterval = n;

	function next() {
		const start = Date.now();
		const rin = ++requestIndex;
		fetch(`/api/diagnostics/test-request?interval=${testingInterval}`)
			.then(res => {
				const length = Date.now() - start;
				const startDate = moment(start).format('HH:mm:ss');
				let s = `Запрос #${rin} выполнен (${n}, ${res.status}, start ${startDate}, time ${(length / 1000).toFixed(1)} s)`;
				if (res.status !== 200) {
					s += `.\nСтатус отличается от ожидаемого: ${res.status}, ${res.statusText || 'ошибка не указана'}`;
				}
				addLog(s);
			})
			.catch(error => {
				const length = Date.now() - start;
				const startDate = moment(start).format('HH:mm:ss');
				addLog(`Запрос #${rin} выполнен с ошибкой (${n}, start ${startDate}, time ${(length / 1000).toFixed(1)} s): ${error}`);
				console.error(error);
			})
			.finally(() => {
				if (n === testingInterval) {
					next();
				}
			});
	}
	next();
}

function stopTesting(): void {
	testingInterval = 0;
	clearTimeout(nextTimer);
}
