import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ChecklistTaskStatusEnum, ChecklistTaskStatusTitle, getRoleName, arrayToMapById, PageInfo } from 'common-lib';
import { get, set } from '~tools/localConfig';
import { loadSubordinateShops } from '~api/shopsApi';
import ChecklistTasksFetcher from '~api/ChecklistTasksFetcher';
import { ListIsEmpty, Spinner } from '~components';
import { Subordinates } from '~components/ui/AddresseeSelector';
import { SearchWizardContentItem } from '~components/ui/SearchAndFiltersWizard';
import { UiContainerContentBreadCrumbs } from '~components/ui/UiContainer';
import { UserContentWrapper } from '../../../containers';
import ChecklistTaskCreateView from './ChecklistTaskCreateView';
import ChecklistTaskView from './ChecklistTaskView';
import ShopChecklistTaskListView from './ShopChecklistTaskListView';
import ManagerChecklistTaskListView from './ManagerChecklistTaskListView';
import { PageNotFound } from '../../index';
import { fillSubordinateMap } from '../../../lib/subordinateTools';

// ключ для хранения фильтров списка
const UChecklistTasksSP_PAGE_INFO = 'UChecklistTasksSP.pageInfo';
// значения "Все" для фильтра
const FILTER_ALL = 'all';

const fetcher = new ChecklistTasksFetcher();

type NumberOrNo = number | undefined;

function getIdFromHash(hash?: string): NumberOrNo {
	try {
		return Number(atob(hash || '')) || undefined;
	} catch (_) {
		return undefined;
	}
}

function sum(arr: number[]) {
	return arr.reduce((s, v) => s + v, 0);
}

type UserTreeShop = {
	all: number,
	completed: number,
	percent: number,
	shop: any,
};

type UserTree = {
	all: number,
	completed: number,
	percent: number,
	users: UserTree[],
	shops: UserTreeShop[],
	user: any,
};

type ComponentStore = {
	checkManagerQueryString: string | undefined,
	checkShopQueryString: string | undefined,
	isSubordinatesRequesting: boolean,
	isManagerDataRequesting: boolean,
	isShopDataRequesting: boolean,
	isChecklistTaskDataRequesting: boolean,
	searchString: string,
};

type State = {
	subordinatesData?: Subordinates | null,
	managerData: any,
	shopData: any,
	savedShopId?: number,
	checklistTaskData: any,
	savedChecklistTaskId: string,
	searchedContent: SearchWizardContentItem[] | null,
};

type Params = {
	managerHashId: string,
	shopHashId: string,
	checklistTaskHashId: string,
};

export default function UserChecklistTaskSectionPage() {
	const [store] = useState<ComponentStore>({
		checkManagerQueryString: get(UChecklistTasksSP_PAGE_INFO, ''),
		checkShopQueryString: get(UChecklistTasksSP_PAGE_INFO, ''),
		isSubordinatesRequesting: false,
		isManagerDataRequesting: false,
		isShopDataRequesting: false,
		isChecklistTaskDataRequesting: false,
		searchString: '',
	});
	const [{
		subordinatesData,
		managerData,
		savedShopId,
		savedChecklistTaskId,
		searchedContent,
	}, setState] = useState<Partial<State>>({});
	const [isLoading, setLoading] = useState<boolean>(true);
	const [shopData, setShopData] = useState<any>(undefined);
	const [checklistTaskData, setChecklistTaskData] = useState<any>(undefined);
	const navigate = useNavigate();
	const { managerHashId, shopHashId, checklistTaskHashId } = useParams<Params>();
	const { pathname } = useLocation();
	const { currentUser: { shops }, isShopUser } = useSelector((state: any) => state.me);

	// сначала загружаем список подчиненных
	// этот список нужен для менеджеров: отображаение задач и крошек, создание задач
	if (subordinatesData === undefined) {
		if (isShopUser) {
			updateState({ subordinatesData: null });
		} else if (!store.isSubordinatesRequesting) {
			loadSubordinateItems();
		}
		return renderLoading();
	}

	// рисуем страницу создания задачи
	if (pathname === '/checklist_tasks/create') {
		return <UserContentWrapper title="Создание задачи по ЧЛ" backLinkTo="/checklistTask">
			<ChecklistTaskCreateView subordinates={subordinatesData} onCreatedSuccess={onChecklistTaskUpdated} />
		</UserContentWrapper>;
	}

	const managerId: NumberOrNo = getIdFromHash(managerHashId);
	const shopId: NumberOrNo = getIdFromHash(shopHashId);
	const checklistTaskId: NumberOrNo = getIdFromHash(checklistTaskHashId);
	const shop = shops?.[0];

	// определяем title, ссылку назад и крошки
	const { title, subTitle } = calculateTitle();
	const backLinkTo = calculateBackLinkTo();
	const breadCrumbs = calculateBreadCrumbs();

	// если выбрана конкретная задача, загружаем и рендерим ее
	if (checklistTaskId) {
		if (checklistTaskData === undefined || savedChecklistTaskId !== `${shopId}/${checklistTaskId}`) {
			if (!store.isChecklistTaskDataRequesting) {
				loadChecklistTask(checklistTaskId, shopId);
			}
			return renderLoading();
		} else if (!checklistTaskData) {
			// в данных пусто
			return render(<PageNotFound />);
		} else {
			// рисуем страницу задачи
			return render(<ChecklistTaskView data={checklistTaskData}
											 onExecuteSuccess={onChecklistTaskUpdated}
											 onRejectSuccess={onChecklistTaskUpdated} />);
		}
	}

	const curPageInfo = PageInfo.parseFromString(location.search || get(UChecklistTasksSP_PAGE_INFO, '') || '');
	curPageInfo.pageIndex = 0;
	curPageInfo.pageSize = 200;
	curPageInfo.orderColumn = 'createdAt';
	curPageInfo.orderDirection = 'desc';

	const filters = getFilters();
	const isFilterSelected = filters.some(i => i.clearable);

	// выбран магазин или текущий пользователь это ДМ
	if (shopId || isShopUser) {
		if (shopData === undefined || savedShopId !== shopId || curPageInfo.toQueryString() !== store.checkShopQueryString && isLoading) {
			if (!store.isShopDataRequesting) {
				loadShopChecklistTaskList(shopId);
			}
			return renderLoading();
		} else if (!shopData?.length) {
			// в данных пусто
			return render(<ListIsEmpty filterSelectedInfo={isFilterSelected}
			                           error={shopData?.error || undefined} />);
		} else {
			// рисуем страницу списка задач для магазина
			return render(<ShopChecklistTaskListView data={shopData} />);
		}
	}

	// иначе обрабатываем данные как страницу менеджера
	if (managerData === undefined || curPageInfo.toQueryString() !== store.checkManagerQueryString) {
		if (!store.isManagerDataRequesting) {
			loadManagerChecklistTaskList();
		}
		return renderLoading();
	} else if (!managerData?.users?.length && !managerData?.shops?.length) {
		// в данных пусто
		return render(<ListIsEmpty filterSelectedInfo={isFilterSelected}
		                           error={managerData?.error || undefined} />);
	} else {
		// ищем уровень менеджера для рендера списка подчиненных
		const root = findManagerTree(managerData);
		if (!root) {
			// в данных пусто
			return render(<ListIsEmpty filterSelectedInfo={isFilterSelected}
			                           error={managerData?.error || undefined} />);
		}
		// рисуем страницу менеджера
		return render(<ManagerChecklistTaskListView data={root} />);
	}

	// вызывается когда задача обновляется или создается
	function onChecklistTaskUpdated() {
		// сбрасываем все данные про задачи
		updateState({
			managerData: undefined,
		});
		setShopData(undefined);
		setChecklistTaskData(undefined);
		navigate(`/checklist_tasks`);
	}

	// вычисляем заголовок для страницы
	function calculateTitle(): { title: string, subTitle?: string } {
		if (isShopUser) {
			if (checklistTaskId) {
				return { title: shop.city, subTitle: shop.address };
			} else {
				return { title: 'Задачи по ЧЛ' };
			}
		} else {
			if (shopId) {
				const shop = subordinatesData?.shopMap.get(shopId);
				if (shop) {
					return { title: shop.city, subTitle: shop.address };
				} else {
					return { title: 'Задачи по ЧЛ' };
				}
			} else {
				if (managerId) {
					const manager = subordinatesData?.userMap.get(managerId);
					return {
						title: manager?.fullName || 'x5',
						subTitle: manager?.shopManagerRole?.shortTitle || getRoleName(manager?.role),
					};
				} else {
					return { title: 'Задачи по ЧЛ' };
				}
			}
		}
	}

	// вычисляем ссылку для перехода назад
	function calculateBackLinkTo(): string | undefined {
		if (isShopUser) {
			return checklistTaskId ? '/checklist_tasks' : undefined;
		} else {
			if (checklistTaskId) {
				const shop = shopId ? subordinatesData?.shopMap.get(shopId) : undefined;
				return shop ? `/checklist_tasks/shop/${btoa(shop.id.toString())}` : '<shop>';
			} else if (shopId) {
				const shop = subordinatesData?.shopMap.get(shopId);
				const manId = shop?.upfId || shop?.tmId || shop?.dfId;
				return manId ? `/checklist_tasks/${btoa(manId.toString())}` : '/checklist_tasks';
			} else if (managerId) {
				const manager = subordinatesData?.userMap.get(managerId);
				const parent = manager?.parent;
				return parent ? `/checklist_tasks/${btoa(parent.id.toString())}` : '/checklist_tasks';
			} else {
				return undefined;
			}
		}
	}


	// вычисляем хлебные крошки
	function calculateBreadCrumbs(): UiContainerContentBreadCrumbs | undefined {
		if (checklistTaskId) {
			return undefined;
		}
		if (!managerId && !shopId) return undefined;
		const res: UiContainerContentBreadCrumbs = [];
		let manager = managerId ? subordinatesData?.userMap.get(managerId) : undefined;
		if (shopId) {
			const shop = subordinatesData?.shopMap.get(shopId);
			res.unshift({
				title: shop?.address || '<address>',
				linkTo: shop ? `/checklist_tasks/shop/${btoa(shop.id.toString())}` : undefined,
			});
			manager = shop?.supervisorUser;
		}
		while (manager?.id) {
			res.unshift({
				title: `${manager.shopManagerRole?.shortTitle || getRoleName(manager.role)} ${manager.shortName}`,
				linkTo: manager.id !== managerId ? `/checklist_tasks/${btoa(manager.id.toString())}` : undefined,
			});
			manager = manager.parent;
		}
		res.unshift({
			title: `Все`,
			linkTo: `/checklist_tasks`,
		});
		return res;
	}

	function findManagerTree(userTree) {
		if (!managerId || userTree.user.id === managerId) return userTree;
		let found;
		userTree.users?.some(i => {
			if (found) return true;
			found = findManagerTree(i);
		});
		return found;
	}

	// метод следит за тем, чтобы все части textParts были найдены хотя бы раз, иначе вернет false
	// в положительном кейсе вернет массив [минимальная позиция в слове, номер слова, длина строки поиска]
	function searchPartsInTexts(textParts, strings): [number, number, number] | false {
		strings = strings.map(s => (s || '').trim().toLowerCase()).filter(s => !!s);
		if (!strings.length) return false;
		let min: [number, number, number] | false = false;
		for (const part of textParts) {
			const k = strings
				.map((s, index) => [s.indexOf(part), index, part.length])
				.filter(i => i[0] >= 0)
				// ищем миниальное вхождение с позицией 0 или выше
				.reduce((v, i) => v === false || i[0] < v[0] ? i : v, false);
			if (k === false) return false;
			if (min === false || k[0] < min[0] || (k[0] === min[0] && k[1] < min[1])) min = k;
		}
		return min;
	}

	// поиск подчиненных из строки поиска
	function searchSubordinates(text: string) {
		const searchParts = String(text || '')
			.replace(/[^\w А-Яа-яёË]+/ig, '')
			.split(' ')
			.map(s => s.trim().toLowerCase())
			.filter(s => !!s && s.length >= 2); // не будем искать короткие слова
		const { shopMap, userMap } = subordinatesData!;
		const managers = Array.from(userMap.values())
			.filter((u: any) => {
				if (!u?.stats?.all) return false;
				// TODO xxx: return [USER_ROLE_DF, USER_ROLE_TM, USER_ROLE_UPF].includes(u.role);
				return false;
			})
			.map(user => {
				// сложная логика рассчета баллов:
				// чем ближе найденная подстрока к началу, тем выше балл
				// совпадение в фамилии стоит = 3000, в имени = 200, в отчестве = 100
				// чсло выбрано большим, чтобы балл не ушел в минус
				const findScore = searchPartsInTexts(searchParts,
					[user.lastName, user.firstName, user.middleName]);
				if (findScore === false) return undefined;
				const score = 300 - findScore[1] * 100 - findScore[0] + findScore[2];
				return {
					score,
					text: `${user.shopManagerRole?.shortTitle || getRoleName(user.role)} ${user.fullName}`,
					fullName: user.fullName,
					linkTo: `/checklistTask/${btoa(user.id.toString())}`,
				};
			})
			.filter(i => !!i)
			// ранжируем по релеванстности поиска
			.sort((a: any, b: any) => a.score !== b.score ? b.score - a.score
				: a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0);
		const shops = Array.from(shopMap.values())
			.filter((s: any) => !!s?.stats?.all)
			.map(shop => {
				// сложная логика рассчета баллов:
				// чем ближе найденная подстрока к началу, тем выше балл
				// совпадение в адресе стоит = 1000, в городе = 500
				// чсло выбрано большим, чтобы балл не ушел в минус
				const findScore = searchPartsInTexts(searchParts,
					[shop.address, shop.city]);
				if (findScore === false) return undefined;
				const score = 1000 - findScore[1] * 500 - findScore[0] + findScore[2];
				return {
					score,
					text: `${shop.city}, ${shop.address}`,
					linkTo: `/checklistTask/shop/${btoa(shop.id.toString())}`,
				};
			})
			.filter(i => !!i)
			// ранжируем по релеванстности поиска
			.sort((a: any, b: any) => a.score !== b.score ? b.score - a.score
				: a.text > b.text ? 1 : b.text > a.text ? -1 : 0);
		const searchedContent1: SearchWizardContentItem[] = [];
		if (managers.length) {
			searchedContent1.push({
				title: 'СОТРУДНИКИ',
				items: managers.map((i: any) => ({
					text: i.text,
					linkTo: i.linkTo,
				})),
				hasMore: managers.length > 50,
			});
		}
		if (shops.length) {
			searchedContent1.push({
				title: 'МАГАЗИНЫ',
				items: shops.map((i: any) => ({
					text: i.text,
					linkTo: i.linkTo,
				})),
				hasMore: shops.length > 50,
			});
		}
		updateState({ searchedContent: searchedContent1 });
	}

	function onSearchChanged(event) {
		const value = event.target.value.trim();
		store.searchString = value;
		if (value.length >= 3) {
			searchSubordinates(value);
		} else {
			updateState({
				searchedContent: null,
			});
		}
	}

	function onSearchClear() {
		store.searchString = '';
		updateState({
			searchedContent: null,
		});
	}

	// рендерит страницу
	function render(children: ReactNode) {
		return <UserContentWrapper
			title={title}
			subTitle={subTitle}
			isPreview={!!checklistTaskId}
			backLinkTo={backLinkTo}
			breadCrumbs={breadCrumbs}
			searchOptions={isShopUser ? undefined : {
				searchString: store.searchString,
				onSearchChanged,
				onSearchClear,
				isLoading: false,
				content: searchedContent || null,
			}}
			filterOptions={checklistTaskId ? undefined : filters}
			onPlusButtonClick={isShopUser || shopId || checklistTaskId ? undefined : onPlusClick}
		>
			{children}
		</UserContentWrapper>;
	}

	function onPlusClick() {
		navigate('/checklist_tasks/create');
	}

	function renderLoading() {
		return <UserContentWrapper title="Задачи по ЧЛ">
			<Spinner onpage />
		</UserContentWrapper>;
	}

	// обновляет стейт при сохранении других полей, как это делает React.Component.setState()
	function updateState(obj: Partial<State>) {
		setState({
			subordinatesData, managerData, shopData, savedShopId, checklistTaskData, savedChecklistTaskId,
			searchedContent,
			...obj,
		});
	}

	function loadSubordinateItems() {
		store.isSubordinatesRequesting = true;
		loadSubordinateShops().then(data => {
			store.isSubordinatesRequesting = false;
			updateState({
				subordinatesData: fillSubordinateMap(data),
			});
		});
	}

	function loadChecklistTask(checklistTaskId: number, shopId?: number) {
		store.isChecklistTaskDataRequesting = true;
		(shopId ? fetcher.getOneByShopId(checklistTaskId, shopId) : fetcher.getOne(checklistTaskId))
			.then(json => {
				store.isChecklistTaskDataRequesting = false;
				setChecklistTaskData(fillChecklistTaskRenderData(json));
				updateState({
					savedChecklistTaskId: `${shopId}/${checklistTaskId}`, // определяем уникальность задачи
				});
			});
	}

	function loadShopChecklistTaskList(shopId?: number) {
		store.isShopDataRequesting = true;
		store.checkShopQueryString = curPageInfo.toQueryString();
		set(UChecklistTasksSP_PAGE_INFO, store.checkShopQueryString);
		(shopId ? fetcher.getListByShopId(shopId, curPageInfo) : fetcher.getList(curPageInfo))
			.then(json => {
				store.isShopDataRequesting = false;
				setShopData(fillShopRenderData(json));
				setLoading(false);
			});
	}

	function loadManagerChecklistTaskList() {
		store.isManagerDataRequesting = true;
		store.checkManagerQueryString = curPageInfo.toQueryString();
		set(UChecklistTasksSP_PAGE_INFO, store.checkManagerQueryString);
		fetcher.getList(curPageInfo).then(json => {
			store.isManagerDataRequesting = false;
			updateState({
				managerData: fillManagerRenderData(subordinatesData, json),
			});
		});
	}

	function getFilters() {
		const selectedValues = curPageInfo.customParams?.statuses || FILTER_ALL;
		return [{
			title: 'СТАТУС',
			options: [
				{ text: 'Все', value: FILTER_ALL },
				...Object.keys(ChecklistTaskStatusEnum).filter(key => key !== ChecklistTaskStatusEnum.STARTED).map(key => ({
					text: ChecklistTaskStatusTitle[key],
					value: key,
				})),
			],
			selected: selectedValues,
			multiselect: true,
			clearable: selectedValues !== FILTER_ALL,
			onChange: value => value !== selectedValues ? onFilterChange(value) : undefined,
		}];
	}

	function onFilterChange(value: string) {
		if (!value) value = FILTER_ALL;
		// в фильтре используется мультиселект, поэтому проверяем на all в конце
		const values: string[] = value ? value.split(',') : [];
		const k = values.indexOf(FILTER_ALL);
		if (k === values.length - 1) {
			value = FILTER_ALL;
		} else if (k >= 0) {
			values.splice(k, 1);
			value = values.join(',');
		}
		curPageInfo.update({ statuses: value === FILTER_ALL ? undefined : value });
		navigate(`${location.pathname}?${curPageInfo.toQueryString()}`);
		setLoading(true);
	}
}

// собирает данные по задаче
function fillChecklistTaskRenderData(json) {
	try {
		const { data } = json;
		const userMap = json.meta?.userMap;
		if (!data) {
			return { error: 'Не удалось определить подробности по задаче' };
		}
		let executorUser;
		if (data.executorUserId) {
			executorUser = userMap?.[data.executorUserId];
			if (!executorUser) {
				return { error: 'Не удалось определить исполнителя' };
			}
		}
		return {
			checklistTaskId: data.id,
			status: data.status,
			department: data.departmentName,
			checkListName: data.checkListName,
			taskId: data.taskId,
			checkListProcessName: data.checkListProcessName,
			checkListQuestion: data.checkListQuestion,
			creatorComment: data.creatorComment,
			questionPhotos: data.questionPhotos,
			taskCreatedAt: data.taskCreatedAt,
			taskDeadlineAt: data.taskDeadlineAt,
			creatorFullname: data.creatorFullname,
			isPhotoRequired: data.isPhotoRequired,
			isCommentRequired: data.isCommentRequired,
			isExpired: data.isExpired,
			executorUser,
			executorComment: data.executorComment,
			executionPhotoIds: JSON.parse(data.executionPhotoIds),
			taskClosedAt: data.taskClosedAt ? new Date(data.taskClosedAt) : undefined,
		};
	} catch (e: any) {
		console.error(e);
		return { error: e instanceof Error ? e.message : String(e) };
	}
}

// строим список задач для магазина
function fillShopRenderData(json) {
	const listArr: any[] = [];
	const groupStatus: any[] = [];
	const checklistTask = json.data;
	const { userMap } = json.meta || {};
	const checklistTaskMap = new Map<any, any>();

	const sortByStartDate = checklistTask
		.filter(i => i.status !== ChecklistTaskStatusEnum.COMPLETED)
		.sort((a, b) => new Date(b.taskCreatedAt).getTime() - new Date(a.taskCreatedAt).getTime());
	const sortByFinishDate = checklistTask
		.filter(i => i.status === ChecklistTaskStatusEnum.COMPLETED)
		.sort((a, b) => new Date(b.taskClosedAt).getTime() - new Date(a.taskClosedAt).getTime());
	const result = [...sortByStartDate, ...sortByFinishDate];
	result.forEach(task => {
		const groupStatus = ChecklistTaskStatusTitle[task.isExpired ? ChecklistTaskStatusEnum.EXPIRED : task.status];
		if (!checklistTaskMap.has(groupStatus)) {
			checklistTaskMap.set(groupStatus, []);
		}
		checklistTaskMap.get(groupStatus)?.push({
			...task
		});
	});
	// группируем задачи по типу: В работе, Новая, Новая Просрочено, Выполнено
	if (checklistTaskMap.has(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.STARTED])) {
		const startTask = checklistTaskMap.get(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.STARTED]);
		checklistTaskMap.delete(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.STARTED]);
		checklistTaskMap.set('1. В работе', startTask);
	}
	if (checklistTaskMap.has(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.PLANNED])) {
		const planTask = checklistTaskMap.get(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.PLANNED]);
		checklistTaskMap.delete(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.PLANNED]);
		checklistTaskMap.set('2. Новая', planTask);
	}
	if (checklistTaskMap.has(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.EXPIRED])) {
		const expiredTask = checklistTaskMap.get(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.EXPIRED]);
		checklistTaskMap.delete(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.EXPIRED]);
		checklistTaskMap.set('3. Просрочено', expiredTask);
	}
	if (checklistTaskMap.has(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.COMPLETED])) {
		const completedTask = checklistTaskMap.get(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.COMPLETED]);
		checklistTaskMap.delete(ChecklistTaskStatusTitle[ChecklistTaskStatusEnum.COMPLETED]);
		checklistTaskMap.set('4. Выполнено', completedTask);
	}

	const sortedStatus = Array.from(checklistTaskMap.keys()).sort();
	sortedStatus.map(status => {
		const sortByStatus = checklistTaskMap.get(status);
		groupStatus.push(...sortByStatus);
	});
	groupStatus.map(ts => {
		const processName = ts.checkListProcessName;
		const question = ts.checkListQuestion;
		const creatorComment = ts.creatorComment;
		const creatorName = ts.creatorFullname;
		const department = ts.departmentName;
		const isExpired = ts.isExpired;
		let executorUser;
		if (ts.executorUserId) {
			executorUser = userMap?.[ts.executorUserId];
			if (!executorUser) {
				return { error: 'Не удалось определить исполнителя' };
			}
		}
		const values: any[] = [
			{ key: 'Вопрос:', value: question },
		];
		if (processName) {
			values.unshift({ key: 'Процесс:', value: processName });
		}
		values.push({ key: 'Автор:', value: creatorName ? creatorName : 'Не указано' });
		values.push({ key: 'Коментарий:', value: creatorComment ? creatorComment : 'Отсутствует' });
		if (executorUser) {
			values.splice(-1, 0, { key: 'Исполнитель:', value: executorUser?.fullName });
		}
		return listArr.push({
			checklistTaskId: ts.id,
			taskId: `№ ${ts.taskId}`,
			checkListName: ts.checkListName,
			isExpired,
			department,
			values,
			status: ts.status,
			taskCreatedAt: ts.taskCreatedAt,
			taskDeadlineAt: ts.taskDeadlineAt,
		});
	});
	return listArr;
}

// строим дерево подчиненных
function fillManagerRenderData(subordinates, json) {
	try {
		const { checklistTaskToShopMap } = json.meta || {};
		const checklistTaskToShops: any[] = Object.values(checklistTaskToShopMap || {});
		return (function fill(user): UserTree {
			const users: UserTree[] = [];
			const shops: UserTreeShop[] = [];
			const { subordinateUsers, subordinateShops } = user;
			subordinateShops?.forEach(subShop => {
				const checklistTask = checklistTaskToShops.filter(i => i.shopId === subShop.id);
				if (checklistTask.length) {
					const all = checklistTask.length;
					const completed = checklistTask.filter(i => i.status === ChecklistTaskStatusEnum.COMPLETED).length;
					const percent = completed / all * 100;
					shops.push({ all, completed, percent, shop: subShop });
					subShop.stats = { all, completed };
				}
			});
			subordinateUsers?.forEach(subUser => {
				const userTree: UserTree = fill(subUser);
				if (userTree.all) {
					users.push(userTree);
				}
			});
			// calc
			const all = sum(shops.map(i => i.all)) + sum(users.map(i => i.all));
			const completed = sum(shops.map(i => i.completed)) + sum(users.map(i => i.completed));
			const percent = completed / all * 100;
			user.stats = { all, completed };
			return { all, completed, percent, users, shops, user };
		})(subordinates);
	} catch (e: any) {
		console.error(e);
		return { error: e instanceof Error ? e.message : String(e) };
	}
}
