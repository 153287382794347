import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Dropdown, Header, Icon, Input, Label, Segment, Table } from 'semantic-ui-react';
import { hashCode } from 'common-lib';
import { get, set } from '~tools/localConfig';

const { console } = global;

export const DIR_ASC = 'ascending';
export const DIR_DESC = 'descending';

const FloatLeft = styled.div`
  float: left;
  margin-right: 1rem;
`;

const FloatRight = styled.div`
  float: right;
  margin-left: 1rem;
`;

const PAGE_SIZE_OPTIONS = [
	{ key: '10', text: '10', value: 10 },
	{ key: '20', text: '20', value: 20 },
	{ key: '50', text: '50', value: 50 },
	{ key: '100', text: '100', value: 100 },
	{ key: '200', text: '200', value: 200 },
];

function renderError(node) {
	return <Segment inverted color="red" style={{ clear: 'both' }}>{node}</Segment>;
}

function renderInfo(node) {
	return <Segment secondary style={{ clear: 'both' }}>{node}</Segment>;
}

/**
 * Вытаскивает функции, которые генерируют новое значение для столбцов таблицы
 * @param headers
 * @returns {string[]}
 */
function selectHeaderKeysWithValueFunc(headers) {
	return Object.keys(headers).filter(key => headers[key].valueFunc);
}

/**
 * Подготовка табличных данных
 */
function updateTableData(inputData, headers) {
	console.time('DataTable.updateTableData');
	let res = [];
	if (inputData && inputData.length) {
		res = inputData.map((dataRow) => {
			// уникальный row key
			const row = Object.assign(
				{ __key: hashCode(JSON.stringify(dataRow)) },
				dataRow,
			);
			const valueFuncKeys = selectHeaderKeysWithValueFunc(headers);
			if (!valueFuncKeys) {
				return row;
			}
			valueFuncKeys.forEach(headerKey => {
				const { valueFunc } = headers[headerKey];
				row[headerKey] = valueFunc(row);
			});
			return row;
		});
	}
	console.timeEnd('DataTable.updateTableData');
	return res;
}

/**
 * Фильтрация табличных данных
 */
function filterTableData(inputData, filterValues) {
	console.time('DataTable.filterTableData');
	let res = inputData;
	const fields = Object.keys(filterValues).filter(s => !s.startsWith('_'));
	if (res && res.length && fields && fields.length) {
		res = res.filter(row => fields.every(field => (filterValues[field] === '$FILTER_EMPTY_VALUES') ? !row[field] : (row[field] === filterValues[field])));
	}
	console.timeEnd('DataTable.filterTableData');
	return res;
}

/**
 * Фильтрация по строке поиска
 */
function filterBySearchString(inputData, searchString) {
	if (!searchString || !inputData || !inputData.length) return inputData;
	searchString = searchString.toLowerCase();
	const keys = Object.keys(inputData[0]).filter(s => !s.startsWith('_')); // исключаем сервисные поля
	return inputData
		.filter(row => keys.some(rowKey => !!row[rowKey] && String(row[rowKey]).toLowerCase().includes(searchString)));
}

/**
 * Сортировка табличных данных
 */
function sortTableData(inputData, prevSortColumn, sortColumn, sortDirection) {
	console.time('DataTable.sortTableData');
	let res = inputData;
	if (res && res.length && sortColumn) {
		if (sortColumn !== prevSortColumn) {
			res = res.sort((valueA, valueB) => {
				if (typeof valueA[sortColumn] === 'number' && typeof valueB[sortColumn] === 'number') {
					return valueA[sortColumn] - valueB[sortColumn];
				}
				const lowerA = String(valueA[sortColumn] || '').toLowerCase().trim();
				const lowerB = String(valueB[sortColumn] || '').toLowerCase().trim();
				return lowerA > lowerB ? 1 : (lowerA < lowerB ? -1 : 0);
			});
		}
		if (sortColumn === prevSortColumn || sortDirection === DIR_DESC) {
			res = res.reverse();
		}
	}
	console.timeEnd('DataTable.sortTableData');
	return res;
}

/**
 * Компонент строки поика
 */
class SearchInput extends React.Component<{ onChange: (s: string) => void, value?: string }>
{
	static propTypes = {
		value: PropTypes.string,
		onChange: PropTypes.func.isRequired,
	};

	state = { isSearchStringEditing: false };

	private declare editingSearchStringTimerId: NodeJS.Timeout;

	handleChangeSearchString = (_, { value }) => {
		clearTimeout(this.editingSearchStringTimerId);

		this.editingSearchStringTimerId = setTimeout(() => {
			this.props.onChange(value);
			this.setState({ isSearchStringEditing: false });
		}, 600);
		if (!this.state.isSearchStringEditing) {
			this.setState({ isSearchStringEditing: true });
		}
	};

	render() {
		return (
			<Input
				size="small"
				icon="search"
				defaultValue={this.props.value || ''}
				placeholder="Поиск..."
				loading={this.state.isSearchStringEditing}
				onChange={this.handleChangeSearchString}
			/>
		);
	}
}

type DataTableState = {
	error?: string,
	_prevSortColumn?: string,
	_prevSortDirection?: string,
	sortColumn?: string,
	sortDirection?: 'ascending' | 'descending',
	searchString?: string,
	filterValues?: any,
	pageSize?: number,
	pageIndex?: number,
	pageCount?: number,
	tableData?: any[] | null,
	tableInitData?: any[] | null,
	tableDataRowsNumber?: number,
}

export default class DataTable extends React.Component<any, DataTableState>
{
	static propTypes = {
		storeKey: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		icon: PropTypes.string,
		readOnly: PropTypes.bool,
		pageIndex: PropTypes.number,
		pageSize: PropTypes.number,
		filterOptions: PropTypes.array,
		tableHeaders: PropTypes.object.isRequired,
		tableData: PropTypes.array,
		onAddItem: PropTypes.func,
		defaultSortColumn: PropTypes.string,
		defaultSortDirection: PropTypes.string,
	};

	private SEARCH_STRING_STORE_KEY: string = '';
	private SORT_COLUMN_STORE_KEY: string = '';
	private SORT_DIRECTION_STORE_KEY: string = '';
	private PAGE_SIZE_STORE_KEY: string = '';

	constructor(props) {
		super(props);
		// проверяем и иничим табличные данные из входных данных
		if (!props.storeKey) {
			this.state = { error: 'Не указано поле storeKey' };
			return;
		}
		if (!props.title) {
			this.state = { error: 'Не указано поле title' };
			return;
		}
		if (!props.tableHeaders) {
			this.state = { error: 'Не указано поле tableHeaders' };
			return;
		}
		// ключи для хранения в localStorage
		this.SEARCH_STRING_STORE_KEY = `${props.storeKey}::searchString`;
		this.SORT_COLUMN_STORE_KEY = `${props.storeKey}::sortColumn`;
		this.SORT_DIRECTION_STORE_KEY = `${props.storeKey}::sortDirection`;
		this.PAGE_SIZE_STORE_KEY = `${props.storeKey}::pageSize`;
		//
		this.state = {
			searchString: get(this.SEARCH_STRING_STORE_KEY),
			filterValues: {},
			sortColumn: get(this.SORT_COLUMN_STORE_KEY) || props.defaultSortColumn,
			sortDirection: get(this.SORT_DIRECTION_STORE_KEY) || props.defaultSortDirection,
			pageSize: (get(this.PAGE_SIZE_STORE_KEY) || props.pageSize || 20) * 1,
			pageIndex: props.pageIndex || 1,
			tableData: null,
		};
	}

	static getDerivedStateFromProps(props, state) {
		// сравниваем хранимые и новые данные для таблицы, сконвертировав их в JSON
		let tableInitData;
		const jsonPropsItems = JSON.stringify(props.tableData);
		if (state._prevJsonPropsItems !== jsonPropsItems) {
			// сверху пришли новые данные для таблицы
			tableInitData = updateTableData(props.tableData, props.tableHeaders);
		}
		const { _prevSortColumn, sortColumn, _prevSortDirection, sortDirection } = state;
		const isNoSortChanged = _prevSortColumn === sortColumn && _prevSortDirection === sortDirection;
		const { _prevFilterValues, filterValues } = state;
		const jsonFilterValues = JSON.stringify(filterValues);
		const isNoFilterChanged = _prevFilterValues === jsonFilterValues;
		const { _prevSearchString, searchString } = state;
		const isNoSearchStringChanged = _prevSearchString === searchString;
		const { _prevPageSize, pageSize, _prevPageIndex, pageIndex } = state;
		const isNoPaginationChanged = _prevPageSize === pageSize && _prevPageIndex === pageIndex;
		if (isNoSortChanged && isNoFilterChanged && isNoSearchStringChanged && !tableInitData && isNoPaginationChanged) {
			// данные в таблице не изменились
			console.debug('DataTable.getDerivedStateFromProps: no updates');
			return null;
		}
		if (!tableInitData) {
			tableInitData = state.tableInitData;
		}
		// фильтруем по выбранным поялм
		let tableData = filterTableData(tableInitData, filterValues);
		// фильтруем по стркое поиска
		tableData = filterBySearchString(tableData, searchString);
		// сортируем
		tableData = sortTableData(tableData, isNoSortChanged ? null : _prevSortColumn, sortColumn, sortDirection);
		const tableDataRowsNumber = tableData.length;
		// расчет количества страниц
		const pageCount = Math.floor((tableData.length - 1) / pageSize) + 1;
		const newPageIndex = Math.max(1, Math.min(state.pageIndex, pageCount));
		// ограничиваем список пагинацией
		const min = (newPageIndex - 1) * pageSize;
		tableData = tableData.slice(min, min + pageSize);
		return {
			tableDataRowsNumber,
			tableData,
			tableInitData,
			_prevFilterValues: jsonFilterValues,
			_prevSearchString: searchString,
			_prevSortColumn: sortColumn,
			_prevSortDirection: sortDirection,
			_prevJsonPropsItems: jsonPropsItems,
			pageIndex: newPageIndex,
			_prevPageIndex: pageIndex,
			pageCount,
			_prevPageSize: pageSize,
		};
	}

	handleSort = (clickedColumn) => {
		const { sortColumn, sortDirection } = this.state;
		this.setState({
			_prevSortColumn: sortColumn,
			_prevSortDirection: sortDirection,
			sortColumn: clickedColumn,
			sortDirection: (sortColumn === clickedColumn)
				? (sortDirection === DIR_ASC) ? DIR_DESC : DIR_ASC
				: DIR_ASC,
		});
	};

	handleChangeSearchString = searchString => {
		set(this.SEARCH_STRING_STORE_KEY, searchString);
		this.setState({ searchString });
	};

	handleFilterChange = (key, value?, content?) => {
		const { [key]: filterValue } = this.state.filterValues;
		if (value !== filterValue) {
			this.setState(({ filterValues }) => {
				filterValues = Object.assign({}, filterValues);
				if (!value) {
					delete filterValues[key];
				} else {
					filterValues[key] = value;
					filterValues[`_content_${key}`] = content;
				}
				return { filterValues };
			});
		}
	}

	handleChangePageIndex = pageIndex => this.setState({ pageIndex });

	handleChangePageSize = (_, { value: pageSize }: { value?: any }) => {
		set(this.PAGE_SIZE_STORE_KEY, pageSize);
		this.setState({ pageSize });
	}

	renderFilter = () => {
		if (this.props.filterOptions) {
			const { filterValues } = this.state;
			const fields = Object.keys(filterValues).filter(s => !s.startsWith('_'));
			return (
				<FloatLeft>
					<Dropdown
						text="Фильтр"
						icon="filter"
						labeled
						button
						className="icon"
					>
						<Dropdown.Menu>
							{this.props.filterOptions.map(o => (
								<Dropdown.Item key={o.field} onClick={e => { e.stopPropagation(); o._ref.open(); /* открываем подменю */ }}>
									<Dropdown text={o.label} scrolling ref={ref => o._ref = ref /* запоминаем ссылку чтобы при клике в пусто месте элемента открыть подменю */}>
										<Dropdown.Menu>
											{o.options.map(o1 => (
												<Dropdown.Item
													{...o1}
													onClick={() => this.handleFilterChange(o.field, o1.value, o1.content) /* контент передаем чтобы отобразить его в кнопке фильтра */}
													selected={o1.value === filterValues[o.field]}
												/>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
					{fields.map(fieldKey => (
						<Label key={fieldKey}>
							{filterValues[`_content_${fieldKey}`]}
							<Icon name="delete" onClick={() => this.handleFilterChange(fieldKey)} />
						</Label>
					))}
				</FloatLeft>
			);
		}
	}

	renderSearchInput = () => (
		<FloatRight>
			<SearchInput value={this.state.searchString || ''} onChange={this.handleChangeSearchString} />
		</FloatRight>
	);

	renderCreateOne = () => {
		if (this.props.onAddItem) {
			return (
				<FloatRight>
					<Button
						primary
						size="small"
						icon="add"
						labelPosition="left"
						content="Создать"
						onClick={() => this.props.onAddItem()}
					/>
				</FloatRight>
			);
		}
	}

	renderHeader = () => {
		const { tableHeaders } = this.props;
		const { sortColumn, sortDirection } = this.state;
		const tableHeaderKeys = Object.keys(tableHeaders);

		return (
			<Table.Header>
				<Table.Row>
					{tableHeaderKeys.map(headerKey => (
						<Table.HeaderCell
							key={headerKey}
							sorted={sortColumn === headerKey ? sortDirection : undefined}
							onClick={() => this.handleSort(headerKey)}
							content={
								(typeof tableHeaders[headerKey] === 'string' ? tableHeaders[headerKey] : tableHeaders[headerKey].title)
								|| undefined
							}
						/>
					))}
				</Table.Row>
			</Table.Header>
		);
	};

	renderBody = () => {
		const { tableHeaders } = this.props;
		const { tableData } = this.state;
		const tableHeaderKeys = Object.keys(tableHeaders);
		return (
			<Table.Body>
				{tableData?.map(dataRow => (
					<Table.Row key={dataRow.__key}>
						{tableHeaderKeys.map(headerKey => (
							<Table.Cell key={headerKey}>
								{tableHeaders[headerKey].viewFunc ? tableHeaders[headerKey].viewFunc(dataRow) : dataRow[headerKey]}
							</Table.Cell>
						))}
					</Table.Row>
				))}
			</Table.Body>
		);
	};

	renderPagination = () => {
		const { pageCount, pageIndex, pageSize } = this.state;
		const lIndex = Math.max(1, (pageIndex || 0) - 3);
		const rIndex = Math.min(pageCount || 0, (pageIndex || 0) + 3);
		const midCount = rIndex - lIndex + 1;
		return (
			<>
				<Dropdown
					button
					className="icon"
					labeled
					options={PAGE_SIZE_OPTIONS}
					defaultValue={pageSize}
					onChange={this.handleChangePageSize}
				/>
				<Button.Group>
					{lIndex > 1 && <Button content="1" onClick={() => this.handleChangePageIndex(1)} />}
					{lIndex > 2 && <Button disabled content="..." />}
					{midCount && Array(rIndex - lIndex + 1).fill(0).map((_, i) => {
						const index = i + lIndex;
						return (
							<Button
								key={index}
								content={index}
								active={index === pageIndex}
								onClick={index !== pageIndex ? () => this.handleChangePageIndex(index) : undefined}
							/>
						);
					})}
					{rIndex < (pageCount || 0) - 1 && <Button disabled content="..." />}
					{rIndex < (pageCount || 0) && <Button content={pageCount} onClick={() => this.handleChangePageIndex(pageCount)} />}
				</Button.Group>
			</>
		);
	}

	render() {
		const hasError = !!this.state.error;
		const isEmpty = !this.state.tableInitData || !this.state.tableInitData.length;
		if (hasError || isEmpty) {
			return (
				<>
					<Header as="h3" icon={this.props.icon} content={this.props.title} />
					{hasError && renderError(this.state.error)}
					{!hasError && isEmpty && renderInfo('В таблице нет записей')}
				</>
			);
		}
		const allCount = this.state.tableInitData?.length || 0;
		const { tableDataRowsNumber } = this.state;
		const countLabel = `Записей: ${allCount === tableDataRowsNumber ? tableDataRowsNumber : `${tableDataRowsNumber} / ${allCount}`}`;
		return (
			<>
				<div>
					<Header
						floated="left"
						as="h3"
						icon={this.props.icon}
						content={this.props.title}
						subheader={countLabel}
					/>
					{this.renderFilter()}
					{this.props.readOnly ? null : this.renderCreateOne()}
					{this.renderSearchInput()}
				</div>
				<Table sortable celled compact>
					{this.renderHeader()}
					{this.renderBody()}
				</Table>
				{this.renderPagination()}
			</>
		);
	}
}
