import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageInfo } from 'common-lib';
import { DataTableWithPageInfo, Spinner } from '~components';
import IncidentTypesFetcher from '~api/IncidentTypesFetcher';
import { fillIncidentTypes, INCIDENT_TYPES_TABLE_HEADERS } from '../incidentsAttrs';

const incidentTypesFetcher = new IncidentTypesFetcher();

export default function AdminIncidentTypesTablePage() {
	const [{ tableData, checkQueryString, isRequesting }, setTableData]: any = useState({});

	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const pageInfo = PageInfo.parseFromString(String(params), { order: 'id:desc' });

	if (tableData === undefined || pageInfo.toQueryString() !== checkQueryString) {
		if (!isRequesting) {
			setTableData({ tableData, checkQueryString, isRequesting: true });
			incidentTypesFetcher.getList(pageInfo)
				.then(fillIncidentTypes)
				.then((tableData: any) => setTableData({
					tableData: tableData || null,
					checkQueryString: pageInfo.toQueryString(),
				}));
		}
	}

	return (
		<>
			{ tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="wrench"
					title="Типы инцидентов"
					headers={ INCIDENT_TYPES_TABLE_HEADERS }
					rows={ tableData.data }
					pageInfo={ tableData.pageInfo }
					onClickItem={ itemId => navigate(`${location.pathname}/${ itemId }`) }
					onAddItem={ () => navigate(`${location.pathname}/+`) }
					onChangePageOptions={ options => {
						const pageInfo1 = PageInfo.parseFromString(location.search);
						pageInfo1.update(options);
						navigate(`${location.pathname}?${ pageInfo1.toQueryString() }`);
					} }
				/>
			) : tableData !== undefined ? (
				<h3>Нет данных</h3>
			) : null }
			{ isRequesting ? <Spinner /> : null }
		</>
	);
}
