import { createReducer } from 'redux-act';
import { FETCH_ME_CONFIG } from '../actions/meActions';

export default createReducer(
	{
		[FETCH_ME_CONFIG]: (state, json) => ({
			...state,
			...(json?.data && {
				...json.data,
				// является ли пользователь ДМ или ЗДМ
				isShopUser: !!json.data.currentUser.shopManagerRoles?.find(i => i.isShopUser),
			}),
		}),
	},
	null,
);
