import { z } from 'zod';
import { FileLocationEnum, FileMarkerType } from 'common-lib';

export const diagnosticsIncidentMarkingDataItemSchema = z.object({
	id: z.number().int(),
	incidentId: z.number().int(),
	goodId: z.number().int(),
	fileId: z.number().int(),
	fileMarkerId: z.number().int(),
	fileMarker: z.nativeEnum(FileMarkerType),
	fileScore: z.number().int(),
	goodExternalId: z.string(),
	goodName: z.string(),
	fileLocation: z.nativeEnum(FileLocationEnum),
	filePathName: z.string(),
	fileSize: z.number().int(),
});

export interface DiagnosticsIncidentMarkingDataItemOptions {
	id: number;
	incidentId: number;
	goodId: number;
	fileId: number;
	fileMarkerId: number;
	fileMarker: FileMarkerType;
	fileScore: number;
	goodExternalId: string;
	goodName: string;
	fileLocation: FileLocationEnum;
	filePathName: string;
	fileSize: number;
}

export class DiagnosticsIncidentMarkingDataItem {
	readonly id: number;
	readonly incidentId: number;
	readonly goodId: number;
	readonly fileId: number;
	readonly fileMarkerId: number;
	readonly fileMarker: FileMarkerType;
	readonly fileScore: number;
	readonly goodExternalId: string;
	readonly goodName: string;
	readonly fileLocation: FileLocationEnum;
	readonly filePathName: string;
	readonly fileSize: number;

	constructor(options: DiagnosticsIncidentMarkingDataItemOptions) {
		this.id = options.id;
		this.incidentId = options.incidentId;
		this.goodId = options.goodId;
		this.fileId = options.fileId;
		this.fileMarkerId = options.fileMarkerId;
		this.fileMarker = options.fileMarker;
		this.fileScore = options.fileScore;
		this.goodExternalId = options.goodExternalId;
		this.goodName = options.goodName;
		this.fileLocation = options.fileLocation;
		this.filePathName = options.filePathName;
		this.fileSize = options.fileSize;
		diagnosticsIncidentMarkingDataItemSchema.parse(this);
	}
}