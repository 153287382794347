import { z } from 'zod';

export const incidentDataItemSchema = z.object({
	incidentTypeId: z.number().int(),
	completed: z.number().int(),
	allCount: z.number().int(),
	incidentTypeName: z.string(),
});

export interface IncidentDataItemOptions {
	incidentTypeId: number;
	completed: number;
	allCount: number;
	incidentTypeName: string;
}

export class IncidentDataItem {
	readonly incidentTypeId: number;
	readonly completed: number;
	readonly allCount: number;
	readonly incidentTypeName: string;

	constructor(options: IncidentDataItemOptions) {
		this.incidentTypeId = options.incidentTypeId;
		this.completed = options.completed;
		this.allCount = options.allCount;
		this.incidentTypeName = options.incidentTypeName;
		incidentDataItemSchema.parse(this);
	}
}