import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { fillError, FeatureNamesEnum } from 'common-lib';
import { updateIncidentData } from '~store/actions/incidentActions';
import { TRANSPARENT_1PX } from '~components/ui/images';
import UiIncidentBadge from '~components/ui/UiIncidentBadge';
import { Spinner, PhotoShooter, PreloaderImage } from '~components';
import UiOverMenu from '~components/ui/UiOverMenu';
import { IncidentTypeRenderItemData } from '../incidentsAttrs';
import initClientsideLogger from '../../../lib/initClientsideLogger';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';

const logger = initClientsideLogger(IncidentView.name);

const InfoStyled = styled.div`
	margin: 18px auto;
	font-size: 16px;
	text-align: center;
	color: #9696A7;
`;

const RadioOptionsFieldStyled = styled.div`
	margin: 2rem auto;
`;

const RadioButtonStyled = styled.div`
  width: 80%;
  max-width: 350px;
  margin: 1rem auto;

  button {
    width: 100%;
    border: 0;
    border-radius: 16px;
    font-size: 18px;
    font-weight: bold;
    padding: 14px 20px;
    cursor: pointer;
    background-color: ${p => p.theme.badgeButtonBg};
    color: ${p => p.theme.badgeButtonColor};

    // для веба разрешаем менять цвет текста и иконок при наведении
    @media (min-width: 900px) {
      &:hover {
        background-color: ${p => p.theme.badgeButtonHoverBg};
        color: ${p => p.theme.badgeButtonHoverColor};
      }
    }

    &.active {
      background-color: ${p => p.theme.badgeButtonActiveBg};
      color: ${p => p.theme.badgeButtonActiveColor};
    }
  }
`;

const CentralPhotoFieldStyled = styled.div`
  width: 85%;
  margin: 1rem auto;

  & > img {
    max-width: 100%;
    border-radius: 16px;
    box-shadow: 0 1px 3px 1px rgba(50, 50, 50, 0.5);
    margin: 0 auto 1rem auto;
  }
`;

const SelectPhotoButtonStyled = styled.button`
  width: 100%;
  border: 0;
  border-radius: 16px;
  font-size: 18px;
  font-weight: bold;
  padding: 14px 20px;
  cursor: pointer;
  background-color: ${p => p.theme.badgeActionButtonBg};
  color: ${p => p.theme.badgeActionButtonColor};

  // для веба разрешаем менять цвет текста и иконок при наведении
  @media (min-width: 900px) {
    &:hover {
      background-color: ${p => p.theme.badgeActionButtonHoverBg};
      color: ${p => p.theme.badgeActionButtonHoverColor};
    }
  }
`;

const LabeledInputFieldStyled = styled.div`
  width: 80%;
  max-width: 350px;
  margin: 2rem auto;

  label {
    display: block;
    color: #222;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  input {
    width: 100%;
    border: 2px solid ${p => p.theme.badgeButtonActiveBg};
    border-radius: 16px;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 20px;
    line-height: 2rem;
  }
`;

const PhotoCaptureIconStyled = styled.img`
	width: 18px;
	height: 18px;
	background-image: url(${'/assets/img/image.svg'});
	background-position: center;
	background-repeat: no-repeat;
	margin: 0 10px -3px 0;
	border-radius: 0;
	box-shadow: none;
`;

const PredictionResultStyled = styled.div`
	margin: 0.3rem 0 0.6rem 0;
	padding: 0.3rem;
	text-align: center;
	font-size: 16px;
	background-color: ${p => p.theme.warnBg};
	border-left: 6px solid ${p => p.theme.warnBorderColor};
`;

interface Props
{
	data: IncidentTypeRenderItemData;
	onSaveSuccess: () => void;
}

interface Store
{
	imageRef: any;
	predictionTimerId: NodeJS.Timer;
}

interface State {
	formData: any;
	isFormDataChanged: boolean;
	isShowConfirmUnsave: boolean;
	isCanSave: boolean;
	isSaving: boolean;
}

export default function IncidentView(props: Props) {
	// tslint:disable-next-line:no-console
	const [store] = useState<Partial<Store>>({});
	const [
		{ formData, isFormDataChanged, isShowConfirmUnsave, isCanSave, isSaving },
		setState,
	] = useState<Partial<State>>({
		// первое состояние формы берем из репорта инцидента
		formData: Object.keys(props.data.report || {}).reduce((acc, key) => {
			acc[key] = { oldValue: props.data.report[key] };
			return acc;
		}, {}),
	});
	const [photoShootingData, setPhotoShootingData] = useState<any>(undefined);
	const [isPredictionAwaiting, setPredictionAwaiting] = useState(false);
	const [predictionResult, setPredictionResult] = useState<string | undefined>();

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	const navigate = useNavigate();
	const meConfig: any = useSelector<any>(state => state.me);

	const isDoPredictPhoto = meConfig.features?.includes(FeatureNamesEnum.INCIDENTS_PHOTO_PREDICT);

	function updateState(obj: Partial<State>) {
		setState({ formData, isFormDataChanged, isShowConfirmUnsave, isCanSave, ...obj });
	}

	function updateFormData(fieldId, newValue) {
		// обновляем значение в formData
		const newFormData = {
			...formData,
			[fieldId]: { ...(formData[fieldId] || {}), newValue, changed: true },
		};
		const { solutionData } = props.data.incidentType;
		// есть хоть одно изменение в форме
		const hasChanges = solutionData.some(meta => {
			const { oldValue, newValue: nv, changed } = newFormData[meta.id] || {};
			return oldValue !== nv && changed;
		});
		// есть ли хоть одно обязательное не заполненное поле
		const isCannotSave = solutionData.some(meta => {
			if (!meta.isRequired && !meta.requiredFormula) {
				return false;
			}
			const { oldValue, newValue: nv1 } = newFormData[meta.id] || {};
			if (!!nv1 || (nv1 === undefined && !!oldValue)) {
				return false; // старое или новое значение не пустое
			}
			// определим возможность сохранения
			if (meta.isRequired) {
				return true; // нужно заполнить поле, нельзя сохранять
			}
			if (meta.requiredFormula) {
				return meta.requiredFormula.some(({ key, value, isRequired }) => {
					const { newValue: nv, oldValue: ov } = newFormData[key];
					return isRequired && (nv === value || (nv === undefined && ov === value));
				});
			}
			return false;
		});
		updateState({
			formData: newFormData,
			isFormDataChanged,
			isCanSave: !isCannotSave && hasChanges,
		});
	}

	function onSave() {
		const value = Object.keys(formData).reduce((acc, key) => {
			acc[key] = formData[key].newValue;
			return acc;
		}, {});
		updateState({ isSaving: true });
		updateIncidentData(props.data.id, 'DONE', value)
			.then(props.onSaveSuccess);
	}

	function onSelect(fieldId, value, newValue) {
		updateFormData(fieldId, newValue);
	}

	function onPhotoCapture(field: any, value: any) {
		console.log('%c*** field, value=', 'background: #eee; color: blue', field, value);
		setPhotoShootingData({ field, value });
	}

	function onPhotoSave(data: string): void {
		store.imageRef.setAttribute('src', data);
		const { field } = photoShootingData;
		updateFormData(field.id, data);
		setPhotoShootingData(undefined);
		if (isDoPredictPhoto) {
			sendPredictionPhoto(data);
		}
	}

	function sendPredictionPhoto(imageUrl: string) {
		setPredictionResult(undefined);
		const match = imageUrl.match(/^data:image\/(png|jpg|jpeg|gif);base64,(.+)/);
		if (match) {
			store.predictionTimerId = setTimeout(cancelPrediction, 3000);
			setPredictionAwaiting(true);
			fetch(imageUrl)
				.then(res => res.blob())
				.then(blob => {
					const data = new FormData();
					data.append('image', blob);
					return fetch('/predict-photo', {
						method: 'post',
						body: data,
					})
						.then(res => res.text())
						.then(text => {
							try {
								// Пример ответа
								// { "code": "0", "resultMessage": "Полка не распознана. Сфотографируйте заново!"}
								const json = JSON.parse(text);
								const { code, resultMessage } = json;
								if (Number(code) !== 1) {
									setPredictionResult(resultMessage);
								}
							} catch (err: any) {
								throw fillError(Error, `Ошибка парсинга ответа от предиктилки`, err);
							}
						})
						.catch(err => {
							logger.error('Во время распознавания фото на странице инцидентов возникла ошибка', err);
							setPredictionResult('Не удалось распознать фотографию из-за ошибки сервера');
						})
						.finally(() => {
							setPredictionAwaiting(false);
						});
				});
		}
	}

	function cancelPrediction() {
		setPredictionAwaiting(false);
	}

	function onPhotoCancel(): void {
		setPhotoShootingData(undefined);
	}

	function onUpdateInput(fieldId: string, newValue: string): void {
		updateFormData(fieldId, newValue);
	}

	// решает, можно ли рендерить поле
	function isFieldVisible(field): boolean {
		const { visibleFormula } = field;
		if (!visibleFormula) return true;
		return visibleFormula.find(({ key, value }: { key: string, value: string }) => {
			return formData[key]?.newValue !== undefined ? formData[key]?.newValue === value : formData[key]?.oldValue === value;
		});
	}

	function renderSelectOptions(field, value) {
		return (
			<RadioOptionsFieldStyled key={field.id}>
				{field.options.map((option) => {
					const active = value.newValue === option.value || (!value.newValue && value.oldValue === option.value);
					const action = meConfig.isShopUser ? () => onSelect(field.id, value, option.value) : undefined;
					return (
						<RadioButtonStyled key={`option-${option.value}`}>
							<button className={active ? 'active' : undefined} onClick={action}>
								{option.title}
								{option.subTitle ? <div>{option.subTitle}</div> : null}
							</button>
						</RadioButtonStyled>
					);
				})}
			</RadioOptionsFieldStyled>
		);
	}

	function renderSelectCentralPhoto(field, value) {
		const { oldValue, newValue } = value;
		const showSnapButton = !oldValue && !newValue;
		const hasImage = !!(oldValue || newValue);
		const imgData = !hasImage ? {}
			: !newValue && oldValue && oldValue.fileId ? { src: `/api/file/download/${oldValue.fileId}?size=middle` }
				: { src: newValue };
		return (
			<CentralPhotoFieldStyled key={field.id}>
				{!newValue && oldValue && oldValue.fileId ? (
					<>
						<PreloaderImage
							src={`/api/file/download/${oldValue.fileId}?size=middle`}
							style={{
								width: "300px",
								height: "300px",
								margin: "0 auto 1rem auto"
							}}
						/>
					</>
				) : null}
				<img
					ref={ref => store.imageRef = ref}
					style={{ display: hasImage && newValue ? 'block' : 'none' }}
					{...imgData}
					alt=""
				/>
				{predictionResult ? (
					<PredictionResultStyled>
						{predictionResult}
					</PredictionResultStyled>
				) : null}
				{meConfig.isShopUser ? (
					<div style={{ margin: '0 auto', maxWidth: '300px' }}>
						<PhotoShooter maxSquareSideWidth={500}
						              onSave={onPhotoSave}
						              onCancel={onPhotoCancel}
						              showAim>
							<SelectPhotoButtonStyled onClick={() => onPhotoCapture(field, value)}>
								<PhotoCaptureIconStyled src={TRANSPARENT_1PX} />
								{showSnapButton ? 'Добавить фотографию' : 'Заменить фотографию'}
							</SelectPhotoButtonStyled>
						</PhotoShooter>
					</div>
				) : null}
			</CentralPhotoFieldStyled>
		);
	}

	function renderInputDouble(field, value) {
		return (
			<LabeledInputFieldStyled key={field.id}>
				<label>{field.title}</label>
				<input type="number"
				       defaultValue={value.oldValue || null}
				       onChange={(e: any) => onUpdateInput(field.id, e.target.value)} />
			</LabeledInputFieldStyled>
		);
	}

	function renderField(field) {
		const value = formData[field.id] || {};
		const { type } = field;
		if (type === 'RADIO_OPTIONS') {
			return renderSelectOptions(field, value);
		} else if (type === 'CENTRAL_PHOTO') {
			return renderSelectCentralPhoto(field, value);
		} else if (type === 'DOUBLE') {
			return renderInputDouble(field, value);
		} else {
			return <div style={{ color: 'red' }}>Unknown type {type}</div>;
		}
	}

	function goBack() {
		const isid = btoa(String(props.data.shopId));
		const itid = btoa(String(props.data.incidentTypeId));
		navigate(`/incidents/shop/${isid}/type/${itid}`);
	}

	return <>
		<UiIncidentBadge title={props.data.goodName} subTitle={props.data.goodExternalId}>
			<div style={{ margin: '2rem 0' }}>
				{props.data.incidentType.solutionData.map((field) => isFieldVisible(field) ? renderField(field) : null)}
			</div>
			{!meConfig.isShopUser ? <InfoStyled>Вы не можете редактировать данные</InfoStyled> : null}
		</UiIncidentBadge>
		{!!isShowConfirmUnsave && (
			<Modal open>
				<Modal.Content>
					<h2>Данные не сохранены</h2>
					<p>
						Вы внесли изменения на карточке. Если вы перейдете назад,
						то ваш выбор не будет сохранен.
					</p>
					<p>
						Вы уверены, что хотите вернуться к списку инцидентов?
					</p>
				</Modal.Content>
				<Modal.Actions>
					<Button positive
					        onClick={() => updateState({ isShowConfirmUnsave: false })}
					        content="Отмена" />
					<Button onClick={goBack} content="Да, вернуться" />
				</Modal.Actions>
			</Modal>
		)}
		{isSaving ? <Spinner onpage text="Сохранение..." /> : null}
		{isPredictionAwaiting ? <Spinner onpage text="Распознавание фото..." /> : null}
		{meConfig.isShopUser ? (
			<UiOverMenu>
				<UiOverMenu.AcceptButton title="Сохранить"
				                         onClick={onSave}
				                         disabled={!isCanSave || isPredictionAwaiting} />
			</UiOverMenu>
		) : null}
	</>;
}
