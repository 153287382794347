export enum TodoItemStatusEnum {
	NEW = 'NEW',
	IN_PROGRESS = 'IN_PROGRESS',
	CANCELED = 'CANCELED',
	STOPPED = 'STOPPED',
	NOT_DONE = 'NOT_DONE',
	DONE = 'DONE',
}

export const TodoItemStatusTitles = {
	[TodoItemStatusEnum.NEW]: 'Новая',
	[TodoItemStatusEnum.IN_PROGRESS]: 'В работе',
	[TodoItemStatusEnum.CANCELED]: 'Отменена',
	[TodoItemStatusEnum.STOPPED]: 'Остановлена',
	[TodoItemStatusEnum.NOT_DONE]: 'Не выполнена',
	[TodoItemStatusEnum.DONE]: 'Выполнена',
}

// поиск значения в списке enum по title
export function todoItemStatusTitleToEnum(title: string): TodoItemStatusEnum | undefined {
	switch(title) {
		case TodoItemStatusTitles[TodoItemStatusEnum.NEW]: return TodoItemStatusEnum.NEW;
		case TodoItemStatusTitles[TodoItemStatusEnum.IN_PROGRESS]: return TodoItemStatusEnum.IN_PROGRESS;
		case TodoItemStatusTitles[TodoItemStatusEnum.CANCELED]: return TodoItemStatusEnum.CANCELED;
		case TodoItemStatusTitles[TodoItemStatusEnum.STOPPED]: return TodoItemStatusEnum.STOPPED;
		case TodoItemStatusTitles[TodoItemStatusEnum.NOT_DONE]: return TodoItemStatusEnum.NOT_DONE;
		case TodoItemStatusTitles[TodoItemStatusEnum.DONE]: return TodoItemStatusEnum.DONE;
	}
}

// определяем вес статуса для сортировки
export function todoItemStatusToWeight(status: TodoItemStatusEnum | undefined): number {
	switch (status) {
		case TodoItemStatusEnum.NEW: return 1;
		case TodoItemStatusEnum.IN_PROGRESS: return 2;
		case TodoItemStatusEnum.CANCELED: return 3;
		case TodoItemStatusEnum.STOPPED: return 4;
		case TodoItemStatusEnum.NOT_DONE: return 5;
		case TodoItemStatusEnum.DONE: return 6;
		default: return 99;
	}
}
