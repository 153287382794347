import { QuestionnaireTypeEnum } from 'common-lib';
import { z } from 'zod';
import {
	QuestionnaireConfigQuestionsItem,
	QuestionnaireConfigQuestionsItemOptions,
	questionnaireConfigQuestionsItemSchema
} from './QuestionnaireConfigQuestionsItem';

export const questionnaireReportResultItemSchema = z.object({
	quizName: z.string(),
	quizType: z.nativeEnum(QuestionnaireTypeEnum),
	quizQuestions: z.array(questionnaireConfigQuestionsItemSchema).optional(),
	userName: z.string(),
	isSupervisor: z.boolean(),
	shopExternalId: z.string().optional(),
	shopName: z.string().optional(),
	respondingAt: z.instanceof(Date),
	respondingResult: z.number().int().nullable().optional(),
});

export interface QuestionnaireReportResultItemOptions {
	quizName: string;
	quizType: QuestionnaireTypeEnum;
	quizQuestions?: (QuestionnaireConfigQuestionsItem | QuestionnaireConfigQuestionsItemOptions)[];
	userName: string;
	isSupervisor: boolean;
	shopExternalId?: string;
	shopName?: string;
	respondingAt: Date | string;
	respondingResult?: number | null;
}

export class QuestionnaireReportResultItem {
	readonly quizName: string;
	readonly quizType: QuestionnaireTypeEnum;
	readonly quizQuestions?: QuestionnaireConfigQuestionsItem[];
	readonly userName: string;
	readonly isSupervisor: boolean;
	readonly shopExternalId?: string;
	readonly shopName?: string;
	readonly respondingAt: Date;
	readonly respondingResult?: number | null;

	constructor(options: QuestionnaireReportResultItemOptions) {
		this.quizName = options.quizName;
		this.quizType = options.quizType;
		if (options.quizQuestions?.length) {
			this.quizQuestions = [];
			for (const item of options.quizQuestions) {
				if (item instanceof QuestionnaireConfigQuestionsItem) {
					this.quizQuestions.push(item);
				} else {
					this.quizQuestions.push(new QuestionnaireConfigQuestionsItem(item));
				}
			}
		}
		this.userName = options.userName;
		this.isSupervisor = options.isSupervisor;
		if (options.shopExternalId !== undefined) this.shopExternalId = options.shopExternalId;
		if (options.shopName !== undefined) this.shopName = options.shopName;
		if (options.respondingAt instanceof Date) {
			this.respondingAt = options.respondingAt;
		} else {
			this.respondingAt = new Date(options.respondingAt);
		}
		if (options.respondingResult !== undefined) this.respondingResult = options.respondingResult;
	}
}