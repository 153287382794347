import { z } from 'zod';
import {
	QuestionnaireTestingOptionsItem,
	QuestionnaireTestingOptionsItemOptions,
	questionnaireTestingOptionsItemSchema
} from './QuestionnaireTestingOptionsItem';
import {
	QuestionnaireConfigQuestionsItem,
	QuestionnaireConfigQuestionsItemOptions,
	questionnaireConfigQuestionsItemSchema
} from './QuestionnaireConfigQuestionsItem';


export const questionnaireTestingItemSchema = questionnaireConfigQuestionsItemSchema.merge(
	z.object({
		options: z.array(questionnaireTestingOptionsItemSchema).optional(),
		correctAnswer: z.string().optional(),
	}),
);

export interface QuestionnaireTestingItemOptions extends QuestionnaireConfigQuestionsItemOptions {
	options?: (QuestionnaireTestingOptionsItem | QuestionnaireTestingOptionsItemOptions)[];
	correctAnswer?: string;
}

export class QuestionnaireTestingItem extends QuestionnaireConfigQuestionsItem {
	readonly options?: QuestionnaireTestingOptionsItem[];
	readonly correctAnswer?: string;

	constructor(options: QuestionnaireTestingItemOptions) {
		super(options);
		if (options.options?.length) {
			this.options = [];
			for (const item of options.options) {
				if (item instanceof QuestionnaireTestingOptionsItem) {
					this.options.push(item);
				} else {
					this.options.push(new QuestionnaireTestingOptionsItem(item));
				}
			}
		}
		if (options.correctAnswer !== undefined) this.correctAnswer = options.correctAnswer;
		questionnaireTestingItemSchema.parse(this);
	}
}