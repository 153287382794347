import { z } from 'zod';

export const incidentUserItemSchema = z.object({
	userId: z.number().int(),
	allCount: z.number().int(),
	completed: z.number().int(),
});

export interface IncidentUserItemOptions {
	userId: number;
	allCount: number;
	completed: number;
}

export class IncidentUserItem {
	readonly userId: number;
	readonly allCount: number;
	readonly completed: number;

	constructor(options: IncidentUserItemOptions) {
		this.userId = options.userId;
		this.allCount = options.allCount;
		this.completed = options.completed;
		incidentUserItemSchema.parse(this);
	}
}
