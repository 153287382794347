import React from 'react';

// теги, не требующие закрывающего тега
const ONE_SIDE_TAGS = ['br', 'hr', 'img'];

/**
 * Обрезает html исходник по длине текста, закрывая теги
 * @param text
 * @param maxl
 * @param suffix
 * @returns {string}
 */
export function truncateHtmlSource(text, maxl, suffix = '...') {
	const openTags: any[] = [];
	const buf: any[] = [];
	const re = /<(\/?)([^\s/>]+)[^>]*>/g;
	let m;
	let lastIndex = 0;
	let len = 0;
	while (m = re.exec(text)) {
		const [full, slash, tagName] = m;
		const tagInLower = tagName.toLowerCase();
		const hasSlash = !!slash;
		const { index } = m;
		const slice = text.substring(lastIndex, index);
		if (!ONE_SIDE_TAGS.includes(tagInLower)) {
			if (!hasSlash) {
				openTags.push(tagInLower);
			} else {
				const lastTag = openTags.pop();
				if (lastTag !== tagInLower) {
					return `Error: lastTag '${lastTag}' not equals to '${tagInLower}'`;
				}
			}
		}
		if (len + slice.length >= maxl) {
			buf.push(slice.substr(0, maxl - len));
			buf.push(suffix);
			// close tags
			if (!ONE_SIDE_TAGS.includes(tagInLower)) {
				if (!hasSlash) openTags.pop();
				else openTags.push(tagInLower);
			}
			let tag;
			while (tag = openTags.pop()) {
				buf.push(`</${tag}>`);
			}
			return buf.join('');
		}
		len += slice.length;
		lastIndex = re.lastIndex;
		buf.push(slice, full);
	}
	if (lastIndex < text.length) {
		const slice = text.substring(lastIndex);
		buf.push(slice);
	}
	return buf.join('');
}

/**
 * Разбивает переносы строк на несколько span с переводом строки.
 * @param text
 * @returns {*}
 */
export function nl2br(text) {
	return text.split('\n').map((s, index) => <span key={index}>{s}<br /></span>);
}
