import { z } from 'zod';
import { TaskStatusEnum } from 'common-lib';

export const taskToShopItemSchema = z.object({
	id: z.number().int(),
	taskId: z.number().int(),
	shopId: z.number().int(),
	status: z.nativeEnum(TaskStatusEnum),
	dueDate: z.instanceof(Date),
	executedAt: z.instanceof(Date).optional(),
	executionComment: z.string().optional(),
	executionInfo: z.object({ photos: z.array(z.number().int()) }).optional(),
	executorUserId: z.number().int().optional(),
	rejectorUserId: z.number().int().optional(),
	rejectionComment: z.string(),
	rejectedAt: z.instanceof(Date),
});

export interface TaskToShopItemOptions {
	id: number;
	taskId: number;
	shopId: number;
	status: TaskStatusEnum;
	dueDate: Date | string;
	executedAt?: Date | string;
	executionComment?: string;
	executionInfo?: { photos: number[] }; // todo PhotoDto
	executorUserId?: number;
	rejectorUserId?: number;
	rejectionComment?: string;
	rejectedAt?: Date | string;
}

export class TaskToShopItem {
	readonly id: number;
	readonly taskId: number;
	readonly shopId: number;
	readonly status: TaskStatusEnum;
	readonly dueDate: Date;
	readonly executedAt?: Date;
	readonly executionComment?: string;
	readonly executionInfo?: { photos: number[] }; // todo PhotoDto
	readonly executorUserId?: number;
	readonly rejectorUserId?: number;
	readonly rejectionComment?: string;
	readonly rejectedAt?: Date;

	constructor(options: TaskToShopItemOptions) {
		this.id = options.id;
		this.taskId = options.taskId;
		this.shopId = options.shopId;
		this.status = options.status;
		if (options.dueDate instanceof Date) {
			this.dueDate = options.dueDate;
		} else {
			this.dueDate = new Date(options.dueDate);
		}
		if (options.executedAt !== undefined) {
			if (options.executedAt instanceof Date) {
				this.executedAt = options.executedAt;
			} else {
				this.executedAt = new Date(options.executedAt);
			}

		}
		if (options.executionComment !== undefined) this.executionComment = options.executionComment;
		if (options.executionInfo !== undefined) this.executionInfo = options.executionInfo;
		if (options.executorUserId !== undefined) this.executorUserId = options.executorUserId;
		if (options.rejectorUserId !== undefined) this.rejectorUserId = options.rejectorUserId;
		if (options.rejectionComment !== undefined) this.rejectionComment = options.rejectionComment;
		if (options.rejectedAt !==undefined) {
			if (options.rejectedAt instanceof Date) {
				this.rejectedAt = options.rejectedAt;
			} else {
				this.rejectedAt = new Date(options.rejectedAt);
			}
		}
		taskToShopItemSchema.parse(this);
	}
}