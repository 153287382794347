import React from 'react';
import styled from 'styled-components';
import { TRANSPARENT_1PX } from './images';

const FaceImageStyled = styled.img`
	border-radius: 100%;
	border: 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	background-size: cover;
    background-position: center;
	width: ${({ size }) => size || Sizes.DEFAULT};
	height: ${({ size }) => size || Sizes.DEFAULT};
`;

enum Sizes {
	DEFAULT = '48px',
}

type Props = {
	url: string
	size?: Sizes
}

export default function FaceImage(props: Props) {
	return (
		<FaceImageStyled
			src={ TRANSPARENT_1PX }
			size={ props.size }
			style={{ backgroundImage: `url(${props.url})` }}
		/>
	);
}
