import { z } from 'zod';

export const questionnaireAnswerDtoSchema = z.object({
		questionIndex: z.number().int(),
});

export interface QuestionnaireAnswerDtoOptions {
	questionIndex: number;
}

export class QuestionnaireAnswerDto {
	readonly questionIndex: number;

	constructor(options: QuestionnaireAnswerDtoOptions) {
		this.questionIndex = options.questionIndex;
		questionnaireAnswerDtoSchema.parse(this);
	}
}