import { fetchDelete, fetchGetThenJson, fetchPostThenJson } from '~tools/fetchBackend';
import {
	PhotoGalleryListResponseDto,
	PhotoGalleryDeleteOneResponseDto,
	PhotoGalleryUploadOneResponseDto,
	PhotoGalleryPayloadRequestDto,
} from 'protocol-lib';

export async function fetchPhotoGalleryList(): Promise<PhotoGalleryListResponseDto> {
	return fetchGetThenJson(`photo_gallery/list`);
}

export async function deletePhoto(fileId: number): Promise<PhotoGalleryDeleteOneResponseDto> {
	return fetchDelete(`photo_gallery/one/${fileId}`);
}

export async function uploadPhoto(data: PhotoGalleryPayloadRequestDto): Promise<PhotoGalleryUploadOneResponseDto> {
	return fetchPostThenJson('photo_gallery/upload', data);
}
