import { z } from 'zod';
import {
	ChecklistTaskExecPhotoItem,
	ChecklistTaskExecPhotoItemOptions,
	checklistTaskExecPhotoItemSchema
} from '../composite';

export const checklistTaskExecRequestDtoSchema = z.object({
	executorComment: z.string().optional(),
	taskDeadlineAt: z.instanceof(Date),
	executionPhotoIds: z.array(checklistTaskExecPhotoItemSchema).optional(),
	isPhotoRequired: z.boolean().optional(),
	isCommentRequired: z.boolean().optional(),
});

export interface ChecklistTaskExecRequestDtoOptions {
	executorComment?: string;
	taskDeadlineAt: Date | string;
	executionPhotoIds?: (ChecklistTaskExecPhotoItem | ChecklistTaskExecPhotoItemOptions)[];
	isPhotoRequired?: boolean;
	isCommentRequired?: boolean;
}

export class ChecklistTaskExecRequestDto {
	readonly executorComment?: string;
	readonly taskDeadlineAt: Date;
	readonly executionPhotoIds?: ChecklistTaskExecPhotoItem[];
	readonly isPhotoRequired?: boolean;
	readonly isCommentRequired?: boolean;

	constructor(options: ChecklistTaskExecRequestDtoOptions) {
		if (options.executorComment !== undefined) this.executorComment = options.executorComment;
		if (options.taskDeadlineAt instanceof Date) {
			this.taskDeadlineAt = options.taskDeadlineAt;
		} else {
			this.taskDeadlineAt = new Date(options.taskDeadlineAt);
		}
		if (options.executionPhotoIds?.length) {
			this.executionPhotoIds = [];
			for (const item of options.executionPhotoIds) {
				if (item instanceof ChecklistTaskExecPhotoItem) {
					this.executionPhotoIds.push(item);
				} else {
					this.executionPhotoIds.push(new ChecklistTaskExecPhotoItem(item));
				}
			}
		}
		if (options.isPhotoRequired !== undefined) this.isPhotoRequired = options.isPhotoRequired;
		if (options.isCommentRequired !== undefined) this.isCommentRequired = options.isCommentRequired;
		checklistTaskExecRequestDtoSchema.parse(this);
	}
}