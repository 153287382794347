import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { TreeListDataItem, TreeListDataItemOptions, treeListDataItemSchema } from '../composite';

export const treeListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: treeListDataItemSchema.optional(),
	}),
);

export interface TreeListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: TreeListDataItem | TreeListDataItemOptions;
}

export class TreeListResponseDto extends BaseResponseDto {
	readonly data?: TreeListDataItem;

	constructor(options: TreeListResponseDtoOptions) {
		super(options);
		if (options.data instanceof TreeListDataItem) {
			this.data = options.data;
		} else if (options.data !== undefined) {
			this.data = new TreeListDataItem(options.data);
		}
		treeListResponseDtoSchema.parse(this);
	}
}
