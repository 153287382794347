import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TaskStatusEnum, TaskStatusTitle, validateString } from 'common-lib';
import { execTask, rejectTask } from '~api/tasksApi';
import { Spinner, UiBadge, UiPhotoGallery } from '~components';
import UiOverMenu from '~components/ui/UiOverMenu';
import UiTextareaBadge from '~components/ui/UiTextareaBadge';
import { nl2br } from '~tools/html';
import { TaskFormData, TaskFormDataFieldValue } from '../tasksAttrs';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';
import { ITaskData } from './UserTaskSectionPage';

const COMMENT_MIN_LENGTH = 5;
const COMMENT_MAX_LENGTH = 2000;
const PHOTO_LIMIT_UPLOAD = 5;

let updateTimerId: NodeJS.Timeout;

interface IProps {
	data: ITaskData;
	onExecuteSuccess: () => void;
	onRejectSuccess: () => void;
}

export default function TaskView(props: IProps) {
	const [isSaving, setSaving] = useState<boolean>();
	const [showRejectingForm, setShowRejectingForm] = useState<any>(false);
	const [formData, setFormData] = useState<TaskFormData>({
		fields: {
			executionComment: { defaultValue: undefined, value: undefined },
			rejectionComment: { defaultValue: undefined, value: undefined },
			photos: { defaultValue: [], value: [] },
		},
		initialized: false,
		hasError: false,
		hasChanges: false,
	});
	const { isShopUser } = useSelector((state: any) => state.me);
	const [lookChanges, setLookChanges] = useState<boolean>(true);

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	if (!formData.initialized) {
		setFormData(fillFormDataFromTask(props.data));
		return null;
	}

	const {
		taskToShopId,
		title,
		description,
		dueDate,
		status,
		owner,
		executor,
		executionComment,
		rejector,
		rejectionComment,
	}: ITaskData = props.data;

	if (!taskToShopId || !title || !description || !dueDate || !status || !owner) {
		return <div>Не удалось получить данные задачи</div>;
	}

	const isSaveDisabled = formData.hasError || !formData.hasChanges || lookChanges;

	// текущий пользователь это ДМ или ЗДМ и он может добавить решение по задаче
	const shopUserCanExec = isShopUser && [TaskStatusEnum.IN_PROGRESS, TaskStatusEnum.REJECTED].includes(status);
	// текущий пользователь это менеджер и он может отклонить решение по задаче
	const supervisorCanReject = !isShopUser && status === TaskStatusEnum.DONE;

	// нужна ли кнопка сохранения
	let overMenu;
	if (shopUserCanExec) {
		overMenu = <UiOverMenu.AcceptButton title="Сохранить"
		                                    onClick={onExecuteTask}
		                                    disabled={isSaveDisabled} />;
	} else if (supervisorCanReject) {
		if (!showRejectingForm) {
			overMenu = <UiOverMenu.DeclineButton title="Отклонить решение"
			                                     onClick={() => setShowRejectingForm(true)} />;
		} else {
			overMenu = <>
				<UiOverMenu.Button title="Отмена" onClick={() => setShowRejectingForm(false)} />
				<UiOverMenu.DeclineButton title="Отклонить"
				                          onClick={onRejectTask}
				                          disabled={isSaveDisabled} />
			</>;
		}
	}

	const photoSrcs = formData.fields.photos.value.filter(i => !i.isDeleted).map(i => i.src);
	const correctPhoto = formData.fields.photos.value.filter(i => i.isNew);
	const isLimitPhoto = photoSrcs.length < PHOTO_LIMIT_UPLOAD;
	const deletedPhotos = formData.fields.photos.value.filter(i => i.isDeleted);
	const isDeletedPhotos = deletedPhotos.length === 0;
	const requiredFields = {
		comment: 'Коментарий обязателен для заполнения',
		photos: 'Необходимо добавить хотя бы одну фотографию',
		photosLimit: 'Необходимо удалить хотя бы одну старую фотографию и загрузить новую',
	};
	const checkCommentField = Object.keys(formData.fields.executionComment).length < 2 && requiredFields.comment;
	const checkPhotosLimit: string | undefined = shopUserCanExec && status === TaskStatusEnum.REJECTED && isDeletedPhotos && correctPhoto.length === 0 && !isLimitPhoto && requiredFields.photosLimit || undefined;
	const checkCorrectPhoto = correctPhoto.length === 0 && requiredFields.photos || undefined;

	return <>
		<UiBadge bigPaddings>
			<h1>{title}</h1>
			<UiBadge.Values values={[{ key: 'Назначил:', value: owner.fullName }]} />
			<UiBadge.DueDateAndStatus dueDate={dueDate}
			                          status={status}
			                          statusText={TaskStatusTitle[status]} />
		</UiBadge>
		<UiBadge bigPaddings>
			<h1>Описание задачи:</h1>
			<div className="description">
				{description ? nl2br(description) : 'отсутствует'}
			</div>
		</UiBadge>
		{rejector ? (
			<UiBadge bigPaddings>
				<UiBadge.Values values={[
					{ key: 'Отклонил:', value: rejector.fullName },
					{ key: 'Причина:', value: nl2br(rejectionComment) },
				]} />
			</UiBadge>
		) : null}
		{shopUserCanExec ? (
			<>
				<UiTextareaBadge
					title="Добавить комментарий:"
					placeholder="Введите комментарий"
					maxLength={COMMENT_MAX_LENGTH}
					error={formData.fields.executionComment.touched && !!formData.fields.executionComment.error}
					infoText={checkCommentField || (formData.fields.executionComment.touched ? formData.fields.executionComment.error : undefined)}
					onChange={e => updateFormData({ executionComment: e.target.value })} />
				<UiBadge bigPaddings>
					<h1>Добавить фотографии:</h1>
					<UiPhotoGallery photos={photoSrcs}
					                readOnly={!isLimitPhoto}
					                isLimit={!isLimitPhoto}
					                infoText={checkPhotosLimit || checkCorrectPhoto || formData.fields.photos.error}
					                onAddPhoto={onAddPhoto} onRemovePhoto={onRemovePhoto} />
				</UiBadge>
			</>
		) : (
			<>
				{executor ? (
					<UiBadge bigPaddings>
						<UiBadge.Values values={[{
							key: 'Выполнил:',
							value: executor.fullName,
						}]} />
						<UiPhotoGallery photos={photoSrcs} readOnly />
						<UiBadge.Values values={[{
							key: 'Комментарий:',
							value: nl2br(executionComment),
						}]} />
					</UiBadge>
				) : null}
				{supervisorCanReject && showRejectingForm ? (
					<UiTextareaBadge
						title="Укажите причину:"
						placeholder="Введите комментарий"
						maxLength={COMMENT_MAX_LENGTH}
						error={formData.fields.rejectionComment.touched && !!formData.fields.rejectionComment.error}
						infoText={formData.fields.rejectionComment.touched ? formData.fields.rejectionComment.error : undefined}
						onChange={e => updateFormData({ rejectionComment: e.target.value })} />
				) : null}
			</>
		)}
		{isSaving ? <Spinner onpage text="Сохранение..." /> : null}
		{overMenu ? <UiOverMenu>{overMenu}</UiOverMenu> : null}
	</>;

	/**
	 * Обновляет данные формы по принципу setState.
	 * Чтобы не ререндерить форму при вводе текста, будет обновлять стейт с задержкой.
	 */
	function updateFormData(inputValue: TaskFormDataFieldValue) {
		setLookChanges(true);
		const { executionComment, rejectionComment, photos } = inputValue;
		const checkPhotosOnDeleted = formData.fields.photos.value.filter(i => !i.isDeleted).length !== PHOTO_LIMIT_UPLOAD;
		clearTimeout(updateTimerId);
		const { fields } = formData;
		let waitChanges = false;
		if (executionComment !== undefined) {
			const o = fields.executionComment;
			o.value = executionComment;
			o.touched = true;
			o.hasChanges = o.value !== o.defaultValue;
			waitChanges = true;
		}
		if (rejectionComment !== undefined) {
			const o = fields.rejectionComment;
			o.value = rejectionComment;
			o.touched = true;
			o.hasChanges = o.value !== o.defaultValue;
			o.error = validateString(COMMENT_MIN_LENGTH, COMMENT_MAX_LENGTH)(o.value) || undefined;
			waitChanges = true;
		}
		if (photos !== undefined) {
			const o = fields.photos;
			// убираем новые фото, которые потом удалились
			o.value = photos.filter(p => !(p.isNew && p.isDeleted));
			o.touched = true;
			o.hasChanges = o.value.some(i => i.isNew || i.isDeleted);
			waitChanges = true;
		}
		// check errors
		fields.executionComment.error = shopUserCanExec
			? validateString(COMMENT_MIN_LENGTH, COMMENT_MAX_LENGTH)(fields.executionComment.value || '') || undefined : undefined;
		fields.rejectionComment.error = supervisorCanReject
			? validateString(COMMENT_MIN_LENGTH, COMMENT_MAX_LENGTH)(fields.rejectionComment.value || '') || undefined : undefined;
		fields.photos.error = shopUserCanExec && fields.photos.value.filter(i => !i.isDeleted).length === 0
			? 'Необходимо добавить хотя бы одну фотографию'
				: shopUserCanExec && status === TaskStatusEnum.REJECTED && !checkPhotosOnDeleted && fields.photos.value.filter(i => i.isDeleted).length === 0
					? 'Необходимо удалить хотя бы одну старую фотографию и загрузить новую'
						: shopUserCanExec && status === TaskStatusEnum.REJECTED && checkPhotosOnDeleted && fields.photos.value.filter(i => i.isNew).length === 0
							? 'Необходимо добавить хотя бы одну фотографию'
								: undefined;
		const hasChanges = fields.executionComment.hasChanges || fields.rejectionComment.hasChanges
			|| fields.photos.hasChanges;
		formData.hasError = !!fields.executionComment.error || !!fields.rejectionComment.error
			|| !!fields.photos.error;
		const newFormData = Object.assign({}, formData, { hasChanges });
		if (!waitChanges) {
			setFormData(newFormData);
		} else {
			updateTimerId = setTimeout(() => {
				setFormData(newFormData);
				setLookChanges(false);
			}, 500);
		}
	}

	// сохраняет решение задачи и выполняет возврат назад по истории
	function onExecuteTask() {
		setSaving(true);
		const executionPhotos = formData.fields.photos.value
			.filter(p => p.isNew || p.isDeleted)
			.map(photo => {
				if (photo.isDeleted) {
					return { id: photo.id, isDeleted: true };
				}
				if (photo.isNew) {
					return { data: photo.src, isNew: true };
				}
				console.error('Ошибка фотографии', { photo, photos: formData.fields.photos });
				throw new Error('Фотография сформирована с ошибкой');
			});
		if (taskToShopId) {
			execTask(taskToShopId, {
				executionComment: formData.fields.executionComment.value,
				executionPhotos,
			})
				.then(() => props.onExecuteSuccess())
				.finally(() => setSaving(false));
		}

	}

	// сохраняет отклонение задачи и выполняет возврат назад по истории
	function onRejectTask() {
		if (taskToShopId) {
			setSaving(true);
			rejectTask(taskToShopId, {
				rejectionComment: formData.fields.rejectionComment.value,
			})
				.then(() => props.onRejectSuccess())
				.finally(() => setSaving(false));
		}
	}

	// добавление фото
	function onAddPhoto(newPhoto) {
		updateFormData({
			photos: [...formData.fields.photos.value, { src: newPhoto, isNew: true }],
		});
	}

	// удаление фото
	function onRemovePhoto(index) {
		const photo = formData.fields.photos.value.filter(i => !i.isDeleted)[index];
		photo.isDeleted = true;
		updateFormData({ photos: formData.fields.photos.value });
	}
}

export function fillFormDataFromTask(taskData): TaskFormData {
	const photos: any[] = taskData.executionInfo?.photos.map(photoId => ({
		id: photoId,
		src: `/api/file/download/${photoId}?size=middle`,
	})) || [];
	return {
		fields: {
			executionComment: {},
			rejectionComment: {},
			photos: {
				defaultValue: photos.map(p => ({ id: p.id, src: p.src })),
				value: photos,
			},
		},
		initialized: true,
		hasError: false,
		hasChanges: false,
	};
}
