import { z } from 'zod';

export const questionnairePhotoSchema = z.object({
	id: z.number().int(),
	src: z.string(),
	index: z.number().int(),
	isNew: z.boolean(),
});

export interface QuestionnairePhotoOptions {
	id: number;
	src: string;
	index: number;
	isNew: boolean;
}

export class QuestionnairePhoto {
	readonly id: number;
	readonly src: string;
	readonly index: number;
	readonly isNew: boolean;

	constructor(options: QuestionnairePhotoOptions) {
		this.id = options.id;
		this.src = options.src;
		this.index = options.index;
		this.isNew = options.isNew;
		questionnairePhotoSchema.parse(this);
	}
}