import { z } from 'zod';

export const photoGalleryItemSchema = z.object({
	fileId: z.number().int(),
	createdAt: z.instanceof(Date),
});

export interface PhotoGalleryItemOptions {
	fileId: number;
	createdAt: Date | string;
}

export class PhotoGalleryItem {
	readonly fileId: number;
	readonly createdAt: Date;

	constructor(options: PhotoGalleryItemOptions) {
		this.fileId = options.fileId;
		if (options.createdAt instanceof Date) {
			this.createdAt = options.createdAt;
		} else {
			this.createdAt = new Date(options.createdAt);
		}
		photoGalleryItemSchema.parse(this);
	}
}