import { z } from 'zod';
import {
	QuestionnaireAnswerDto,
	QuestionnaireAnswerDtoOptions,
	questionnaireAnswerDtoSchema,
	QuestionnaireTestingAnswerItem,
	QuestionnaireTestingAnswerItemOptions,
	questionnaireTestingAnswerItemSchema
} from '../composite';

export const questionnaireAnswerTestingRequestDtoSchema = questionnaireAnswerDtoSchema.merge(
	z.object({
		value: z.union([
			z.string(),
			z.array(z.number().int()),
			z.array(questionnaireTestingAnswerItemSchema),
			z.null()
		]),
	}),
);

export interface QuestionnaireAnswerTestingRequestDtoOptions extends QuestionnaireAnswerDtoOptions {
	value: string | null | (QuestionnaireTestingAnswerItem | QuestionnaireTestingAnswerItemOptions | number)[];
}

export class QuestionnaireAnswerTestingRequestDto extends QuestionnaireAnswerDto {
	readonly value: string | (QuestionnaireTestingAnswerItem | number)[] | null;

	constructor(options: QuestionnaireAnswerTestingRequestDtoOptions) {
		super(options);
		if (options.value instanceof Array) {
			this.value = [];
			for (const item of options.value) {
				if (typeof item === 'number') {
					this.value.push(item);
				} else if (item instanceof QuestionnaireTestingAnswerItem) {
					this.value.push(item);
				} else {
					this.value.push(new QuestionnaireTestingAnswerItem(item));
				}
			}
		} else {
			this.value = options.value;
		}
		questionnaireAnswerTestingRequestDtoSchema.parse(this);
	}
}