import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { TaskStatusEnum, PageInfo, TaskStatusTitle, getRoleName, UserRoles, arrayToMapById } from 'common-lib';
import { get, set } from '~tools/localConfig';
import { loadSubordinateShops } from '~api/shopsApi';
import TasksFetcher from '~api/TasksFetcher';
import { ListIsEmpty, ManagerWithoutRole, Spinner } from '~components';
import { Subordinates } from '~components/ui/AddresseeSelector';
import { SearchWizardContentItem } from '~components/ui/SearchAndFiltersWizard';
import { UiContainerContentBreadCrumbs } from '~components/ui/UiContainer';
import { UserContentWrapper } from '../../../containers';
import TaskCreateView from './TaskCreateView';
import TaskView from './TaskView';
import ShopTaskListView from './ShopTaskListView';
import ManagerTaskListView from './ManagerTaskListView';
import { PageNotFound } from '../../index';
import { fillSubordinateMap } from '../../../lib/subordinateTools';
import {
	ShopDataItem,
	TaskDataItem,
	TaskListResponseDto,
	TaskOneResponseDto,
	TaskToShopItem,
	UserDataItem
} from 'protocol-lib';

// ключ для хранения фильтров списка
const UTasksSP_PAGE_INFO = 'UTasksSP.pageInfo';
// значения "Все" для фильтра
const FILTER_ALL = 'all';

const fetcher = new TasksFetcher();

type NumberOrNo = number | undefined;

function getIdFromHash(hash?: string): NumberOrNo {
	try {
		return Number(atob(hash || '')) || undefined;
	} catch (_) {
		return undefined;
	}
}

function sum(arr: number[]) {
	return arr.reduce((s, v) => s + v, 0);
}

type UserTreeShop = {
	all: number,
	completed: number,
	percent: number,
	shop: any,
};

type UserTree = {
	all: number,
	completed: number,
	percent: number,
	users: UserTree[],
	shops: UserTreeShop[],
	user: any,
};

type ComponentStore = {
	checkManagerQueryString: string | undefined,
	checkShopQueryString: string | undefined,
	isSubordinatesRequesting: boolean,
	isManagerDataRequesting: boolean,
	isShopDataRequesting: boolean,
	isTaskDataRequesting: boolean,
	searchString: string,
};

type State = {
	subordinatesData?: Subordinates | null,
	managerData: any,
	shopData: any,
	savedShopId?: number,
	taskData: ITaskData,
	savedTaskId: string,
	searchedContent: SearchWizardContentItem[] | null,
};

type Params = {
	managerHashId: string,
	shopHashId: string,
	taskHashId: string,
};

export interface ITaskData {
	taskToShopId?: number;
	taskId?: number;
	title?: string;
	description?: string;
	dueDate?: Date;
	status?: TaskStatusEnum;
	owner?: UserDataItem;
	executor?: UserDataItem;
	executionComment?: string;
	executionInfo?: { photos: number[] };
	executionDate?: Date;
	rejector?: UserDataItem;
	rejectionComment?: string;
	rejectionDate?: Date;
	error?: string;
}

export default function UserTaskSectionPage() {
	const [store] = useState<ComponentStore>({
		checkManagerQueryString: get(UTasksSP_PAGE_INFO, ''),
		checkShopQueryString: get(UTasksSP_PAGE_INFO, ''),
		isSubordinatesRequesting: false,
		isManagerDataRequesting: false,
		isShopDataRequesting: false,
		isTaskDataRequesting: false,
		searchString: '',
	});
	const [{
		subordinatesData,
		managerData,
		shopData,
		savedShopId,
		taskData,
		savedTaskId,
		searchedContent,
	}, setState] = useState<Partial<State>>({});
	const [hasNoRole, setHasNoRole] = useState<boolean | undefined>(undefined);
	const navigate = useNavigate();
	const { managerHashId, shopHashId, taskHashId } = useParams<Params>();
	const { pathname } = useLocation();
	const { currentUser: { shops }, isShopUser } = useSelector((state: any) => state.me);

	// сначала загружаем список подчиненных
	// этот список нужен для менеджеров: отображаение задач и крошек, создание задач
	if (subordinatesData === undefined) {
		if (isShopUser) {
			updateState({ subordinatesData: null });
		} else if (!store.isSubordinatesRequesting) {
			loadSubordinateItems();
		}
		return renderLoading();
	}

	// рисуем страницу создания задачи
	if (pathname === '/tasks/create') {
		return <UserContentWrapper title="Создание задачи" backLinkTo="/tasks" isPreview>
			<TaskCreateView subordinates={subordinatesData} onCreatedSuccess={onTaskUpdated} />
		</UserContentWrapper>;
	}

	const managerId: NumberOrNo = getIdFromHash(managerHashId);
	const shopId: NumberOrNo = getIdFromHash(shopHashId);
	const taskId: NumberOrNo = getIdFromHash(taskHashId);
	const shop = shops?.[0];

	// определяем title, ссылку назад и крошки
	const { title, subTitle } = calculateTitle();
	const backLinkTo = calculateBackLinkTo();
	const breadCrumbs = calculateBreadCrumbs();

	// если выбрана конкретная задача, загружаем и рендерим ее
	if (taskId) {
		if (taskData === undefined || savedTaskId !== `${shopId}/${taskId}`) {
			if (!store.isTaskDataRequesting) {
				loadTask(taskId, shopId);
			}
			return renderLoading();
		} else if (!taskData) {
			// в данных пусто
			return render(<PageNotFound />);
		} else {
			// рисуем страницу задачи
			return render(<TaskView data={taskData}
			                        onExecuteSuccess={onTaskUpdated}
			                        onRejectSuccess={onTaskUpdated} />);
		}
	}

	const curPageInfo = PageInfo.parseFromString(location.search || get(UTasksSP_PAGE_INFO, '') || '');
	curPageInfo.pageIndex = 0;
	curPageInfo.pageSize = 200;
	curPageInfo.orderColumn = 'createdAt';
	curPageInfo.orderDirection = 'desc';

	const filters = getFilters();
	const isFilterSelected = filters.some(i => i.clearable);

	// выбран магазин или текущий пользователь это ДМ
	if (shopId || isShopUser) {
		if (shopData === undefined || savedShopId !== shopId || curPageInfo.toQueryString() !== store.checkShopQueryString) {
			if (!store.isShopDataRequesting) {
				loadShopTaskList(shopId);
			}
			return renderLoading();
		} else if (!shopData?.length) {
			// в данных пусто
			return render(<ListIsEmpty filterSelectedInfo={isFilterSelected}
			                           error={shopData?.error || undefined} />);
		} else {
			// рисуем страницу списка задач для магазина
			return render(<ShopTaskListView data={shopData} />);
		}
	}

	// иначе обрабатываем данные как страницу менеджера
	if (managerData === undefined || curPageInfo.toQueryString() !== store.checkManagerQueryString) {
		if (!store.isManagerDataRequesting) {
			loadManagerTaskList();
		}
		return renderLoading();
	} else if (!managerData?.users?.length && !managerData?.shops?.length) {
		if (hasNoRole) {
			return render(<ManagerWithoutRole/>);
		}
		// в данных пусто
		return render(<ListIsEmpty filterSelectedInfo={isFilterSelected}
		                           error={managerData?.error || undefined} />);
	} else {
		// ищем уровень менеджера для рендера списка подчиненных
		const root = findManagerTree(managerData);
		if (!root) {
			// в данных пусто
			return render(<ListIsEmpty filterSelectedInfo={isFilterSelected}
			                           error={managerData?.error || undefined} />);
		}
		// рисуем страницу менеджера
		return render(<ManagerTaskListView data={root} />);
	}

	// вызывается когда задача обновляется или создается
	function onTaskUpdated() {
		// сбрасываем все данные про задачи
		updateState({
			managerData: undefined,
			shopData: undefined,
			taskData: undefined,
		});
		navigate(`/tasks`);
	}

	// вычисляем заголовок для страницы
	function calculateTitle(): { title: string, subTitle?: string } {
		if (isShopUser) {
			if (taskId) {
				return { title: shop.city, subTitle: shop.address };
			} else {
				return { title: 'Задачи' };
			}
		} else {
			if (shopId) {
				const shop = subordinatesData?.shopMap.get(shopId);
				if (shop) {
					return { title: shop.city, subTitle: shop.address };
				} else {
					return { title: 'Задачи' };
				}
			} else {
				if (managerId) {
					const manager = subordinatesData?.userMap.get(managerId);
					return {
						title: manager?.fullName || 'x5',
						subTitle: manager?.shopManagerRole?.shortTitle || getRoleName(manager?.role),
					};
				} else {
					return { title: 'Задачи' };
				}
			}
		}
	}

	// вычисляем ссылку для перехода назад
	function calculateBackLinkTo(): string | undefined {
		if (isShopUser) {
			return taskId ? '/tasks' : undefined;
		} else {
			if (taskId) {
				const shop = shopId ? subordinatesData?.shopMap.get(shopId) : undefined;
				return shop ? `/tasks/shop/${btoa(shop.id.toString())}` : '<shop>';
			} else if (shopId) {
				const shop = subordinatesData?.shopMap.get(shopId);
				const manId = shop?.upfId || shop?.tmId || shop?.dfId;
				return manId ? `/tasks/${btoa(manId.toString())}` : '/tasks';
			} else if (managerId) {
				const manager = subordinatesData?.userMap.get(managerId);
				const parent = manager?.parent;
				return parent ? `/tasks/${btoa(parent.id.toString())}` : '/tasks';
			} else {
				return undefined;
			}
		}
	}

	function getNextRole(role: string): string {
		switch (role) {
			// TODO xxx: begin
			// case USER_ROLE_DT: return USER_ROLE_DF;
			// case USER_ROLE_DF: return USER_ROLE_TM;
			// case USER_ROLE_TM: return USER_ROLE_UPF;
			// TODO xxx: end
			default: return 'Магазин';
		}
	}

	// вычисляем хлебные крошки
	function calculateBreadCrumbs(): UiContainerContentBreadCrumbs | undefined {
		if (taskId) {
			return undefined;
		}
		if (!managerId && !shopId) return undefined;
		const res: UiContainerContentBreadCrumbs = [];
		let manager = managerId ? subordinatesData?.userMap.get(managerId) : undefined;
		if (shopId) {
			const shop = subordinatesData?.shopMap.get(shopId);
			res.unshift({
				title: shop?.address || '<address>',
				linkTo: shop ? `/tasks/shop/${btoa(shop.id.toString())}` : undefined,
			});
			manager = shop?.supervisorUser;
		}
		while (manager?.id) {
			res.unshift({
				title: `${manager.shopManagerRole?.shortTitle || getRoleName(manager.role)} ${manager.shortName}`,
				linkTo: manager.id !== managerId ? `/tasks/${btoa(manager.id.toString())}` : undefined,
			});
			manager = manager.parent;
		}
		res.unshift({
			title: `Все`,
			linkTo: `/tasks`,
		});
		return res;
	}

	function findManagerTree(userTree) {
		if (!managerId || userTree.user.id === managerId) return userTree;
		let found;
		userTree.users?.some(i => {
			if (found) return true;
			found = findManagerTree(i);
		});
		return found;
	}

	// метод следит за тем, чтобы все части textParts были найдены хотя бы раз, иначе вернет false
	// в положительном кейсе вернет массив [минимальная позиция в слове, номер слова, длина строки поиска]
	function searchPartsInTexts(textParts, strings): [number, number, number] | false {
		strings = strings.map(s => (s || '').trim().toLowerCase()).filter(s => !!s);
		if (!strings.length) return false;
		let min: [number, number, number] | false = false;
		for (const part of textParts) {
			const k = strings
				.map((s, index) => [s.indexOf(part), index, part.length])
				.filter(i => i[0] >= 0)
				// ищем миниальное вхождение с позицией 0 или выше
				.reduce((v, i) => v === false || i[0] < v[0] ? i : v, false);
			if (k === false) return false;
			if (min === false || k[0] < min[0] || (k[0] === min[0] && k[1] < min[1])) min = k;
		}
		return min;
	}

	// поиск подчиненных из строки поиска
	function searchSubordinates(text: string) {
		const searchParts = String(text || '')
			.replace(/[^\w А-Яа-яёË]+/ig, '')
			.split(' ')
			.map(s => s.trim().toLowerCase())
			.filter(s => !!s && s.length >= 2); // не будем искать короткие слова
		const { shopMap, userMap } = subordinatesData!;
		const managers = Array.from(userMap.values())
			.filter((u: any) => {
				if (!u?.stats?.all) return false;
				// TODO xxx: return [USER_ROLE_DF, USER_ROLE_TM, USER_ROLE_UPF].includes(u.role);
				return false;
			})
			.map(user => {
				// сложная логика рассчета баллов:
				// чем ближе найденная подстрока к началу, тем выше балл
				// совпадение в фамилии стоит = 3000, в имени = 200, в отчестве = 100
				// чсло выбрано большим, чтобы балл не ушел в минус
				const findScore = searchPartsInTexts(searchParts,
					[user.lastName, user.firstName, user.middleName]);
				if (findScore === false) return undefined;
				const score = 300 - findScore[1] * 100 - findScore[0] + findScore[2];
				return {
					score,
					text: `${user.shopManagerRole?.shortTitle || getRoleName(user.role)} ${user.fullName}`,
					fullName: user.fullName,
					linkTo: `/tasks/${btoa(user.id.toString())}`,
				};
			})
			.filter(i => !!i)
			// ранжируем по релеванстности поиска
			.sort((a: any, b: any) => a.score !== b.score ? b.score - a.score
				: a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0);
		const shops = Array.from(shopMap.values())
			.filter((s: any) => !!s?.stats?.all)
			.map(shop => {
				// сложная логика рассчета баллов:
				// чем ближе найденная подстрока к началу, тем выше балл
				// совпадение в адресе стоит = 1000, в городе = 500
				// чсло выбрано большим, чтобы балл не ушел в минус
				const findScore = searchPartsInTexts(searchParts,
					[shop.address, shop.city]);
				if (findScore === false) return undefined;
				const score = 1000 - findScore[1] * 500 - findScore[0] + findScore[2];
				return {
					score,
					text: `${shop.city}, ${shop.address}`,
					linkTo: `/tasks/shop/${btoa(shop.id.toString())}`,
				};
			})
			.filter(i => !!i)
			// ранжируем по релеванстности поиска
			.sort((a: any, b: any) => a.score !== b.score ? b.score - a.score
				: a.text > b.text ? 1 : b.text > a.text ? -1 : 0);
		const searchedContent1: SearchWizardContentItem[] = [];
		if (managers.length) {
			searchedContent1.push({
				title: 'СОТРУДНИКИ',
				items: managers.map((i: any) => ({
					text: i.text,
					linkTo: i.linkTo,
				})),
				hasMore: managers.length > 50,
			});
		}
		if (shops.length) {
			searchedContent1.push({
				title: 'МАГАЗИНЫ',
				items: shops.map((i: any) => ({
					text: i.text,
					linkTo: i.linkTo,
				})),
				hasMore: shops.length > 50,
			});
		}
		updateState({ searchedContent: searchedContent1 });
	}

	function onSearchChanged(event) {
		const value = event.target.value.trim();
		store.searchString = value;
		if (value.length >= 3) {
			searchSubordinates(value);
		} else {
			updateState({
				searchedContent: null,
			});
		}
	}

	function onSearchClear() {
		store.searchString = '';
		updateState({
			searchedContent: null,
		});
	}

	// рендерит страницу
	function render(children: ReactNode) {
		return <UserContentWrapper
			title={title}
			subTitle={subTitle}
			backLinkTo={backLinkTo}
			isPreview={!!taskId}
			breadCrumbs={breadCrumbs}
			searchOptions={hasNoRole || isShopUser ? undefined : {
				searchString: store.searchString,
				onSearchChanged,
				onSearchClear,
				isLoading: false,
				content: searchedContent || null,
			}}
			filterOptions={hasNoRole || taskId ? undefined : filters}
			onPlusButtonClick={hasNoRole || isShopUser || shopId || taskId ? undefined : onPlusClick}
		>
			{children}
		</UserContentWrapper>;
	}

	function onPlusClick() {
		navigate('/tasks/create');
	}

	function renderLoading() {
		return <UserContentWrapper title="Задачи">
			<Spinner onpage />
		</UserContentWrapper>;
	}

	// обновляет стейт при сохранении других полей, как это делает React.Component.setState()
	function updateState(obj: Partial<State>) {
		setState({
			subordinatesData, managerData, shopData, savedShopId, taskData, savedTaskId,
			searchedContent,
			...obj,
		});
	}

	function loadSubordinateItems() {
		store.isSubordinatesRequesting = true;
		loadSubordinateShops().then(res => {
			store.isSubordinatesRequesting = false;
			updateState({
				subordinatesData: fillSubordinateMap(res.data),
			});
		});
	}

	function loadTask(taskId: number, shopId?: number) {
		store.isTaskDataRequesting = true;
		(shopId ? fetcher.getOneByShopId(taskId, shopId) : fetcher.getOne(taskId))
			.then((json: TaskOneResponseDto) => {
				store.isTaskDataRequesting = false;
				updateState({
					taskData: fillTaskRenderData(json),
					savedTaskId: `${shopId}/${taskId}`, // определяем уникальность задачи
				});
			});
	}

	function loadShopTaskList(shopId?: number) {
		store.isShopDataRequesting = true;
		store.checkShopQueryString = curPageInfo.toQueryString();
		set(UTasksSP_PAGE_INFO, store.checkShopQueryString);
		(shopId ? fetcher.getListByShopId(shopId, curPageInfo) : fetcher.getList(curPageInfo))
			.then((json: TaskListResponseDto) => {
				store.isShopDataRequesting = false;
				const result = {
					...json,
					dataItems: json.data,
					meta: json.meta
				};
				updateState({
					shopData: fillShopRenderData(result),
					savedShopId: shopId,
				});
			});
	}

	function loadManagerTaskList() {
		store.isManagerDataRequesting = true;
		store.checkManagerQueryString = curPageInfo.toQueryString();
		set(UTasksSP_PAGE_INFO, store.checkManagerQueryString);
		fetcher.getList(curPageInfo).then((json: any) => {
			store.isManagerDataRequesting = false;
			if (json.meta?.options?.isNotShopManager) {
				setHasNoRole(true);
				updateState({ managerData: {} });
			} else {
				setHasNoRole(false);
				updateState({
					managerData: fillManagerRenderData(subordinatesData, json),
				});
			}
		});
	}

	function getFilters() {
		const selectedValues = curPageInfo.customParams?.statuses || FILTER_ALL;
		return [{
			title: 'СТАТУС',
			options: [
				{ text: 'Все', value: FILTER_ALL },
				...Object.keys(TaskStatusEnum).map(key => ({
					text: TaskStatusTitle[key],
					value: key,
				})),
			],
			selected: selectedValues,
			multiselect: true,
			clearable: selectedValues !== FILTER_ALL,
			onChange: value => value !== selectedValues ? onFilterChange(value) : undefined,
		}];
	}

	function onFilterChange(value: string) {
		if (!value) value = FILTER_ALL;
		// в фильтре используется мультиселект, поэтому проверяем на all в конце
		const values: string[] = value ? value.split(',') : [];
		const k = values.indexOf(FILTER_ALL);
		if (k === values.length - 1) {
			value = FILTER_ALL;
		} else if (k >= 0) {
			values.splice(k, 1);
			value = values.join(',');
		}
		curPageInfo.update({ statuses: value === FILTER_ALL ? undefined : value });
		navigate(`${location.pathname}?${curPageInfo.toQueryString()}`);
	}
}

// собирает данные по задаче
function fillTaskRenderData(json: TaskOneResponseDto) {
	try {
		const task = json.data;
		const { userMap, taskToShopMap } = json.meta || {};
		if (task && userMap && taskToShopMap) {
			const owner = userMap[task.ownerId];
			if (!owner) {
				return { error: 'Не удалось определить автора задачи' };
			}
			const ts: TaskToShopItem = Object.values(taskToShopMap)[0];
			if (!ts) {
				return { error: 'Не удалось определить подробности по задаче' };
			}
			let executor: UserDataItem | undefined;
			if (ts.executorUserId) {
				executor = userMap[ts.executorUserId];
				if (!executor) {
					return { error: 'Не удалось определить исполнителя' };
				}
			}
			let rejector: UserDataItem | undefined;
			if (ts.rejectorUserId) {
				rejector = userMap[ts.rejectorUserId];
				if (!rejector) {
					return { error: 'Не удалось определить отклонившего сотрудника' };
				}
			}
			return {
				taskToShopId: ts.id,
				taskId: task.id,
				title: task.title,
				description: task.description,
				dueDate: ts.dueDate,
				status: ts.status,
				owner,
				executor,
				executionComment: ts.executionComment,
				executionInfo: ts.executionInfo,
				executionDate: ts.executedAt ? ts.executedAt : undefined,
				rejector,
				rejectionComment: ts.rejectionComment,
				rejectionDate: ts.rejectedAt ? ts.rejectedAt : undefined,
			};
		} else {
			return {};
		}
	} catch (e: any) {
		console.error(e);
		return { error: e instanceof Error ? e.message : String(e) };
	}
}

// строим список задач для магазина
function fillShopRenderData(json: TaskListResponseDto) {
	try {
		const tasks = json.data;
		const { taskToShopMap, userMap, shopMap } = json.meta || {};
		if (tasks && taskToShopMap && userMap && shopMap) {
			const taskMap: Map<number, TaskDataItem> = arrayToMapById(tasks);
			const shop: ShopDataItem = Object.values(shopMap || {})[0];
			if (!shop) {
				return {};
			}
			const taskToShops: TaskToShopItem[] = Object.values(taskToShopMap);
			return taskToShops.map(ts => {
				const task = taskMap.get(ts.taskId);
				if (!task) {
					throw new Error('Не удалось определить задачу');
				}
				const owner: UserDataItem = userMap[task.ownerId];
				const values: any[] = [{ key: 'Назначил', value: owner.fullName }];
				if (ts.executorUserId) {
					const executor: UserDataItem = userMap[ts.executorUserId];
					if (executor) {
						values.push({ key: 'Выполнил', value: executor.fullName });
					} else {
						throw new Error( 'Не удалось определить исполнителя');
					}
				}
				if (ts.rejectorUserId && ts.status === TaskStatusEnum.REJECTED) {
					const rejector: UserDataItem = userMap[ts.rejectorUserId];
					if (rejector) {
						values.push({ key: 'Отклонил', value: rejector.fullName });
					} else {
						throw new Error('Не удалось определить отклонившего сотрудника');
					}
				}
				return {
					taskId: task.id,
					title: task.title,
					shopId: shop.id,
					values,
					status: ts.status,
					dueDate: new Date(ts.dueDate),
				};
			});
		}  else {
			return {};
		}
	} catch (e: any) {
		console.error(e);
		return { error: e instanceof Error ? e.message : String(e) };
	}
}

// строим дерево подчиненных
function fillManagerRenderData(subordinates, json) {
	try {
		const { taskToShopMap } = json.meta || {};
		const taskToShops: any[] = Object.values(taskToShopMap || {});
		return (function fill(user): UserTree {
			const users: UserTree[] = [];
			const shops: UserTreeShop[] = [];
			const { subordinateUsers, subordinateShops } = user;
			subordinateShops?.forEach(subShop => {
				const tasks = taskToShops.filter(i => i.shopId === subShop.id);
				if (tasks.length) {
					const all = tasks.length;
					const completed = tasks.filter(i => i.status === TaskStatusEnum.DONE).length;
					const percent = completed / all * 100;
					shops.push({ all, completed, percent, shop: subShop });
					subShop.stats = { all, completed };
				}
			});
			subordinateUsers?.forEach(subUser => {
				const userTree: UserTree = fill(subUser);
				if (userTree.all) {
					users.push(userTree);
				}
			});
			// calc
			const all = sum(shops.map(i => i.all)) + sum(users.map(i => i.all));
			const completed = sum(shops.map(i => i.completed)) + sum(users.map(i => i.completed));
			const percent = completed / all * 100;
			user.stats = { all, completed };
			return { all, completed, percent, users, shops, user };
		})(subordinates);
	} catch (e: any) {
		console.error(e);
		return { error: e instanceof Error ? e.message : String(e) };
	}
}
