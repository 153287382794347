import { z } from 'zod';
import { TaskToShopItem, TaskToShopItemOptions, taskToShopItemSchema } from '../composite';

export const taskExecResponseDtoSchema = z.object({
	data: taskToShopItemSchema.optional(),
});

export interface TaskExecResponseDtoOptions {
	data?: TaskToShopItem | TaskToShopItemOptions;
}

export class TaskExecResponseDto extends Response {
	readonly data?: TaskToShopItem;

	constructor(options: TaskExecResponseDtoOptions) {
		super();
		if (options.data !== undefined) {
			if (options.data instanceof TaskToShopItem) {
				this.data = options.data;
			} else {
				this.data = new TaskToShopItem(options.data);
			}
		}
		taskExecResponseDtoSchema.parse(this);
	}
}