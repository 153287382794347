import * as React from 'react';
import { Button, Dimmer, Form, Image, Loader, Message, Modal, Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { isProdServer } from 'common-lib';
import { updateFcmToken } from '~store/actions/firebaseActions';
import { login, logout, relogin } from '~store/actions/loginActions';
import { getFcmToken } from '~tools/androidFunctions';
import { getAuthorizedCookie, getRefreshTokenCookie } from '~tools/cookie';
import { getQueryParameterByName } from '~tools/location';

const LogoDiv = styled.div`
  margin: 4rem auto;
  text-align: center;

  img {
    width: 400px;
  }

  @media (max-height: 550px) {
    margin: 0 auto;
  }

  @media (max-width: 700px) {
    img {
      width: 300px;
    }
  }

  @media (max-width: 500px) {
    img {
      width: 200px;
    }
  }
`;

const SilverInputForm = styled.div`
  input {
    background-color: #f7f7f7 !important;
    border-color: #aaaaaa !important;
  }
`;

const RefreshIconStyled = styled.div`
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 9999;
  opacity: 0.5;
  font-size: 0.6rem;
  background: rgba(255, 255, 255, 0.8);
`;

interface State {
	error?: boolean | string;
	formData: any;
	canLogin: boolean;
	requestingLevel: number;
}

export default class LoginPage extends React.Component<unknown, State>
{
	state = {
		error: false,
		formData: {
			agree: true,
		},
		canLogin: false,
		requestingLevel: 0,
	};

	updateFcmFromMobile = async () => {
		// первым делом проверим токен из query
		let token = getQueryParameterByName('fcm_token');
		// второй способ - вытащить из андроид приложения
		if (!token) token = getFcmToken();
		// обновляем токен если удалось его получить
		if (token) {
			return updateFcmToken(token);
		}
	};

	handleSign = () => {
		this.setState(
			({ requestingLevel }) => ({ requestingLevel: requestingLevel + 1 }),
			() => login(this.state.formData)
				.then(() => this.updateFcmFromMobile())
				.then(() => {
					setTimeout(() => global.location.reload()); // таймер чтобы finally успел отработать
				})
				.catch(error => {
					console.error(error);
					const statusCode = error.getStatus?.();
					this.setState({ error: getErrorTextByStatusCode(statusCode) || true });
				})
				.finally(() => this.setState(({ requestingLevel }) => ({ requestingLevel: requestingLevel - 1 }))),
		);
	};

	/*
	logout()
		.then(({ redirectUrl }) => {
		  // сервер может попросить перейти по другому адресу
		  window.location.replace(redirectUrl || '/');
		})
		.catch(err => {
		  console.error('[Ошибка разлогина]', err);
		  window.location.replace('/');
		});
	 */

	handleLogout = () => {
		this.setState(
			({ requestingLevel }) => ({ requestingLevel: requestingLevel + 1 }),
			() => logout()
				.then(({ redirectUrl }) => {
					// сервер может попросить перейти по другому адресу
					if (redirectUrl) {
						setTimeout(() => window.location.replace(redirectUrl));
					} else {
						setTimeout(() => global.location.reload()); // таймер чтобы finally успел отработать
					}
				})
				.catch(error => {
					console.error(error);
					const statusCode = error.getStatus?.();
					this.setState({ error: getErrorTextByStatusCode(statusCode) || true });
				})
				.finally(() => this.setState(({ requestingLevel }) => ({ requestingLevel: requestingLevel - 1 }))),
		);
	}

	handleResign = () => {
		this.setState(
			({ requestingLevel }) => ({ requestingLevel: requestingLevel + 1 }),
			() => relogin()
				.then(() => {
					setTimeout(() => global.location.reload()); // таймер чтобы finally успел отработать
				})
				.catch(error => {
					console.error(error);
					const statusCode = error.getStatus?.();
					this.setState({ error: getErrorTextByStatusCode(statusCode) || true });
				})
				.finally(() => this.setState(({ requestingLevel }) => ({ requestingLevel: requestingLevel - 1 }))),
		);
	}

	onCredChange = (e, data) => {
		const { name, value, checked } = data;
		this.setState(prevState => {
			const formData = {
				...prevState.formData,
				[name]: name === 'agree' ? checked : value,
			};
			return {
				formData,
				canLogin: formData.username?.length > 0
					&& formData.password?.length > 2
					&& formData.agree === true,
				error: prevState.error ? false : undefined,
			};
		});
	}

	onKeyPress = ({ charCode }) => {
		if ([10, 13].includes(charCode) && !this.state.requestingLevel) {
			this.handleSign();
		}
	}

	renderSigninForm = () => (
		<Modal open dimmer="inverted" size="mini" className="login-modal">
			<Modal.Header>
				<LogoDiv>
					<Image src="/assets/logo.png" centered />
					<h2>Задачи магазина</h2>
				</LogoDiv>
			</Modal.Header>
			<Modal.Content>
				<Form loading={!!this.state.requestingLevel} error={this.state.error}
				      onSubmit={this.handleSign}>
					<SilverInputForm>
						<Form.Input label="Логин"
						            type="text"
						            name="username"
						            onChange={this.onCredChange}
						            onKeyPress={this.onKeyPress} />
						<Form.Input label="Пароль"
						            type="password"
						            name="password"
						            onChange={this.onCredChange}
						            onKeyPress={this.onKeyPress} />
						<div>
							<Checkbox
								name="agree"
								label="Я согласен с"
								defaultChecked={true}
								onChange={this.onCredChange} />
							&nbsp;
							<a href="/privacy.html">Политикой
								конфиденциальности</a>
						</div>
					</SilverInputForm>
					<Message error
					         content={this.state.error === true ? 'Реквизиты не подошли' : this.state.error} />
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Form.Button positive
				             disabled={!!this.state.requestingLevel || !this.state.canLogin}
				             onClick={this.handleSign}>Вход</Form.Button>
			</Modal.Actions>
		</Modal>
	);

	renderResignForm = () => (
		<Modal open dimmer="inverted" size="mini" className="login-modal">
			<Modal.Header>
				<LogoDiv>
					<Image src="/assets/logo.png" width={306} centered />
					<h2 style={{ fontSize: '1.8vmax' }}>Задачи магазина</h2>
				</LogoDiv>
			</Modal.Header>
			<Modal.Content>
				<p>Вы можете повторно авторизоваться</p>
			</Modal.Content>
			<Modal.Actions>
				<Button disabled={!!this.state.requestingLevel}
				        onClick={this.handleLogout}>Выйти</Button>
				<Button positive disabled={!!this.state.requestingLevel}
				        onClick={this.handleResign}>Войти
					повторно</Button>
			</Modal.Actions>
		</Modal>
	);

	render() {
		const authToken = getAuthorizedCookie();
		const refreshToken = getRefreshTokenCookie();
		if (authToken) {
			return (
				<Dimmer active inverted>
					<Loader inverted />
				</Dimmer>
			);
		} else {
			return (
				<>
					{refreshToken ? this.renderResignForm() : this.renderSigninForm()}
					{!isProdServer ? (
						<RefreshIconStyled>
							{location.hostname}&nbsp;
							<Button icon="refresh" size="tiny"
							        onClick={() => document.location.reload()} />
						</RefreshIconStyled>
					) : null}
				</>
			);
		}
	}
}

function getErrorTextByStatusCode(code: number | undefined): string {
	if (code === 500) {
		return 'Ошибка на сервере';
	}
	if (code === 502 || code === 504) {
		return 'Сервер не доступен';
	}
	return '';
}
