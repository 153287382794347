import { ResponseErrorEnum } from '../enums';

export class HttpError extends Error
{
	constructor(
		private readonly _status: number,
		private readonly _code: ResponseErrorEnum,
		private readonly _message?: string,
		private readonly _args?: any[],
	) {
		super(_message);
	}

	getStatus = () => this._status;

	getJson = () => ({
		error: this._message,
		error_code: this._code,
	});

	getArgs = () => this._args;
}

HttpError.prototype.name = 'HttpError';
