import { z } from 'zod';

export const questionnairePhotoAnswerItemSchema = z.object({
	title: z.string(),
	subTitle: z.string(),
	fileId: z.string(),
	src: z.string(),
});

export interface QuestionnaireAnswerPhotoItemOptions {
	title: string;
	subTitle: string;
	fileId: string;
	src: string;
}

export class QuestionnaireAnswerPhotoItem {
	title: string;
	subTitle: string;
	fileId: string;
	src: string;

	constructor(options: QuestionnaireAnswerPhotoItemOptions) {
		this.title = options.title;
		this.subTitle = options.subTitle;
		this.fileId = options.fileId;
		this.src = options.src;
		questionnairePhotoAnswerItemSchema.parse(this);
	}
}