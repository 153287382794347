import { z } from 'zod';

export const taskDataItemSchema = z.object({
	id: z.number().int(),
	title: z.string(),
	description: z.string(),
	executionPeriodDays: z.number().int(),
	ownerId: z.number().int(),
});

export interface TaskDataItemOptions {
	id: number;
	title: string;
	description: string;
	executionPeriodDays: number;
	ownerId: number;
}

export class TaskDataItem {
	readonly id: number;
	readonly title: string;
	readonly description: string;
	readonly executionPeriodDays: number;
	readonly ownerId: number;

	constructor(options: TaskDataItemOptions) {
		this.id = options.id;
		this.title = options.title;
		this.description = options.description;
		this.executionPeriodDays = options.executionPeriodDays;
		this.ownerId = options.ownerId;
		taskDataItemSchema.parse(this);
	}

}