/**
 * Приводит список к мапу по заданному полю.
 * @param list
 * @param field
 * @returns {*}
 */
export function arrayToMapByField<T extends any>(list: T[], field: string): Map<any, T> {
	return list?.reduce((map, item: any) => map.set(item[field], item), new Map<any, T>());
}

export function arrayToMapById<T>(list: T[]): Map<any, T> {
	return arrayToMapByField(list, 'id');
}

/**
 * Строит карту по двум полям
 * @param list
 * @param fieldA
 * @param fieldB
 * @returns [a1: b1, a2: b2...]
 */
export function mappingArrayByFields<T>(list: T[], fieldA: string, fieldB: string): Map<any, T> {
	return list?.reduce((map, item: any) => map.set(item[fieldA], item[fieldB]), new Map<any, T>());
}

/**
 * Приводит список к сету по заданному полю
 * @param list
 * @param field
 */
export function arrayToSetByField<T>(list: T[], field: string): Set<any> {
	return list?.reduce((set, i: any) => set.add(i[field]), new Set<any>());
}

export function arrayToSetThenToArrayByField<T>(list: T[], field: string) {
	return Array.from(arrayToSetByField(list, field));
}

/**
 * Сортировка списка объектов по полю
 * @param list
 * @param field
 */
export function sortArrayByField<T>(list: T[], field: string): T[] {
	return list.sort((a: any, b: any) => (a[field] > b[field])
		? 1
		: (a[field] < b[field])
			? -1
			: 0);
}

/**
 * Оставляет только уникальные элементы в списке.
 * Уникальность проверяет по указанному полю.
 * @param list
 * @param field
 * @returns {*}
 */
export function distinctListByField<T>(list: T[], field: string): T[] {
	return Array.from(arrayToSetByField(list, field));
}

export function distinctListById(list: any[]): number[] {
	return distinctListByField<number>(list, 'id');
}
