export function validateString(minl: number, maxl?: number): (s: string) => string | false {
	return (str: any): string | false => {
		if (typeof str !== 'string') {
			return 'Значение не является строкой';
		}
		return (minl > 0 && str.length < minl) || (maxl && maxl > 0 && str.length > maxl)
			? `Длина должна быть от ${minl} до ${maxl}${str.length ? `, сейчас длина ${str.length}` : ''}`
			: false;
	}
}

export function validateHasValue() {
	return (v: any): string | false => !v && 'Нужно указать значение';
}

export function validateIsNumber() {
	return (num: any): string | false => (!Number.isFinite(num) || Number.isNaN(Number(num)))
		? 'Нужно указать число'
		: false;
}

export function validateIsPositiveNumber() {
	return (num: any): string | false => (!Number.isFinite(num) || Number.isNaN(Number(num)))
		? 'Нужно указать положительное число'
		: (num < 0)
			? 'Нужно указать положительное число'
			: false;
}

export function validateNumber(min: number, max: number) {
	return (num: any): string | false => (!Number.isFinite(num) || Number.isNaN(Number(num)))
		? 'Нужно указать число'
		: (num < min || num > max)
			? `Нужно указать число от ${min} до ${max}`
			: false;
}

export function validateInteger(min: number, max?: number) {
	return (num: any): string | false => (!Number.isFinite(num) || Number.isNaN(Number(num)))
		? 'Нужно указать целое число'
		: (Math.floor(num) !== num)
			? 'Нужно указать целое число'
			: (num < min || max !== undefined && num > max)
				? `Нужно указать целое число от ${min} до ${max}`
				: false;
}

export function validateIsTimeoffset() {
	return (value: any): string | false => (!Number.isFinite(value) || Number.isNaN(Number(value)))
		? 'Нужно указать часовой пояс в минутах'
		: (value > 900 || value < -900)
			? 'Нужно указать правильный часовой пояс в минутах'
			: false;
}

/**
 * Проверяет формат даты в формате ISO-8601 без часового пояса.
 * @returns {(function(*=): (string|boolean))|*}
 */
export function validateIsIso8601() {
	return (str: any): string | false => {
		if (typeof str !== 'string') {
			return 'Значение не является строкой';
		}
		const check = Date.parse(str);
		return Number.isNaN(check) ? 'Строка должна соответствовать станадарту ISO-8601' : false;
	};
}

export function validateIsBoolean() {
	return (flag: any): string | false => flag === true || flag === false
		? false
		: 'Нужно указать логическое значение';
}

export function validateIsEmail() {
	return (value: any): string | false => value
		.toLowerCase()
		.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
		? false
		: 'Нужно указать email';
}

/**
 * Провеярет наличие лишних ключей в объекте. Возвращает набор лишних ключей или false.
 * @param obj:Object Проверяемый объект
 * @param availableKeys:String[] Допустимый набор ключей
 * @returns {*[] | false}
 */
export function checkExcessKeys(obj: object, availableKeys: string[]): string[] | false {
	const failKeys: string[] = [];
	Object.keys(obj).forEach(key => {
		if (!availableKeys.includes(key)) {
			failKeys.push(key);
		}
	});
	return failKeys.length ? failKeys : false;
}

/**
 * Проверяет наличие лишних ключей в объекте и кидает ошибку если такие есть.
 * @param obj:Object Проверяемый объект
 * @param availableKeys:String[] Допустимый набор ключей
 * @param varName:String Имя объекта, чтобы отличить его среди логов
 */
export function throwIfExcessKeys(obj: object, availableKeys: string[], varName: string): void {
	if (!obj) return;
	const failKeys = checkExcessKeys(obj, availableKeys);
	if (failKeys) {
		throw new Error(`Неожидаемые ключи: ${failKeys.join(', ')}. Переменная '${varName}' ожидает только этот набор ключей: ${availableKeys.join(', ')}.`);
	}
}
