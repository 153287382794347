import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { TaskDataItem, TaskDataItemOptions, taskDataItemSchema, } from '../composite';
import { TaskMeta, TaskMetaOptions, taskMetaSchema } from '../meta';

export const taskListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(taskDataItemSchema).optional(),
		meta: taskMetaSchema.optional(),
	}),
);

export interface TaskListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (TaskDataItem | TaskDataItemOptions)[];
	meta?: TaskMeta | TaskMetaOptions;
}

export class TaskListResponseDto extends BaseResponseDto {
	readonly data?: TaskDataItem[];
	readonly meta?: TaskMeta;

	constructor(options: TaskListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof TaskDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new TaskDataItem(item));
				}
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof TaskMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new TaskMeta(options.meta);
			}
		}
		taskListResponseDtoSchema.parse(this);
	}

}