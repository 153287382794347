import { z } from 'zod';


export const questionnaireExecutionConfigSchema = z.object({
	startAtDate: z.string(),
	startAtTime: z.string(),
	finishAtDate: z.string(),
	finishAtTime: z.string(),
	repeatCount: z.number().int(),
	repeatEveryDays: z.number().int().optional(),
});
export interface QuestionnaireExecutionConfigOptions {
	startAtDate: string;
	startAtTime: string;
	finishAtDate: string;
	finishAtTime: string;
	repeatCount: number;
	repeatEveryDays?: number;
}

export class QuestionnaireExecutionConfig {
	readonly startAtDate: string;
	readonly startAtTime: string;
	readonly finishAtDate: string;
	readonly finishAtTime: string;
	readonly repeatCount: number;
	readonly repeatEveryDays?: number;

	constructor(options: QuestionnaireExecutionConfigOptions) {
		this.startAtDate = options.startAtDate;
		this.startAtTime = options.startAtTime;
		this.finishAtDate = options.finishAtDate;
		this.finishAtTime = options.finishAtTime;
		this.repeatCount = options.repeatCount;
		if (options.repeatEveryDays !== undefined) this.repeatEveryDays = options.repeatEveryDays;
		questionnaireExecutionConfigSchema.parse(this);
	}
}
