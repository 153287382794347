export enum AccountSettingKeyEnum {
	// адресаты для отправки очтета анализа структуры
	structureAnalisysReportAddressees = 'STRUCTURE_ANALISYS_REPORT_ADDRESSEES',

	// адресаты для получения ежедневного отчета по инцидентам
	incidentsDailyReportAddressees = 'INCIDENTS_DAILY_REPORT_ADDRESSEES',

	// адресаты для получения ежедневного отчета по инцидентам (только по Ленте, на период тестирования)
	incidentsDailyReportAddresseesLentaTest = 'INCIDENTS_DAILY_REPORT_ADDRESSEES_LENTA_TEST',
}
