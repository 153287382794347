import { z } from 'zod';
import {
	TreeSubordinateUserDto,
	TreeSubordinateUserDtoOptions,
	treeSubordinateUserDtoSchema
} from './TreeSubordinateUserDto';
import {
	TreeSubordinateShopDto,
	TreeSubordinateShopDtoOptions,
	treeSubordinateShopDtoSchema
} from './TreeSubordinateShopDto';

export const treeListDataItemSchema = z.object({
	subordinateUsers: z.array(treeSubordinateUserDtoSchema).optional(),
	subordinateShops: z.array(treeSubordinateShopDtoSchema).optional(),
});

export interface TreeListDataItemOptions {
	subordinateUsers?: (TreeSubordinateUserDto | TreeSubordinateUserDtoOptions)[];
	subordinateShops?: (TreeSubordinateShopDto | TreeSubordinateShopDtoOptions)[];
}

export class TreeListDataItem {
	readonly subordinateUsers?: TreeSubordinateUserDto[];
	readonly subordinateShops?: TreeSubordinateShopDto[];

	constructor(options: TreeListDataItemOptions) {
		if (options.subordinateUsers?.length) {
			this.subordinateUsers = [];
			for (const item of options.subordinateUsers) {
				if (item instanceof TreeSubordinateUserDto) {
					this.subordinateUsers.push(item);
				} else {
					this.subordinateUsers.push(new TreeSubordinateUserDto(item));
				}
			}
		}
		if (options.subordinateShops?.length) {
			this.subordinateShops = [];
			for (const item of options.subordinateShops) {
				if (item instanceof TreeSubordinateShopDto) {
					this.subordinateShops.push(item);
				} else {
					this.subordinateShops.push(new TreeSubordinateShopDto(item));
				}
			}
		}
		treeListDataItemSchema.parse(this);
	}
}