import { z } from 'zod';

export const shopDataItemSchema = z.object({
	id: z.number().int(),
	externalId: z.string(),
	name: z.string(),
	regionId: z.number().int(),
	city: z.string(),
	address: z.string(),
	phone: z.string(),
	email: z.string(),
	timeOffset: z.number().int(),
});

export interface ShopDataItemOptions {
	id: number;
	externalId: string;
	name: string;
	regionId: number;
	city: string;
	address: string;
	phone: string;
	email: string;
	timeOffset: number;
}

export class ShopDataItem
{
	readonly id: number;
	readonly externalId: string;
	readonly name: string;
	readonly regionId: number;
	readonly city: string;
	readonly address: string;
	readonly phone: string;
	readonly email: string;
	readonly timeOffset: number;

	constructor(options: ShopDataItemOptions) {
		this.id = options.id;
		this.externalId = options.externalId;
		this.name = options.name;
		this.regionId = options.regionId;
		this.city = options.city;
		this.address = options.address;
		this.phone = options.phone;
		this.email = options.email;
		this.timeOffset = options.timeOffset;
		shopDataItemSchema.parse(this);
	}
}