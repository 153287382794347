import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { copyText } from '~tools/copyText';
import { Nowrap } from '~components';

const ErrorText = styled.span`
  color: red;
`

const RoleSpan = styled.span`
  display: inline-block;
  color: #666;
  font-size: .8em;
  font-weight: 500;
  margin-right: 0.6em;
`

/**
 * Выполняет обогащение данных для таблицы
 */
export const fillTableData = ({ data: rows = [], meta, pageInfo }) => {
	// заполняем пользователей из meta в rows
	const { shopManagerMap = {}, shopManagerRoleMap = {}, userMap = {} } = meta || {};
	const shopManagers = Object
		.values(shopManagerMap)
		.map((sm: any) => {
			sm.user = userMap[sm.userId];
			sm.role = shopManagerRoleMap[sm.roleId];
			return sm;
		})
		.sort((a: any, b: any) => {
			return b.role.subLevel - a.role.subLevel;
		})
	rows.forEach((shop: any) => {
		shop.managers = shopManagers.filter((sm: any) => sm.shopId === shop.id);
	})
	return { rows, pageInfo };
}

export const TABLE_HEADERS = {
	id: {
		title: '#',
		style: { maxWidth: '150px' },
		viewFunc: (row, hl) => (
			<Popup content="Нажми, чтобы скопировать" trigger={(
				<Nowrap style={{ cursor: 'pointer' }} onClick={event => {
					event.stopPropagation()
					copyText(row.id)
				}}>
					{hl(row.id)}
					<Icon name="copy"
					      style={{ color: '#aaa', fontSize: '0.9em', float: 'right' }} />
				</Nowrap>
			)} />
		),
	},
	externalId: {
		title: 'Внешний ID',
		style: { maxWidth: '150px' },
		viewFunc: (row, hl) => row.externalId ? (
			<Popup content="Нажми, чтобы скопировать" trigger={(
				<Nowrap style={{ cursor: 'pointer' }} onClick={event => {
					event.stopPropagation()
					copyText(row.externalId)
				}}>
					{hl(row.externalId)}
					<Icon name="copy"
					      style={{ color: '#aaa', fontSize: '0.9em', float: 'right' }} />
				</Nowrap>
			)} />
		) : null,
	},
	name: {
		title: 'Название',
		viewFunc: (row, hl) => (
			<Link to={`/admin/shops/${row.id}`}>
				{hl(row.name)}
			</Link>
		),
	},
	$managers: {
		title: 'Менеджеры',
		viewFunc: (row, hl) => row.managers
			.filter(man => !man.role.isShopUser)
			.map(man => (<div key={man.id}>
				<RoleSpan>{man.role.shortTitle}</RoleSpan>
				<Link to={`/admin/users/${man.user?.id}`}>
					{hl(man.user.fullName)}
				</Link>
			</div>)),
	},
	$dms: {
		title: 'Директора',
		viewFunc: (row, hl) => row.managers
			.filter(man => man.role.isShopUser)
			.map(man => {
				return (<div key={man.id}>
					<RoleSpan>{man.role.shortTitle}</RoleSpan>
					<Link to={`/admin/users/${man.userId}`}>
						{hl(man.user?.fullName || 'не указано')}
					</Link>
				</div>);
			}),
	},
}
