import { createReducer } from 'redux-act'
import {
  ADD_ERROR,
  REMOVE_ERROR,
} from '../actions/errorActions'

export default createReducer(
  {
    [ADD_ERROR]: (errors, error) => {
      error.id = Math.random().toString(36).substr(0, 10)
      return [...errors, error]
    },
    [REMOVE_ERROR]: (errors, errorId) => {
      return errors.filter(e => e.id !== errorId)
    },
  },
  [],
)
