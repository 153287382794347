import React, { useEffect, useState } from 'react';
import { Spinner } from '~components';
import { Button, Modal } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import * as QuizApi from '~api/quizApi';
import { UserContentWrapper } from '~containers';
import { QuizQuestion, UserQuizSolving } from './UserQuizSolving';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';
import { useSelector } from 'react-redux';
import { QuestionnaireAnswerQuizRequestDto } from 'protocol-lib';

export default function UserQuizPage() {
	const params = useParams();
	const quizId: number = Number(params.id);

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	const meConfig: any = useSelector<any>(state => state.me.currentUser);
	const isDmManager = meConfig.shopManagerRoles !== undefined;

	const navigate = useNavigate();

	const [quizName, setQuizName] = useState<string>('');
	const [isShowQuizStopped, setIsShowQuizStopped] = useState<boolean>(false);
	const [questions, setQuestions] = useState<QuizQuestion[] | undefined>([]);

	async function checkQuizAvailability(): Promise<boolean> {
		return await QuizApi.fetchRunningQuizList().then(res => {
			return res.data?.findIndex(item => item.id === quizId) !== -1;
		}).catch(() => {
			return true;
		});
	}

	function submit(payload: QuestionnaireAnswerQuizRequestDto[]) {
		QuizApi.answerQuiz(quizId, payload).then(() => {
			navigate('/quiz-list', { replace: true });
		}).catch(error => {
			if ([400].includes(error.getStatus())) {
				setIsShowQuizStopped(true);
			} else {
					throw new Error(error);
			}
		});
	}

	useEffect(() => {
		QuizApi.fetchQuiz(quizId).then(res => {
			setQuizName(res.data?.title || '');
			setQuestions(res.data?.questionnaireConfig?.questions.map((item: any) => {
				const question: QuizQuestion = {
					text: item.text,
					type: item.type,
					startDate: res.data?.startDate || '',
					startTime: res.data?.startTime || '',
					photos: item.photos,
					isRequired: item.isRequired,
					isHidden: !!item.visibleOptions,
				};

				if (item.options) {
					question.options = item.options;
				}

				if (item.visibleOptions) {
					question.visibleOptions = item.visibleOptions;
				}

				return question;
			}));
		});

	}, []);

	if (!quizName || !questions?.length) {
		return <Spinner onpage />;
	}

	return (
		<UserContentWrapper isPreview title={`Опрос: ${quizName}`} backLinkTo="/quiz-list">
			<UserQuizSolving questions={questions} submit={submit} />
			{isShowQuizStopped ? (
				<Modal open>
					<Modal.Content>
						<p>
							Опрос был остановлен.
						</p>
						<p>
							Данные не сохранены.
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={() => {
							navigate('/quiz-list', { replace: true });
						}} content="OK" />
					</Modal.Actions>
				</Modal>
			) : null}
		</UserContentWrapper>
	);
}
