import { QuestionnaireQuestionTypeEnum } from 'common-lib';
import { z } from 'zod';
import { QuestionnairePhoto, questionnairePhotoSchema } from './QuestionnairePhoto';


export const questionnaireConfigQuestionsItemSchema = z.object({
	text: z.string(),
	photos: z.array(z.union([questionnairePhotoSchema, z.number().int(), z.string()])),
	type: z.nativeEnum(QuestionnaireQuestionTypeEnum),
	isInvalid: z.boolean().optional(),
});

export interface QuestionnaireConfigQuestionsItemOptions {
	text: string;
	type: QuestionnaireQuestionTypeEnum;
	photos: (QuestionnairePhoto | number | string)[];
	isInvalid?: string;
}

export class QuestionnaireConfigQuestionsItem {
	readonly text: string;
	readonly type: QuestionnaireQuestionTypeEnum;
	readonly photos: (QuestionnairePhoto | number | string)[];
	readonly isInvalid?: string;

	constructor(options: QuestionnaireConfigQuestionsItemOptions) {
		this.text = options.text;
		this.type = options.type;
		this.photos = options.photos;
		if (options.isInvalid !== undefined) this.isInvalid = options.isInvalid;
		questionnaireConfigQuestionsItemSchema.parse(this);
	}
}