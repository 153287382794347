// логин может состоять только из букв и цифр
const LOGIN_ACCESSIBLE_CHARS = '123456789ABCDEFGHKMNPQRSTUVWXTZabcdefghkmnopqrstuvwxyz';

// пароль может состоять только из букв и цифр
const PASSWORD_ACCESSIBLE_CHARS = '123456789ABCDEFGHKMNPQRSTUVWXTZabcdefghkmnopqrstuvwxyz';

// eslint-disable-next-line max-len
const LOREM_IPSUM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis luctus vehicula neque a faucibus. Ut eu neque feugiat, feugiat arcu ut, bibendum justo. Ut sit amet justo a elit finibus volutpat. In facilisis fermentum urna quis pellentesque. Aliquam gravida arcu vel mauris sodales pulvinar. Etiam pulvinar, nisl a interdum semper, metus mauris viverra ante, a hendrerit enim nibh ut ante. Praesent fringilla tincidunt sapien in sodales. Sed vehicula placerat lobortis. Proin pellentesque maximus arcu sit amet hendrerit. Phasellus semper posuere mollis. Mauris non lacus egestas, faucibus orci ac, blandit justo. Suspendisse vel congue augue. Duis sit amet lacus venenatis, luctus est et, consequat massa.
Donec quis feugiat metus. Suspendisse sit amet tincidunt arcu. Suspendisse non enim laoreet leo venenatis fermentum nec nec libero. Morbi et aliquam eros. Ut sed molestie ante, non tristique purus. Integer congue eros auctor ante eleifend dignissim. Vestibulum pulvinar mi id dui aliquam, ut dignissim diam laoreet. Nullam porttitor sollicitudin est, in sagittis arcu tristique id.
Vestibulum ultrices metus risus, nec maximus est cursus eget. Sed placerat augue vitae odio malesuada, ultrices fringilla lectus fermentum. Donec nec aliquet est. Proin pharetra, ipsum eu molestie dignissim, magna quam ultrices orci, ut viverra nisi lacus id lorem. Donec mollis et ipsum et finibus. In hac habitasse platea dictumst. Integer est magna, vehicula quis volutpat vitae, tincidunt vel elit. Mauris sapien nibh, aliquam sit amet mauris id, euismod porttitor magna. Duis cursus mollis felis nec laoreet. Quisque nunc ante, interdum eu scelerisque id, facilisis a tellus. Nam ut sodales mi.
Curabitur hendrerit orci mauris, ac lobortis lorem aliquet quis. Donec vitae convallis tortor, eget tempus enim. Cras vel lectus dictum, efficitur ligula eu, commodo tellus. Etiam in sodales elit. Aliquam faucibus viverra justo eu iaculis. Vestibulum dictum sapien vel metus finibus luctus. Nulla a felis dolor. Sed quis libero sagittis, rhoncus tortor id, hendrerit nibh.
Vestibulum vulputate auctor tellus ac pellentesque. Nam lacinia efficitur nibh et vulputate. Donec posuere, erat sed vehicula elementum, elit augue vulputate lectus, eget pellentesque massa nisl eu ipsum. Vivamus nulla lacus, euismod nec odio nec, dapibus consequat dui. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris tristique, neque in ornare congue, augue ligula rhoncus metus, at pretium turpis mauris et libero. Etiam porttitor tortor sed cursus ultricies. Vestibulum neque eros, imperdiet vitae elit ut, consectetur pretium leo. Pellentesque sed erat eget massa congue fermentum.`;

export function randomFromArray<T>(arr: T[]): T {
	return arr[Math.floor(Math.random() * arr.length)];
}

export function randomNumber(min: number, max: number): number {
	return min + Math.random() * (max - min);
}

export function randomInteger(min: number, max: number): number {
	return Math.round(randomNumber(min, max));
}

export function randomPhone(): string {
	const ri = randomInteger;
	return `+7 (${ri(900, 999)}) ${ri(100, 999)}-${ri(10, 99)}-${ri(10, 99)}`;
}

export function loremIpsum(length = 1000): string {
	return LOREM_IPSUM.substr(
		randomInteger(0, Math.max(0, LOREM_IPSUM.length - length)),
		length);
}

export function randomString(chars: string, length = 10): string {
	let random = '';
	for (let i = 0; i < length; i++) {
		const rnum = Math.floor(Math.random() * chars.length);
		random += chars.substr(rnum, 1);
	}
	return random;
}

export function randomEasyPassword(min = 6, max = 12): string {
	let s = '';
	const len = randomInteger(min, max);
	while (s.length < len) {
		s += Math.random().toString(35).substr(2);
	}
	return s.substr(0, len);
}

export function randomStringLogin(length = 10): string {
	return randomString(LOGIN_ACCESSIBLE_CHARS, length);
}

export function randomStringPass(length = 10): string {
	return randomString(PASSWORD_ACCESSIBLE_CHARS, length);
}
