import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'semantic-ui-react';
import UiBadge from '~components/ui/UiBadge';
import UiOverMenu from '~components/ui/UiOverMenu';
import { Spinner, UiPhotoGallery2, UserPhotoPreview } from '~components';
import isMobile from 'is-mobile';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { QuestionnaireQuestionTypeEnum, UserRoles } from 'common-lib';
import {
	QuestionnaireAnswerPhotoItem,
	QuestionnaireAnswerQuizRequestDto,
	QuestionnaireVisible,
} from 'protocol-lib';

const QuestionList = styled.div`
	& > div {
		margin-bottom: 1rem;

		&.invalid {
			background: rgba(255, 220, 220, 0.5);
			border-radius: 8px;
			padding: 8px;
			border: 2px solid pink;
		}
	}

	span.__spark {
		margin-left: 0.3rem;
		color: red;
	}

	div.__radioList {
        display: inline-block;

		div {
			cursor: default;
			padding: 0.3rem 0 0.3rem 2rem;
			background-image: url(/assets/img/ui/radio_empty.svg);
			background-repeat: no-repeat;
			background-size: initial;
			background-position: left center;

			&.active {
				background-image: url(/assets/img/ui/radio_checked.svg);
			}
		}
	}

	div.__checkboxList {
        display: inline-block;

		div {
			cursor: default;
			padding: 0.3rem 0 0.3rem 2rem;
			background-image: url(/assets/img/ui/checkbox_empty.svg);
			background-repeat: no-repeat;
			background-size: initial;
			background-position: left center;

			&.active {
				background-image: url(/assets/img/ui/checkbox_checked.svg);
			}
		}
	}
`;

const InfoStyled = styled.div`
	margin: 18px auto;
	font-size: 16px;
	text-align: center;
	color: #9696A7;
`;

export type QuizQuestion = {
	text: string,
	type: string,
	isRequired?: boolean,
	isHidden: boolean,
	startDate: string,
	startTime: string,
	photos?: any[],
	isInvalid?: boolean,
	options?: string[],
	visibleOptions?: QuestionnaireVisible,
};

type AnswerPhotoType = {
	questionIndex: number,
	fileId: number | string,
};

export function UserQuizSolving(props) {
	const [answers, setAnswers] = useState<any[]>(props.questions.map(() => null));
	const [questions, setQuestions] = useState<QuizQuestion[]>(props.questions);
	const [issetErrors, setIssetErrors] = useState<boolean>(false);
	const [showQuestionFileId, setShowQuestionFileId] = useState<number | string>();
	const [showAnswerFileId, setShowAnswerFileId] = useState<AnswerPhotoType>();
	const [answerPhotosMap] = useState(new Map<number, any[]>());
	const [isSubmited, setSubmited] = useState<boolean>(false);

	const meConfig: any = useSelector<any>(state => state.me.currentUser);

	const navigate = useNavigate();

	useEffect(() => {
		if (isSubmited) {
			submit();
		}
	}, [isSubmited]);

	useEffect(() => {
		if (showQuestionFileId || showAnswerFileId) {
			if (location.hash !== '#preview') {
				location.hash = '#preview';
				navigate(location.pathname + location.hash);
			}
			window.addEventListener('popstate', onBack, false);
		}
	}, [showQuestionFileId, showAnswerFileId]);

	function onBack(e) {
		e.preventDefault();
		setShowQuestionFileId(undefined);
		setShowAnswerFileId(undefined);
		window.removeEventListener('popstate', onBack);
	}

	function updateAnswers(type: string, index: number, target: any): void {
		if (type === QuestionnaireQuestionTypeEnum.CHECKERS) {
			if (!answers[index]) {
				answers[index] = [];
			}
			if (target.checked) {
				answers[index].push(+target.value);
			} else {
				answers[index].splice(answers[index].indexOf(+target.value), 1);
			}
		} else if( type === QuestionnaireQuestionTypeEnum.PHOTO) {
			answers[index] = target;
		} else {
			answers[index] = target.value;
		}

		// переключаем отображение скрытых вопросов
		setQuestions(questions.map((item, i) => {
			if (item.visibleOptions) {
				const answer = answers[item.visibleOptions.questionIndex];
				item.isHidden = answer === null || !answer.includes(item.visibleOptions.answerIndex);
				if (item.isHidden) {
					answers[i] = null;
				}
			}
			return item;
		}));

		setAnswers(answers);
	}

	function validate(): boolean {
		let isValid: boolean = true;

		console.debug('%c*** questions=', 'background: #eee; color: blue', questions);

		answers.map((answer, index) => {
			if (!questions[index].isRequired || questions[index].isHidden) {
				return;
			}

			if (
				answer !== null &&
				answer !== '' &&
				(!Array.isArray(answer) || answer.length)
			) {
				if (questions[index].type === QuestionnaireQuestionTypeEnum.STRING && answer.length > 2000) {
					questions[index].isInvalid = true;
					isValid = false;
				} else {
					questions[index].isInvalid = false;
				}
			} else {
				questions[index].isInvalid = true;
				isValid = false;
			}
		});

		setQuestions([...questions]);

		return isValid;
	}

	function submit(): void {
		if (!validate()) {
			setIssetErrors(true);
			setSubmited(false);
			return;
		}

		setIssetErrors(false);

		const payload: QuestionnaireAnswerQuizRequestDto[] = answers.map((answer, index) => {
			return new QuestionnaireAnswerQuizRequestDto({
				questionIndex: index,
				value: answer,
			});
		});

		props.submit(payload);
	}

	const PHOTO_LIMIT_UPLOAD = 3;

	// клик на фото
	function clickQuestionImage(fileId: number | string) {
		setShowQuestionFileId(fileId);
	}

	function clickAnswerImage(questionIndex: number, fileId: number | string): void {
		setShowAnswerFileId({questionIndex, fileId});
	}

	if(showAnswerFileId) {
		const { questionIndex, fileId } = showAnswerFileId;
		const answerPhotos = answerPhotosMap.get(questionIndex);
		const answerPhotoIndex = answerPhotos?.findIndex(p => p.fileId === fileId);

		return <UserPhotoPreview
			selectedIndex={Number(answerPhotoIndex)}
			imageList={answerPhotos || []}
			isMobile={isMobile()}
			onClose={() => {
				setShowAnswerFileId(undefined);
			}}
		/>;
	}

	if(showQuestionFileId) {
		const questionPhotoIds = questions
			.filter(i => i.photos?.includes(showQuestionFileId))
			.map(i => i.photos)
			.flat();
		const questionIndex = questionPhotoIds.findIndex(p => p === showQuestionFileId);

		const questionPhotos = questionPhotoIds.map(p => {
			const day = moment(questions[0].startDate).format('DD.MM');
			const time = questions[0].startTime;
			return {
				fileId: p,
				title: day,
				subTitle: time,
				src: `/api/file/download/${p}?size=middle`,
			};
		});

		return <UserPhotoPreview
			selectedIndex={Number(questionIndex)}
			imageList={questionPhotos}
			isMobile={isMobile()}
			onClose={() => {
				setShowQuestionFileId(undefined);
			}}
		/>;
	}


	return (
		<>
			<UiBadge bigPaddings>
				<QuestionList>
					{questions.map((item, index) => {
						const answerPhotos = answerPhotosMap.get(index);
						const isLimitPhoto = (answerPhotos?.length || 0) < PHOTO_LIMIT_UPLOAD;

						const photoSrcs = item.photos?.map(p => {
							return ({
								fileId: p,
								src: `/api/file/download/${p}?size=middle`,
							});
						});

						// добавление фото
						function onAddPhoto(newPhoto) {
							if (!answerPhotosMap.has(index)) {
								answerPhotosMap.set(index, []);
							}
							const answerPhotos1 = answerPhotosMap.get(index);
							if (answerPhotos1) {
								const nowDate = moment();
								answerPhotos1.push({
									title: nowDate.format('DD.MM'),
									subTitle: nowDate.format('HH:mm'),
									fileId: Math.random().toString(36),
									src: newPhoto,
								});
								updateAnswers(item.type, index, answerPhotos1);
							}
						}

						// удаление фото
						function onRemovePhoto(photoIndex, questionIndex) {
							const answerPhotos1 = answerPhotosMap.get(questionIndex);
							if (answerPhotos1) {
								answerPhotos1.splice(photoIndex, 1);
								updateAnswers(item.type, index, answerPhotos1);
							}
						}

						return (
							<div
								key={item.text}
								className={item.isInvalid ? 'invalid' : ''}
								hidden={item.isHidden}
							>
								<h1>{item.text}{item.isRequired ?
									<span className="__spark">*</span> : null}</h1>
								{item.photos?.length ? (
									<div style={{ marginBottom: '5px' }}>
										<UiPhotoGallery2 photos={photoSrcs || []}
										                 onClickImage={meConfig.role !== UserRoles.ADMIN ? clickQuestionImage : undefined}
										                 readOnly />
									</div>
								) : null}
								{item.type === QuestionnaireQuestionTypeEnum.STRING &&
									<Input
										fluid
										type="text"
										defaultValue={answers[index]}
										disabled={isSubmited}
										placeholder="Введите текст"
										onChange={event => updateAnswers(item.type, index, event.target)}
									/>
								}

								{item.type === QuestionnaireQuestionTypeEnum.FLOAT &&
									<Input
										fluid
										type="number"
										defaultValue={answers[index]}
										disabled={isSubmited}
										placeholder="Введите число"
										onChange={event => updateAnswers(item.type, index, event.target)}
									/>
								}

								{item.type === QuestionnaireQuestionTypeEnum.RADIO ? (
									<div className="__radioList">
										{item.options?.map((option, i) => {
											const checked = answers[index] === i.toString();
											return (
												<div
													key={i}
													className={checked ? 'active' : ''}
													onClick={() => !isSubmited && updateAnswers(item.type, index, {
														checked: !checked,
														value: i.toString(),
													})}>{option}</div>
											);
										})}
									</div>
								) : null}

								{item.type === QuestionnaireQuestionTypeEnum.CHECKERS ? (
									<div className="__checkboxList">
										{item.options?.map((option, i) => {
											const checked = answers[index]?.includes(i);
											return (
												<div
													key={i}
													className={checked ? 'active' : ''}
													onClick={() => !isSubmited && updateAnswers(item.type, index, {
														checked: !checked,
														value: i.toString(),
													})}>{option}</div>
											);
										})}
									</div>
								) : null}

								{item.type === QuestionnaireQuestionTypeEnum.PHOTO && (
									<div style={{ marginTop: '10px' }}>
										<UiPhotoGallery2 photos={answers[index]}
										                 onAddPhoto={isLimitPhoto && !isSubmited ? onAddPhoto : undefined}
										                 readOnly={!isLimitPhoto}
										                 isLimit={!isLimitPhoto}
										                 onClickImage={meConfig.role !== UserRoles.ADMIN ? (fileId => clickAnswerImage(index, fileId)) : undefined}
										                 onRemovePhoto={(i) => !isSubmited && onRemovePhoto(i, index)} />
									</div>
								)}
							</div>
						);
					})}
				</QuestionList>
				{issetErrors &&
					<div style={{ color: 'red' }}>
						Заполните необходимые поля
					</div>
				}
			</UiBadge>
			<UiOverMenu>
				<UiOverMenu.AcceptButton title="Завершить" onClick={() => setSubmited(true)} disabled={isSubmited}/>
			</UiOverMenu>
			{meConfig.role !== UserRoles.ADMIN && isSubmited ? (
				<Spinner onpage text='Сохранение...' />
			) : null}
		</>
	);
}
