import { z } from 'zod';
import { UserDataItem, UserDataItemOptions, userDataItemSchema } from '../models';
import { FileDataItem, FileDataItemOptions, fileDataItemSchema } from './FileDataItem';
import { LogDataItem, LogDataItemOptions, logDataItemSchema } from './LogDataItem';


export const dashboardMetaSchema = z.object({
	fileMap: z.map(z.string(), fileDataItemSchema),
	logMap: z.map(z.string(), logDataItemSchema),
	userMap: z.map(z.string(), userDataItemSchema),
});

export interface DashboardMetaOptions {
	fileMap?: Record<string, FileDataItem | FileDataItemOptions>;
	logMap?: Record<string, LogDataItem | LogDataItemOptions>;
	userMap: Record<string, UserDataItem | UserDataItemOptions>;
}

export class DashboardMeta {
	readonly fileMap?: Record<string, FileDataItem>;
	readonly logMap?: Record<string, LogDataItem>;
	readonly userMap?: Record<string, UserDataItem>;

	constructor(options: DashboardMetaOptions) {
		if (options.fileMap !== undefined) {
			this.fileMap = {};
			for(const [key, value] of Object.entries(options.fileMap)) {
				if (value instanceof FileDataItem) {
					this.fileMap[key] = value;
				} else {
					this.fileMap[key] = new FileDataItem(value);
				}
			}
		}
		if (options.logMap !== undefined) {
			this.logMap = {};
			for(const [key, value] of Object.entries(options.logMap)) {
				if (value instanceof LogDataItem) {
					this.logMap[key] = value;
				} else {
					this.logMap[key] = new LogDataItem(value);
				}
			}
		}
		if (options.userMap !== undefined) {
			this.userMap = {};
			for(const [key, value] of Object.entries(options.userMap)) {
				if (value instanceof UserDataItem) {
					this.userMap[key] = value;
				} else {
					this.userMap[key] = new UserDataItem(value);
				}
			}
		}
		dashboardMetaSchema.parse(this);
	}
}