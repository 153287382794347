import React from 'react';
import styled from 'styled-components';
import { isDev } from 'common-lib';
import { UiFaceProgressBadge } from '~components';
import { IncidentTypeStat } from '../incidentsAttrs';

type Props = {
	shopId: number
	isShopUser: boolean
	data: IncidentTypeStat[]
}

type CalcStat = {
	all: number
	completed: number
	count: number
	percent: number
}

const StatsStyled = styled.div`
  margin-bottom: 12px;
  color: #333;
  
  &::before  {
    margin-right: 0.7em;
    content: '(dev)';
    font-size: 0.8em;
    color: #888;
  }
`

export default function ShopIncidentTypeListView(props: Props) {
	const stat: CalcStat = calc(props.data);
	console.log('Статы страницы:', stat);
	return <>
		{isDev && stat.count ? (
			<StatsStyled>
				Инцидентов: {stat.all}.
				Выполнено: {stat.completed}{stat.percent ? ` (${stat.percent.toFixed(stat.percent < 10 ? 1 : 0)}%)` : null}.
			</StatsStyled>
		) : null}
		{props.data.map(stat => {
			const { all, completed, id, key, name } = stat;
			const shopLink = props.isShopUser ? '' : `shop/${btoa(String(props.shopId))}/`;
			return <UiFaceProgressBadge
				key={key}
				title={name}
				progress={{ value: completed, max: all }}
				linkTo={`/incidents/${shopLink}type/${btoa(String(id))}`}
			/>
		})}
	</>;
}

function calc(typeStats: IncidentTypeStat[]): CalcStat {
	const stat: CalcStat = {
		all: 0,
		completed: 0,
		count: typeStats?.length || 0,
		percent: 0,
	};
	typeStats?.forEach((typeStat => {
		stat.all += typeStat.all;
		stat.completed += typeStat.completed;
	}));
	stat.percent = stat.all ? (stat.completed / stat.all * 100) : 0;
	return stat;
}
