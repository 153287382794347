import { z } from 'zod';

export const questionsSettingsSchema = z.object({
	mixOptionAnswers: z.boolean(),
	showTestResult: z.boolean(),
});

export interface QuestionsSettingsOptions {
	messageTitle: boolean;
	addressees: boolean;
}

export class QuestionsSettings {
	readonly messageTitle: boolean;
	readonly addressees: boolean;

	constructor(options: QuestionsSettingsOptions) {
		this.messageTitle = options.messageTitle;
		this.addressees = options.addressees;
		questionsSettingsSchema.parse(this);
	}
}
