import { calcPeriods } from './calcPeriods';

const MAX_SIZE_IN_CATEGORY = 100;

export class TodoCreateWarnings
{
	private existTodoExIds: string[] = [];
	private existTodoExIdCount = 0;
	private nonExistUserExIds: string[] = [];
	private nonExistUserExIdCount = 0;
	private nonExistShopExIds: string[] = [];
	private nonExistShopExIdCount = 0;
	private emptyTitles: number[] = [];
	private emptyTitleCount = 0;
	private wrongStatuses: number[] = [];
	private wrongStatusCount = 0;

	get length(): number {
		return this.existTodoExIdCount + this.nonExistUserExIdCount + this.nonExistShopExIdCount
			+ this.emptyTitleCount + this.wrongStatusCount;
	}

	addExistTodoExIds = (value: string) => {
		if (this.existTodoExIdCount++ < MAX_SIZE_IN_CATEGORY) {
			this.existTodoExIds.push(value);
		}
	}

	addNonExistUserExIds = (value: string) => {
		if (this.nonExistUserExIdCount++ < MAX_SIZE_IN_CATEGORY) {
			this.nonExistUserExIds.push(value);
		}
	}

	addNonExistShopExIds = (value: string) => {
		if (this.nonExistShopExIdCount++ < MAX_SIZE_IN_CATEGORY) {
			this.nonExistShopExIds.push(value);
		}
	}

	addEmptyTitles = (value: number) => {
		if (this.emptyTitleCount++ < MAX_SIZE_IN_CATEGORY) {
			this.emptyTitles.push(value);
		}
	}

	addWrongStatuses = (value: number) => {
		if (this.wrongStatusCount++ < MAX_SIZE_IN_CATEGORY) {
			this.wrongStatuses.push(value);
		}
	}

	getLines = () => {
		const res: string[] = [];
		// existTodoExIds
		if (this.existTodoExIdCount) res.push(
			`Задачи с номерами "${this.existTodoExIds.join('", "')}" уже были добавлены, эти записи не будут добавлены.`
			+ (this.existTodoExIds.length < this.existTodoExIdCount
				? ` Выведены не все номера задач, всего предупреждений: ${this.existTodoExIdCount}.` : ''));
		// nonExistUserExIds
		if (this.nonExistUserExIdCount) res.push(
			`Пользователи с id "${this.nonExistUserExIds.join('", "')}" отсутствуют в базе, эти записи не будут добавлены.`
			+ (this.nonExistUserExIds.length < this.nonExistUserExIdCount
				? ` Выведены не все id пользователей, всего предупреждений: ${this.nonExistUserExIdCount}.` : ''));
		// nonExistShopExIds
		if (this.nonExistShopExIdCount) res.push(
			`Магазины с id "${this.nonExistShopExIds.join('", "')}" отсутствуют в базе, эти записи не будут добавлены.`
			+ (this.nonExistShopExIds.length < this.nonExistShopExIdCount
				? ` Выведены не все id магазинов, всего предупреждений: ${this.nonExistShopExIdCount}.` : ''));
		// emptyTitles
		if (this.emptyTitleCount) res.push(
			`${calcPeriods(this.emptyTitles)} не указано поле title, эти записи не будут добавлены.`
			+ (this.emptyTitles.length < this.emptyTitleCount
				? ` Выведены не все номера строк, всего предупреждений: ${this.emptyTitleCount}.` : ''));
		// wrongStatuses
		if (this.wrongStatusCount) res.push(
			`${calcPeriods(this.wrongStatuses)} неверно указано значение в поле status, эти записи не будут добавлены.`
			+ (this.wrongStatuses.length < this.wrongStatusCount
				? ` Выведены не все номера строк, всего предупреждений: ${this.wrongStatusCount}.` : ''));
		return res;
	}
}
