export function calcPeriods(arr: (string | number)[]) {
	if (arr.length === 1) {
		return `В строке ${arr[0]}`;
	}
	arr = Array.from(new Set(arr));
	arr.push('end');
	const res = arr.reduce((acc, i) => {
		if (i === 'end') {
			if (acc.first === null) {
				acc.items.push(acc.last);
			} else {
				acc.items.push(`${acc.first}-${acc.last}`);
			}
		} else if (acc.last === (i as number) - 1) {
			if (acc.first === null) {
				acc.first = acc.last;
			}
		} else if (acc.first !== null) {
			acc.items.push(`${acc.first}-${acc.last}`);
			acc.first = null;
		} else if (acc.last !== null) {
			acc.items.push(acc.last);
		}
		acc.last = i;
		return acc;
	}, {
		first: null,
		last: null,
		items: [],
	} as any);
	return `В строках [${res.items.join(', ')}]`;
}
