import { z } from 'zod';
import { fileDataItemSchema, FileTypeDataItem, FileTypeDataItemOptions } from '../../../meta';

export const diagnosticsFileGalleryMetaSchema = z.object({
	fileTypeMap: z.map(z.string(), fileDataItemSchema).optional(),
});

export interface DiagnosticsFileGalleryMetaOptions {
	fileTypeMap?: Record<string, FileTypeDataItem | FileTypeDataItemOptions>;
}

export class DiagnosticsFileGalleryMeta {
	readonly fileTypeMap?: Record<string, FileTypeDataItem>;

	constructor(options: DiagnosticsFileGalleryMetaOptions) {
		if (options.fileTypeMap !== undefined) {
			this.fileTypeMap = {};
			for(const [key, value] of Object.entries(options.fileTypeMap)) {
				if (value instanceof FileTypeDataItem) {
					this.fileTypeMap[key] = value;
				} else {
					this.fileTypeMap[key] = new FileTypeDataItem(value);
				}
			}
		}
		diagnosticsFileGalleryMetaSchema.parse(this);
	}
}