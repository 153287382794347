import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserRoles, PageInfo } from 'common-lib';
import { DataTableWithPageInfo, Spinner } from '~components';
import { getUsersList } from '~api/usersApi';
import { fillTableData, IFilledTableData, TABLE_HEADERS } from '../usersAttrs';
import { useLocation, useNavigate } from 'react-router-dom';

interface ITableDate {
	tableData: IFilledTableData;
	checkQueryString: string;
	isTableDataRequesting?: boolean;
}

export default function AdminUsersTablePage() {
	const [{ tableData, checkQueryString, isTableDataRequesting }, setTableData] = useState<ITableDate>({
		tableData: {
			rows: [],
			pageInfo: {
				pageCount: 0,
				pageIndex: 0,
				pageSize: 0,
				allCount: 0,
				orderColumn: '',
				orderDirection: 'desc'
			}
		},
		checkQueryString: '',
	});
	const meConfig = useSelector((state: any) => state.me.currentUser);

	const location = useLocation();
	const navigate = useNavigate();
	const pageInfo = PageInfo.parseFromString(location.search);
	pageInfo.meta = new Set(['username', 'shoproles']);

	if (!tableData || pageInfo.toQueryString() !== checkQueryString) {
		if (!isTableDataRequesting) {
			setTableData({ tableData, checkQueryString, isTableDataRequesting: true });
			getUsersList(pageInfo).then(json => {
				const newTableData = fillTableData(json);
				setTableData({
					tableData: newTableData,
					checkQueryString: pageInfo.toQueryString(),
				});
			});
		}
	}

	return (
		<>
			{tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="users"
					title="Пользователи"
					headers={TABLE_HEADERS}
					rows={tableData.rows}
					pageInfo={tableData.pageInfo}
					onClickItem={itemId => navigate(`${location.pathname}/${itemId}`)}
					onAddItem={[UserRoles.ADMIN, UserRoles.SUPPORT].includes(meConfig.role) ? () => {
						navigate(`${location.pathname}/+`);
					} : null}
					onChangePageOptions={options => {
						const pageInfo1 = PageInfo.parseFromString(location.search);
						pageInfo1.update(options);
						navigate(`${location.pathname}?${pageInfo1.toQueryString()}`);
					}}
				/>
			) : null}
			{isTableDataRequesting ? <Spinner /> : null}
		</>
	);
}
