import { IncidentType, IncidentTypeErrors } from '../IncidentType';
import { validateString } from '../../utils';

export function validateIncidentType(incidentType: IncidentType): IncidentTypeErrors {
	const errors: IncidentTypeErrors = {
		hasError: false, // будет перезаписано
		name: validateString(1, 100)(incidentType.name) || false,
		type: validateString(1, 50)(incidentType.type) || false,
		dataSeparator: validateString(1, 10)(incidentType.data.separator) || false,
		inputDataEmpty: incidentType.data.inputData.length === 0,
		cardTemplateEmpty: incidentType.data.cardTemplate.length === 0,
		solutionDataEmpty: incidentType.data.solutionData.length === 0,
		outputDataEmpty: incidentType.data.outputData.length === 0,
	}
	errors.hasError = Object.keys(errors).some(key => (errors as any)[key] !== false);
	return errors;
}
