import { z } from 'zod';
import { UserDataItem, UserDataItemOptions, userDataItemSchema } from '../../users';
import { ShopDataItem, ShopDataItemOptions, shopDataItemSchema } from '../../shops';
import { QuestExecToShopItem, QuestExecToShopItemOptions, questExecToShopItemSchema } from './QuestExecToShopItem';

export const questionnaireManagerStatsDataSchema = z.object({
	subordinateUsers: z.array(userDataItemSchema).optional(),
	subordinateShops: z.array(shopDataItemSchema).optional(),
	questExecToShops: z.array(questExecToShopItemSchema).optional(),
	isNotShopManager: z.boolean().optional(),
});

export interface QuestionnaireManagerStatsDataOptions {
	subordinateUsers?: (UserDataItem | UserDataItemOptions)[];
	subordinateShops?: (ShopDataItem | ShopDataItemOptions)[];
	questExecToShops?: (QuestExecToShopItem | QuestExecToShopItemOptions)[];
	isNotShopManager?: boolean;
}

export class QuestionnaireManagerStatsData {
	readonly subordinateUsers?: UserDataItem[];
	readonly subordinateShops?: ShopDataItem[];
	readonly questExecToShops?: QuestExecToShopItem[];
	readonly isNotShopManager?: boolean;

	constructor(options: QuestionnaireManagerStatsDataOptions) {
		if (options.subordinateUsers?.length) {
			this.subordinateUsers = [];
			for (const item of options.subordinateUsers) {
				if (item instanceof UserDataItem) {
					this.subordinateUsers.push(item);
				} else {
					this.subordinateUsers.push(new UserDataItem(item));
				}
			}
		}
		if (options.subordinateShops?.length) {
			this.subordinateShops = [];
			for (const item of options.subordinateShops) {
				if (item instanceof ShopDataItem) {
					this.subordinateShops.push(item);
				} else {
					this.subordinateShops.push(new ShopDataItem(item));
				}
			}
		}
		if (options.questExecToShops?.length) {
			this.questExecToShops = [];
			for (const item of options.questExecToShops) {
				if (item instanceof QuestExecToShopItem) {
					this.questExecToShops.push(item);
				} else {
					this.questExecToShops.push(new QuestExecToShopItem(item));
				}
			}
		}
		if (options.isNotShopManager !== undefined) this.isNotShopManager = options.isNotShopManager;
	}
}