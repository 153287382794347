import { z } from 'zod';
import { IncidentShopItem, IncidentShopItemOptions, incidentShopItemSchema } from './IncidentShopItem';
import { IncidentUserItem, IncidentUserItemOptions, incidentUserItemSchema } from './IncidentUserItem';


export const incidentManagerDataItemSchema = z.object({
	shops: incidentShopItemSchema.optional(),
	users: incidentUserItemSchema.optional(),
});

export interface IncidentManagerDataItemOptions {
	shops?: (IncidentShopItem | IncidentShopItemOptions)[];
	users?: (IncidentUserItem | IncidentUserItemOptions)[];
}

export class IncidentManagerDataItem {
	readonly shops?: IncidentShopItem[];
	readonly users?: IncidentUserItem[];

	constructor(options: IncidentManagerDataItemOptions) {
		if (options.shops?.length) {
			this.shops = [];
			for (const item of options.shops) {
				if (item instanceof IncidentShopItem) {
					this.shops.push(item);
				} else {
					this.shops.push(new IncidentShopItem(item));
				}
			}
		}
		if (options.users?.length) {
			this.users = [];
			for (const item of options.users) {
				if (item instanceof IncidentUserItem) {
					this.users.push(item);
				} else {
					this.users.push(new IncidentUserItem(item));
				}
			}
		}
		incidentManagerDataItemSchema.parse(this);
	}
}
