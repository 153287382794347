import { createReducer } from 'redux-act';
import {
  FETCH_INCIDENT_DATA,
  FETCH_INCIDENT_ITEM,
  FETCH_INCIDENT_TYPES,
} from '../actions/incidentActions';

const makeHashTable = o => o.reduce((acc, i) => {
  acc[i.id] = i;
  return acc;
}, {});

/**
 * Обновляет статистику по статусам для магазина и для каждого типа инцидентов
 */
function updateStats() {
  Object.keys(this.stats).forEach(key => this.stats[key] = 0); // reset shop.stats
  let shopAll = 0;
  this.incidentTypes.forEach((it) => {
    Object.keys(it.stats).forEach(key => it.stats[key] = 0); // reset incidentType.stats
    it.incidents.forEach((i) => {
      it.stats[i.state] = (it.stats[i.state] || 0) + 1;
      this.stats[i.state] = (this.stats[i.state] || 0) + 1;
      shopAll++;
    });
    it.stats.ALL = it.incidents.length;
  });
  // всего
  this.stats.ALL = shopAll;
}

export default createReducer(
  {
    [FETCH_INCIDENT_DATA]: (state, { shops, incidents, incidentTypes, goods }) => {
      // фильтр магазинов без инцидентов
      const shopIds = Array.from(new Set(incidents.map(i => i.shopId)));
      shops = shops.filter(s => shopIds.includes(s.id));
      // составление хешей для списков
      const shopMap = makeHashTable(shops);
      const incTypeMap = makeHashTable(incidentTypes);
      const goodMap = makeHashTable(goods);
      // парсинг объектов
      incidentTypes.forEach(it => it.data = JSON.parse(it.data));
      incidents.forEach((i) => {
        i.inputData = JSON.parse(i.inputData);
        i.report = JSON.parse(i.report);
      });
      // обогащение объектов: магазины -> типы инцидентов -> инциденты -> тотвары
      incidents.forEach((i) => {
        const shop = shopMap[i.shopId];
        if (!shop.incidentTypes) shop.incidentTypes = [];
        // поиск типа инцидента в магазине либо добавление нового типа инцидента в магазин
        let incidentType = shop.incidentTypes.find(it => it.id === i.incidentTypeId);
        if (!incidentType) {
          incidentType = Object.assign({ incidents: [] }, incTypeMap[i.incidentTypeId]);
          shop.incidentTypes.push(incidentType);
        }
        incidentType.incidents.push(i);
        delete i.shopId;
        delete i.incidentTypeId;
        i.good = goodMap[i.goodId];
        delete i.goodId;
      });
      // сортировка магазинов по алфавиту
      shops.sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1);
      // для каждого магазина добавляем метод updateStats для обновления статистики
      shops.forEach((shop) => {
        shop.stats = {};
        shop.incidentTypes.forEach(it => it.stats = {});
        shop.updateStats = updateStats.bind(shop);
        shop.updateStats();
      });
      return { ...state, shops };
    },
    [FETCH_INCIDENT_ITEM]: (state, newIncident) => {
      // надо просто обновить данные об инциденте в существующем списке
      if (state && state.shops && newIncident) {
        let incident = null;
        state.shops.some(s => s
          .incidentTypes.some(it => it
            .incidents.some((i) => {
              if (i.id === newIncident.id) {
                incident = i;
                return true;
              }
              return false;
            })));
        if (incident) {
          incident.state = newIncident.state;
          incident.report = JSON.parse(newIncident.report);
          // обновляем статусы
          state.shops.forEach(shop => shop.updateStats());
          return { ...state };
        }
      }
      // ничего не изменилось, возвращаем старый объект
      return state;
    },
    [FETCH_INCIDENT_TYPES]: (state, incidentTypes) => ({ ...state, incidentTypes }),
  },
  null,
);
