import React, { ReactNode } from 'react';
import styled from 'styled-components';
import UiBadge from './UiBadge';

const TitleStyle = styled.div`
	font-size: 22px;
	line-height: 25px;
	text-align: center;
	margin: 10px 0;
`;

const SubTitleStyle = styled.div`
	font-size: 16px;
	line-height: 16px;
	color: #9696A7;
	text-align: center;
`;

type Props = {
	title: string           // заголовок
	subTitle?: string       // подзаголовок
	children: ReactNode
}

export default function UiIncidentBadge(props: Props) {
	return (
		<UiBadge>
			<div className="title">
				<TitleStyle>{props.title}</TitleStyle>
				{props.subTitle ? (
					<SubTitleStyle>{props.subTitle}</SubTitleStyle>
				) : null}
			</div>
			{props.children}
		</UiBadge>
	);
}
