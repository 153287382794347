import React, { useState } from 'react';
import { Loader, Modal } from 'semantic-ui-react';
import { PageInfo } from 'common-lib';
import { getDashboardIncidentImportList } from '~api/dashboardApi';
import { DataTableWithPageInfo, Spinner } from '~components';
import { fillIncidentImportTableData, INCIDENT_IMPORTS_TABLE_HEADERS } from '../dashboardAttrs';
import { useLocation, useNavigate } from 'react-router-dom';

export default function DashboardIncidentImportsTablePage() {
	const [{ tableData, checkQueryString, isTableDataRequesting }, setTableData] = useState<any>({});
	const [{ isShowLogModal, showLogMessages }, setShowLogModal] = useState<any>({});

	const navigate = useNavigate();
	const location = useLocation();
	const pageInfo = PageInfo.parseFromString(location.search, { order: 'id:desc' });
	pageInfo.meta = new Set(['users', 'incidentTypes', 'files', 'logs']);

	if (!tableData || pageInfo.toQueryString() !== checkQueryString) {
		if (!isTableDataRequesting) {
			setTableData({ tableData, checkQueryString, isTableDataRequesting: true });
			getDashboardIncidentImportList(pageInfo).then((json: any) => {
				const newTableData = fillIncidentImportTableData(json);
				setTableData({
					tableData: newTableData,
					checkQueryString: pageInfo.toQueryString(),
				});
			});
		}
	}

	// список колбэков, будет использоваться при отрисовке строк
	const callbacks = {
		onShowLogModal: (row) => {
			setShowLogModal({
				isShowLogModal: true,
				showLogMessages: row.log.message.split('\n'),
			});
		},
	};

	const closeLogModal = () => setShowLogModal({ isShowLogModal: false });

	return (
		<>
			{tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="list layout"
					title="Статистика загрузки инцидентов"
					headers={INCIDENT_IMPORTS_TABLE_HEADERS}
					callbacks={callbacks}
					rows={tableData.rows}
					pageInfo={tableData.pageInfo}
					onChangePageOptions={options => {
						pageInfo.update(options);
						navigate(`${location.pathname}?${pageInfo.toQueryString()}`);
					}}
				/>
			) : null}
			{isTableDataRequesting
				? <Spinner />
				: isShowLogModal ? (
					<Modal open onClose={closeLogModal}>
						<Modal.Content>{showLogMessages.map((s, i) => <p key={i}>{s}</p>)}</Modal.Content>
					</Modal>
				) : null}
			{isTableDataRequesting ? <Loader /> : null}
		</>
	);
}
