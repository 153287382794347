import { z } from 'zod';
import { QuestionnaireDataItem, questionnaireDataItemSchema } from '../composite';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';

export const questionnaireOneWithBaseResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: questionnaireDataItemSchema,
	}),
);

export interface QuestionnaireOneResponseDtoOptions extends BaseResponseDtoOptions {
	data?: QuestionnaireDataItem;
}

export class QuestionnaireOneResponseDto extends BaseResponseDto {
	readonly data?: QuestionnaireDataItem;

	constructor(options: QuestionnaireOneResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) this.data = options.data;
		questionnaireOneWithBaseResponseDtoSchema.parse(this);
	}
}
