import { z } from 'zod';

export const questionnaireVisibleSchema = z.object({
	questionIndex: z.number().int(),
	answerIndex: z.number().int(),
});

export interface QuestionnaireVisibleOptions {
	questionIndex: number;
	answerIndex: number;
}

export class QuestionnaireVisible {
	readonly questionIndex: number;
	readonly answerIndex: number;

	constructor(options: QuestionnaireVisibleOptions) {
		this.questionIndex = options.questionIndex;
		this.answerIndex = options.answerIndex;
		questionnaireVisibleSchema.parse(this);
	}
}
