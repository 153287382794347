import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { InfoMessagesDataItem, InfoMessagesDataItemOptions, infoMessagesDataItemSchema } from '../composite';
import { InfoMessagesMeta, InfoMessagesMetaOptions, infoMessagesMetaSchema } from '../meta';

export const infoMessagesOneResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: infoMessagesDataItemSchema.optional(),
		meta: infoMessagesMetaSchema.optional(),
	}),
);

export interface InfoMessagesOneResponseDtoOptions extends BaseResponseDtoOptions {
	data?: InfoMessagesDataItem | InfoMessagesDataItemOptions;
	meta?: InfoMessagesMeta | InfoMessagesMetaOptions;
}

export class InfoMessagesOneResponseDto extends BaseResponseDto {
	readonly data?: InfoMessagesDataItem;
	readonly meta?: InfoMessagesMeta;

	constructor(options: InfoMessagesOneResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			if (options.data instanceof InfoMessagesDataItem) {
				this.data = options.data;
			} else {
				this.data = new InfoMessagesDataItem(options.data);
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof InfoMessagesMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new InfoMessagesMeta(options.meta);
			}
		}
		infoMessagesOneResponseDtoSchema.parse(this);
	}
}