import { z } from 'zod';
import { OptionsItem, OptionsItemOptions, optionsItemSchema } from '../../../meta';

export const questionnaireMetaSchema = z.object({
	options: z.map(z.string(), optionsItemSchema).optional(),
});

export interface QuestionnaireMetaOptions {
	options?: Record<string, OptionsItem | OptionsItemOptions>;
}

export class QuestionnaireMeta {
	readonly options?: Record<string, OptionsItem>;

	constructor(options: QuestionnaireMetaOptions) {
		if (options.options !== undefined) {
			this.options = {};
			for (const [key, value] of Object.entries(options.options)) {
				if (value instanceof OptionsItem) {
					this.options[key] = value;
				} else {
					this.options[key] = new OptionsItem(value);
				}
			}
		}
		questionnaireMetaSchema.parse(this);
	}
}
