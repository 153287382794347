import { z } from 'zod';

export const fileDataItemSchema = z.object({
	id: z.number().int(),
	name: z.string(),
	fileTypeId: z.number().int(),
	size: z.number().int(),
	downloadCount: z.number().int(),
});

export interface FileDataItemOptions {
	id: number;
	name: string;
	fileTypeId: number;
	size: number;
	downloadCount: number;
}

export class FileDataItem {
	readonly id: number;
	readonly name: string;
	readonly fileTypeId: number;
	readonly size: number;
	readonly downloadCount: number;

	constructor(options: FileDataItemOptions) {
		this.id = options.id;
		this.name = options.name;
		this.fileTypeId = options.fileTypeId;
		this.size = options.size;
		this.downloadCount = options.downloadCount;
		fileDataItemSchema.parse(this);
	}
}