import moment from 'moment';
import { ONE_DAY_MS } from '../enums';
import { declOfNum } from './string';

function twoDigits(d: number) {
	return (d >= 0 && d < 10) ? `0${d}` : d.toString();
}

/**
 * Формирует строку в формате SQL Date
 */
export function toSqlMonth(date: Date): string {
	return date.getFullYear() + '-' + twoDigits(1 + date.getMonth());
}

/**
 * Формирует строку в формате SQL Date
 */
export function toSqlDate(date: Date): string {
	return moment(date).format('YYYY-MM-DD');
}

/**
 * Формирует строку в формате SQL DateTime
 */
export function toSqlDateTime(date: Date): string {
	return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * Формирует строку в формате SQL Date по поясу UTC
 */
export function toUTCSqlDate(date: Date): string {
	return moment(date).utcOffset(0).format('YYYY-MM-DD');
}

/**
 * Формирует строку в формате SQL DateTime по поясу UTC
 */
export function toUTCSqlDateTime(date: Date): string {
	return moment(date).utcOffset(0).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * Формирует строку в человеческом формате
 */
export function toHumanDate(date: Date): string {
	return moment(date).format('DD.MM.YYYY');
}

export function toHumanTime(date: Date):string {
	return moment(date).format('HH:mm:ss');
}

const SQL_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/;

/**
 * Проверяет формат даты YYYY-MM-DD
 */
export function checkSqlDate(dateStr: string): boolean {
	if (!dateStr) return false;
	const matches = dateStr.match(SQL_DATE_REGEX);
	if (!matches) return false;
	const y = +matches[1];
	const m = +matches[2];
	const d = +matches[3];
	if (y < 0 || d < 1 || m < 1 || m > 12) return false;
	if ([1, 3, 5, 7, 8, 10, 12].includes(m) && d <= 31) return true;
	if ([4, 6, 9, 11].includes(m) && d <= 30) return true;
	return (d <= 28 || (d <= 29 && !(y % 4)));
}

const SQL_TIME_REGEX = /^(\d{2}):(\d{2}):?(\d{2})?\.?(\d{1,3})?$/;

/**
 * Проверяет формат времени hh:mm[:ss][.sss]
 */
export function checkSqlTime(timeStr:string): boolean {
	if (!timeStr) return false;
	const matches = timeStr.match(SQL_TIME_REGEX);
	if (!matches) return false;
	const h = +matches[1];
	const m = +matches[2];
	const s = +matches[3];
	return h >= 0 && h < 24 && m >= 0 && m < 60 && (Number.isNaN(s) || (s >= 0 && s < 60));
}

const SQL_MONTH_REGEX = /^(\d{4})-(\d{2})$/;

/**
 * Проверяет формат даты для месяца YYYY-MM
 */
export function checkSqlMonth(monthStr: string): boolean {
	if (!monthStr) return false;
	const matches = monthStr.match(SQL_MONTH_REGEX);
	if (!matches) return false;
	const y = +matches[1];
	const m = +matches[2];
	return (y >= 0 && m > 0 && m < 13);
}

const HUMAN_DATE_REGEX = /^(\d{2})\.(\d{2})\.(\d{4})$/;

/**
 * Проверяет формат даты DD.MM.YYYY
 */
export function checkHumanDate(dateStr: string): boolean {
	if (!dateStr) return false;
	const matches = dateStr.match(HUMAN_DATE_REGEX);
	if (!matches) return false;
	const d = +matches[1];
	const m = +matches[2];
	const y = +matches[3];
	if (y < 0 || d < 1 || m < 1 || m > 12) return false;
	if ([1, 3, 5, 7, 8, 10, 12].includes(m) && d <= 31) return true;
	if ([4, 6, 9, 11].includes(m) && d <= 30) return true;
	return (d <= 28 || (d <= 29 && !(y % 4)));
}

/**
 * Выполняет смену времени для указанного часового пояса.
 * @param date
 * @param timeZoneOffset
 * @param hours
 * @param minutes
 * @param seconds
 * @param ms
 * @returns {boolean|Date}
 */
export function dateSetTimeForTimeZone(date: Date, timeZoneOffset: number, hours: number, minutes: number, seconds: number, ms: number): Date {
	const date1 = new Date(date.getTime());
	if (Number.isFinite(hours)) date1.setUTCHours(hours);
	if (Number.isFinite(minutes)) date1.setUTCMinutes(minutes);
	if (Number.isFinite(seconds)) date1.setUTCSeconds(seconds);
	if (Number.isFinite(ms)) date1.setUTCMilliseconds(ms);
	return new Date(date1.getTime() - timeZoneOffset * 60e3);
}

/**
 * Добавляет к дате указанное количество дней.
 * @param date
 * @param addDays
 */
export function dateAddDays(date: Date, addDays: number): Date | false {
	if (!date) return false;
	if (!Number.isFinite(addDays)) return false;
	const date1 = new Date(date.getTime());
	date1.setUTCDate(date1.getUTCDate() + addDays);
	return date1;
}

export function fromHumanDateToSqlDate(dateStr: string): string | false {
	if (!dateStr) return false;
	const matches = dateStr.match(HUMAN_DATE_REGEX);
	if (!matches) return false;
	return `${matches[3]}-${matches[2]}-${matches[1]}`;
}

export function fromSqlDateToHumanDate(dateStr: string): string  | false {
	if (!dateStr) return false;
	const matches = dateStr.match(SQL_DATE_REGEX);
	if (!matches) return false;
	return `${matches[3]}.${matches[2]}.${matches[1]}`;
}

/**
 * Переводит Date в формате Excel в Date.
 * Пример: 44394 -> 2021-07-17
 * @param excelDay
 * @returns {Date}
 */
export function fromExcelDateToDate(excelDay: number): Date {
	return new Date((excelDay - 25569) * ONE_DAY_MS);
}

export function dateToHumanDate(date: moment.Moment): string {
	return date.format('DD.MM.YYYY');
}

/**
 * Приведет длительность к строке
 * ex: "41 минута", "1 день 1 минута", "меньше минуты"
 * @param minutes
 */
export function timeLengthToStr(minutes: number): string {
	const time: string[] = [];
	if (minutes < 1) {
		time.push(`меньше минуты`);
	}
	if (minutes > 24 * 60) {
		const days = Math.floor(minutes / 1440);
		minutes = minutes % 1440;
		time.push(`${days} ${declOfNum(days, ['день', 'дня', 'дней'])}`);
	}
	if (minutes > 60) {
		const hours = Math.floor(minutes / 60);
		minutes = minutes % 60;
		time.push(`${hours} ${declOfNum(hours, ['час', 'часа', 'часов'])}`);
	}
	if (minutes > 0) {
		const mins = Math.floor(minutes);
		if (mins > 0) {
			time.push(`${mins} ${declOfNum(mins, ['минута', 'минуты', 'минут'])}`);
		}
	}
	return time.join(' ');
}

/**
 * Вернет текстом сколько прошло времени между датами
 * @param date1
 * @param date2
 */
export function lengthBetweenDates(date1: Date, date2: Date): string {
	const minutes = Math.abs(date1.getTime() - date2.getTime()) / 60_000;
	return timeLengthToStr(minutes);
}
