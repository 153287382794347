import { z } from 'zod';
import { QuestionnaireExecutionStatusEnum, QuestionnaireTypeEnum } from 'common-lib';

export const questionnaireRunningItemSchema = z.object({
	id: z.number().int(),
	title: z.string(),
	type: z.nativeEnum(QuestionnaireTypeEnum),
	status: z.nativeEnum(QuestionnaireExecutionStatusEnum),
	finishedCount: z.number().int(),
	questionCount: z.number().int(),
	repeatCount: z.number().int(),
	startAt: z.string(),
	finishAt: z.string(),
});

export interface QuestionnaireRunningItemOptions {
	id: number;
	title: string;
	type: QuestionnaireTypeEnum;
	status: QuestionnaireExecutionStatusEnum;
	finishedCount: number;
	questionCount: number;
	repeatCount: number;
	startAt: string;
	finishAt: string;
}

export class QuestionnaireRunningItem {
	readonly id: number;
	readonly title: string;
	readonly type: QuestionnaireTypeEnum;
	readonly status: QuestionnaireExecutionStatusEnum;
	readonly finishedCount: number;
	readonly questionCount: number;
	readonly repeatCount: number;
	readonly startAt: string;
	readonly finishAt: string;

	constructor(options: QuestionnaireRunningItemOptions) {
		this.id = options.id;
		this.title = options.title;
		this.type = options.type;
		this.status = options.status;
		this.finishedCount = options.finishedCount;
		this.questionCount = options.questionCount;
		this.repeatCount = options.repeatCount;
		this.startAt = options.startAt;
		this.finishAt = options.finishAt;
		questionnaireRunningItemSchema.parse(this);
	}
}