import { z } from 'zod';

export const pageInfoDtoSchema = z.object({
	allCount: z.number(),
	orderColumn: z.string(),
	orderDirection: z.enum(['asc', 'desc']),
	pageCount: z.number(),
	pageIndex: z.number(),
	pageSize: z.number(),
});

interface PageInfoResponseDtoOptions {
	allCount: number;
	orderColumn: string;
	orderDirection: 'asc' | 'desc';
	pageCount: number;
	pageIndex: number;
	pageSize: number;
}

export class PageInfoDto
{
	allCount: number;
	orderColumn: string;
	orderDirection: 'asc' | 'desc';
	pageCount: number;
	pageIndex: number;
	pageSize: number;

	constructor(options: PageInfoResponseDtoOptions) {
		this.allCount = options.allCount;
		this.orderColumn = options.orderColumn;
		this.orderDirection = options.orderDirection;
		this.pageCount = options.pageCount;
		this.pageIndex = options.pageIndex;
		this.pageSize = options.pageSize;
		pageInfoDtoSchema.parse(this);
	}
}