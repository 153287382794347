import { z } from 'zod';

export const incidentTypeMapItemSchema = z.object({
	id: z.number().int(),
	name: z.string(),
	type: z.string(),
	data: z.string(),
	version: z.number().int(),
	updatedAt: z.instanceof(Date),
});

export interface IncidentTypeMapItemOptions {
	id: number;
	name: string;
	type: string;
	data: string; // todo JSON
	version: number;
	updatedAt: Date | string;
}

export class IncidentTypeMapItem {
	readonly id: number;
	readonly name: string;
	readonly type: string;
	readonly data: string; // todo JSON
	readonly version: number;
	readonly updatedAt: Date;

	constructor(options: IncidentTypeMapItemOptions) {
		this.id = options.id;
		this.name = options.name;
		this.type = options.type;
		this.data = options.data;
		this.version = options.version;
		if (options.updatedAt instanceof Date) {
			this.updatedAt = options.updatedAt;
		} else {
			this.updatedAt = new Date(options.updatedAt);
		}
		incidentTypeMapItemSchema.parse(this);
	}
}
