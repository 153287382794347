/**
 * Копирует поля из одного объекта в другой
 * @param from
 * @param to
 * @param keys
 */
export function copyFields(from: any, to: any, keys: string[]): any {
	for (const key of keys) {
		if (from[key] !== undefined) to[key] = from[key];
	}
	return to;
}
