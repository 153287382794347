import { z } from 'zod';

export const incidentShopItemSchema = z.object({
	shopId: z.number().int(),
	allCount: z.number().int(),
	completed: z.number().int(),
});

export interface IncidentShopItemOptions {
	shopId: number;
	allCount: number;
	completed: number;
}

export class IncidentShopItem {
	readonly shopId: number;
	readonly allCount: number;
	readonly completed: number;

	constructor(options: IncidentShopItemOptions) {
		this.shopId = options.shopId;
		this.allCount = options.allCount;
		this.completed = options.completed;
		incidentShopItemSchema.parse(this);
	}
}
