import { Action } from 'redux';
import { createAction } from 'redux-act';
import { UserRoles } from 'common-lib';
import fetchBackend from '~tools/fetchBackend';

type MePayload = {
	isRequesting: boolean,
	config?: {
		role: UserRoles,
		fullName: string,
	},
}

export const FETCH_ME_CONFIG = createAction<MePayload>('FETCH_ME_CONFIG');

export const fetchMeConfig = () => (dispatch: (action: Action) => void) => {
	return fetchBackend('get', 'me/config')
		.then((res: any) => res?.json())
		.then(data => dispatch(FETCH_ME_CONFIG(data)));
};

export const fetchMeDemoConfig = () => (dispatch) => {
	dispatch(FETCH_ME_CONFIG({
		isRequesting: false,
		config: {
			role: UserRoles.MANAGER,
			fullName: 'Ивано Иван Иванович',
		},
	}));
};
