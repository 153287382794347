import React, { ReactNode } from 'react';

export interface Props {
	children: ReactNode;
	maxSquareSideWidth: number;
	onSave: (data: string) => void;
	onCancel: () => void;
}

export function PhotoShooterV1(props: Props) {
	const inputElement = React.useRef<HTMLInputElement>(null);

	function handleClick() {
		inputElement.current!.click();
	}

	function onChange() {
		if (!inputElement.current) throw new Error('inputRef not inited');
		if (inputElement.current.files?.[0]) {
			const reader = new FileReader(); // загружаем картинку через ридер
			reader.onload = e => {
				const image = document.createElement('img'); // рисуем файлик в <img/>
				image.onload = () => {
					const { width: imw, height: imh } = image;
					const squareSize = Math.min(imw, imh); // будем обрезать картинку до квадрата
					const dx = Math.abs(imw - squareSize) / 2;
					const dy = Math.abs(imh - squareSize) / 2;
					const distSquareSize = Math.min(squareSize, props.maxSquareSideWidth); // макс размер картинки
					const canvas = document.createElement('canvas');
					canvas.width = distSquareSize;
					canvas.height = distSquareSize;
					const context = canvas.getContext('2d')!!; // скейлим и рисуем картинку на <canvas/>
					context.drawImage(image, dx, dy, squareSize, squareSize, 0, 0, distSquareSize, distSquareSize);
					const data = canvas.toDataURL('image/png');
					props.onSave(data);
				};
				image.setAttribute('src', e.target?.result as string);
			};
			reader.readAsDataURL(inputElement.current.files[0]);
		}
	}

	return <>
		<div onClick={handleClick}>
			{props.children}
		</div>
		<input ref={inputElement}
		       className="file-input"
		       type="file"
		       accept="image/*"
		       capture="user"
		       style={{ cursor: 'pointer', position: 'absolute', top: '-9999px' }}
		       onChange={onChange}
		/>
	</>;
}
