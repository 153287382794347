import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CSSProp } from 'styled-components/native/dist';

export const PreloadingImageStyled = styled.div`
	width: 60px;
	height: 60px;
	border: 1px solid #BFBFBF;
	border-radius: 16px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
	position: relative;
	background-image: url(${p => p.src});

	&.loading {
		background-image: none;

		&::after {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
			content: "";
			background-image: url(/assets/img/user-photo/preload.svg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			animation-name: spin;
			animation-duration: 3000ms;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}

		@keyframes spin {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
	}

	&.load-error {
		background-image: url(/assets/img/user-photo/load-error.svg);
	}
`;

interface Props
{
	children?: any;
	src: string;
	style?: CSSProp;
	onClick?: () => void;
}

export default function PreloaderImage(props: Props) {
	const [isLoading, setLoading] = useState(true);
	const [isLoadError, setLoadError] = useState(false);

	useEffect(() => {
		const img = document.createElement('img');
		img.style.setProperty('display', 'none');
		img.src = props.src;
		img.onload = () => {
			setLoading(false);
			document.body.removeChild(img);
		};
		img.onerror = () => {
			setLoading(false);
			setLoadError(true);
			document.body.removeChild(img);
		};
		document.body.appendChild(img);

	}, []);

	return (
		<PreloadingImageStyled
			className={isLoading ? 'loading' : isLoadError ? 'load-error' : undefined}
			style={props.style}
			src={props.src}
			onClick={props.onClick}>
			{props.children}
		</PreloadingImageStyled>
	);
}
