import React from 'react';
import styled from 'styled-components';
import { getRoleName } from 'common-lib';
import { UiFaceProgressBadge } from '~components';
import { UserTree, UserTreeShop } from '../incidentsAttrs';

type Props = {
	data: UserTree
}

type CalcStat = {
	all: number
	completed: number
	count: number
	percent: number
}

const StatsStyled = styled.div`
  margin-bottom: 12px;
  color: #333;
`;

export default function ManagerIncidentListView(props: Props) {
	const stat: CalcStat = calc(props.data.users, props.data.shops);
	console.log('Статы страницы:', stat);
	return <>
		{/*{stat.count ? (
			<StatsStyled>
				Инцидентов: {stat.all}.
				Выполнено: {stat.completed}{stat.percent ? ` (${stat.percent.toFixed(stat.percent < 10 ? 1 : 0)}%)` : null}.
			</StatsStyled>
		) : null}*/}
		{props.data.users?.map((userTree: UserTree) => {
			const { all, completed, user } = userTree;
			return <UiFaceProgressBadge
				key={user.id}
				faceFullName={user.fullName}
				title={user.fullName}
				subTitle={user.shopManagerRole?.shortTitle || getRoleName(user.role)}
				progress={{ value: completed, max: all }}
				linkTo={`/incidents/${btoa(String(user.id))}`}
			/>
		})}
		{props.data.shops?.map((shopStat: UserTreeShop) => {
			const { all, completed, shop } = shopStat;
			return <UiFaceProgressBadge
				key={shop.id}
				faceFullName={shop.city}
				title={shop.address}
				subTitle={shop.city}
				progress={{ value: completed, max: all }}
				linkTo={`/incidents/shop/${btoa(String(shop.id))}`}
			/>
		})}
	</>;
}

function calc(users?: UserTree[], shops?: UserTreeShop[]): CalcStat {
	const stat: CalcStat = {
		all: 0,
		completed: 0,
		count: (users?.length || 0) + (shops?.length || 0),
		percent: 0,
	};
	users?.forEach((user => {
		stat.all += user.all;
		stat.completed += user.completed;
	}));
	shops?.forEach((shop => {
		stat.all += shop.all;
		stat.completed += shop.completed;
	}));
	stat.percent = stat.all ? (stat.completed / stat.all * 100) : 0;
	return stat;
}
