export const mainTheme = {
	containerBg: '#E4E6EB',
	headerBg: '#2C2D4F',
	headerColor: '#FFF',
	headerFontSize: '24px',
	breadCrumbsBg: '#FFF',
	breadCrumbsActiveBg: '#2C2D4F',
	breadCrumbsActiveColor: '#FFF',
	badgeButtonBg: '#E4E5FB',
	badgeButtonColor: '#9596B9',
	badgeButtonHoverBg: '#dcddfc',
	badgeButtonHoverColor: '#8e8fb7',
	badgeButtonActiveBg: '#7A80EE',
	badgeButtonActiveColor: '#FFF',
	badgeActionButtonBg: '#2C2E4E',
	badgeActionButtonColor: '#FFF',
	badgeActionButtonHoverBg: '#1e1f36',
	badgeActionButtonHoverColor: '#FFF',
	buttonBg: '#BFBFC7',
	buttonColor: '#FFF',
	buttonAcceptBg: '#7B7FEF',
	buttonAcceptHoverBg: '#6B6FDF',
	buttonAcceptDisabledBg: '#a2a4d9',
	buttonDeclineBg: '#D67C7D',
	buttonDeclineHoverBg: '#C66C6D',
	buttonDeclineDisabledBg: '#CE9697',
	warnBg: '#FCF8C7',
	warnBorderColor: '#F3D82D',
};
