import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	DashboardIncidentImportDataItem,
	DashboardIncidentImportDataItemOptions,
	dashboardIncidentImportDataItemSchema
} from '../composite';
import {
	DashboardIncidentMeta,
	DashboardIncidentMetaOptions,
	dashboardIncidentMetaSchema
} from '../meta';

export  const dashboardIncidentImportListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(dashboardIncidentImportDataItemSchema).optional(),
		meta: dashboardIncidentMetaSchema.optional(),
	}),
);
export interface DashboardIncidentImportListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (DashboardIncidentImportDataItem | DashboardIncidentImportDataItemOptions)[];
	meta?: DashboardIncidentMeta | DashboardIncidentMetaOptions;
}

export class DashboardIncidentImportListResponseDto extends BaseResponseDto {
	readonly data?: DashboardIncidentImportDataItem[];
	readonly meta?: DashboardIncidentMeta;

	constructor(options: DashboardIncidentImportListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof DashboardIncidentImportDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new DashboardIncidentImportDataItem(item));
				}
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof DashboardIncidentMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new DashboardIncidentMeta(options.meta);
			}
		}
		dashboardIncidentImportListResponseDtoSchema.parse(this);
	}
}