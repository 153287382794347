import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { ShopDataItem, ShopDataItemOptions, shopDataItemSchema } from '../composite';
import { ShopMeta, ShopMetaOptions, shopMetaSchema } from '../meta';

export const shopWithBaseResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(shopDataItemSchema).optional(),
		meta: shopMetaSchema.optional(),
	}),
);

export interface ShopListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (ShopDataItem | ShopDataItemOptions)[];
	meta?: ShopMeta | ShopMetaOptions;
}

export class ShopListResponseDto extends BaseResponseDto {
	readonly data?: ShopDataItem[];
	readonly meta?: ShopMeta;

	constructor(options: ShopListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof ShopDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new ShopDataItem(item));
				}
			}
		}
		if (options.meta instanceof ShopMeta) {
			this.meta = options.meta;
		} else if (options.meta !== undefined) {
			this.meta = new ShopMeta(options.meta);
		}
		shopWithBaseResponseDtoSchema.parse(this);
	}
}
