import React from 'react';
import { getRoleName } from 'common-lib';
import { UiFaceProgressBadge } from '~components';

type Props = {
	data: any
}

export default function ManagerTaskListView(props: Props) {
	return <>
		{props.data.users?.map(userTree => {
			const { all, completed, user } = userTree;
			return <UiFaceProgressBadge
				key={user.id}
				faceFullName={user.fullName}
				title={user.fullName}
				subTitle={user.shopManagerRole?.shortTitle || getRoleName(user.role)}
				progress={{ value: completed, max: all }}
				linkTo={`/tasks/${btoa(user.id)}`}
			/>;
		})}
		{props.data.shops?.map(shopTree => {
			const { all, completed, shop } = shopTree;
			return <UiFaceProgressBadge
				key={shop.id}
				faceFullName={shop.city}
				title={shop.address}
				subTitle={shop.city}
				progress={{ value: completed, max: all }}
				linkTo={`/tasks/shop/${btoa(shop.id)}`}
			/>;
		})}
	</>;
}
