import React, { useState } from 'react';
import { Loader, Modal } from 'semantic-ui-react';
import JSZip from 'jszip';
import { PageInfo } from 'common-lib';
import { getDashboardStructureImportList } from '~api/dashboardApi';
import { DataTableWithPageInfo, Spinner } from '~components';
import { fillStructureImportTableData, STRUCTURE_IMPORTS_TABLE_HEADERS } from '../dashboardAttrs';
import { useLocation, useNavigate } from 'react-router-dom';

export default function DashboardStructureImportsTablePage() {
	const [{
		tableData,
		checkQueryString,
		isTableDataRequesting,
	}, setTableData] = useState<any>({});
	const [{ isShowLogModal, showLogMessages }, setShowLogModal] = useState<any>({});
	const [{
		isShowImportFileModal,
		showImportFileContent,
		showImportFileForId,
		isFileImportRequesting,
	}, setShowImportFileModal] = useState<any>({});

	const navigate = useNavigate();
	const location = useLocation();
	const pageInfo = PageInfo.parseFromString(location.search, { order: 'id:desc' });
	pageInfo.meta = new Set(['users', 'files', 'logs']);

	if (!tableData || pageInfo.toQueryString() !== checkQueryString) {
		if (!isTableDataRequesting) {
			setTableData({ tableData, checkQueryString, isTableDataRequesting: true });
			getDashboardStructureImportList(pageInfo).then((json: any) => {
				const newTableData = fillStructureImportTableData(json);
				setTableData({
					tableData: newTableData,
					checkQueryString: pageInfo.toQueryString(),
				});
			});
		}
	}

	// список колбэков, будет использоваться при отрисовке строк
	const callbacks = {
		onShowLogModal: (row) => {
			setShowLogModal({
				isShowLogModal: true,
				showLogMessages: row.log.message.split('\n'),
			});
		},
		onShowImportFileModal: (row) => {
			// загружаем файл импорт, разархивируем и отображаем
			setShowImportFileModal({ isFileImportRequesting: true });
			fetch(`/api/file/download/${row.fileId}`)
				.then(response => response.blob())
				.then(blob => {
					console.log('Скачан файл:', blob);
					if (blob.size) {
						return getZipper().loadAsync(blob);
					} else {
						return null;
					}
				})
				.then((jsZip: JSZip | null) => {
					console.log('Разархивирован файл:', jsZip);
					if (jsZip) {
						return jsZip
							.file(Object.keys(jsZip.files)[0])
							?.async('string')
							.then(fileContent => {
								console.log('Еще чтото:', fileContent);
								setShowImportFileModal({
									isShowImportFileModal: true,
									showImportFileContent: JSON.stringify(JSON.parse(fileContent || ''), null, '  '),
									showImportFileForId: row.id,
								});
							});
					} else {
						setShowImportFileModal({
							isShowImportFileModal: true,
							showImportFileContent: 'Не удалось скачать файл. Файл отсутствует на сервере или произошла ошибка сети.',
							showImportFileForId: undefined,
						});
					}
				});
		},
	};

	const closeLogModal = () => setShowLogModal({ isShowLogModal: false });

	const closeImportFileModal = () => setShowImportFileModal({ isShowImportFileModal: false });

	return (
		<>
			{tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="users"
					title="История импорта структуры"
					headers={STRUCTURE_IMPORTS_TABLE_HEADERS}
					callbacks={callbacks}
					rows={tableData.rows}
					pageInfo={tableData.pageInfo}
					onChangePageOptions={options => {
						pageInfo.update(options);
						navigate(`${location.pathname}?${pageInfo.toQueryString()}`);
					}}
				/>
			) : null}
			{isTableDataRequesting
				? <Spinner />
				: isShowLogModal ? (
					<Modal open closeIcon onClose={closeLogModal}>
						<Modal.Content>{showLogMessages.map((s, i) => <p
							key={i}>{s}</p>)}</Modal.Content>
					</Modal>
				) : isShowImportFileModal ? (
					<Modal open closeIcon onClose={closeImportFileModal}>
						<Modal.Header>
							<h2>Просмотр файла (номер импорта {showImportFileForId})</h2>
						</Modal.Header>
						<Modal.Content>
							<pre>{showImportFileContent}</pre>
						</Modal.Content>
					</Modal>
				) : null}
			{isTableDataRequesting || isFileImportRequesting ? <Loader /> : null}
		</>
	);
}

const getZipper = () => {
	return new JSZip();
};
