import { z } from 'zod';
import { ShopDataItem, ShopDataItemOptions, shopDataItemSchema } from '../../shops';
import { TreeSubordinateUserDto, treeSubordinateUserDtoSchema } from './TreeSubordinateUserDto';


export const treeSubordinateShopDtoSchema = shopDataItemSchema.merge(
	z.object({
		supervisorUser: treeSubordinateUserDtoSchema.optional(),
	}),
);

export interface TreeSubordinateShopDtoOptions extends ShopDataItemOptions {
	supervisorUser?: TreeSubordinateUserDto;
}

export class TreeSubordinateShopDto extends ShopDataItem {
	readonly supervisorUser?: TreeSubordinateUserDto;

	constructor(options: TreeSubordinateShopDtoOptions) {
		super(options);
		if (options.supervisorUser instanceof TreeSubordinateUserDto) {
			this.supervisorUser = options.supervisorUser;
		} else if (options.supervisorUser !== undefined) {
			this.supervisorUser = new TreeSubordinateUserDto(options.supervisorUser);
		}
		treeSubordinateShopDtoSchema.parse(this);
	}
}