import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { IncidentMeta, IncidentMetaOptions, incidentMetaSchema } from '../meta';
import {
	IncidentGoodListDataItem,
	IncidentGoodListDataItemOptions,
	incidentGoodListDataItemSchema
} from '../composite';

export const incidentGoodListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(incidentGoodListDataItemSchema).optional(),
		meta: incidentMetaSchema.optional(),
	}),
);

export interface IncidentGoodListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (IncidentGoodListDataItem | IncidentGoodListDataItemOptions)[];
	meta?: IncidentMeta | IncidentMetaOptions;
}

export class IncidentGoodListResponseDto extends BaseResponseDto {
	readonly data?: IncidentGoodListDataItem[];
	readonly meta?: IncidentMeta;

	constructor(options: IncidentGoodListResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof IncidentGoodListDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new IncidentGoodListDataItem(item));
				}
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof IncidentMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new IncidentMeta(options.meta);
			}
		}
		incidentGoodListResponseDtoSchema.parse(this);
	}
}
