import { z } from 'zod';

export const taskPhotoItemSchema = z.object({
	data: z.string().optional(),
	isNew: z.boolean().optional(),
});

export interface TaskPhotoItemOptions {
	data?: string;
	isNew?: boolean;
}

export class TaskPhotoItem {
	readonly data?: string;
	readonly isNew?: boolean;

	constructor(options: TaskPhotoItemOptions) {
		if (options.data !== undefined) this.data = options.data;
		if (options.isNew !== undefined) this.isNew = options.isNew;
		taskPhotoItemSchema.parse(this);
	}
}