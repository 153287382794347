import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';
import * as QuizApi from '~api/quizApi';
import { TRANSPARENT_1PX } from './images';
import { BreadCrumbsStyled, ContainerStyled, ContentStyled, HamburgerContentDimmerStyled,
	HamburgerContentStyled, HeaderBackArrowImg, HeaderHamburgerImg,
	HeaderStyled, PlusButtonStyled } from './uiCountainerStyles';
import { FiltersWizardOption, SearchAndFiltersWizard, SearchWizardOptions } from '~components/ui/SearchAndFiltersWizard';
import { Icon, Popup } from 'semantic-ui-react';

/*
 * UiContainer component
 */

export default function UiContainer(props: { children: ReactNode }) {
	return (
		<ContainerStyled>
			{props.children}
		</ContainerStyled>
	);
}

/*
 * UiContainerHeader component
 */

type HeaderProps = {
	title: string;               // заголовок
	isPreview?: boolean;         // режим просмотра
	subTitle?: string;           // подзаголовок
	backLinkTo?: string;         // ссылка для стрелки назад (onBackClick будет пропущен)
	onBackClick?: Function;      // обработчик клика по стрелке назад (только если backLinkTo не указан)
	hamburger?: boolean;         // отображать гамбургер
	isHamburgerDoted?: boolean;  // отображать гамбургер с точкой
	onHamburgerClick?: Function; // обработчик клика по гамбургеру
	copyLink?: boolean;          // отображение копирования ссылки
	onCopyLink?: () => void;     // обработчик клика по ссылке
	backToSubShop?: string;      // для перехода к опросам подчиненного магазина
};

UiContainer.Header = function UiContainerHeader(props: HeaderProps) {
	const [isOpen, setOpen] = useState<boolean>(false);

	async function checkQuizAvailability(): Promise<boolean> {
		return await QuizApi.fetchRunningQuizList().then(res => {
			return !!res.data?.length;
		}).catch(() => {
			return false;
		});
	}

	// Открытие попапа по времени 500 мс
	function onOpenPopup() {
		setOpen(true);
		setTimeout(() => setOpen(false), 500);
	}

	return (
		<>
			<HeaderStyled titleLength={props.title?.length}>
				<table>
					<tbody>
						<tr>
							{props.backLinkTo ? (
								<td className="backArrow">
									<Link to={props.backLinkTo}>
										<HeaderBackArrowImg src={TRANSPARENT_1PX} />
									</Link>
								</td>
							) : props.onBackClick ? (
								<td className="backArrow">
									<HeaderBackArrowImg src={TRANSPARENT_1PX}
									                    onClick={props.onBackClick} />
								</td>
							) : props.backToSubShop ? (
								<td className="backArrow">
									<a href={props.backToSubShop}>
										<HeaderBackArrowImg src={TRANSPARENT_1PX} />
									</a>
								</td>
							) : null}
							<td className="title">
								<div>
									{props.title}
									{props.subTitle ?
										<div className="subTitle">{props.subTitle}</div> : null}
								</div>
							</td>
							{props.hamburger ? (
								<td className="hamburger">
									<HeaderHamburgerImg
										className={props.isHamburgerDoted ? 'notified' : undefined}
										src={TRANSPARENT_1PX}
										onClick={props.onHamburgerClick}
									/>
								</td>
							) : null}
							{props.copyLink ? (
								<td className="copy_link">
									<Popup content="Скопировано" on="click" open={isOpen} onOpen={onOpenPopup} position="bottom right" pinned trigger={(
										<div onClick={props.onCopyLink}>
											<Icon name="copy" />
											<p>Копировать ссылку</p>
										</div>
									)} />
								</td>
							) : null}
						</tr>
					</tbody>
				</table>
				<div className="left corner" />
				<div className="right corner" />
			</HeaderStyled>
		</>
	);
};

/*
 * UiContainerContent component
 */

interface ContentProps
{
	children: ReactNode;
	breadCrumbs?: UiContainerContentBreadCrumbs; // хлебные крошки
	onPlusButtonClick?: () => void;
	searchOptions?: SearchWizardOptions;
	filtersOptions?: FiltersWizardOption[];
}

export type UiContainerContentBreadCrumbs = UiContainerContentBreadCrumb[];

export type UiContainerContentBreadCrumb = {
	title: string   // заголовок
	linkTo?: string // ссылка для перехода
};

type BreadCrunbsDivRef = React.MutableRefObject<HTMLDivElement | undefined>;

UiContainer.Content = function UiContainerContent(props: ContentProps) {
	const ref: BreadCrunbsDivRef = useRef<HTMLDivElement>();
	useEffect(() => {
		scrollBreadCrumbs(300);
	});

	function scrollBreadCrumbs(ms) {
		setTimeout(() => {
			const current = ref.current;
			if (current) {
				current.scroll({ top: 0, left: current.scrollWidth * 2, behavior: 'smooth' });
			}
		}, ms);
	}

	return (
		<ContentStyled>
			<div className="content-box">
				{props.breadCrumbs?.length ? renderBreadCrumbs(props.breadCrumbs, ref) : null}
				{props.children}
			</div>
			{props.onPlusButtonClick ? (
				<UiContainer.PlusButton onClick={props.onPlusButtonClick} />
			) : null}
			{props.searchOptions || props.filtersOptions ? (
				<SearchAndFiltersWizard
					searchOptions={props.searchOptions}
					filterOptions={props.filtersOptions} />
			) : null}
		</ContentStyled>
	);
};

function renderBreadCrumbs(breadCrumbs: UiContainerContentBreadCrumbs, ref: BreadCrunbsDivRef) {
	return (
		<BreadCrumbsStyled ref={ref}>
			{breadCrumbs.reduce((nodes: any[], { title, linkTo }, index) => {
				const isLast = index === breadCrumbs.length - 1;
				nodes.push(<div key={`${index}_${linkTo}`}
				                className={isLast ? 'active' : undefined}>
					{linkTo ? <Link to={linkTo}>{title}</Link> : title}
				</div>);
				if (!isLast) {
					nodes.push(<img key={`${index}_arr`} src="/assets/img/caret-right.svg"
					                alt="" />);
				}
				return nodes;
			}, [])}
		</BreadCrumbsStyled>
	);
}

/*
 * UiContainerPlusButton component.
 * Кнопка плюс, будет скрываться при скроллинге вниз и появляться при скроллинге вверх.
 */

type PlusButtonProps = {
	onClick?: () => void;
};

type ScrollInfoState = {
	lastChecked: number
	yOffset: number
	checkDirectionTimerId?: NodeJS.Timeout
};

UiContainer.PlusButton = function UiContainerPlusButton(props: PlusButtonProps) {
	const [isHidden, setHidden] = useState<boolean>();
	const [scrollInfo] = useState<ScrollInfoState>({
		lastChecked: Date.now(),
		yOffset: window.scrollY,
		checkDirectionTimerId: undefined,
	});
	const ref = useRef<HTMLImageElement>();

	const checkDirection = () => {
		const left = Date.now() - scrollInfo.lastChecked;
		if (left < 300) {
			// функция должна работать не чаще чем 0.3 секунды
			clearTimeout(scrollInfo.checkDirectionTimerId!!);
			scrollInfo.checkDirectionTimerId = setTimeout(checkDirection, left);
			return;
		}
		scrollInfo.lastChecked = Date.now();
		const y = window.scrollY;
		const d = y - scrollInfo.yOffset;
		if (scrollInfo.yOffset !== undefined && d > 0 !== isHidden) {
			setHidden(d > 0);
		}
		scrollInfo.yOffset = y;
	};

	useEffect(() => {
		const onScroll = () => {
			checkDirection();
		};
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, [isHidden]);

	return (
		<PlusButtonStyled ref={ref} src={TRANSPARENT_1PX} className={isHidden ? 'hidden' : null}
		                  onClick={props.onClick} />
	);
};

/*
 * UiContainerHamburgerContent component
 */

type HamburgerContentProps = {
	onClickDimmer?: () => void;
	children: ReactNode
};

UiContainer.HamburgerContent = function UiContainerHamburgerContent(props: HamburgerContentProps) {
	return (
		<HamburgerContentDimmerStyled onClick={props.onClickDimmer}>
			<HamburgerContentStyled onClick={e => e.stopPropagation()}>
				{props.children}
			</HamburgerContentStyled>
		</HamburgerContentDimmerStyled>
	);
};
