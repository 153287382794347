import { z } from 'zod';
import { UserDataItem, UserDataItemOptions, userDataItemSchema } from '../../users';
import {
	InfoMessagesToAddresseeDataItem,
	InfoMessagesToAddresseeDataItemOptions,
	infoMessagesToAddresseeDataItemSchema
} from './InfoMessagesToAddresseeDataItem';
import { OptionsItem, OptionsItemOptions, optionsItemSchema } from '../../../meta';

export const infoMessagesMetaSchema = z.object({
	options: z.map(z.string(), optionsItemSchema).optional(),
	infoMessageToAddresseeMap: infoMessagesToAddresseeDataItemSchema.optional(),
	userMap: userDataItemSchema.optional(),
});

export interface InfoMessagesMetaOptions {
	options?: Record<string, OptionsItem | OptionsItemOptions>;
	infoMessageToAddresseeMap?: Record<string, InfoMessagesToAddresseeDataItem | InfoMessagesToAddresseeDataItemOptions>;
	userMap?: Record<string, UserDataItem | UserDataItemOptions>;
}

export class InfoMessagesMeta {
	readonly options?: Record<string, OptionsItem>;
	readonly infoMessageToAddresseeMap?: Record<string, InfoMessagesToAddresseeDataItem>;
	readonly userMap?: Record<string, UserDataItem>;

	constructor(options: InfoMessagesMetaOptions) {
		if (options.options !== undefined) {
			this.options = {};
			for (const [key, value] of Object.entries(options.options)) {
				if (value instanceof OptionsItem) {
					this.options[key] = value;
				} else {
					this.options[key] = new OptionsItem(value);
				}
			}
		}
		if (options.infoMessageToAddresseeMap !== undefined) {
			this.infoMessageToAddresseeMap = {};
			for (const [key, value] of Object.entries(options.infoMessageToAddresseeMap)) {
				if (value instanceof InfoMessagesToAddresseeDataItem) {
					this.infoMessageToAddresseeMap[key] = value;
				} else {
					this.infoMessageToAddresseeMap[key] = new InfoMessagesToAddresseeDataItem(value);
				}
			}
		}
		if (options.userMap !== undefined) {
			this.userMap = {};
			for (const [key, value] of Object.entries(options.userMap)) {
				if (value instanceof UserDataItem) {
					this.userMap[key] = value;
				} else {
					this.userMap[key] = new UserDataItem(value);
				}
			}
		}
		infoMessagesMetaSchema.parse(this);
	}
}