import { z } from 'zod';

export const checklistTaskExecPhotoItemSchema = z.object({
	id: z.string().optional(),
	isDeleted: z.boolean().optional(),
	data: z.string().optional(),
});

export interface ChecklistTaskExecPhotoItemOptions {
	id?: string;
	isDeleted?: boolean;
	data?: string;
}

export class ChecklistTaskExecPhotoItem {
	readonly id?: string;
	readonly isDeleted?: boolean;
	readonly data?: string;

	constructor(options: ChecklistTaskExecPhotoItemOptions) {
		if (options.data !== undefined) this.data = options.data;
		if (options.id !== undefined) this.id = options.id;
		if (options.isDeleted !== undefined) this.isDeleted = options.isDeleted;
		checklistTaskExecPhotoItemSchema.parse(this);
	}
}