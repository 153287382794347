import React from 'react';
import { Link } from 'react-router-dom';

export default function AdminDiagnosticsPage() {
	return (
		<>
			<h1>Diagnostics</h1>
			<ul>
				<li>
					<Link to="/admin/diagnostics/file-gallery">Галерея файлов</Link>
				</li>
				<li>
					<Link to="/admin/diagnostics/incident-marking">Маркировка инцидентов</Link>
				</li>
				<li>
					<Link to="/admin/diagnostics/request-testing">Тестирование запросов</Link>
				</li>
			</ul>
		</>
	);
}
