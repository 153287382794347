// типы сборки

export const isDev = process.env.NODE_ENV === 'development';

export const isTest = process.env.NODE_ENV === 'test';

export const isProd = process.env.NODE_ENV === 'production';

// сервер - назначение

export const isLocalServer = process.env.SERVER_ENV === 'local';

export const isTestServer = process.env.SERVER_ENV === 'test';

export const isReleaseServer = process.env.SERVER_ENV === 'release';

export const isProdServer = process.env.SERVER_ENV === 'prod';

export function getServerUrl(): string {
	if (isLocalServer) {
		return 'http://localhost:3000';
	}
	if (isTestServer) {
		return 'https://test.mon-check.ru';
	}
	if (isReleaseServer) {
		return 'https://release.mon-check.ru';
	}
	if (isProdServer) {
		return 'https://moncheck.monetka.ru';
	}
	throw new Error('Invalid context: Environment is not defined');
}
