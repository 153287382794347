import React from 'react';
import { IncidentStateEnum, fromSqlDateToHumanDate, isDev } from 'common-lib';
import { UiGoodInfoBadge } from '~components';
import { StatusColor } from '~components/ui/UiGoodInfoBadge';
import { IncidentTypeRenderData } from '../incidentsAttrs';

type Props = {
	isShopUser: boolean
	data: IncidentTypeRenderData
}

export default function IncidentTypeView(props: Props) {
	return <>
		{!props.data.incidents?.length ? (
			<div><i>Нет данных</i></div>
		) : null}
		{props.data.incidents.map(incident => {
			const values = incident.incidentType.cardTemplate.map(field => {
				if (field.type === 'VALUE') {
					return getFieldWithValue(incident, field);
				}
			})
				.filter(o => !!o);
			const iid = btoa(String(incident.id));
			const itid = btoa(String(incident.incidentTypeId));
			const isid = btoa(String(incident.shopId));
			const linkTo = incident.state === 'PROCESSING' ? undefined
				: props.isShopUser ? `/incidents/type/${itid}/incident/${iid}`
				: `/incidents/shop/${isid}/type/${itid}/incident/${iid}`;
			const color = incident.state === 'PROCESSING' ? StatusColor.blue
				: incident.state === IncidentStateEnum.DONE ? StatusColor.green : StatusColor.orange;
			return <UiGoodInfoBadge
				key={incident.id}
				color={color}
				title={incident.goodName}
				subTitle={incident.state === 'PROCESSING' ? 'Обновление...' : incident.goodExternalId}
				values={values}
				linkTo={linkTo}
			/>;
		})}
	</>;
}

const VALUE_FORMAT_STRING = 'STRING';
const VALUE_FORMAT_FLOOR = 'FLOOR';
const VALUE_FORMAT_DOUBLE = 'DOUBLE';
const VALUE_FORMAT_HUMAN_DATE = 'HUMAN_DATE';

function reformat(value, format, decimalSize) {
	// дефолтного значения быть не может
	// eslint-disable-next-line default-case
	switch (format) {
		case VALUE_FORMAT_STRING:
			return (value || '');
		case VALUE_FORMAT_FLOOR:
			value = Number(value);
			return (Number.isNaN(value)) ? undefined : Math.floor(value);
		case VALUE_FORMAT_DOUBLE:
			value = Number(value);
			if (Number.isNaN(value)) return undefined;
			return (decimalSize > 0)
				? value.toFixed(decimalSize)
				: value;
		case VALUE_FORMAT_HUMAN_DATE:
			value = fromSqlDateToHumanDate(value);
			return (!value) ? undefined : value;
	}
}

function getFieldWithValue(incident, field) {
	const { title, from, format, decimalSize, devOnly } = field;
	if (devOnly && !isDev) return null;
	const source = from.split('.');
	let value;
	switch (source[0]) {
		case 'inputData':
			value = incident.inputData[source[1]];
			break;
		default:
			value = null;
	}
	if (value !== undefined && value !== null) {
		value = reformat(value, format, decimalSize);
	}
	return {
		key: title,
		devOnly,
		value,
	};
}
