import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	DiagnosticsIncidentMarkingDataItem,
	DiagnosticsIncidentMarkingDataItemOptions,
	diagnosticsIncidentMarkingDataItemSchema
} from '../composite';

export const diagnosticsIncidentMarkingListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: diagnosticsIncidentMarkingDataItemSchema.optional(),
	}),
);

export interface DiagnosticsIncidentMarkingListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (DiagnosticsIncidentMarkingDataItem | DiagnosticsIncidentMarkingDataItemOptions)[];
}

export class DiagnosticsIncidentMarkingListResponseDto extends BaseResponseDto {
	readonly data?: DiagnosticsIncidentMarkingDataItem[];

	constructor(options: DiagnosticsIncidentMarkingListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof DiagnosticsIncidentMarkingDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new DiagnosticsIncidentMarkingDataItem(item));
				}
			}
		}
		diagnosticsIncidentMarkingListResponseDtoSchema.parse(this);
	}
}