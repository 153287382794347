import React from 'react';
import { connect } from 'react-redux';
import ExcelJS from 'exceljs';
import { Accordion, Button, Dimmer, Header, Icon, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { clearIncidentImportData, saveIncidentImportData } from '~store/actions/incidentImportActions';
import { loadReportFileList } from '~store/actions/incidentReportFileActions';
import { HOC } from '~components';

interface Props {
	navigate: any;
	incidentReport: any;
	clearIncidentImportData: () => void;
	saveIncidentImportData: (data: any) => void;
	loadReportFileList: (data: any ) => Promise<any>;
}

class AdminIncidentsPage extends React.Component<Props>
{
	state = {
		isRequesting: true,
		openTabSet: new Set([1]),
	};

	fileInputRef: any = null;

	componentDidMount() {
		// clear cache
		this.props.clearIncidentImportData();
		this.updateReportFileList();
	}

	/**
	 * Обновление списка отчетов
	 */
	updateReportFileList = () => {
		this.setState({ isRequesting: true }, () => {
			this.props.loadReportFileList('files')
				.then(() => this.setState({ isRequesting: false }));
		});
	}

	/**
	 * Пользователь выбрал файл для импорта
	 */
	onFileChange = () => {
		// читаем XLSX файл
		const files = this.fileInputRef?.files;
		console.debug('%c*** files=', 'background: #eee; color: blue', files);
		const reader = new FileReader();
		reader.readAsArrayBuffer(files[0]);
		reader.onload = () => {
			const buf: any = reader.result;
			new ExcelJS.Workbook().xlsx.load(buf)
				.then((wb) => {
					console.debug('%c*** wb=', 'background: #eee; color: blue', wb);
					const ws = wb.getWorksheet(1);
					const data: any[] = [];
					if (!ws) {
						throw new Error('Объект worksheet не создался');
					}
					ws.eachRow((row, rowNumber) => {
						if (rowNumber < 2) return undefined;
						console.debug('%c*** row.values=', 'background: #eee; color: blue', row.values);
						const item = {
							shopExId: row.values[1] * 1,
							shopName: row.values[4],
							goodExId: row.values[2],
							goodName: row.values[3],
							upPrib: row.values[5] * 1,
							ostatok: row.values[6] * 1,
						};
						data.push(item);
						console.debug('%c*** data=', 'background: #eee; color: blue', data);
					});
					// запоминаем данные и переходим на страницу импорта
					return this.props.saveIncidentImportData(data);
				})
				.then(() => this.props.navigate('/admin/incidents/import'));
		};
	}

	/**
	 * Пользователь нажал кнопку скачать
	 */
	downloadFile = (file) => {
		const a = document.createElement('a');
		a.setAttribute('href', `/api/file/download/${file.id}`);
		a.setAttribute('download', file.name);
		a.setAttribute('type', 'plain/text');
		a.click();
		setTimeout(this.updateReportFileList, 1e3);
	}

	handleClickAccordion = (_, data: any) => {
		const { index } = data;
		const { openTabSet } = this.state;
		if (openTabSet.has(index)) openTabSet.delete(index); else openTabSet.add(index);
		this.setState(({ openTabSet }));
	}

	getFileTitle = (file) => {
		let size = file.size;
		if (size < 3e3) {
			size = `${size} b`;
		} else if (size < 2e6) {
			size = `${(size / 1e3).toFixed(1)} Kb`;
		} else {
			size = `${(size / 1e6).toFixed(1)} Mb`;
		}
		return `${file.name} (${size})`;
	}

	renderReportList = () => {
		const { reportFiles } = this.props.incidentReport;
		if (!reportFiles || !reportFiles.length) {
			return <p>Отчетов нет</p>;
		}
		const newFiles = reportFiles.filter(f => f.downloadCount === 0).sort((a, b) => b.id - a.id);
		const oldFiles = reportFiles.filter(f => f.downloadCount > 0).sort((a, b) => b.id - a.id);

		const { openTabSet } = this.state;
		return (
			<Accordion fluid styled>
				{!!newFiles.length && (
					<>
						<Accordion.Title active={openTabSet.has(1)}
						                 index={1}
						                 onClick={this.handleClickAccordion}>
							<Icon name="dropdown" />
							Новые отчеты
						</Accordion.Title>
						<Accordion.Content active={openTabSet.has(1)}>
							{newFiles.map((file) => (
								<div key={file.id} style={{ marginBottom: '.5rem' }}>
									<Button icon labelPosition="left"
									        onClick={() => this.downloadFile({
										        id: file.fileId,
										        fileName: file.name,
									        })}>
										<Icon name="file" />
										{this.getFileTitle(file)}
									</Button>
								</div>
							))}
						</Accordion.Content>
					</>
				)}
				{!!oldFiles.length && (
					<>
						<Accordion.Title active={openTabSet.has(2)}
						                 index={2}
						                 onClick={this.handleClickAccordion}>
							<Icon name="dropdown" />
							Ранее скачанные отчеты
						</Accordion.Title>
						<Accordion.Content active={openTabSet.has(2)}>
							{oldFiles.map((file) => (
								<div key={file.id} style={{ marginBottom: '.5rem' }}>
									<Button icon labelPosition="left"
									        onClick={() => this.downloadFile({
										        id: file.fileId,
										        fileName: file.name,
									        })}>
										<Icon name="file" />
										{this.getFileTitle(file)}
									</Button>
									<span style={{ marginLeft: '2rem' }}>
                    Скачано: {file.downloadCount}
                  </span>
								</div>
							))}
						</Accordion.Content>
					</>
				)}
			</Accordion>
		);
	}

	render() {
		if (this.state.isRequesting) {
			return (
				<Dimmer active inverted>
					<Loader inverted>Загрузка списка отчетов...</Loader>
				</Dimmer>
			);
		}

		return (
			<div>
				<Link to="/admin/incidents/types">Типы инцидентов</Link>
				<Header as="h3" icon="upload" content="Загрузка инцидентов" />
				<Button
					content="Выберите файл"
					labelPosition="left"
					icon="file"
					onClick={() => this.fileInputRef.click()}
				/>
				<input
					ref={ref => this.fileInputRef = ref}
					type="file"
					hidden
					onChange={this.onFileChange}
				/>
				<Header as="h3" icon="download" content="Выгрузка отчетов" />
				{this.renderReportList()}
			</div>
		);
	}
}

export default connect(
	(state: any) => ({ incidentReport: state.incidentReport }),
	{ clearIncidentImportData, saveIncidentImportData, loadReportFileList },
)(HOC(AdminIncidentsPage));
