import React, { ReactNode } from 'react';
import {  UiContainer } from '~components';
import { copyText } from '~tools/copyText';

interface Props
{
	title: string;
	subTitle: string | undefined;
	copyLink?: boolean;
	backToSubShop?: string | undefined;
	onCopyLink?: () => void;
	onBackClick?: () => void;
	children: ReactNode;
}

export default function NoauthUserContentWrapper(props: Props) {

	function copyUrl() {
		const url = window.location.href;
		copyText(url);
	}

	return (
		<UiContainer>
			<UiContainer.Header
				title={props.title}
				subTitle={props.subTitle}
				copyLink={props.copyLink}
				backToSubShop={props.backToSubShop}
				onBackClick={props.onBackClick}
				onCopyLink={copyUrl} />
			<UiContainer.Content>
				{props.children}
			</UiContainer.Content>
		</UiContainer>
	);
}
