import { createAction } from 'redux-act';
import fetchBackend from '~tools/fetchBackend';

export const SAVE_INCIDENT_IMPORT_DATA = createAction('SAVE_INCIDENT_IMPORT_DATA');

export const CHECK_INCIDENT_IMPORT_DATA = createAction('CHECK_INCIDENT_IMPORT_DATA');

export const saveIncidentImportData = data => dispatch => new Promise((resolve) => {
  dispatch(SAVE_INCIDENT_IMPORT_DATA(data));
  resolve(undefined);
});

export const clearIncidentImportData = () => dispatch => new Promise((resolve) => {
  dispatch(SAVE_INCIDENT_IMPORT_DATA(null));
  resolve(undefined);
});

export const checkIncidentImportData = checkData => dispatch => fetchBackend('put', 'incident/import/check', checkData)
  .then(response => response.json && response.json())
  .then(data => dispatch(CHECK_INCIDENT_IMPORT_DATA(data)));

export const sendIncidentImportData = (date, type, importData) => fetchBackend('post', `incident/import?date=${date}&type=${type}`, importData);
