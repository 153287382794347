export { default as AdminQuizListPage } from './admin/AdminQuizListPage';
export { default as AdminQuizCreatePage } from './admin/AdminQuizCreatePage';
export { default as AdminTestingCreatePage } from './admin/AdminTestingCreatePage';
export { default as AdminQuizReportsPage } from './admin/AdminQuizReportsPage';
export { default as AdminQuizStatisticPage } from './admin/AdminQuizStatisticPage';

export { default as UserQuizListPage } from './user/UserQuizListPage';
export { default as UserQuizManagersPage } from './user/UserQuizManagersPage';
export { default as UserQuizSubShopPage } from './user/UserQuizSubShopPage';
export { default as UserQuizPage } from './user/UserQuizPage';
export { default as UserTestingPage } from './user/UserTestingPage';

export { default as NoauthUserQuizViewPage } from './noauth-user/NoauthUserQuizViewPage';
export { default as NoauthUserTestViewPage } from './noauth-user/NoauthUserTestViewPage';
