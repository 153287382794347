import { z } from 'zod';

export const questionnaireTestingOptionsItemSchema = z.object({
	index: z.number().int(),
	value: z.string(),
	correctAnswer: z.boolean(),
});

export interface QuestionnaireTestingOptionsItemOptions {
	index: number;
	value: string;
	correctAnswer: boolean;
}

export class QuestionnaireTestingOptionsItem {
	readonly index: number;
	readonly value: string;
	readonly correctAnswer: boolean;

	constructor(options: QuestionnaireTestingOptionsItemOptions) {
		this.index = options.index;
		this.value = options.value;
		this.correctAnswer = options.correctAnswer;
		questionnaireTestingOptionsItemSchema.parse(this);
	}
}