import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { ChecklistTaskListDataItem, ChecklistTaskListDataItemOptions, checklistTaskListDataItemSchema } from '../composite';
import { ChecklistTaskMeta, ChecklistTaskMetaOptions, checklistTaskMetaSchema } from '../meta';

export const checklistTaskListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(checklistTaskListDataItemSchema).optional(),
		meta: checklistTaskMetaSchema.optional(),
	}),
);

export interface ChecklistTaskListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (ChecklistTaskListDataItem | ChecklistTaskListDataItemOptions)[];
	meta?: ChecklistTaskMeta | ChecklistTaskMetaOptions;
}

export class ChecklistTaskListResponseDto extends BaseResponseDto {
	readonly data?: ChecklistTaskListDataItem[];
	readonly meta?: ChecklistTaskMeta;

	constructor(options: ChecklistTaskListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof ChecklistTaskListDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new ChecklistTaskListDataItem(item));
				}
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof ChecklistTaskMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new ChecklistTaskMeta(options.meta);
			}
		}
		checklistTaskListResponseDtoSchema.parse(this);
	}

}