export enum UserRoles
{
	ADMIN = 'ADMIN', // администратор, имеет доступ ко всем разделам в админке
	ROBOT = 'ROBOT', // УЗ бота, выполняющего импорт/экспорт данных
	SUPPORT = 'SUPPORT', // специалист поддержки
	CHIEF = 'CHIEF', // начальник менеджеров, директор по торговле
	MANAGER = 'MANAGER', // управляющий одним магазинов или несколькими магазинами
}

//export const SUPERVISOR_ROLES = [USER_ROLE_DT, USER_ROLE_DF, USER_ROLE_TM, USER_ROLE_UPF];

export function getRoleName(role: UserRoles | undefined): string | undefined {
	switch (role) {
		case UserRoles.ADMIN:
			return 'Администратор';
		case UserRoles.ROBOT:
			return 'Робот';
		case UserRoles.SUPPORT:
			return 'Саппорт';
		case UserRoles.CHIEF: // ДТ
			return 'Директор по торговле';
		case UserRoles.MANAGER: // ДФ, ТМ, УПФ, ДМ, ЗДМ
			return 'Менеджер';
		default:
			return undefined;
	}
}

// Роли пользователей-исполнителей
// export const PERFORMER_USER_ROLES = [
//   USER_ROLE_DF,
//   USER_ROLE_TM,
//   USER_ROLE_UPF,
//   USER_ROLE_DM,
//   USER_ROLE_ZDM,
// ];
