import { z } from 'zod';
import {
	ChecklistTaskListDataItem,
	ChecklistTaskListDataItemOptions,
	checklistTaskListDataItemSchema
} from './ChecklistTaskListDataItem';

export const checklistTaskDataItemSchema = checklistTaskListDataItemSchema.merge(
	z.object({
		questionPhotos: z.string().nullable().optional(),
		isPhotoRequired: z.boolean().optional(),
		isCommentRequired: z.boolean().optional(),
		executorShopId: z.number().int().optional(),
		executorComment: z.string().optional(),
		executionPhotoIds: z.object({ photos: z.array(z.number()) }).optional(), // fixme
	}),
);

export interface ChecklistTaskDataItemOptions extends ChecklistTaskListDataItemOptions {
	questionPhotos?: string | null;
	isPhotoRequired?: boolean;
	isCommentRequired?: boolean;
	executorShopId?: number;
	executorComment?: string;
	executionPhotoIds?: { photos: number[] };
}

export class ChecklistTaskDataItem extends ChecklistTaskListDataItem {
	readonly questionPhotos?: string | null;
	readonly isPhotoRequired?: boolean;
	readonly isCommentRequired?: boolean;
	readonly executorShopId?: number;
	readonly executorComment?: string;
	readonly executionPhotoIds?: { photos: number[] };

	constructor(options: ChecklistTaskDataItemOptions) {
		super(options);
		if (options.questionPhotos !== undefined) this.questionPhotos = options.questionPhotos;
		if (options.isPhotoRequired !== undefined) this.isPhotoRequired = options.isPhotoRequired;
		if (options.isCommentRequired !== undefined) this.isCommentRequired = options.isCommentRequired;
		if (options.executorShopId !== undefined) this.executorShopId = options.executorShopId;
		if (options.executorComment !== undefined) this.executorComment = options.executorComment;
		if (options.executionPhotoIds !== undefined) this.executionPhotoIds = options.executionPhotoIds;
		checklistTaskDataItemSchema.parse(this);
	}

}