import { z } from 'zod';

export const taskRejectRequestDtoSchema = z.object({
	rejectionComment: z.string().optional(),
});

export interface TaskRejectRequestDtoOptions {
	rejectionComment?: string;
}

export class TaskRejectRequestDto {
	readonly rejectionComment?: string;

	constructor(options: TaskRejectRequestDtoOptions) {
		if (options.rejectionComment !== undefined) this.rejectionComment = options.rejectionComment;
		taskRejectRequestDtoSchema.parse(this);
	}
}
