import { z } from 'zod';

export const infoMessagesStatsItemSchema = z.object({
	created: z.number().int(),
	viewed: z.number().int().optional(),
});

export interface InfoMessagesStatsItemOptions {
	created: number;
	viewed?: number;
}

export class InfoMessagesStatsItem {
	readonly created: number;
	readonly viewed?: number;

	constructor(options: InfoMessagesStatsItemOptions) {
		this.created = options.created;
		this.viewed = options.viewed;
		infoMessagesStatsItemSchema.parse(this);
	}
}