import { z } from 'zod';
import { InfoMessagesStatsItem, infoMessagesStatsItemSchema } from './InfoMessagesStatsItem';

export const infoMessagesDataItemSchema = z.object({
	id: z.number().int(),
	subject: z.string(),
	fileId: z.number(),
	isImportant: z.boolean(),
	externalMessageId: z.string().nullable(),
	externalMessageDate: z.instanceof(Date).nullable(),
	stats: infoMessagesStatsItemSchema.optional(),
	ownerId: z.number().int(),
	createdAt: z.instanceof(Date),
});


export interface InfoMessagesDataItemOptions {
	id: number;
	subject: string;
	fileId: number;
	isImportant: boolean;
	externalMessageId: string | null;
	externalMessageDate: Date | string | null;
	stats?: InfoMessagesStatsItem;
	ownerId: number;
	createdAt: Date | string;
}

export class InfoMessagesDataItem {
	readonly id: number;
	readonly subject: string;
	readonly fileId: number;
	readonly isImportant: boolean;
	readonly externalMessageId: string | null;
	readonly externalMessageDate: Date | null;
	readonly stats?: InfoMessagesStatsItem;
	readonly ownerId: number;
	readonly createdAt: Date;

	constructor(options: InfoMessagesDataItemOptions) {
		this.id = options.id;
		this.subject = options.subject;
		this.fileId = options.fileId;
		this.isImportant = options.isImportant;
		this.externalMessageId = options.externalMessageId;
		if (options.externalMessageDate instanceof Date) {
			this.externalMessageDate = options.externalMessageDate;
		} else if (options.externalMessageDate !== null) {
			this.externalMessageDate = new Date(options.externalMessageDate);
		} else {
			this.externalMessageDate = options.externalMessageDate;
		}
		if (options.stats !== undefined) this.stats = options.stats;
		this.ownerId = options.ownerId;
		if (options.createdAt instanceof Date) {
			this.createdAt = options.createdAt;
		} else {
			this.createdAt = new Date(options.createdAt);
		}
		infoMessagesDataItemSchema.parse(this);
	}
}