import { z } from 'zod';

export const infoMessagesPayloadRequestDtoSchema = z.object({
	text: z.string().optional(),
	isImportant: z.boolean().optional(),
	subject: z.string().optional(),
	linkAddressees: z.array(z.number().int()).optional(),
});

export interface InfoMessagesPayloadRequestDtoOptions {
	text?: string;
	isImportant?: boolean;
	subject?: string;
	linkAddressees?: number[];
}

export class InfoMessagesPayloadRequestDto {
	readonly text?: string;
	readonly isImportant?: boolean;
	readonly subject?: string;
	readonly linkAddressees?: number[];

	constructor(options: InfoMessagesPayloadRequestDtoOptions) {
		if (options.text !== undefined) this.text = options.text;
		if (options.isImportant !== undefined) this.isImportant = options.isImportant;
		if (options.subject !== undefined) this.subject = options.subject;
		if (options.linkAddressees !== undefined) this.linkAddressees = options.linkAddressees;
		infoMessagesPayloadRequestDtoSchema.parse(this);
	}
}