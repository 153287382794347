import { z } from 'zod';

export const incidentGoodListDataItemSchema = z.object({
	id: z.number().int(),
	shopId: z.number().int(),
	incidentTypeId: z.number().int(),
	goodId: z.number().int(),
	state: z.string(),
	inputData: z.string(),
	report: z.string().nullable(),
	isReported: z.boolean(),
	shopDiscountId: z.number().int().nullable(),
});

export interface IncidentGoodListDataItemOptions {
	id: number;
	shopId: number;
	incidentTypeId: number;
	goodId: number;
	state: string;
	inputData: string;
	report: string | null;
	isReported: boolean;
	shopDiscountId: number | null;

}

export class IncidentGoodListDataItem {
	readonly id: number;
	readonly shopId: number;
	readonly incidentTypeId: number;
	readonly goodId: number;
	readonly state: string;
	readonly inputData: string;
	readonly report: string | null;
	readonly isReported: boolean;
	readonly shopDiscountId: number | null;

	constructor(options: IncidentGoodListDataItemOptions) {
		this.id = options.id;
		this.shopId = options.shopId;
		this.incidentTypeId = options.incidentTypeId;
		this.goodId = options.goodId;
		this.state = options.state;
		this.inputData = options.inputData;
		this.report = options.report;
		this.isReported = options.isReported;
		this.shopDiscountId = options.shopDiscountId;
		incidentGoodListDataItemSchema.parse(this);
	}
}
