import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { IncidentManagerDataItem, IncidentManagerDataItemOptions, incidentManagerDataItemSchema } from '../composite';
import { IncidentMeta, IncidentMetaOptions, incidentMetaSchema } from '../meta';

export const incidentManagerStatisticResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: incidentManagerDataItemSchema.optional(),
		meta: incidentMetaSchema.optional(),
	}),
);

export interface IncidentManagerStatisticResponseDtoOptions extends BaseResponseDtoOptions {
	data?: IncidentManagerDataItem | IncidentManagerDataItemOptions;
	meta?: IncidentMeta | IncidentMetaOptions;
}

export class IncidentManagerStatisticResponseDto extends BaseResponseDto {
	readonly data?: IncidentManagerDataItem;
	readonly meta?: IncidentMeta;

	constructor(options: IncidentManagerStatisticResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			if (options.data instanceof IncidentManagerDataItem) {
				this.data = options.data;
			} else {
				this.data = new IncidentManagerDataItem(options.data);
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof IncidentMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new IncidentMeta(options.meta);
			}
		}
		incidentManagerStatisticResponseDtoSchema.parse(this);
	}
}
