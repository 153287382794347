import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Accordion, Dropdown, Icon, Label, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { CancelSaveDeleteButtons, EditForm, FlexGrid, Spinner } from '~components';
import { fillInfoMessageForAdminEdit, getFieldsInfo } from '../infoMessagesAttrs';
import {
	createInfoMessage,
	deleteInfoMessage,
	getInfoMessageBodyByFileId,
	getInfoMessageByIdWithUsers,
	searchUsers,
	updateInfoMessage,
} from '~api/infoMessagesApi';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';

const AddresseeSpan = styled.span`
  display: inline-block;
  margin-top: .5em;
  margin-right: .5em;
`;

type Props = {
}

export default function AdminInfoMessageEditPage(props: Props) {
	const [{ infoMessage, itemUsers, itemBody, isItemDataRequesting }, setItemData] = useState<any>({});
	const [addresseeList, setAddressees] = useState<any>();
	const [searchOptions, setSearchOptions] = useState<any>();
	const [formChanges, setFormChanges] = useState<any>({});
	const [userListAccordion, setUserListAccordion] = useState<any>();
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	const { infoMessageId }: any = params;
	const isNew = (infoMessageId === '+');

	if (!isNew && !infoMessage) {
		if (!isItemDataRequesting) {
			setItemData({ isItemDataRequesting: true });
			// загружаем информацию о сообщении
			getInfoMessageByIdWithUsers(infoMessageId).then((json: any) => {
				// загружаем тело сообщения
				getInfoMessageBodyByFileId(json.data.fileId)
					.then(htmlBody => {
						setItemData({
							infoMessage: fillInfoMessageForAdminEdit(json),
							itemBody: htmlBody,
						});
					});
			});
		}
		return <Spinner />;
	}

	if (!isNew && !infoMessage) {
		return <Segment inverted color="red" style={{ clear: 'both' }}>Сообщение не загружено</Segment>
	}

	// если сообщение импортировано из почты, то выводим тело письма только для просмотра
	if (infoMessage) {
		if (infoMessage.externalMessageId) {
			infoMessage.readOnlyHtml = itemBody;
		} else {
			infoMessage.text = itemBody;
		}
	}

	// для обновления списков нужно изменить объект
	const updateAddressees = addressees => setAddressees({ ...addressees });

	// определяет список адресатов
	if (!addresseeList) {
		const list = {
			created: [],
			viewed: [],
		};
		if (isNew) {
			updateAddressees(list);
			return null;
		}
		const adrList = (infoMessage.infoMessageToAddressees || [])
			?.map(ima => ({ status: ima.status, ...ima.addressee }))
			?.sort((a, b) => a.fullName > b.fullName ? 1 : a.fullName < b.fullName ? -1 : 0);
		list.created = adrList.filter(u => u.status === 'CREATED'); // TODO заменить на InfoMessageToAddresseeEnum
		list.viewed = adrList.filter(u => u.status === 'VIEWED'); // TODO заменить на InfoMessageToAddresseeEnum
		updateAddressees(list);
		return null;
	}
	const fieldsInfo = getFieldsInfo(isNew);
	const title = `${isNew ? 'Создание' : 'Редактирование'} сообщения`;

	// метод удаления адресата из списка
	const onAddresseeDelete = au => {
		if (au.__isNew) {
			addresseeList.created = addresseeList.created.filter(u => u.id !== au.id);
			addresseeList.viewed = addresseeList.viewed.filter(u => u.id !== au.id);
			updateAddressees(addresseeList);
		} else {
			au.__deleted = true;
			updateAddressees(addresseeList);
		}
	};

	// метод восстановления адресата в списке
	const onAddresseeRestore = au => {
		delete au.__deleted;
		updateAddressees(addresseeList);
	};

	// поиск пользователей при изменении строки поиска
	const onSearchChange = (_, { searchQuery }) => {
		if (searchQuery.length < 3) {
			return;
		}
		searchUsers(searchQuery)
			.then(({ data, pageInfo: { allCount } }: any) => {
				// фильтр тех, кто уже есть в списке
				const adrIdsSet = new Set();
				addresseeList.created.forEach(au => adrIdsSet.add(au.id));
				addresseeList.viewed.forEach(au => adrIdsSet.add(au.id));
				const searchUsersKeys = data
					?.filter(u => !adrIdsSet.has(u.id))
					?.map(u => ({ key: u.id, text: u.fullName, value: u.id }));
				if (allCount > data?.length) {
					searchUsersKeys.push({
						key: 'else',
						text: `По фразе '${searchQuery}' еще найдено ${allCount - data.length}`,
						value: 'else',
						disabled: true,
					});
				}
				setSearchOptions([searchUsersKeys, data]);
			});
	};

	// добавление адресата
	const onAddresseeAdd = (e, { value }: any) => {
		// в текущем списке такого же пользователя не должно быть
		if (addresseeList.created.find(u => u.id === value)) return undefined;
		if (addresseeList.viewed.find(u => u.id === value)) return undefined;
		addresseeList.created.push(Object.assign({ __isNew: true }, searchOptions[1].find(u => u.id === value)));
		updateAddressees(addresseeList);
	};

	// сохранение
	const onSave = () => {
		const linkAddressees = addresseeList.created.filter(u => u.__isNew).map(u => u.id);
		const unlinkAddressees = [].concat(
			addresseeList.created.filter(u => u.__deleted).map(u => u.id),
			addresseeList.viewed.filter(u => u.__deleted).map(u => u.id),
		);
		if (!formChanges[1]) {
			if (isNew) {
				formChanges[0].isImportant = !!formChanges[0].isImportant;
				createInfoMessage(Object.assign({}, formChanges[0], {
					linkAddressees: linkAddressees?.length ? linkAddressees : undefined,
					unlinkAddressees: unlinkAddressees?.length ? unlinkAddressees : undefined,
				}))
					.then(() => navigate('/admin/infomsg'));
			} else {
				updateInfoMessage(infoMessage.id, Object.assign({}, formChanges[0], {
					linkAddressees: linkAddressees?.length ? linkAddressees : undefined,
					unlinkAddressees: unlinkAddressees?.length ? unlinkAddressees : undefined,
				}))
					.then(() => navigate('/admin/infomsg'));
			}
		}
	};

	const onDelete = () => {
		setItemData({ infoMessage, itemUsers, itemBody, isItemDataRequesting: true });
		deleteInfoMessage(infoMessage.id)
			.then(() => navigate('/admin/infomsg'));
	};

	let deletedCount = 0;
	let addedCount = 0;
	let readedCount = 0;
	let unreadedCount = 0;

	addresseeList.created.forEach(ua => {
		if (ua.__deleted) deletedCount++;
		if (ua.__isNew) addedCount++;
		if (ua.status === 'CREATED') unreadedCount++;
		if (ua.status === 'VIEWED') readedCount++;
	});
	addresseeList.viewed.forEach(ua => {
		if (ua.__deleted) deletedCount++;
		if (ua.__isNew) addedCount++;
		if (ua.status === 'CREATED') unreadedCount++;
		if (ua.status === 'VIEWED') readedCount++;
	});

	const hasFormChanges = Object.keys(formChanges[0] || {}).length > 0;
	const canSave = (hasFormChanges && !formChanges[1]) || (!isNew && (deletedCount > 0 || addedCount > 0));

	return (
		<FlexGrid>
			<FlexGrid.Item maxWidth="800px">
				<EditForm
					title={title}
					icon="envelope"
					metaFields={fieldsInfo}
					itemData={infoMessage}
					onChange={(values, a1, a2, hasErrors) => setFormChanges([values, hasErrors])}
				/>
				<CancelSaveDeleteButtons
					canSave={canSave}
					onCancel={() => navigate(-1)}
					onSave={onSave}
					onDelete={!isNew ? onDelete : undefined}
				/>
			</FlexGrid.Item>
			<FlexGrid.Item maxWidth="400px">
				<h3>Адресаты</h3>
				<label>Добавить адресата</label>
				<div>
					<Dropdown
						onSearchChange={onSearchChange}
						onChange={onAddresseeAdd}
						search
						selection
						options={searchOptions?.[0] || []}
						noResultsMessage="Укажите фамилию или имя (мин 3 буквы)"
						style={{ minWidth: '300px', width: '50%' }}
						value={undefined}
					/>
				</div>
				<div style={{ margin: '.5em 0' }}>
					Прочитано адресатами: {readedCount} из {addresseeList.created.length + addresseeList.viewed.length}
					{deletedCount ? <div>При сохранении будет удалено: {deletedCount}</div> : null}
					{addedCount ? <div>При сохранении будет добавлено: {addedCount}</div> : null}
					{unreadedCount ? <div>Не прочитали сообщение: {unreadedCount}</div> : null}
				</div>
				<Accordion fluid styled>
					<Accordion.Title active={userListAccordion === 1} index={1}
					                 onClick={() => setUserListAccordion(userListAccordion === 1 ? 0 : 1)}>
						<Icon name="dropdown" />
						Прочитали ({addresseeList.viewed.length})
					</Accordion.Title>
					<Accordion.Content active={userListAccordion === 1}>
						{addresseeList.viewed.map((au => (
							<AddresseeSpan key={au.id}>
								<Label color={au.__isNew ? 'blue' : au.__deleted ? 'grey' : undefined}>
									<Icon name="envelope open" color="green" title="прочитано" />
									<Link to={`/admin/users/${au.id}`}>{au.fullName}</Link>
									{!au.__deleted && (
										<Icon name="delete" title="Нажмите чтобы удалить"
										      onClick={() => onAddresseeDelete(au)} />
									)}
									{au.__deleted && (
										<Label.Detail
											style={{ cursor: 'pointer' }}
											content="(удален)"
											title="Нажмите чтобы восстановить"
											onClick={() => onAddresseeRestore(au)}
										/>
									)}
								</Label>
							</AddresseeSpan>
						)))}
					</Accordion.Content>
					<Accordion.Title active={userListAccordion === 2} index={1}
					                 onClick={() => setUserListAccordion(userListAccordion === 2 ? 0 : 2)}>
						<Icon name="dropdown" />
						Не прочитали ({addresseeList.created.length})
					</Accordion.Title>
					<Accordion.Content active={userListAccordion === 2}>
						{addresseeList.created.map((au => (
							<AddresseeSpan key={au.id}>
								<Label color={au.__isNew ? 'blue' : au.__deleted ? 'grey' : undefined}>
									{!au.__isNew && <Icon name="envelope outline" title="не прочитано" />}
									<Link to={`/admin/users/${au.id}`}>{au.fullName}</Link>
									{!au.__isNew && !au.__deleted && (
										<Icon name="delete" title="Нажмите чтобы удалить"
										      onClick={() => onAddresseeDelete(au)} />
									)}
									{!au.__isNew && au.__deleted && (
										<Label.Detail
											style={{ cursor: 'pointer' }}
											content="(удален)"
											title="Нажмите чтобы восстановить"
											onClick={() => onAddresseeRestore(au)}
										/>
									)}
									{au.__isNew && (
										<Label.Detail
											style={{ cursor: 'pointer' }}
											content="(новый)"
											title="Надмите чтобы удалить"
											onClick={() => onAddresseeDelete(au)}
										/>
									)}
								</Label>
							</AddresseeSpan>
						)))}
					</Accordion.Content>
				</Accordion>
			</FlexGrid.Item>
		</FlexGrid>
	);
}
