import { z } from 'zod';

export const featureConfigurationPayloadRequestDtoSchema = z.object({
	enabledAll: z.boolean(),
	shopIds: z.array(z.number().int()).nullable(),
});

export interface FeatureConfigurationPayloadRequestDtoOptions {
	enabledAll: boolean;
	shopIds: number[] | null;
}

export class FeatureConfigurationPayloadRequestDto {
	readonly enabledAll: boolean;
	readonly shopIds: number[] | null;

	constructor(options: FeatureConfigurationPayloadRequestDto) {
		this.enabledAll = options.enabledAll;
		this.shopIds = options.shopIds;
		featureConfigurationPayloadRequestDtoSchema.parse(this);
	}
}