import { z } from 'zod';
import { TaskMeta, TaskMetaOptions } from '../meta';
import { TaskDataItem, TaskDataItemOptions, taskDataItemSchema } from '../composite';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';

export const taskOneResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: taskDataItemSchema.optional(),
		meta: taskDataItemSchema.optional(),
	}),
);

export interface TaskOneResponseDtoOptions extends BaseResponseDtoOptions {
	data?: TaskDataItem | TaskDataItemOptions;
	meta?: TaskMeta | TaskMetaOptions;
}

export class TaskOneResponseDto extends BaseResponseDto {
	readonly data?:  TaskDataItem;
	readonly meta?: TaskMeta;

	constructor(options: TaskOneResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			if (options.data instanceof TaskDataItem) {
				this.data = options.data;
			} else {
				this.data = new TaskDataItem(options.data);
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof TaskMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new TaskMeta(options.meta);
			}
		}
		taskOneResponseDtoSchema.parse(this);
	}
}