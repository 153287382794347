import { z } from 'zod';

export const authCredentialItemSchema = z.object({
	id: z.number().int(),
	userId: z.number().int(),
	username: z.string(),
});

export interface AuthCredentialItemOptions {
	id: number;
	userId: number;
	username: string;
}

export class AuthCredentialItem {
	readonly id: number;
	readonly userId: number;
	readonly username: string;

	constructor(options: AuthCredentialItemOptions) {
		this.id = options.id;
		this.userId = options.userId;
		this.username = options.username;
		authCredentialItemSchema.parse(this);
	}

}