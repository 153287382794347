import { z } from 'zod';

export const optionsItemSchema = z.object({
	isNotShopManager: z.boolean().optional(),
});


export interface OptionsItemOptions {
	isNotShopManager?: boolean;
}

export class OptionsItem {
	readonly isNotShopManager?: boolean;

	constructor(options: OptionsItemOptions) {
		if (options.isNotShopManager !== undefined) this.isNotShopManager = options.isNotShopManager;
		optionsItemSchema.parse(this);
	}
}