import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	QuestionnaireRunningItem,
	QuestionnaireRunningItemOptions,
	questionnaireRunningItemSchema
} from '../composite';

export const questionnaireRunningListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(questionnaireRunningItemSchema).optional(),
	}),
);

export interface QuestionnaireRunningListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (QuestionnaireRunningItem | QuestionnaireRunningItemOptions)[];
}

export class QuestionnaireRunningListResponseDto  extends BaseResponseDto {
	readonly data?: QuestionnaireRunningItem[];

	constructor(options: QuestionnaireRunningListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof QuestionnaireRunningItem) {
					this.data.push(item);
				} else {
					this.data.push(new QuestionnaireRunningItem(item));
				}
			}
		}
		questionnaireRunningListResponseDtoSchema.parse(this);
	}

}