import React, { useEffect, useState } from 'react';
import { ListIsEmpty, Spinner, UiBadge } from '~components';
import * as QuizApi from '~api/quizApi';
import { UserContentWrapper } from '~containers';
import styled from 'styled-components';
import { QuestionnaireTypeTitle } from 'common-lib';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const TitleAndQuizTypeStyle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

    .quiz_type {
        font-size: 10px;
        font-weight: bold;
        padding: 2px 8px;
        border-radius: 16px;
        color: gray;
        background-color: #ddd;
        text-transform: uppercase;
    }
`;

const ButtonStyled = styled.button`
    flex: 1;
    margin: 0 6px;
    height: 60px;
    border: 0;
    border-radius: 16px;
    background-color: ${p => p.active ? '#696969' : '#e0e1e2'};
    color: ${p => p.active ? '#fff' : '#333232'};
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    max-width: 400px;

    // для веба разрешаем менять цвет текста и иконок при наведении
    @media (min-width: 900px) {
        &:hover {
	        color: #ffffff;
            background-color: #80878bcc;
        }
    }
`;


const MenuManagerStyled = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 10px 10px;
	background: #fff;
	border-radius: 20px 20px 0 0;
	box-shadow: 0 -2px 7px rgba(0, 0, 0, 0.1);
	z-index: 4;

	a {
        display: contents;
	}

	@media (max-width: 450px), (max-height: 450px) {
		${ButtonStyled} {
  			height: 55px;
		}
	}
	@media (max-width: 380px), (max-height: 380px) {
		${ButtonStyled} {
	        height: 40px;
    	    font-size: 15px;
		}
	}
`;

export default function UserQuizListPage() {
	// todo Разобраться с типами quizList
	const [quizList, setQuizList] = useState<any[] | undefined>(undefined);

	const meConfig: any = useSelector<any>(state => state.me.currentUser);
	const isDmManager = meConfig.shopManagerRoles !== undefined;

	useEffect(() => {
		if (isDmManager) {
			updateList();
		} else {
			updateListForManager();
		}
	}, []);

	function updateList() {
		QuizApi.fetchRunningQuizList()
			.then(res => {
				if (res.data) {
					setQuizList(res.data);
				}
		});
	}

	function updateListForManager() {
		QuizApi.fetchRunningQuizListForManager().then(res => {
			if (res.data) {
				setQuizList(res.data);
			}
		});
	}

	if (!quizList) {
		return <Spinner onpage text={<div>Загрузка<br />списка опросов...</div>} />;
	}

	return (
		<UserContentWrapper title="Опросы и тесты">
			{isDmManager ? (
				// Список опросов для ДМ
				<>
					{quizList.length ? quizList.map(item => {
						const pathToQuiz = item.type.toLowerCase(); // quiz | testing
						return (
							<UiBadge bigPaddings linkTo={`/${pathToQuiz}/${item.id}`} key={item.id}>
								<TitleAndQuizTypeStyle>
									<h1>{item.title}</h1>
									<div className="quiz_type">{QuestionnaireTypeTitle[item.type]}</div>
								</TitleAndQuizTypeStyle>
								<UiBadge.DateTimePeriod dateStart={new Date(item.startAt)} dateFinish={new Date(item.finishAt)} />
								{item.repeatCount > 1 ? (
									<UiBadge.QuizCount leftCount={item.repeatCount - item.finishedCount} repeatCount={item.repeatCount} />
								) : null}
							</UiBadge>
						);
					}) : <ListIsEmpty />}
				</>
			) : (
				// Список опросов для менеджера
					<>
						{quizList.length ? quizList.map(item => {
							const pathToQuiz = item.type.toLowerCase(); // quiz | testing
							return (
								<UiBadge bigPaddings linkTo={`/${pathToQuiz}/${item.id}`} key={item.id}>
									<TitleAndQuizTypeStyle>
										<h1>{item.title}</h1>
										<div className="quiz_type">{QuestionnaireTypeTitle[item.type]}</div>
									</TitleAndQuizTypeStyle>
									<UiBadge.DateTimePeriod dateStart={new Date(item.startAt)} dateFinish={new Date(item.finishAt)} />
									{item.repeatCount > 1 ? (
										<UiBadge.QuizCount leftCount={item.repeatCount - item.finishedCount} repeatCount={item.repeatCount} />
									) : null}
								</UiBadge>
							);
						}) : <ListIsEmpty />}
					</>
			)}

			{!isDmManager ? (
				<MenuManagerStyled>
					<Link to="/quiz-managers">
						<ButtonStyled>
							Опросы магазинов
						</ButtonStyled>
					</Link>
						<ButtonStyled active>
							Мои опросы
						</ButtonStyled>
				</MenuManagerStyled>
			) : null}
		</UserContentWrapper>
	);
}
