/**
 * Отрезает дробную часть без округления
 * @param n
 * @param fixed
 */
export function toTruncFixed(n: number, fixed: number): number {
	const p = 10 ** (fixed || 0);
	const a = Math.trunc(n * p);
	return a / p;
}

export function toRoundFixed(n: number, fixed: number): number {
	const p = 10 ** (fixed || 0);
	const a = Math.round(n * p);
	return a / p;
}
