import { z } from 'zod';
import { QuestionnaireExecutionStatusEnum, QuestionnaireTypeEnum } from 'common-lib';
import {
	QuestionnaireExecutionConfig,
	QuestionnaireExecutionConfigOptions,
	questionnaireExecutionConfigSchema
} from './QuestionnaireExecutionConfig';
import { QuestionnaireConfig, QuestionnaireConfigOptions, questionnaireConfigSchema } from './QuestionnaireConfig';
import { QuestionnaireReportConfig, questionnaireReportConfigSchema } from './QuestionnaireReportConfig';

export const questionnaireDataItemSchema = z.object({
	id: z.number().int(),
	title: z.string(),
	type: z.nativeEnum(QuestionnaireTypeEnum),
	enabled: z.boolean(),
	status: z.nativeEnum(QuestionnaireExecutionStatusEnum).optional(),
	startAt: z.instanceof(Date).optional(),
	finishAt: z.instanceof(Date).optional(),
	startDate: z.string().optional(),
	startTime: z.string().optional(),
	questionCount: z.number().int(),
	addresseesCount: z.number().int(),
	questForManagers: z.number().int().optional(),
	answeredCount: z.number().int(),
	addresseesShopIds: z.string().optional(),
	addresseesManagerIds: z.string().optional(),
	questionnaireConfig: questionnaireConfigSchema.optional(),
	executionConfig: questionnaireExecutionConfigSchema,
	reportConfig: questionnaireReportConfigSchema.optional(),
});


export interface QuestionnaireDataItemOptions {
	id: number;
	title: string;
	type: QuestionnaireTypeEnum;
	enabled: boolean;
	status?: QuestionnaireExecutionStatusEnum;
	startAt?: Date | string;
	finishAt?: Date | string;
	startDate?: string;
	startTime?: string;
	questionCount: number;
	addresseesCount: number;
	questForManagers?: number;
	answeredCount: number;
	addresseesShopIds?: string;
	addresseesManagerIds?: string;
	questionnaireConfig?: QuestionnaireConfig | QuestionnaireConfigOptions | string;
	executionConfig: QuestionnaireExecutionConfig | QuestionnaireExecutionConfigOptions | string;
	reportConfig?: QuestionnaireReportConfig;
}

export class QuestionnaireDataItem
{
	readonly id: number;
	readonly title: string;
	readonly type: QuestionnaireTypeEnum;
	readonly enabled: boolean;
	readonly status?: QuestionnaireExecutionStatusEnum;
	readonly startAt?: Date;
	readonly finishAt?: Date;
	readonly startDate?: string;
	readonly startTime?: string;
	readonly questionCount: number;
	readonly addresseesCount: number;
	readonly questForManagers?: number;
	readonly answeredCount: number;
	readonly addresseesShopIds?: string;
	readonly addresseesManagerIds?: string;
	readonly questionnaireConfig?: QuestionnaireConfig;
	readonly executionConfig: QuestionnaireExecutionConfig;
	readonly reportConfig?: QuestionnaireReportConfig;

	constructor(options: QuestionnaireDataItemOptions) {
		this.id = options.id;
		this.title = options.title;
		this.type = options.type;
		this.enabled = options.enabled;
		if (options.status !== undefined) this.status = options.status;
		if (options.startAt !== undefined) {
			if (options.startAt instanceof Date) {
				this.startAt = options.startAt;
			} else {
				this.startAt = new Date(options.startAt);
			}
		}
		if (options.finishAt !== undefined) {
			if (options.finishAt instanceof Date) {
				this.finishAt = options.finishAt;
			} else {
				this.finishAt = new Date(options.finishAt);
			}
		}
		this.questionCount = options.questionCount;
		this.addresseesCount = options.addresseesCount;
		if (options.questForManagers !== undefined) this.questForManagers = options.questForManagers;
		this.answeredCount = options.answeredCount;
		// Questionnaire Execution Config
		if (options.executionConfig instanceof QuestionnaireExecutionConfig) {
			this.executionConfig = options.executionConfig;
		} else if (typeof options.executionConfig === 'string') {
			this.executionConfig = new QuestionnaireExecutionConfig(JSON.parse(options.executionConfig));
		} else {
			this.executionConfig = new QuestionnaireExecutionConfig(options.executionConfig);
		}
		// Questionnaire Config
		if (options.questionnaireConfig !== undefined) {
			if (options.questionnaireConfig instanceof QuestionnaireConfig) {
				this.questionnaireConfig = options.questionnaireConfig;
			} else if (typeof options.questionnaireConfig === 'string') {
				this.questionnaireConfig = new QuestionnaireConfig(JSON.parse(options.questionnaireConfig));
			} else {
				this.questionnaireConfig = new QuestionnaireConfig(options.questionnaireConfig);
			}
		}
		if (options.addresseesShopIds !== undefined) this.addresseesShopIds = options.addresseesShopIds;
		if (options.addresseesManagerIds !== undefined) this.addresseesManagerIds = options.addresseesManagerIds;
		if (options.startDate !== undefined) this.startDate = options.startDate;
		if (options.startTime !== undefined) this.startTime = options.startTime;
		if (options.reportConfig !== undefined) this.reportConfig = options.reportConfig;
		questionnaireDataItemSchema.parse(this);
	}
}