import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TRANSPARENT_1PX } from '~components/ui/images';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

export const FloatButtonStyled = styled.div`
	display: flex;
	align-items: center;
	position: fixed;
	bottom: 24px;
	left: calc(50% - 50px);
	width: 100px;
	transition: transform 0.3s;

	&.hidden {
		transform: translateY(120px);
	}
`;

export const ButtonStyled = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 0.6rem 1.3rem 0.3rem 1.3rem;
	border-radius: 20px;
	width: 100px;
	background-color: #7A7CEA;
	cursor: pointer;
	transition: transform 0.3s;
	
	&:hover {
		background-color: #5A5CCB;
	}

	.icon.search, .icon.filter {
		position: relative;
		font-size: 1.6rem;
		color: #FFF;

		&::before {
			font-size: 1.6rem;
		}

		&.active::after {
			content: " ";
			display: block;
			width: 7px;
			height: 7px;
			position: absolute;
			background: #FFA8B7;
			right: 0;
			top: -5px;
			border: 3px solid #7A7CE3;
			box-sizing: content-box;
			border-radius: 20px;
		}
	}

	// для веба разрешаем менять цвет текста и иконок при наведении
	@media (min-width: 900px) {
		&:hover {
			//background-image: url(/assets/img/user-menu/plus-button-active.svg);
			transform: scale(1.05);
		}
	}

	@media (max-width: 550px) {
		bottom: 90px;
	}

	@media (max-width: 380px) {
		bottom: 80px;
	}
`;

const WizardDimmerStyled = styled.div`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(20, 20, 20, 0.8);
	z-index: 4;
`;

const WizardStyled = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 100%;
	min-height: 300px;
	width: 100%;
	max-width: 400px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	background-color: #FFF;
	padding: 0 1rem 1rem 1rem;

	hr {
		width: 50px;
		min-height: 6px;
		max-height: 6px;
		margin: 0.7rem auto;
		background: silver;
		border: 0;
		border-radius: 3px;
	}
`;

const WizardContentStyled = styled.div`
	overflow-y: auto;
`;

const FilterGroupStyled = styled.div`
	position: relative;
	padding: 0 10px;
	margin-bottom: 30px;
`;

const FilterHeaderStyled = styled.div`
	margin: 0 0 10px 6px;
	font-size: 14px;
	font-weight: bold;
	color: #2C2E4E;
`;

const FilterClearImg = styled.img`
	position: absolute;
	top: 0;
	right: 20px;
	width: 24px;
	height: 24px;
	background-image: url(/assets/img/filter-clear.svg);
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
`;

const FilterOptionsStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const FilterOptionStyled = styled.div`
	font-size: 16px;
	font-weight: 400;
	color: #474764;
	padding: 2px 6px;
	margin: 2px 0;
	cursor: pointer;
	border-radius: 4px;

	// для веба разрешаем менять цвет текста и иконок при наведении
	@media (min-width: 900px) {
		&:hover {
			background-color: ${p => p.theme.badgeButtonHoverBg};
			color: ${p => p.theme.badgeButtonHoverColor};
		}
	}

	&.selected {
		font-weight: bold;
		background-color: ${p => p.theme.badgeButtonActiveBg};
		color: ${p => p.theme.badgeButtonActiveColor};
	}
`;

const SearchWizardStyled = styled.div`
	margin: 0 20px 0 20px;

	.not-found-info {
		p:first-child {
			font-size: 15px;
			color: gray;
		}

		p:last-child {
			font-size: 13px;
			font-style: italic;
			color: silver;
		}
	}
`;

const SearchFieldStyled = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	height: 40px;
	border: 1px solid #BFBFBF;
	border-radius: 8px;
	background-color: #E4E6EB;

	input {
		flex: 1;
		margin-top: 2px;
		font-size: 16px;
		color: #2C2D4F;
		border: none;
		background: none;
		outline: none;
	}
`;

const SearchImg = styled.img`
	width: 20px;
	height: 20px;
	margin: 0 8px 0 10px;
	background-image: url(/assets/img/ui/input-search.svg);
	background-position: center;
	background-repeat: no-repeat;
`;

const SearchClearImg = styled.img`
	width: 24px;
	height: 24px;
	margin: 0 10px;
	background-image: url(/assets/img/ui/input-clear.svg);
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;

	// для веба разрешаем менять цвет текста и иконок при наведении
	@media (min-width: 900px) {
		&:hover {
			background-image: url(/assets/img/ui/input-clear-hover.svg);
		}
	}
`;

const SearchGroupStyled = styled.div`
	position: relative;
	padding: 0 10px;
	margin-bottom: 30px;
`;

const SearchHeaderStyled = styled.div`
	margin: 0 0 10px 6px;
	font-size: 14px;
	font-weight: bold;
	color: #2C2E4E;
`;

const SearchListStyled = styled.div`
`;

const SearchItemStyled = styled.div`
	font-size: 16px;
	color: #7A7CF2;
	font-weight: 400;
	padding: 2px 6px;
	margin: 2px 0;
	cursor: pointer;
	text-decoration: none;

	// для веба разрешаем менять цвет текста и иконок при наведении
	@media (min-width: 900px) {
		&:hover {
			color: #7A7CF2;
		}
	}

	.stats {
		display: block;
		margin: -2px 0 2px 0;
		font-size: 12px;
		color: #444;

		&:before {
			content: "";
			width: 8px;
			background: silver;
			margin: 0 5px 0 10px;
			border-radius: 50px;
			display: inline-block;
			height: 8px;
		}

		&.stats-red:before {
			background-color: #E24942;
		}

		&.stats-orange:before {
			background-color: #FF9900;
		}

		&.stats-green:before {
			background-color: #63B655;
		}
	}

	@media (min-width: 700px) {
		&:hover {
			text-decoration: underline;
		}
	}
`;

interface FilterItemPair
{
	text: string;
	value: string;
}

export interface FiltersWizardOption
{
	title: string;           // название группы фильтров
	options: FilterItemPair[]; // опции
	selected?: string;       // выбранные value
	multiselect?: boolean;   // возможность выбрать несколько пунктов
	clearable?: boolean;     // возможность очистить фильтр
	onChange: (value: string | null) => void;
}

export interface SearchWizardOptions
{
	searchString?: string;
	onSearchChanged: ChangeEventHandler<HTMLInputElement>;
	onSearchClear: () => void;
	isLoading: boolean;
	content: SearchWizardContentItem[] | null;
}

export interface SearchWizardContentItem
{
	title: string;
	items: {
		text: string;
		linkTo: string;
	}[];
	hasMore: boolean;
}

interface Props
{
	searchOptions?: SearchWizardOptions;
	filterOptions?: FiltersWizardOption[];
}

interface ScrollInfoState
{
	lastChecked: number;
	yOffset: number;
	checkDirectionTimerId?: NodeJS.Timeout;
}

export function SearchAndFiltersWizard(props: Props) {
	const [isHidden, setHidden] = useState<boolean>();
	const [isShowWizard, setShowWizard] = useState(false);
	const [scrollInfo] = useState<ScrollInfoState>({
		lastChecked: Date.now(),
		yOffset: window.scrollY,
		checkDirectionTimerId: undefined,
	});
	const searchInputRef = useRef<HTMLInputElement>(null);
	const floatButtonRef = useRef<HTMLDivElement>(null);

	const checkDirection = () => {
		const left = Date.now() - scrollInfo.lastChecked;
		if (left < 300) {
			// функция должна работать не чаще чем 0.3 секунды
			clearTimeout(scrollInfo.checkDirectionTimerId!!);
			scrollInfo.checkDirectionTimerId = setTimeout(checkDirection, left);
			return;
		}
		scrollInfo.lastChecked = Date.now();
		const y = window.scrollY;
		const d = y - scrollInfo.yOffset;
		if (scrollInfo.yOffset !== undefined && d > 0 !== isHidden) {
			setHidden(d > 0);
		}
		scrollInfo.yOffset = y;
	}

	useEffect(() => {
		const onScroll = () => {
			checkDirection();
		};
		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, [isHidden]);

	const isShowSearch = !!props.searchOptions;
	const isShowFilter = !!props.filterOptions;

	const isFilterSelected = props.filterOptions?.some(i => i.clearable);

	return (
		<>
			<FloatButtonStyled ref={floatButtonRef} className={isHidden ? 'hidden' : null}>
				<ButtonStyled onClick={() => setShowWizard(true)}>
					{isShowSearch ? <Icon name="search" /> : null}
					{isShowFilter ? (
						<Icon name="filter" className={isFilterSelected ? 'active' : undefined} />
					) : null}
				</ButtonStyled>
			</FloatButtonStyled>
			{isShowWizard ? (
				<WizardDimmerStyled onClick={() => setShowWizard(false)}>
					<WizardStyled onClick={e => e.stopPropagation()}>
						<hr />
						<WizardContentStyled>
							{renderSearch()}
							{renderFilters()}
						</WizardContentStyled>
					</WizardStyled>
				</WizardDimmerStyled>
			) : null}
		</>
	);

	function onSearchClear() {
		props.searchOptions?.onSearchClear();
		searchInputRef.current!.value = '';
	}

	function renderSearch() {
		if (!props.searchOptions) return null;
		return (
			<SearchWizardStyled>
				<SearchFieldStyled>
					<SearchImg src={TRANSPARENT_1PX} />
					<input ref={searchInputRef}
					       placeholder="Поиск"
					       defaultValue={props.searchOptions.searchString}
					       onChange={props.searchOptions.onSearchChanged} />
					{props.searchOptions.searchString ? (
						<SearchClearImg src={TRANSPARENT_1PX} onClick={onSearchClear} />
					) : null}
				</SearchFieldStyled>
				{props.searchOptions.content?.map((section, index) => (
					<SearchGroupStyled key={`${index}_${section.title}`}>
						<SearchHeaderStyled>{section.title}:</SearchHeaderStyled>
						<SearchListStyled>
							{section.items.map((item, index1) => (
								<SearchItemStyled key={index1}>
									<Link
										key={index1}
										onClick={() => setShowWizard(false)}
										to={item.linkTo}
									>
										{item.text}
									</Link>
								</SearchItemStyled>
							))}
						</SearchListStyled>
					</SearchGroupStyled>
				))}
				{props.searchOptions.content && !props.searchOptions.content.length ? (
					<div className="not-found-info">
						<p>Поиск не дал результатов.</p>
						<p>Попробуйте уточнить критерии поиска.</p>
					</div>
				) : null}
			</SearchWizardStyled>
		);
	}

	function renderFilters() {
		if (props.searchOptions?.content || !props.filterOptions) return null;
		return (
			props.filterOptions.map((filter, index) => {
				const { title, selected, options, multiselect, clearable, onChange } = filter;
				let selectedValue: string[] = [];
				if (selected) {
					if (multiselect) {
						selectedValue = selected.split(',');
					} else {
						selectedValue = [selected];
					}
				}

				return (
					<FilterGroupStyled key={`${title}-${index}`}>
						<FilterHeaderStyled>
							{title}:
							{clearable ? (
								<FilterClearImg src={TRANSPARENT_1PX}
								                onClick={() => onChange(null)} />
							) : null}
						</FilterHeaderStyled>
						<FilterOptionsStyled onClick={() => setShowWizard(false)}>
							{options.map(({ text, value }, index) => (
								<FilterOptionStyled key={`${index}-${value}`}
								                    className={selectedValue.includes(value) ? 'selected' : undefined}
								                    onClick={() => onClick(value)}>
									{text}
								</FilterOptionStyled>
							))}
						</FilterOptionsStyled>
					</FilterGroupStyled>
				)

				function onClick(value) {
					if (!multiselect) {
						onChange(value);
					} else if (selectedValue.includes(value)) {
						const k = selectedValue.indexOf(value);
						const arr = [...selectedValue];
						arr.splice(k, 1);
						onChange(arr.length ? arr.join(',') : null);
					} else {
						onChange(selectedValue.concat(value).join(','));
					}
				}
			})
		);
	}
}
