import { z } from 'zod';
import {
	IncidentGoodMapItem, IncidentGoodMapItemOptions,
	incidentGoodMapItemSchema,
	IncidentTypeMapItem, IncidentTypeMapItemOptions,
	incidentTypeMapItemSchema
} from '../composite';
import { OptionsItem, OptionsItemOptions, optionsItemSchema } from '../../../meta';

export const incidentMetaSchema = z.object({
	options: z.map(z.string(), optionsItemSchema).optional(),
	goodMap: z.map(z.string(), incidentGoodMapItemSchema).optional(),
	incidentTypeMap: z.map(z.string(), incidentTypeMapItemSchema).optional(),
});

export interface IncidentMetaOptions {
	options?: Record<string, OptionsItem | OptionsItemOptions>;
	goodMap?: Record<string, IncidentGoodMapItem | IncidentGoodMapItemOptions>;
	incidentTypeMap?: Record<string, IncidentTypeMapItem | IncidentTypeMapItemOptions>;
}

export class IncidentMeta {
	readonly options?: Record<string, OptionsItem>;
	readonly goodMap?: Record<string, IncidentGoodMapItem>;
	readonly incidentTypeMap?: Record<string, IncidentTypeMapItem>;

	constructor(options: IncidentMetaOptions) {
		if (options.options !== undefined) {
			this.options = {};
			for (const [key, value] of Object.entries(options.options)) {
				if (value instanceof OptionsItem) {
					this.options[key] = value;
				} else {
					this.options[key] = new OptionsItem(value);
				}
			}
		}
		if (options.goodMap !== undefined) {
			this.goodMap = {};
			for(const [key, value] of Object.entries(options.goodMap)) {
				if (value instanceof IncidentGoodMapItem) {
					this.goodMap[key] = value;
				} else {
					this.goodMap[key] = new IncidentGoodMapItem(value);
				}
			}
		}
		if (options.incidentTypeMap !== undefined) {
			this.incidentTypeMap = {};
			for(const [key, value] of Object.entries(options.incidentTypeMap)) {
				if (value instanceof IncidentTypeMapItem) {
					this.incidentTypeMap[key] = value;
				} else {
					this.incidentTypeMap[key] = new IncidentTypeMapItem(value);
				}
			}
		}
		incidentMetaSchema.parse(this);
	}
}