import { z } from 'zod';
import { AuthCredentialItem, AuthCredentialItemOptions, authCredentialItemSchema } from './AuthCredentialItem';
import { ShopManagerMeta, ShopManagerMetaOptions, shopManagerMetaSchema } from '../../../meta';

export const userMetaSchema = shopManagerMetaSchema.merge(
	z.object({
		authCredentialMap: z.map(z.string(), authCredentialItemSchema).optional(),
	}),
);

export interface UserMetaOptions extends ShopManagerMetaOptions{
	authCredentialMap?: Record<string, AuthCredentialItem | AuthCredentialItemOptions>;
}

export class UserMeta extends ShopManagerMeta {
	readonly authCredentialMap?: Record<string, AuthCredentialItem>;

	constructor(options: UserMetaOptions) {
		super(options);
		if (options.authCredentialMap !== undefined) {
			this.authCredentialMap = {};
			for(const [key, value] of Object.entries(options.authCredentialMap)) {
				if (value instanceof AuthCredentialItem) {
					this.authCredentialMap[key] = value;
				} else {
					this.authCredentialMap[key] = new AuthCredentialItem(value);
				}
			}
		}
		userMetaSchema.parse(this);
	}
}