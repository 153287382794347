import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	QuestionnaireQuizReportResultItem,
	QuestionnaireQuizReportResultItemOptions,
	questionnaireQuizReportResultItemSchema,
} from '../composite';

export const questionnaireQuizReportResultResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: questionnaireQuizReportResultItemSchema.optional(),
	}),
);

export interface QuestionnaireQuizReportResultResponseDtoOptions extends BaseResponseDtoOptions {
	data?: QuestionnaireQuizReportResultItem | QuestionnaireQuizReportResultItemOptions;
}

export class QuestionnaireQuizReportResultResponseDto extends BaseResponseDto {
	readonly data?: QuestionnaireQuizReportResultItem;

	constructor(options: QuestionnaireQuizReportResultResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			if (options.data instanceof QuestionnaireQuizReportResultItem) {
				this.data = options.data;
			} else {
				this.data = new QuestionnaireQuizReportResultItem(options.data);
			}
		}
		questionnaireQuizReportResultResponseDtoSchema.parse(this);
	}
}