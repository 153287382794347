import { z } from 'zod';
import {
	QuestionnaireAnswerQuizRequestDto,
	QuestionnaireAnswerQuizRequestDtoOptions,
	questionnaireAnswerQuizRequestDtoSchema,
} from '../requests';
import {
	QuestionnaireReportResultItem,
	QuestionnaireReportResultItemOptions,
	questionnaireReportResultItemSchema
} from './QuestionnaireReportResultItem';
import {
	QuestionnaireQuizItem,
	QuestionnaireQuizItemOptions,
	questionnaireQuizItemSchema
} from './QuestionnaireQuizItem';

export const questionnaireQuizReportResultItemSchema = questionnaireReportResultItemSchema.merge(
	z.object({
		respondingData: z.array(questionnaireAnswerQuizRequestDtoSchema).optional(),
		quizQuestions: z.array(questionnaireQuizItemSchema).optional(),
	}),
);

export interface QuestionnaireQuizReportResultItemOptions extends QuestionnaireReportResultItemOptions {
	respondingData?: (QuestionnaireAnswerQuizRequestDto | QuestionnaireAnswerQuizRequestDtoOptions)[];
	quizQuestions?: (QuestionnaireQuizItem | QuestionnaireQuizItemOptions)[];
}

export class QuestionnaireQuizReportResultItem extends QuestionnaireReportResultItem {
	readonly respondingData?: QuestionnaireAnswerQuizRequestDto[];
	readonly quizQuestions?: QuestionnaireQuizItem[];

	constructor(options: QuestionnaireQuizReportResultItemOptions) {
		super(options);
		if (options.respondingData?.length) {
			this.respondingData = [];
			for (const item of options.respondingData) {
				if (item instanceof QuestionnaireAnswerQuizRequestDto) {
					this.respondingData.push(item);
				} else {
					this.respondingData.push(new QuestionnaireAnswerQuizRequestDto(item));
				}
			}
		}
		if (options.quizQuestions?.length) {
			this.quizQuestions = [];
			for (const item of options.quizQuestions) {
				if (item instanceof QuestionnaireQuizItem) {
					this.quizQuestions.push(item);
				} else {
					this.quizQuestions.push(new QuestionnaireQuizItem(item));
				}
			}
		}
		questionnaireQuizReportResultItemSchema.parse(this);
	}
}