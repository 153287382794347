import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserRoles, PageInfo } from 'common-lib';
import { DataTableWithPageInfo, Spinner } from '~components';
import { getShopsList } from '~api/shopsApi';
import { fillTableData, TABLE_HEADERS } from '../shopsAttrs';
import { useLocation, useNavigate } from 'react-router-dom';

type State = {
	tableData: any
	checkQueryString: string
	isTableDataRequesting: boolean
};

export default function AdminShopsTablePage() {
	const [{
		tableData,
		checkQueryString,
		isTableDataRequesting,
	}, setTableData] = useState<Partial<State>>({});
	const { role } = useSelector((state: any) => state.me.currentUser);

	const location = useLocation();
	const navigate = useNavigate();
	const pageInfo = PageInfo.parseFromString(location.search);
	pageInfo.meta = new Set(['users']);

	if (!tableData || pageInfo.toQueryString() !== checkQueryString) {
		if (!isTableDataRequesting) {
			setTableData({ tableData, checkQueryString, isTableDataRequesting: true });
			getShopsList(pageInfo).then((json: any) => {
				const newTableData = fillTableData(json);
				setTableData({
					tableData: newTableData,
					checkQueryString: pageInfo.toQueryString(),
				});
			});
		}
	}

	return (
		<>
			{tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="warehouse"
					title="Магазины"
					headers={TABLE_HEADERS}
					rows={tableData.rows}
					pageInfo={tableData.pageInfo}
					onClickItem={itemId => navigate(`${location.pathname}/${itemId}`)}
					onAddItem={[UserRoles.ADMIN, UserRoles.SUPPORT].includes(role) ? () => {
						navigate(`${location.pathname}/+`);
					} : null}
					onChangePageOptions={options => {
						const pageInfo1 = PageInfo.parseFromString(location.search);
						pageInfo1.update(options);
						navigate(`${location.pathname}?${pageInfo1.toQueryString()}`);
					}}
				/>
			) : null}
			{isTableDataRequesting ? <Spinner /> : null}
		</>
	);
}
