import { z } from 'zod';
import {
	QuestionnaireAnswerTestingRequestDto,
	QuestionnaireAnswerTestingRequestDtoOptions,
	questionnaireAnswerTestingRequestDtoSchema
} from '../requests';
import {
	QuestionnaireReportResultItem,
	QuestionnaireReportResultItemOptions,
	questionnaireReportResultItemSchema
} from './QuestionnaireReportResultItem';
import {
	QuestionnaireTestingItem,
	QuestionnaireTestingItemOptions,
	questionnaireTestingItemSchema
} from './QuestionnaireTestingItem';

export const questionnaireTestingReportResultItemSchema = questionnaireReportResultItemSchema.merge(
	z.object({
		respondingData: z.array(questionnaireAnswerTestingRequestDtoSchema).optional(),
		quizQuestions: z.array(questionnaireTestingItemSchema).optional(),
	}),
);

export interface QuestionnaireTestingReportResultItemOptions extends QuestionnaireReportResultItemOptions {
	respondingData?: (QuestionnaireAnswerTestingRequestDto | QuestionnaireAnswerTestingRequestDtoOptions)[];
	quizQuestions?: (QuestionnaireTestingItem | QuestionnaireTestingItemOptions)[];
}

export class QuestionnaireTestingReportResultItem extends QuestionnaireReportResultItem {
	readonly respondingData?: QuestionnaireAnswerTestingRequestDto[];
	readonly quizQuestions?: QuestionnaireTestingItem[];

	constructor(options: QuestionnaireTestingReportResultItemOptions) {
		super(options);
		if (options.respondingData?.length) {
			this.respondingData = [];
			for (const item of options.respondingData) {
				if (item instanceof QuestionnaireAnswerTestingRequestDto) {
					this.respondingData.push(item);
				} else {
					this.respondingData.push(new QuestionnaireAnswerTestingRequestDto(item));
				}
			}
		}
		if (options.quizQuestions?.length) {
			this.quizQuestions = [];
			for (const item of options.quizQuestions) {
				if (item instanceof QuestionnaireTestingItem) {
					this.quizQuestions.push(item);
				} else {
					this.quizQuestions.push(new QuestionnaireTestingItem(item));
				}
			}
		}
		questionnaireTestingReportResultItemSchema.parse(this);
	}
}