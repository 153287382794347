import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminUserLayout from '../layouts/AdminUserLayout';
import SupportUserLayout from '../layouts/SupportUserLayout';
import UserLayout2 from '../layouts/UserLayout2';
import {
	AdminDiagnosticsFileGalleryPage,
	AdminDiagnosticsIncidentMarkingPage,
	AdminDiagnosticsRequestTestingPage,
	AdminDiagnosticsPage,
	AdminIncidentImportPage,
	AdminIncidentsPage,
	AdminIncidentTypeEditPage,
	AdminIncidentTypesTablePage,
	AdminInfoMessageEditPage,
	AdminInfoMessagesTablePage,
	AdminShopEditPage,
	AdminShopsTablePage,
	AdminUserEditPage,
	AdminUsersTablePage,
	DashboardIncidentImportsTablePage,
	DashboardPage,
	DashboardStructureImportsTablePage,
	PageNotFound,
	UserIncidentSectionPage,
	UserInfoMessagesListPage,
	UserInfoMessageViewPage,
	UserTaskSectionPage,
	UserChecklistTaskSectionPage,
	UserTodoListPage,
	AdminQuizListPage,
	AdminQuizCreatePage,
	AdminTestingCreatePage,
	AdminQuizReportsPage,
	AdminQuizStatisticPage,
	UserQuizListPage,
	UserQuizManagersPage,
	UserQuizSubShopPage,
	UserQuizPage,
	UserTestingPage,
	AdminConfigurationListPage,
	AdminConfigurationEditPage,
	UserPhotoGalleryPage,
	NoauthUserQuizViewPage,
	NoauthUserTestViewPage,
} from '../pages';

const ADMIN_ROUTE = (
	<AdminUserLayout>
		<Routes>
			<Route path="/" element={<DashboardPage />} />
			<Route path="/admin/dashboard/incident/import" element={<DashboardIncidentImportsTablePage />} />
			<Route path="/admin/dashboard/structure/import" element={<DashboardStructureImportsTablePage />} />
			<Route path="/admin/incidents" element={<AdminIncidentsPage />} />
			<Route path="/admin/incidents/import" element={<AdminIncidentImportPage />} />
			<Route path="/admin/incidents/types" element={<AdminIncidentTypesTablePage />} />
			<Route path="/admin/incidents/types/:incidentTypeId" element={<AdminIncidentTypeEditPage />} />
			{/* Информационные сообщения */}
			<Route path="/admin/infomsg" element={<AdminInfoMessagesTablePage />} />
			<Route path="/admin/infomsg/:infoMessageId" element={<AdminInfoMessageEditPage />} />
			{/* Магазины */}
			<Route path="/admin/shops" element={<AdminShopsTablePage />} />
			<Route path="/admin/shops/:shopId" element={<AdminShopEditPage />} />
			{/* Пользователи */}
			<Route path="/admin/users" element={<AdminUsersTablePage />} />
			<Route path="/admin/users/:userId" element={<AdminUserEditPage />} />
			{/* Опросы */}
			<Route path="/admin/quiz" element={<AdminQuizListPage />} />
			<Route path="/admin/quiz/create" element={<AdminQuizCreatePage />} />
			<Route path="/admin/quiz/edit/:id" element={<AdminQuizCreatePage />} />
			<Route path="/admin/testing/create" element={<AdminTestingCreatePage />} />
			<Route path="/admin/testing/edit/:id" element={<AdminTestingCreatePage />} />
			<Route path="/admin/quiz/reports" element={<AdminQuizReportsPage />} />
			<Route path="/admin/quiz/statistic/:id" element={<AdminQuizStatisticPage />} />
			{/* Управление конфигурациями */}
			<Route path="/admin/configuration" element={<AdminConfigurationListPage />} />
			<Route path="/admin/configuration/edit" element={<AdminConfigurationEditPage />} />
			<Route path="/admin/configuration/edit/:id" element={<AdminConfigurationEditPage />} />
			{/* Диагностика */}
			<Route path="/admin/diagnostics" element={<AdminDiagnosticsPage />} />
			<Route path="/admin/diagnostics/file-gallery" element={<AdminDiagnosticsFileGalleryPage />} />
			<Route path="/admin/diagnostics/incident-marking" element={<AdminDiagnosticsIncidentMarkingPage />} />
			<Route path="/admin/diagnostics/request-testing" element={<AdminDiagnosticsRequestTestingPage />} />
			{/* Ошибка: страница не найдена */}
			<Route path="*" element={<Navigate to='/404' replace />} />
			<Route path="/404" element={<PageNotFound />} />
		</Routes>
	</AdminUserLayout>
);

const SUPPORT_ROUTE = (
	<SupportUserLayout>
		<Routes>
			<Route path="/" element={<DashboardPage />} />
			<Route path="/admin/dashboard/incident/import" element={<DashboardIncidentImportsTablePage />} />
			<Route path="/admin/dashboard/structure/import" element={<DashboardStructureImportsTablePage />} />
			<Route path="/admin/incidents" element={<AdminIncidentsPage />} />
			<Route path="/admin/incidents/import" element={<AdminIncidentImportPage />} />
			<Route path="/admin/infomsg" element={<AdminInfoMessagesTablePage />} />
			<Route path="/admin/infomsg/:infoMessageId" element={<AdminInfoMessageEditPage />} />
			<Route path="/admin/shops" element={<AdminShopsTablePage />} />
			<Route path="/admin/shops/:shopId" element={<AdminShopEditPage />} />
			<Route path="/admin/users" element={<AdminUsersTablePage />} />
			<Route path="/admin/users/:userId" element={<AdminUserEditPage />} />
			<Route path="/admin/quiz" element={<AdminQuizListPage />} />
			<Route path="/admin/quiz/create" element={<AdminQuizCreatePage />} />
			<Route path="/admin/quiz/edit/:id" element={<AdminQuizCreatePage />} />
			<Route path="/admin/quiz/reports" element={<AdminQuizReportsPage />} />
			{/* Ошибка: страница не найдена */}
			<Route path="*" element={<Navigate to='/404' replace />} />
			<Route path="/404" element={<PageNotFound />} />
		</Routes>
	</SupportUserLayout>
);

const ids = [];

const SUPERVISOR_ROUTE = (
	<UserLayout2>
		<Routes>
			<Route path="/" element={<Navigate to="/incidents" replace />} />
			<Route path="/quiz-list" element={<UserQuizListPage />} />
			<Route path="/quiz-managers/:managerIds?" element={<UserQuizManagersPage />} />
			<Route path="/quiz-subshop/:shopId" element={<UserQuizSubShopPage />} />
			<Route path="/quiz/:id" element={<UserQuizPage />} />
			<Route path="/testing/:id" element={<UserTestingPage />} />
			<Route path="/incidents" element={<UserIncidentSectionPage />} />
			<Route path="/incidents/shop/:shopHashId" element={<UserIncidentSectionPage />} />
			<Route path="/incidents/shop/:shopHashId/type/:incidentTypeHashId" element={<UserIncidentSectionPage />} />
			<Route path="/incidents/shop/:shopHashId/type/:incidentTypeHashId/incident/:incidentHashId"
			       element={<UserIncidentSectionPage />} />
			<Route path="/incidents/:managerHashId" element={<UserIncidentSectionPage />} />
			{/* Задачи */}
			<Route path="/tasks" element={<UserTaskSectionPage />} />
			<Route path="/tasks/create" element={<UserTaskSectionPage />} />
			<Route path="/tasks/shop/:shopHashId" element={<UserTaskSectionPage />} />
			<Route path="/tasks/shop/:shopHashId/:taskHashId" element={<UserTaskSectionPage />} />
			<Route path="/tasks/:managerHashId" element={<UserTaskSectionPage />} />
			{/* Задачи по ЧЛ */}
			<Route path="/checklist_tasks" element={<UserChecklistTaskSectionPage />} />
			<Route path="/checklist_tasks/shop/:shopHashId" element={<UserChecklistTaskSectionPage />} />
			<Route path="/checklist_tasks/shop/:shopHashId/:checklistTaskHashId" element={<UserChecklistTaskSectionPage />} />
			<Route path="/checklist_tasks/:managerHashId" element={<UserChecklistTaskSectionPage />} />
			{/* Список дел */}
			<Route path="/todos" element={<UserTodoListPage />} />
			{/* Информационные сообщения */}
			<Route path="/infomsg" element={<UserInfoMessagesListPage />} />
			<Route path="/infomsg/:infoMessageId" element={<UserInfoMessageViewPage />} />
			{/* Ошибка: страница не найдена */}
			<Route path="*" element={<Navigate to='/404' replace />} />
			<Route path="/404" element={<PageNotFound />} />
		</Routes>
	</UserLayout2>
);

const DM_ZDM_ROUTE = (
	<UserLayout2>
		<Routes>
			<Route path="/" element={<Navigate to="/incidents" replace />} />
			{/* Опросы */}
			<Route path="/quiz-list" element={<UserQuizListPage />} />
			<Route path="/quiz/:id" element={<UserQuizPage />} />
			<Route path="/testing/:id" element={<UserTestingPage />} />
			{/* Инциденты */}
			<Route path="/incidents" element={<UserIncidentSectionPage />} />
			<Route path="/incidents/type/:incidentTypeHashId" element={<UserIncidentSectionPage />} />
			<Route path="/incidents/type/:incidentTypeHashId/incident/:incidentHashId" element={<UserIncidentSectionPage />} />
			{/* Задачи */}
			<Route path="/tasks" element={<UserTaskSectionPage />} />
			<Route path="/tasks/:taskHashId" element={<UserTaskSectionPage />} />
			{/* Задачи по ЧЛ */}
			<Route path="/checklist_tasks" element={<UserChecklistTaskSectionPage />} />
			<Route path="/checklist_tasks/:checklistTaskHashId" element={<UserChecklistTaskSectionPage />} />
			{/* Список дел */}
			<Route path="/todos" element={<UserTodoListPage />} />
			{/* Информационные сообщения */}
			<Route path="/infomsg" element={<UserInfoMessagesListPage />} />
			<Route path="/infomsg/:infoMessageId" element={<UserInfoMessageViewPage />} />
			{/* Фото галерея */}
			<Route path="/photo-gallery" element={<UserPhotoGalleryPage />} />
			{/* страница не найдена */}
			<Route path="*" element={<Navigate to='/404' replace />} />
			<Route path="/404" element={<PageNotFound />} />
		</Routes>
	</UserLayout2>
);

const NOAUTH_ROUTE = (
	<UserLayout2>
		<Routes>
			{/* Отчеты по опросам */}
			<Route path="/noauth-user/quiz-reports/:quizReportHashToken?/:subRole?/:subPath?" element={<NoauthUserQuizViewPage />} />
			<Route path="/noauth-user/testing-reports/:testReportHashToken?/:subRole?/:subPath?" element={<NoauthUserTestViewPage />} />
			{/* страница не найдена */}
			<Route element={<PageNotFound />} />
		</Routes>
	</UserLayout2>
);

export default {
	ADMIN_ROUTE,
	SUPPORT_ROUTE,
	SUPERVISOR_ROUTE,
	DM_ZDM_ROUTE,
	NOAUTH_ROUTE,
};
