import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { declOfNum, QuestionnaireTypeEnum, QuestionnaireTypeTitle } from 'common-lib';
import '../styles.css';
import { PagePaginator } from '~components';
import * as QuizApi from '~api/quizApi';
import Speedometer from '~components/ui/Speedometer';
import { addError } from '~store/actions/errorActions';
import { UserContentWrapper } from '~containers';
import { QuizQuestion, UserQuizSolving } from '../user/UserQuizSolving';
import { ITestingQuestion, ITestingSettings, UserTestingSolving } from '../user/UserTestingSolving';
import {
	PageInfoDto,
	QuestionnaireDataItem,
	QuestionnaireListResponseDto,
} from 'protocol-lib';

const HeaderPane = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PAGE_SIZE_OPTIONS = [
	{ key: '10', text: '10', value: 10 },
	{ key: '20', text: '20', value: 20 },
	{ key: '50', text: '50', value: 50 },
	{ key: '100', text: '100', value: 100 },
	{ key: '200', text: '200', value: 200 },
];

export function AdminQuizListPage() {
	const [quizList, setQuizList] = useState<QuestionnaireDataItem[]>();
	const [selectedQuizId, setSelectedQuizId] = useState<number | null>(null);
	const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
	const [isShowConfirmDisable, setIsShowConfirmDisable] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<string | null>();
	const [quizModal, setQuizModal] = useState<boolean>(false);
	const [quizModalName, setQuizModalName] = useState<string>('');
	const [questionnaireModalType, setQuestionnaireModalType] = useState<string>('QUIZ');
	const [testingModal, setTestingModal] = useState<boolean>(false);
	const [testingModalSettings, setTestingModalSettings] = useState<ITestingSettings>();
	const [quizModalQuestions, setQuizModalQuestions] = useState<QuizQuestion[]>([]);

	const [pageIndex, setPageIndex] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(PAGE_SIZE_OPTIONS[0].value);
	const [pageInfo, setPageInfo] = useState<PageInfoDto>();

	function getQuizList(): void {
		QuizApi.fetchQuizList(pageIndex, pageSize).then((res: QuestionnaireListResponseDto) => {
			setQuizList(res.data);
			setPageInfo(res.pageInfo);
		})
			.catch(err => {
				setAlertMessage(err.message || err.toString());
			});
	}

	function isQuizOneTimeFinished(quiz) {
		const now = Date.now();
		const startDate = new Date(`${quiz.executionConfig.startAtDate} ${quiz.executionConfig.startAtTime}`);
		const finishDate = new Date(`${quiz.executionConfig.finishAtDate} ${quiz.executionConfig.finishAtTime}`);
		if (!quiz.executionConfig.repeatEveryDays) {
			if (now > finishDate.getTime()) {
				return true;
			}
			if (['FINISHED', 'REPORTED', 'STOPPED'].includes(quiz.status)) {
				return true;
			}
		}
		if(quiz.executionConfig.repeatEveryDays) {
				if (now > finishDate.getTime()) {
					return true;
			}
				if (now > startDate.getTime() && ['FINISHED', 'REPORTED', 'STOPPED'].includes(quiz.status)) {
					return true;
				}
		}
	}

	function isQuizRunning(quiz) {
		console.log('%c*** quiz=', 'background: #eee; color: blue', quiz);
		if (quiz.status === 'RUNNING') {
			return true;
		}
		const { startAt, finishAt } = quiz;
		if (!startAt || !finishAt) return false;
		const startTime = new Date(startAt).getTime();
		const finishTime = new Date(finishAt).getTime();
		const now = Date.now();
		if (now >= startTime && now <= finishTime) return true;
		return false;

	}

	function enableQuiz(id: number): void {
		const quiz = quizList?.find(item => item.id === id);
		if (isQuizOneTimeFinished(quiz)) {
			setAlertMessage('Время выполнения опроса уже вышло');
			return;
		}
		QuizApi.enableQuiz(id)
			.then(() => getQuizList())
			.then(() => setTimeout(getQuizList, 3000))
			.catch(err => {
				setAlertMessage(err.message || err.toString());
			});
	}

	function disableQuiz(id: number | null = null): void {
		const quizId = typeof id === 'number' ? id : selectedQuizId;

		if (typeof quizId !== 'number') {
			return;
		}

		QuizApi.disableQuiz(quizId).then(() => {
			setIsShowConfirmDisable(false);
			getQuizList();
		})
			.then(() => setTimeout(getQuizList, 3000))
			.catch(err => {
				setAlertMessage(err.message || err.toString());
			});
	}

	function deleteQuiz(): void {
		if (typeof selectedQuizId !== 'number') {
			return;
		}

		QuizApi.deleteQuiz(selectedQuizId).then(() => {
			setIsShowConfirmDelete(false);
			getQuizList();
		})
			.catch(err => {
				setAlertMessage(err.message || err.toString());
			});
	}

	function createCopyQuiz(id): void {
		const confirmed = confirm('Вы дейстивтельно хотите создать копию опроса?');
		if (confirmed) {
			QuizApi.createQuizDuplicate(id).then(()=>{
				getQuizList();
			})
				.catch(err => {
					setAlertMessage(err.message || err.toString());
				});
		}
	}

	function showModalQuiz(quizId): void {
		QuizApi.fetchQuiz(quizId).then((res: any) => {
			setQuizModalQuestions(res.data.questionnaireConfig.questions.map(item => {
				const question: QuizQuestion = {
					text: item.text,
					type: item.type,
					startDate: res.data.executionConfig.startAtDate,
					startTime: res.data.executionConfig.startAtTime,
					photos: item.photos,
					isRequired: item.isRequired,
					isHidden: !!item.visibleOptions,
				};

				if (item.options) {
					question.options = item.options;
				}

				if (item.visibleOptions) {
					question.visibleOptions = item.visibleOptions;
				}

				return question;
			}));
			setQuizModalName(res.data.title);
			setQuizModal(true);
		});
	}

	function showModalTesting(testingId): void {
		QuizApi.fetchQuiz(testingId).then((res: any) => {
			setQuizModalQuestions(res.data.questionnaireConfig.questions.map(item => {
				const question: ITestingQuestion = {
					text: item.text,
					type: item.type,
					startDate: res.data.executionConfig.startAtDate,
					startTime: res.data.executionConfig.startAtTime,
					photos: item.photos,
				};

				if (item.options) {
					question.options = item.options;
				}

				return question;
			}));
			setQuizModalName(res.data.title);
			setQuestionnaireModalType(res.data.type);
			setTestingModalSettings(res.data.questionnaireConfig.questionsSettings);
			setTestingModal(true);
		});
	}

	function onPageSizeChange(_, { value: pageSize }: any): void {
		setPageIndex(0);
		setPageSize(pageSize);
	}

	function onPageChange(page): void {
		setPageIndex(page);
	}

	useEffect(() => {
		getQuizList();
	}, [pageIndex, pageSize]);

	console.log('%c*** pageInfo, page, pageSize=', 'background: #eee; color: blue', pageInfo, pageIndex, pageSize);

	return (
		<>
			<HeaderPane>
				<div>
					<Header as="h3" icon="edit" content="Опросы и тесты" />
				</div>
				<div>
					<Button primary size="small" icon="add" labelPosition="left"
					        content="Создать опрос" as={Link} to="/admin/quiz/create" />
					<Button primary size="small" icon="add" labelPosition="left"
					        content="Создать тест" as={Link} to="/admin/testing/create" />
					<Button size="small" icon="file excel outline" labelPosition="left"
					        content="Список отчетов" as={Link} to="/admin/quiz/reports" />
				</div>
			</HeaderPane>


			{quizList?.length ? (
				// список опросов
				<>
					{quizList.map(item => {
						const quizPlayDisabled = isQuizOneTimeFinished(item) || item.enabled;
						const repeatText = item.executionConfig.repeatEveryDays
							? (item.executionConfig.repeatEveryDays === 1
								? 'каждый день'
								: `каждые ${item.executionConfig.repeatEveryDays} ${declOfNum(item.executionConfig.repeatEveryDays, ['день', 'дня', 'дней'])}`)
							: null;
						const nowDateAndTime = moment().format();
						const finishDateAndTime = moment(item.finishAt ||
							`${item.executionConfig.finishAtDate}T${item.executionConfig.finishAtTime}`).format();
						const dateIsOver = nowDateAndTime > finishDateAndTime;
						return (
							<Segment key={item.id} className="quiz-list__item">
								<div className="__title">
									{item.title}
									<p className="__subtitle">
										{QuestionnaireTypeTitle[item.type]} для {item.questForManagers ? 'менеджеров' : 'магазинов'}
									</p>
								</div>
								<div className="__status">
									<div>
										{!item.enabled && !quizPlayDisabled && !dateIsOver &&  `${QuestionnaireTypeTitle[item.type]} остановлен`}
										{!item.enabled && quizPlayDisabled && dateIsOver && !item.status && `${QuestionnaireTypeTitle[item.type]} остановлен`}
										{(!item.enabled && !dateIsOver && quizPlayDisabled && item.status === 'FINISHED' && !item.executionConfig.repeatEveryDays) && `${QuestionnaireTypeTitle[item.type]} завершен`}
										{(!item.enabled && dateIsOver && quizPlayDisabled && item.status === 'FINISHED' && !item.executionConfig.repeatEveryDays) && `${QuestionnaireTypeTitle[item.type]} завершен`}
										{(!item.enabled && !dateIsOver && quizPlayDisabled && item.status === 'FINISHED' && item.executionConfig.repeatEveryDays) && `${QuestionnaireTypeTitle[item.type]} остановлен`}
										{(!item.enabled && dateIsOver && quizPlayDisabled && item.status === 'FINISHED' && item.executionConfig.repeatEveryDays) && `${QuestionnaireTypeTitle[item.type]} остановлен`}
										{(item.status !== undefined && item.enabled && ['IDLE', 'SCHEDULED'].includes(item.status)) && `${QuestionnaireTypeTitle[item.type]} запланирован`}
										{(item.status !== undefined && item.enabled && ['STOPPED', 'REPORTED'].includes(item.status)) && 'Формирование отчета'}
										{(item.enabled && item.status === 'RUNNING') && `${QuestionnaireTypeTitle[item.type]} сейчас идет`}
									</div>
									<div>
										{(item.enabled && item.status === 'RUNNING') &&
											moment(item.finishAt).format('Завершение DD.MM.YYYY в HH:mm')}
									</div>
								</div>
								<div className="__counter">
									<div style={{ marginRight: '10px'}}>
										<Speedometer width={60} height={60}
										             value={item.answeredCount}
										             max={item.addresseesCount * item.executionConfig.repeatCount} />
									</div>
									<div>
										<div>{item.questionCount} {declOfNum(item.questionCount, ['вопрос', 'вопроса', 'вопросов'])}</div>
										{item.questForManagers ? (
											<div>{item.addresseesCount} {declOfNum(item.addresseesCount, ['менеджер', 'менеджера', 'менеджеров'])}</div>
										) : (
											<div>{item.addresseesCount} {declOfNum(item.addresseesCount, ['магазин', 'магазина', 'магазинов'])}</div>
										)}
										<div>{item.addresseesCount * item.executionConfig.repeatCount} {declOfNum((item.addresseesCount * item.executionConfig.repeatCount), ['получатель', 'получателя', 'получателей'])}</div>
									</div>
								</div>
								<div className="__repeat">
									{item.executionConfig.repeatEveryDays ?
										<>
											<div>{`Повторять ${repeatText}`}</div>
											<div>
												{!item.enabled && !quizPlayDisabled && !dateIsOver && 'Следующий запуск'}
												{!item.enabled && quizPlayDisabled && !dateIsOver && item.status === 'FINISHED' && 'Последний запуск'}
												{!item.enabled && quizPlayDisabled && dateIsOver && !item.status && 'Время для запуска уже вышло'}
												{(item.status !== undefined && item.enabled && ['IDLE', 'SCHEDULED'].includes(item.status)) && 'Следующий запуск'}
												{(!item.enabled && quizPlayDisabled && dateIsOver && item.status === 'FINISHED') && 'Последний запуск'}
												{item.enabled && item.status === 'RUNNING' && `Время ${QuestionnaireTypeTitle[item.type]}а`}
											</div>
											{(!item.enabled && quizPlayDisabled && dateIsOver && !item.status) ? '' : (
												<div>
													{item.startAt ?
														<>
															{moment(item.startAt).format('DD.MM.YYYY HH:mm')}
															{' - '}
															{moment(item.finishAt).format('DD.MM.YYYY HH:mm')}
														</> :
														<>
															{item.executionConfig.startAtDate?.split('-').reverse().join('.')} {item.executionConfig.startAtTime}
															{' - '}
															{item.executionConfig.finishAtDate?.split('-').reverse().join('.')} {item.executionConfig.finishAtTime}
														</>
													}
												</div>
											)}
										</> :
										<>
											<div>Разовый {QuestionnaireTypeTitle[item.type].toLowerCase()}</div>
											<div>
												{!item.enabled && !quizPlayDisabled && 'Следующий запуск'}
												{!item.enabled && quizPlayDisabled && dateIsOver && !item.status && 'Время для запуска уже вышло'}
												{(item.status !== undefined && item.enabled && ['IDLE', 'SCHEDULED'].includes(item.status)) && 'Следующий запуск'}
												{(!item.enabled && item.status === 'FINISHED') && 'Последний запуск'}
												{item.enabled && item.status === 'RUNNING' && `Время ${QuestionnaireTypeTitle[item.type]}а`}
											</div>
											{(!item.enabled && quizPlayDisabled && dateIsOver && !item.status) ? '' : (
												<div>
													{item.startAt ?
														<>
															{moment(item.startAt).format('DD.MM.YYYY HH:mm')}
															{' - '}
															{moment(item.finishAt).format('DD.MM.YYYY HH:mm')}
														</> :
														<>
															{item.executionConfig.startAtDate?.split('-').reverse().join('.')} {item.executionConfig.startAtTime}
															{' - '}
															{item.executionConfig.finishAtDate?.split('-').reverse().join('.')} {item.executionConfig.finishAtTime}
														</>
													}
												</div>
											)}
										</>
									}
								</div>
								<div className="__actions">
									<div className="quiz-list__action">
										<Icon
											className="quiz-list__disable"
											name="stop circle outline"
											color={item.enabled ? 'black' : 'red'}
											disabled={!item.enabled}
											onClick={() => {
												if (isQuizRunning(item)) {
													setSelectedQuizId(item.id);
													setIsShowConfirmDisable(true);
												} else {
													disableQuiz(item.id);
												}
											}}
										/>
										{!item.enabled && <div className="quiz-list__action-text">Остановлен</div>}
									</div>
									<div className="quiz-list__action">
										<Icon
											className="quiz-list__enable"
											name="play circle outline"
											color={item.enabled ? 'green' : quizPlayDisabled ? 'grey' : 'black'}
											disabled={quizPlayDisabled}
											onClick={() => enableQuiz(item.id)}
										/>
										{item.enabled && <div className="quiz-list__action-text">Запущен</div>}
									</div>
									<div className="quiz-list__action">
										<Icon
											className="quiz-list__action"
											name="copy outline"
											onClick={() => createCopyQuiz(item.id)}
										/>
									</div>
									<div className="quiz-list__action">
										<Icon
											className="quiz-list__action"
											name="eye"
											onClick={()=> {
												item.type === QuestionnaireTypeEnum.TESTING
													? showModalTesting(item.id)
													: showModalQuiz(item.id);
											}}
										/>
									</div>
									<div className="quiz-list__action">
										{item.type === QuestionnaireTypeEnum.TESTING ? (
											<Link
												to={`/admin/testing/edit/${item.id}`}
												className={item.enabled ? 'quiz-list__disabled' : ''}
												onClick={event => {
													if (item.enabled) {
														event.preventDefault();
													}
												}}
											>
												<Icon
													name="edit outline"
													color="black"
													disabled={item.enabled}
												/>
											</Link>
										) : (
											<Link
												to={`/admin/quiz/edit/${item.id}`}
												className={item.enabled ? 'quiz-list__disabled' : ''}
												onClick={event => {
													if (item.enabled) {
														event.preventDefault();
													}
												}}
											>
												<Icon
													name="edit outline"
													color="black"
													disabled={item.enabled}
												/>
											</Link>
										)}
									</div>
									<div className="quiz-list__action">
										<Icon
											name="trash alternate outline"
											disabled={item.enabled}
											onClick={() => {
												setSelectedQuizId(item.id);
												setQuestionnaireModalType(item.type);
												setIsShowConfirmDelete(true);
											}}
										/>
									</div>
								</div>
							</Segment>
						);
					})}

					{pageInfo ? (
						<PagePaginator
							pageIndex={pageIndex}
							pageSize={pageSize}
							pageCount={pageInfo.pageCount}
							sizeOptions={PAGE_SIZE_OPTIONS}
							onChangePageSize={onPageSizeChange}
							onChangePageIndex={onPageChange}
						/>
					) : null}
				</>
			) : quizList ? (
				// пустой список
				<Segment secondary style={{ clear: 'both' }}>Нет записей</Segment>
			) : null}

			{alertMessage ? (
				// информационный алерт
				<Modal open size="tiny" closeOnEscape closeOnDimmerClick onClose={() => setAlertMessage(null)}>
					<Modal.Content>
						{alertMessage}
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={() => setAlertMessage(null)} content="Ок" />
					</Modal.Actions>
				</Modal>
			) : null}

			{quizModal  ? (
				<Modal open onClose={() => setQuizModal(false)} closeIcon>
					<Modal.Content style={{ backgroundColor: '#E4E6EB' }}>
						<UserContentWrapper title={`Опрос: ${quizModalName}`} isPreview>
							<UserQuizSolving questions={quizModalQuestions} submit={() => alert('Опрос заполнен успешно')} />
						</UserContentWrapper>
					</Modal.Content>
				</Modal>
			) : null}

			{testingModal  ? (
				<Modal open onClose={() => setTestingModal(false)} closeIcon>
					<Modal.Content style={{ backgroundColor: '#E4E6EB' }}>
						<UserContentWrapper title={`Тест: ${quizModalName}`} isPreview>
							<UserTestingSolving questions={quizModalQuestions} quizType={questionnaireModalType} questionsSettings={testingModalSettings} submit={() => alert(
								'Тест заполнен успешно'
							)} />
						</UserContentWrapper>
					</Modal.Content>
				</Modal>
			) : null}

			{isShowConfirmDelete ? (
				<Modal open size="tiny" closeOnEscape closeOnDimmerClick
				       onClose={() => setIsShowConfirmDelete(false)}>
					<Modal.Content>
						<p>
							{QuestionnaireTypeTitle[questionnaireModalType]} будет удален безвозвратно
						</p>
						<p>
							Продолжить?
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={() => setIsShowConfirmDelete(false)}
						        content="Нет" />
						<Button onClick={deleteQuiz} content="Да" />
					</Modal.Actions>
				</Modal>
			) : null}

			{isShowConfirmDisable ? (
				<Modal open size="tiny" closeOnEscape closeOnDimmerClick
				       onClose={() => setIsShowConfirmDisable(false)}>
					<Modal.Content>
						<p>
							В данный момент проводится {QuestionnaireTypeTitle[questionnaireModalType].toLowerCase()}
						</p>
						<p>
							Прервать?
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={() => setIsShowConfirmDisable(false)}
						        content="Нет" />
						<Button onClick={() => disableQuiz()} content="Да" />
					</Modal.Actions>
				</Modal>
			) : null}
		</>
	);
}

export default connect(
	null,
	{ addError },
)(AdminQuizListPage);
