import { createReducer } from 'redux-act';
import { LOAD_REPORT_FILE_LIST } from '../actions/incidentReportFileActions';

export default createReducer(
  {
    [LOAD_REPORT_FILE_LIST]: (state, { data: reportFiles, meta }) => {
      const { fileMap = {} } = meta || {};
      reportFiles.forEach(f => {
        const file = fileMap[f.fileId];
        f.downloadCount = file?.downloadCount;
        f.size = file?.size;
        f.name = file?.name;
      });
      return { ...state, reportFiles };
    },
  },
  {
    reportFiles: null,
  },
);
