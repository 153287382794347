/**
 * Метод создает копию текста в буфере обмена
 * @param str
 */
export const copyText = str => {
	const el = document.createElement('textarea');
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};

/**
 * Кодирование строки в base-64
 * @param str
 * @returns {string}
 */
export const str_to_base64 = str => Buffer.from(str).toString('base64');

/**
 * Кодирование bse-64 в строку
 * @param buf
 * @returns {string}
 */
export const base64_to_str = buf => Buffer.from(buf, 'base64').toString();

/**
 * Кодирование строки Unicode в base-64
 * @param str
 * @returns {string}
 */
export const utf8_to_base64 = str => window.btoa(unescape(encodeURIComponent(str)));

/**
 * Декодирование строки из base-64 в Unicode
 * @param str
 * @returns {string}
 */
export const base64_to_utf8 = str => decodeURIComponent(escape(window.atob(str)));

export const encrypt = str => str.split('').map(char => String.fromCharCode(char.charCodeAt(0) ^ 1)).reverse().join('');

export const decrypt = str => str.split('').reverse().map(char => String.fromCharCode(char.charCodeAt(0) ^ 1)).join('');

export const encrypt_utf8_to_base64 = str => window.btoa(encrypt(unescape(encodeURIComponent(str))));

export const decrypt_utf8_from_base64 = str => decodeURIComponent(escape(decrypt(window.atob(str))));
