import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { UserDataItem, UserDataItemOptions, userDataItemSchema } from '../composite';
import { UserMeta, UserMetaOptions, userMetaSchema } from '../meta';

export const userWithBaseResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(userDataItemSchema).optional(),
		meta: userMetaSchema.optional(),
	}),
);

export interface UserListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (UserDataItem | UserDataItemOptions)[];
	meta?: UserMeta | UserMetaOptions;
}

export class UserListResponseDto extends BaseResponseDto {
	readonly data?: UserDataItem[];
	readonly meta?: UserMeta;

	constructor(options: UserListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof UserDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new UserDataItem(item));
				}
			}
		}
		if (options.meta instanceof UserMeta) {
			this.meta = options.meta;
		} else if (options.meta !== undefined) {
			this.meta = new UserMeta(options.meta);
		}
		userWithBaseResponseDtoSchema.parse(this);
	}
}
