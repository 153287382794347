import React, {ReactElement, useEffect, useState} from 'react';
import { Button, Form, Header, Icon, Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import {
	declOfNum,
	QuestionnaireExecutionStatusTitle,
	QuestionnaireTypeEnum,
	QuestionnaireTypeTitle
} from 'common-lib';
import { ICheckedSubordinatesValue2 } from '~components/ui/AddresseeSelectorQuizStatistic';
import * as ShopsApi from '~api/shopsApi';
import * as UsersApi from '~api/usersApi';
import * as QuizApi from '~api/quizApi';
import { AddresseeSelectorTestStatistic, AddresseeSelectorQuizStatistic, Spinner } from '~components';
import { fillSubordinateMap2 } from '../../../lib/subordinateTools';
import { QuestionnaireStatisticReportItem } from 'protocol-lib';

const StatHeaderStyle = styled.div`
	display: flex;
  	align-items: center;
  	justify-content: space-between;

  	.navigate {
		display: flex;
		align-items: center;

		.back {
			margin-right: 10px;
		}
    }

	.buttons_stats {
        display: flex;
        align-items: center;
		justify-content: flex-end;
	}

	.buttons {
        display: flex;
		white-space: nowrap;
		margin-right: 20px;
		button:first-child {
			margin-right: 15px;
		}

		@media(max-width: 1300px) {
			flex-direction: column;
			margin-right: 0;
		}
	}

	.stat {
		white-space: nowrap;
	}

	@media(max-width: 960px) {
		flex-direction: column;
        align-items: flex-start;
	}
`;

const ModalContentStyle = styled.div`
	display:flex;
	flex-direction: column;
	align-items: center;

  	form {
		min-width: 70%;
    }
`;

interface IEmailData {
	emails: string;
	shopIds?: number[];
	managerIds?: number[];
	userCount: number;
	questExecId: number;
}

export default function AdminQuizStatisticPage() {
	function genCleanTreeInfo(): any {
		return {
			checkedAttemptUsers: new Map(),
			checkedShops: new Set(),
			checkedManagers: new Set(),
			checkedUsers: new Set(),
			checkedNotallUsers: new Set(),
			openedBranches: new Set(),
			openedShops: new Set<number>(),
			branchSelectedCount: new Map(),
			checkedAnswerShops: new Set(),
		};
	}

	const params = useParams();
	const quizReportId = Number(params.id);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isOpenModal1, setOpenModal1] = useState<boolean>(false);
	const [isOpenModal2, setOpenModal2] = useState<boolean>(false);
	const [quizData, setQuizData] = useState<QuestionnaireStatisticReportItem>();
	const [email, setEmail] = useState<string>('');
	const [originalSubordinates, setOriginalSubordinates] = useState<any>([]);
	const [subordinates, setSubordinates] = useState<any>([]);
	const [treeInfo, setTreeInfo] = useState<any>(genCleanTreeInfo());
	const [validationErrors, setValidationErrors] = useState<string[]>();
	const [activeFilter, setActiveFilter] = useState(0);

	useEffect(() => {
		setLoading(true);
		QuizApi.getStatReportId(quizReportId).then(res => {
			setQuizData(res.data);
		});
		setLoading(false);
	}, []);

	useEffect(() => {
		setLoading(true);
		if (quizData?.shops) {
			const shops = quizData.shops;
			ShopsApi.loadStatisticSubordinateShops(shops).then(res => {
				const subs = fillSubordinateMap2(res.data, quizData);
				setOriginalSubordinates(subs);
				updateSubordinatesByFilter(subs, 0);
			});
		} else if (quizData?.managers) {
			const managers = quizData.managers;
			UsersApi.loadStatisticSubordinateManagers(managers).then(res => {
				const subs = fillSubordinateMap2(res.data, quizData);
				setOriginalSubordinates(subs);
				updateSubordinatesByFilter(subs, 0);
			});
		}
		setLoading(false);
	}, [quizData]);

	function updateSubordinatesByFilter(subs: any, _activeFilter: number): void {
		function filterShop(shop: any): boolean {
			if (_activeFilter === 0) return true;
			else if (_activeFilter === 1) {
				return shop.answeredCount > 0;
			} else {
				return shop.answeredCount < shop.quizCount;
			}
		}

		function filterManagerSubs(user: any): any {
			user = { ...user };
			user.subordinateUsers = user.subordinateUsers?.map(filterManagerSubs).filter(Boolean);
			return user;
		}

		function filterSubs(user: any): any {
			user = { ...user };
			user.subordinateUsers = user.subordinateUsers?.map(filterSubs).filter(Boolean);
			user.subordinateShops = user.subordinateShops?.filter(filterShop);
			user.subShopSet = new Set();
			user.subordinateShops?.forEach(s => user.subShopSet.add(s.id)); // count shops
			user.subordinateUsers?.forEach(u =>  Array.from(u.subShopSet).forEach(n => user.subShopSet.add(n)));
			return user.subordinateShops?.length || user.subordinateUsers?.length
				? user
				: undefined;
		}

		const res = (quizData?.isSupervisor ? filterManagerSubs(subs) : filterSubs(subs)) || {};

		function recountShops(user: any): void {
			user.subordinateUsers?.forEach(recountShops);
			user.shopCount = user.subShopSet?.size;
		}
		if (!quizData?.isSupervisor) {
			recountShops(res);
		}

		setSubordinates(res);
		setTreeInfo(genCleanTreeInfo());
	}

	function handleChangeFilter(newActiveFilter): void {
		updateSubordinatesByFilter(originalSubordinates, newActiveFilter);
		setActiveFilter(newActiveFilter);
	}

	function onChangeTreeInfo(newTreeInfo: ICheckedSubordinatesValue2): void {
		setTreeInfo({ ...newTreeInfo });
	}

	const checkedUsersAttempt: number[] = Array.from(treeInfo.checkedAttemptUsers.values());
	const sizeAttemptUser = quizData?.isSupervisor
		? treeInfo.checkedManagers.size
		: checkedUsersAttempt.reduce((acc, i) => acc + i, 0);

	const selectedShopIds: number[] = Array.from(treeInfo.checkedShops.values());
	const selectedManagerIds: number[] = Array.from(treeInfo.checkedUsers.values());

	function validate(s: string): string[] | void {
		const emails: string[] = [];
		const list = s
			.split(/[\s,;]+/)
			.map(s1 => s1.trim())
			.filter(Boolean)
			.map(checkEmail);
		for (const s2 of list) {
			if (s2) {
				emails.push(s2);
			} else {
				return;
			}
		}
		if (!emails.length) return;
		return emails;
	}

	function checkEmail(s: string): string | undefined {
		if (/^\S+@\S+\.\S+$/g.test(s)) return s;
	}

	function renderValidationErrors(): ReactElement | null {
		if (!validationErrors?.length) return null;
		return (
			<>
				<ul className="validation-errors">
					{validationErrors.map(error => {
						return <li key={error}>{error}</li>;
					})}
				</ul>
			</>
		);
	}

	function validateAndSubmitForm(e, isSuperVisor?: boolean) {
		e.preventDefault();
		const emails = validate(email);
		if (!emails) {
			setValidationErrors(['Не правильно введён email']);
			return;
		}
		setOpenModal2(true);
		setValidationErrors(undefined);
		let data: IEmailData;
		if (isSuperVisor) {
			data = {
				emails: email,
				managerIds: selectedManagerIds,
				userCount: sizeAttemptUser,
				questExecId: quizReportId
			};
		} else {
			data = {
				emails: email,
				shopIds: selectedShopIds,
				userCount: sizeAttemptUser,
				questExecId: quizReportId
			};
		}
		// отправка данных по емейл
		QuizApi.fetchPhotoApplication(data)
			.then()
			.catch(err => {
				console.error(err);
			});
	}

	function onEmailChange(e) {
		setEmail(e.target.value);
	}

	const downloadFile = (isSupervisor: boolean, questExecId: number, shopIds?, managerIds?) => {
		if (!isSupervisor) {
			QuizApi.createReportByShops(questExecId, shopIds, activeFilter)
				.then(res => {
					const a = document.createElement('a');
					a.setAttribute('style', 'display: none');
					document.body.appendChild(a);
					a.setAttribute('href', `/api/file/download/${res.fileId}`);
					a.click();
					document.body.removeChild(a);
				});
		} else {
			QuizApi.createReportByManagers(questExecId, managerIds, activeFilter)
				.then(res => {
					const a = document.createElement('a');
					a.setAttribute('style', 'display: none');
					document.body.appendChild(a);
					a.setAttribute('href', `/api/file/download/${res.fileId}`);
					a.click();
					document.body.removeChild(a);
				});
		}

	};

	return (
		<>
			{isLoading  ? (
				<Spinner />
			) : (
				<>
					{quizData ? (
						<>
							<StatHeaderStyle>
								<div className="navigate">
									<div className="back">
										<Link style={{ display: 'inline-block' }} to="/admin/quiz/reports">
											<Icon name="arrow left" size="big" />
										</Link>
									</div>
									<div>
										<Link style={{ display: 'inline-block' }} to="/admin/quiz">
											<p style={{ fontSize: '1rem' }}>Опросы</p>
										</Link>
										<Icon size="large" name="triangle right" />
										<div>
											<Header as="h3" content={`Статистика: ${QuestionnaireTypeTitle[quizData.type]} "${quizData.title}"`} />
										</div>
									</div>
								</div>
								<div className="buttons_stats">
									<div className="buttons">
										{quizData.type === QuestionnaireTypeEnum.QUIZ ? (
											<Button
												style={{ width: '190px' }}
												icon
												labelPosition="left"
												size="tiny"
												disabled={sizeAttemptUser === 0 || activeFilter === 2}
												onClick={() => sizeAttemptUser > 0 ? setOpenModal1(true) : undefined}
											>
												<Icon name="save" />
												Скачать фото
												({sizeAttemptUser})
											</Button>
										) : null}
										<Button
											style={{ width: '190px' }}
											icon
											labelPosition="left"
											size="tiny"
											disabled={sizeAttemptUser === 0}
											onClick={() => sizeAttemptUser > 0
												? downloadFile(quizData.isSupervisor, quizReportId, selectedShopIds, selectedManagerIds)
												: undefined}
										>
											<Icon name="file" />
											Скачать отчет ({sizeAttemptUser})
										</Button>
									</div>
									<div className="stat">
										<div>Начало: {moment(quizData.startedAt).format('DD.MM.YYYY HH:mm')}</div>
										<div>Окончание: {moment(quizData.finishedAt).format('DD.MM.YYYY HH:mm')}</div>
										<div>Статус: {QuestionnaireExecutionStatusTitle[quizData.status]}</div>
									</div>
								</div>
							</StatHeaderStyle>

							{/* Дерево магазинов */}
							{quizData.type === QuestionnaireTypeEnum.QUIZ ? (
								<AddresseeSelectorQuizStatistic
									key={activeFilter}
									activeFilter={activeFilter}
									style={{ marginTop: '1rem' }}
									subordinates={subordinates}
									quizData={quizData}
									onFilterChange={handleChangeFilter}
									treeInfo={treeInfo}
									updateTreeInfo={onChangeTreeInfo}
								/>
							) : (
								<AddresseeSelectorTestStatistic
									key={activeFilter}
									activeFilter={activeFilter}
									style={{ marginTop: '1rem' }}
									subordinates={subordinates}
									quizData={quizData}
									onFilterChange={handleChangeFilter}
									treeInfo={treeInfo}
									updateTreeInfo={onChangeTreeInfo}
								/>
							)}

							{isOpenModal1 &&  sizeAttemptUser > 0 ? (
								<Modal
									size="tiny"
									closeOnDimmerClick
									closeOnEscape
									onClose={() =>setOpenModal1(false)}
									onOpen={() => setOpenModal1(true)}
									open={isOpenModal1}>
									<Modal.Content>
										<ModalContentStyle>
											<p>Ссылка на фото будет отправлена на электронную почту</p>
											<p>
												Выбрано{quizData.isSupervisor
												? `: ${selectedManagerIds.length} ${declOfNum(selectedManagerIds.length, ['менеджер', 'менеджера', 'менеджеров'])} ${sizeAttemptUser} ${declOfNum(sizeAttemptUser, ['попытка', 'попытки', 'попыток'])}`
												: ` ${sizeAttemptUser} ${declOfNum(sizeAttemptUser, ['магазин', 'магазина', 'магазинов'])}`}
											</p>
											<Form onSubmit={(e) => validateAndSubmitForm(e, quizData.isSupervisor)}>
												<input
													name="email"
													value={email}
												    placeholder="Введите email"
												    onChange={onEmailChange} />
												{renderValidationErrors()}
											</Form>
										</ModalContentStyle>
									</Modal.Content>
									<Modal.Actions>
										<Button onClick={() => setOpenModal1(false)} content="Отмена" />
										<Button
											type="submit"
											onClick={(e) => validateAndSubmitForm(e, quizData.isSupervisor)}
											positive
											content="Отправить"/>
									</Modal.Actions>
									<Modal
										onClose={() => setOpenModal2(false)} open={isOpenModal2} size="small">
										<ModalContent>
											<p>Заявка на формирование фотографий принята.</p>
											<p>Ссылка на скачивание архива фотографий придет на указанный (-ые) email.</p>
										</ModalContent>
										<ModalActions>
											<Button positive onClick={() => {
												setOpenModal2(false);
												setOpenModal1(false);
											}} content="Ок"/>
										</ModalActions>
									</Modal>
								</Modal>
							) : null}
						</>
					) : null}
				</>
			)}
		</>
	);
}
