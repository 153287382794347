import { z } from 'zod';
import { ShopDataItem, ShopDataItemOptions, shopDataItemSchema } from '../../shops';
import { UserDataItem, UserDataItemOptions, userDataItemSchema } from '../../users';
import { ShopManagerRoleItem, ShopManagerRoleItemOptions, shopManagerRoleItemSchema } from '../../../meta';


export const treeSubordinateUserDtoSchema = userDataItemSchema.merge(
	z.object({
		parent: userDataItemSchema.optional(),
		shortName: z.string(),
		shopCount: z.number().int(),
		userCount: z.number().int(),
		shopManagerRole: shopManagerRoleItemSchema.optional(),
		subordinateShops: z.array(shopDataItemSchema).optional(),
		subordinateUsers: z.array(userDataItemSchema).optional(),
	}),
);

export interface TreeSubordinateUserDtoOptions extends UserDataItemOptions {
	parent?: TreeSubordinateUserDtoOptions;
	shortName: string;
	shopCount: number;
	userCount: number;
	shopManagerRole?: ShopManagerRoleItem | ShopManagerRoleItemOptions;
	subordinateShops?: (ShopDataItem | ShopDataItemOptions)[];
	subordinateUsers?: (UserDataItem | UserDataItemOptions)[];
}

export class TreeSubordinateUserDto extends UserDataItem {
	readonly parent?: TreeSubordinateUserDto;
	readonly shortName: string;
	readonly shopCount: number;
	readonly userCount: number;
	readonly shopManagerRole?: ShopManagerRoleItem;
	readonly subordinateShops?: ShopDataItem[];
	readonly subordinateUsers?: UserDataItem[];

	constructor(options: TreeSubordinateUserDtoOptions) {
		super(options);
		if (options.parent !== undefined) this.parent = options.parent;
		this.shortName = options.shortName;
		this.shopCount = options.shopCount;
		this.userCount = options.userCount;
		if (options.shopManagerRole instanceof ShopManagerRoleItem) {
			this.shopManagerRole = options.shopManagerRole;
		} else if (options.shopManagerRole !== undefined) {
			this.shopManagerRole = new ShopManagerRoleItem(options.shopManagerRole);
		}
		if (options.subordinateShops?.length) {
			this.subordinateShops = [];
			for (const item of options.subordinateShops) {
				if (item instanceof ShopDataItem) {
					this.subordinateShops.push(item);
				} else {
					this.subordinateShops.push(new ShopDataItem(item));
				}
			}
		}
		if (options.subordinateUsers?.length) {
			this.subordinateUsers = [];
			for (const item of options.subordinateUsers) {
				if (item instanceof UserDataItem) {
					this.subordinateUsers.push(item);
				} else {
					this.subordinateUsers.push(new UserDataItem(item));
				}
			}
		}
		treeSubordinateUserDtoSchema.parse(this);
	}
}