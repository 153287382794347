import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { IncidentDataItem, IncidentDataItemOptions, incidentDataItemSchema } from '../composite';

export const incidentTypeStatisticResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: incidentDataItemSchema.optional(),
	}),
);

export interface IncidentTypeStatisticResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (IncidentDataItem | IncidentDataItemOptions)[];
}

export class IncidentTypeStatisticResponseDto extends BaseResponseDto {
	readonly data?: IncidentDataItem[];

	constructor(options: IncidentTypeStatisticResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof IncidentDataItem) {
					this.data.push(item);
				} else{
					this.data.push(new IncidentDataItem(item));
				}
			}
		}
		incidentTypeStatisticResponseDtoSchema.parse(this);
	}
}
