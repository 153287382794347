import { HttpError } from './HttpError';
import { ResponseErrorEnum } from '../enums';

export class ResponseError extends HttpError
{
	constructor(status: number, code: ResponseErrorEnum, message?: string, args?: any[]) {
		super(status, code, message, args);
	}

	/*
	 * Генераторы ошибок
	 */

	static error = (statusCode: number, errorCode: ResponseErrorEnum, message: string, ...args: any[]) => {
		if (typeof errorCode !== 'string') {
			throw new Error(`errorCode value ${errorCode} must be a string`);
		}
		return new ResponseError(statusCode, errorCode, message, args);
	}

	static unhandledException = (message: string, ...args: any[]) => ResponseError.error(500,
		ResponseErrorEnum.ERROR_CODE_UNHANDLED_EXCEPTION, message, ...args);

	static accessDenied = (message: string, ...args: any[]) => ResponseError.error(403,
		ResponseErrorEnum.ERROR_CODE_USER_HAS_NO_ACCESS, message, ...args);

	static notFound = (message: string, ...args: any[]) => ResponseError.error(404,
		ResponseErrorEnum.ERROR_CODE_NOT_FOUND, message, ...args);

	static requiredFields = (message: string, ...args: any[]) => ResponseError.error(400,
		ResponseErrorEnum.ERROR_CODE_NOT_ALL_REQUIRED_FIELDS, message, ...args);

	static validationInput = (message: string, ...args: any[]) => ResponseError.error(400,
		ResponseErrorEnum.ERROR_CODE_VALIDATION_INPUT, message, ...args);

	static badRequest = (message: string, ...args: any[]) => ResponseError.error(400,
		ResponseErrorEnum.ERROR_CODE_BAD_REQUEST, message, ...args);
}

ResponseError.prototype.name = 'ResponseError';

/*
 * Ответы состояния
 */

export const PROCESSING = new ResponseError(102,
	ResponseErrorEnum.ERROR_CODE_PROCESSING,
	'Сервер уже занят аналогичной задачей');

/*
 * Ошибки доступа и ошибки приложения
 */

export const AUTH_TOKEN_NOT_FOUND = new ResponseError(403,
	ResponseErrorEnum.ERROR_CODE_AUTH_TOKEN_NOT_FOUND,
	'Токен авторизации не найден');

export const AUTH_USER_NOT_FOUND = new ResponseError(403,
	ResponseErrorEnum.ERROR_CODE_AUTH_USER_NOT_FOUND,
	'Авторизованный пользователь не найден');

export const USER_HAS_NO_ACCESS = new ResponseError(403,
	ResponseErrorEnum.ERROR_CODE_USER_HAS_NO_ACCESS,
	'У пользователя не достаточно прав для использования этого API');

export const ROUTE_NOT_FOUND = new ResponseError(404,
	ResponseErrorEnum.ERROR_CODE_ROUTE_NOT_FOUND,
	'Роут не найден');

export const SERVER_ERROR = new ResponseError(500,
	ResponseErrorEnum.ERROR_CODE_SERVER_ERROR,
	'Произошла ошибка выполнения');

export const DATABASE_READ_ERROR = new ResponseError(500,
	ResponseErrorEnum.ERROR_CODE_DATABASE_READ_ERROR,
	'Произошла ошибка при чтении данных из БД');

export const DATABASE_WRITE_ERROR = new ResponseError(500,
	ResponseErrorEnum.ERROR_CODE_DATABASE_WRITE_ERROR,
	'Произошла ошибка при записи данных в БД');

export const DATABASE_UNIQUE_CONSTRAINT_ERROR = new ResponseError(500,
	ResponseErrorEnum.ERROR_CODE_DATABASE_UNIQUE_CONSTRAINT_ERROR,
	'Произошла ошибка уникальной записи при сохранении данных в БД');

export const PARSE_INPUT_DATA_EXCEPTION = new ResponseError(500,
	ResponseErrorEnum.ERROR_CODE_PARSE_INPUT_DATA_EXCEPTION,
	'Произошла ошибка во время парсинга входнных данных');

export const UNHANDLED_EXCEPTION = new ResponseError(500,
	ResponseErrorEnum.ERROR_CODE_UNHANDLED_EXCEPTION,
	'Произошла необработанная ошибка');

/*
 * Ошибки при регистрации пользователя
 */

export const HAVE_NO_MAIL_TEMPLATE_FOR_INPUT = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_HAVE_NO_MAIL_TEMPLATE_FOR_INPUT,
	'Не найден шаблон письма, который бы подошел для отправленных входных данных');

/*
 * User error responses
 */

export const USER_HAS_NO_ACCESS_TO_CHAGE_VALUE = new ResponseError(403,
	ResponseErrorEnum.ERROR_CODE_USER_HAS_NO_ACCESS_TO_CHAGE_VALUE,
	'Не достаточно прав на изменение');

export const NOT_ALL_REQUIRED_FIELDS = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_NOT_ALL_REQUIRED_FIELDS,
	'Не все обязательные поля заполнены');

export const VALIDATION_INPUT = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_VALIDATION_INPUT,
	'Поля не прошли валидацию');

export const UNIQUE_CONSTRAINT_ERROR = new ResponseError(400,
	ResponseErrorEnum.ERROR_UNIQUE_CONSTRAINT_ERROR,
	'При сохранении записи выявлена ошибка уникальности');

export const CSV_FILE_REQUIRED = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_CSV_FILE_REQUIRED,
	'Csv файл не был загружен. Проверьте content-type.');

export const REQUESTED_ITEM_NOT_FOUND = new ResponseError(404,
	ResponseErrorEnum.ERROR_CODE_REQUESTED_ITEM_NOT_FOUND,
	'Запрашиваемые данные не найдены');

export const USER_HAS_NO_ACCESS_TO_CHANGE_VALUE = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_USER_HAS_NO_ACCESS_TO_CHANGE_VALUE,
	'У пользователя не достаточно прав для обновления данных в запросе');

export const AUTH_AS_CURRENT_USER = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_AUTH_AS_CURRENT_USER,
	'Пользователь не может выполнять авторизацию под собой');

/*
 * Not found responses
 * Не являются ошибками, но содержат текст ошибки, сообщающий об отсутствии данных
 */

export const USER_NOT_FOUND = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_USER_NOT_FOUND,
	'Пользователь не найден');

export const USER_ROLE_NOT_FOUND = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_USER_ROLE_NOT_FOUND,
	'Роль не найдена');

export const FILE_NOT_FOUND = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_FILE_NOT_FOUND,
	'Файл не найден');

export const DF_NOT_FOUND = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_DF_NOT_FOUND,
	'ДФ не найден');

export const TM_NOT_FOUND = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_TM_NOT_FOUND,
	'ТМ не найден');

export const UPF_NOT_FOUND = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_UPF_NOT_FOUND,
	'УПФ не найден');

export const SHOP_NOT_FOUND = new ResponseError(400,
	ResponseErrorEnum.ERROR_CODE_SHOP_NOT_FOUND,
	'Магазин не найден');
