import React from 'react';
import PropTypes from 'prop-types';
import { AdminMenu, VersionInfo } from '~components';

interface ISupportUserLayoutProps {
	children: React.ReactNode;
}

export default class SupportUserLayout extends React.Component<ISupportUserLayoutProps>
{
	static propTypes = {
		children: PropTypes.node.isRequired,
	};

	render() {
		return (
			<>
				<AdminMenu />
				<div style={{ padding: '4rem 1rem' }}>
					{this.props.children}
				</div>
				<VersionInfo />
			</>
		);
	}
}
