import {
	fetchDelete,
	fetchGetThenJson,
	fetchPostThenJson,
	fetchPutThenJson,
} from '~tools/fetchBackend';
import { throwIfExcessKeys } from 'common-lib';
import { ShopListResponseDto, TreeListResponseDto } from 'protocol-lib';

const LIST_AVAIL_KEYS = ['page', 'order', 'search', 'meta'];

/**
 * Запрос списка магазинов.
 * @param pageInfo
 * @returns {Promise<unknown>}
 */
export async function getShopsList(pageInfo): Promise<ShopListResponseDto> {
	throwIfExcessKeys(pageInfo.toQueryObject(), LIST_AVAIL_KEYS, 'getShopsList.pageInfo');
	const query = pageInfo.toQueryString();
	return await fetchGetThenJson(`shops${query ? `?${query}` : ''}`);
}

/**
 * Запрос одного магазина.
 * @param id
 * @param meta
 * @returns {Promise<unknown>}
 */
export async function getShopById(id, meta) {
	if (!id) {
		throw new Error('Параметр id не может быть пустым при вызове getShopById');
	}
	const query = meta ? `?meta=${meta}` : '';
	return await fetchGetThenJson(`shop/${id}${query}`);
}

/**
 * Запрос на создание магазина.
 * @param itemData
 * @returns {Promise<unknown>}
 */
export async function createShop(itemData) {
	return await fetchPostThenJson('shop', itemData);
}

/**
 * Запрос на обновление магазина.
 * @param id
 * @param itemData
 * @returns {Promise<unknown>}
 */
export async function updateShop(id, itemData) {
	return await fetchPutThenJson(`shop/${id}`, itemData);
}

/**
 * Запрос на удаление магазина.
 * @param id
 * @returns {Promise<*>}
 */
export async function deleteShop(id) {
	return await fetchDelete(`shop/${id}`);
}

/**
 * Запрос списка подчиненных магазинов.
 * @returns {Promise<*>}
 */
export async function loadSubordinateShops(): Promise<TreeListResponseDto> {
	return await fetchGetThenJson(`structure/subordinate_shops/tree`);
}

/**
 * Запрос списка подчиненных магазинов для статистики.
 * @returns {Promise<*>}
 */
export async function loadStatisticSubordinateShops(shops): Promise<TreeListResponseDto> {
	return await fetchPostThenJson('statistic/subordinate_shops/tree', shops);
}
