import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Popup } from 'semantic-ui-react';
import { fromSqlDateToHumanDate, toHumanDate, toHumanTime } from 'common-lib';
import { Nowrap } from '~components';
import { copyText } from '~tools/copyText';

/**
 * Выполняет обогащение данных для таблицы истории импорта инцидентов.
 */
export const fillIncidentImportTableData = ({ data: rows = [], meta, pageInfo }) => {
	rows.forEach((row: any) => {
		row.humanDate = fromSqlDateToHumanDate(row.date);
		const createdAt = new Date(row.createdAt);
		row.humanCreatedDate = toHumanDate(createdAt);
		row.humanCreatedTime = toHumanTime(createdAt);
		// заполняем данные из meta в rows
		if (row.ownerId) row.owner = meta.userMap?.[row.ownerId];
		if (row.incidentTypeId) row.incidentType = meta.incidentTypeMap?.[row.incidentTypeId];
		if (row.fileId) row.file = meta.fileMap?.[row.fileId];
		if (row.logId) row.log = meta.logMap?.[row.logId];
	})
	return { rows, pageInfo };
}

/**
 * Выполняет обогащение данных для таблицы истории импорта структуры.
 */
export const fillStructureImportTableData = ({ data: rows = [], meta, pageInfo }) => {
	rows.forEach((row: any) => {
		row.humanDate = fromSqlDateToHumanDate(row.date);
		const createdAt = new Date(row.createdAt);
		row.humanCreatedDate = toHumanDate(createdAt);
		row.humanCreatedTime = toHumanTime(createdAt);
		// заполняем данные из meta в rows
		if (row.ownerId) row.owner = meta.userMap?.[row.ownerId];
		if (row.fileId) row.file = meta.fileMap?.[row.fileId];
		if (row.logId) row.log = meta.logMap?.[row.logId];
		// суммируем счетчики
		row.createdCount = row.createdUsersCount + row.createdShopsCount;
		row.updatedCount = row.updatedUsersCount + row.updatedShopsCount;
	})
	return { rows, pageInfo };
}

export const INCIDENT_IMPORTS_TABLE_HEADERS = {
	id: {
		title: '#',
		style: { maxWidth: '150px' },
		viewFunc: (row, hl) => (
			<Popup content="Нажми, чтобы скопировать" trigger={(
				<Nowrap style={{ cursor: 'pointer' }} onClick={event => {
					event.stopPropagation()
					copyText(row.id)
				}}>
					{hl(row.id)}
					<Icon name="copy"
					      style={{ color: '#aaa', fontSize: '0.9em', float: 'right' }} />
				</Nowrap>
			)} />
		),
	},
	$file: {
		title: 'Файл',
		viewFunc: row => row.file && (
			<Button icon labelPosition="left" size="tiny" onClick={() => downloadFile(row.file)}
			        title={row.file.name}>
				<Icon name="file" />
				{getFileTitleText(row.file)}
			</Button>
		),
	},
	date: {
		title: 'День инцидента',
		viewFunc: row => <Nowrap>{row.humanDate}</Nowrap>,
	},
	createdAt: {
		title: 'Время загрузки',
		viewFunc: row => (
			<span>
			{row.humanCreatedDate}
				&nbsp;
				<small>{row.humanCreatedTime}</small>
	</span>
		),
	},
	$incidentType: {
		title: 'Тип инцидента',
		viewFunc: (row, hl) => hl(row.incidentType.name),
	},
	$owner: {
		title: 'Загрузил',
		viewFunc: (row, hl) => (
			<Link to={`/admin/users/${row.ownerId}`}>
				{row.owner?.fullName ? hl(row.owner.fullName) : '(не указан)'}
			</Link>
		),
	},
	state: {
		title: 'Статус',
		viewFunc: row => (
			<Label
				color={row.state === 'WARN' ? 'orange' : row.state === 'ERROR' ? 'red' : undefined}
			>
				{row.state}
			</Label>
		),
	},
	$count: {
		title: 'Добавлено / Предупреждений',
		valueFunc: row => `${row.addedCount} / ${row.warnCount}`,
		viewFunc: row => (
			<>
				<Label circular
				       color={row.addedCount ? 'green' : undefined}>{row.addedCount}</Label>
				{!!row.warnCount && (
					<>
						&nbsp;/&nbsp;
						<Label circular
						       color={row.warnCount > 0 ? 'orange' : 'red'}>{row.warnCount}</Label>
					</>
				)}
			</>
		),
	},
	$message: {
		title: 'Лог',
		viewFunc: (row, _, callbacks) => row.logId ? (
			<Button size="tiny" onClick={() => callbacks.onShowLogModal(row)} content="Просмотр" />
		) : null,
	},
};

export const STRUCTURE_IMPORTS_TABLE_HEADERS = {
	id: {
		title: '#',
		style: { maxWidth: '150px' },
		viewFunc: (row, hl) => (
			<Popup content="Нажми, чтобы скопировать" trigger={(
				<Nowrap style={{ cursor: 'pointer' }} onClick={event => {
					event.stopPropagation()
					copyText(row.id)
				}}>
					{hl(row.id)}
					<Icon name="copy"
					      style={{ color: '#aaa', fontSize: '0.9em', float: 'right' }} />
				</Nowrap>
			)} />
		),
	},
	$file: {
		title: 'Файл',
		viewFunc: row => row.file && (
			<Button icon labelPosition="left" size="tiny" onClick={() => downloadFile(row.file)}
			        title={row.file.name}>
				<Icon name="file" />
				{getFileTitleText(row.file)}
			</Button>
		),
	},
	createdAt: {
		title: 'Время загрузки',
		viewFunc: row => (
			<span>
			{row.humanCreatedDate}
				&nbsp;
				<small>{row.humanCreatedTime}</small>
	</span>
		),
	},
	status: {
		title: 'Статус',
		viewFunc: row => (
			<Label
				color={row.state === 'WARN' ? 'orange' : row.state === 'ERROR' ? 'red' : undefined}
			>
				{row.status}
			</Label>
		),
	},
	$count: {
		title: 'Добавлено / Изменено / Предупреждений',
		valueFunc: row => `${row.createdCount} / ${row.updatedCount} / ${row.warnCount}`,
		viewFunc: row => (
			<>
				<Label circular color={row.createdCount + row.updatedCount ? 'green' : undefined}>
					{row.createdCount} / {row.updatedCount}
				</Label>
				{!!row.warnCount && (
					<>
						&nbsp;/&nbsp;
						<Label circular
						       color={row.warnCount > 0 ? 'orange' : 'red'}>{row.warnCount}</Label>
					</>
				)}
			</>
		),
	},
	$owner: {
		title: 'Загрузил',
		viewFunc: (row, hl) => (
			<Link to={`/admin/users/${row.ownerId}`}>
				{row.owner?.fullName ? hl(row.owner.fullName) : '(не указан)'}
			</Link>
		),
	},
	$message: {
		title: 'Лог',
		viewFunc: (row, _, callbacks) => row.logId ? (
			<Button size="tiny" onClick={() => callbacks.onShowLogModal(row)} content="Просмотр" />
		) : null,
	},
	$showFile: {
		title: 'Просмотр файла',
		viewFunc: (row, _, callbacks) => row.fileId ? (
			<Button size="tiny" onClick={() => callbacks.onShowImportFileModal(row)}>
				Просмотр
			</Button>
		) : null,
	},
};

const getFileTitleText = file => {
	let size = file.size;
	if (size < 2000) {
		size = `${size} b`;
	} else if (size < 2000_000) {
		size = `${(size / 1000).toFixed(1)} Kb`;
	} else {
		size = `${(size / 1000_000).toFixed(1)} Mb`;
	}
	return `Скачать (${size})`;
}

const downloadFile = file => {
	const a = document.createElement('a');
	a.setAttribute('style', 'display: none');
	document.body.appendChild(a);
	a.setAttribute('href', `/api/file/download/${file.id}`);
	a.setAttribute('download', file.name);
	a.setAttribute('type', 'plain/text');
	a.click();
	document.body.removeChild(a);
}
