const ORANGE = '#ec8d14';
const GREEN = '#32b132';
const GREEN_DARK = '#1a531b';
const BLUE = '#3e8ec1';
const RED = '#d40d0d';
const SILVER = '#5b5959';
const SILVER_LIGHT = '#9d9c9c';
const PURPLE = '#a06598';
const POTATO = '#a08765';

export const Colors = {
  default: SILVER,
  normal: BLUE,
  success: GREEN,
  warn: ORANGE,
  error: RED,
};
