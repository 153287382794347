import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	declOfNum,
	getRoleName,
	getServerUrl,
	QuestionnaireExecutionStatusEnum,
	QuestionnaireExecutionToShopStatusEnum,
	UserRoles
} from 'common-lib';
import { TRANSPARENT_1PX } from '~components/ui/images';
import { UiBadge } from '~components/index';
import { Button, ButtonGroup, Header, Progress } from 'semantic-ui-react';
import { identifyColorProgress } from '../../pages/quiz/admin/quizAttrs';

const ShopsHeaderStyle = styled.div`
	display: flex;
  	justify-content: space-between;
  	align-items: center;

    .shops {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 42px;

	    h3 {
		    margin-bottom: 0;
	    }
    }

    .checked_shop_user {
        display: flex;
	    p {
	    	margin-bottom: 0;
	    }
	    p:first-child {
		    margin-right: 5px;
	    }
    }

    @media(max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const TextFilterStyle = styled.div`
	font-size: 16px;
	color: rgba(0, 0, 0, .6);

	p {
        padding: 20px 30px;
    }
`;

const ProgressStyle = styled.div`
	display: grid;
    grid-template-columns: 2fr auto;
    grid-column-gap: 5px;
    align-items: center;

	.progress_bar {
		.label {
		    top: 0;
            width: 100%;
		}
	}

    &.header {
		width: 300px;
    }
    &.subordinates {
		width: 150px;
    }

	&.shop_user {
		margin-right: 5px;
	}
`;

const ProgressCountStyle = styled.div`
    display: flex;
    justify-content: center;
    white-space: nowrap;
    padding-right: 5px;
	min-width: 70px;
    margin-right: ${p => p.addMarginRight && '24px'};
`;

const MainProgressStyle = styled.div`
    display: flex;
	align-items: center;

	.main_progress {
        display: flex;
		align-items: center;
		margin-right: 5px;
		flex-direction: column;
	}
`;

interface ISelectSubordinatesProps {
	style?: CSSProperties;
	subordinates: ISubordinates2;
	quizData: any;
	onFilterChange: (i: number) => void;
	treeInfo: ICheckedSubordinatesValue2;
	updateTreeInfo: (newTreeInfo: any) => void;
	activeFilter: number;
}

export interface ISubordinates2 {
	subordinateUsers?: ISubordinateUser2[];
	subordinateShops?: ISubordinateShop2[];
	userMap: Map<number, ISubordinateUser2>;
	shopMap: Map<number, ISubordinateShop2>;
}

export interface ISubordinateUser2 {
	id: number;
	shopCount: number;
	role: UserRoles;
	lastName: string;
	firstName: string;
	middleName: string;
	fullName: string;
	shortName: string;
	parent?: ISubordinateUser2;
	subordinateUsers?: ISubordinateUser2[];
	subordinateShops?: ISubordinateShop2[];
	shopManagerRole?: any;
	answeredCount: number;
	answeredData?: any;
}

export interface ISubordinateShop2 {
	id: number;
	city: string;
	address: string;
	upfId?: number;
	tmId?: number;
	dfId?: number;
	supervisorUser: ISubordinateUser2;
	answeredCount: number;
	quizCount: number;
	answeredData: any;
}

export interface ICheckedSubordinatesValue2 {
	checkedAttemptUsers: Map<number, number>;
	checkedShops: Set<number>;
	checkedManagers: Set<number>;
	checkedUsers: Set<number>;
	checkedNotallUsers: Set<number>;
	openedBranches: Set<number>;
	openedShops: Set<number>;
	branchSelectedCount: Map<number, number>;
}

export default function AddresseeSelectorQuizStatistic(props: ISelectSubordinatesProps) {
	const { subordinates, treeInfo, updateTreeInfo, activeFilter } = props;

	const percentForShops = props.quizData.isSupervisor
		? Math.floor(100 / (props.quizData.addresseesCount) * props.quizData.answeredCount)
		: Math.floor(100 / (props.quizData.addresseesCount * props.quizData.repeatCount) * props.quizData.answeredCount);
	const allRepeatCount = props.quizData.isSupervisor
		? null
		: props.quizData.addresseesCount * props.quizData.repeatCount;
	const completedCountShops = props.quizData.answeredCount;
	const allCountShops = props.quizData.isSupervisor
		? props.quizData.addresseesCount
		: props.quizData.addresseesCount * props.quizData.repeatCount;

	useEffect(() => {
		// обновляем галки если в списке есть выбранные
		recountTree(subordinates, treeInfo, activeFilter);
	}, []);

	const onCheckedChange = () => {
		recountTree(subordinates, treeInfo, activeFilter, props.quizData.isSupervisor);
		updateTreeInfo(treeInfo);
	};

	const onCollapseChange = () => {
		updateTreeInfo(treeInfo);
	};

	const buttons = [
		{ title: `Все (${allCountShops})`},
		{ title: `Выполнено (${completedCountShops})`},
		{ title: `Не выполнено (${allCountShops - completedCountShops})`}
	];

	function filterChange (i) {
		props.onFilterChange(i);
	}
	const filteredUsers = subordinates.subordinateUsers
		?.filter(user => selectedFilter(user, activeFilter));

	const filteredShops = subordinates.subordinateShops
		?.filter(shop => selectedFilter(shop, activeFilter));

	return (
		<div style={props.style}>
			<ShopsHeaderStyle>
				<div className="shops">
					{props.quizData.isSupervisor ? (
						<Header as="h3" content={`Менеджеры: ${props.quizData.addresseesCount}`}  style={{ minWidth: '173px', marginBottom: '0px'}} />
						) : (
						<Header as="h3" content={`Магазины: ${props.quizData.addresseesCount} Сотрудники: ${allRepeatCount}`} />
					)}
					{props.quizData.isSupervisor ? (
						<div className="checked_shop_user">
							<p>
								Выбрано: {treeInfo.checkedUsers.size} {declOfNum(treeInfo.checkedUsers.size, ['менеджер', 'менеджера', 'менеджеров'])}
							</p>
						</div>
						) : (
						<div className="checked_shop_user">
							<p>
								Выбрано: {treeInfo.checkedShops.size} {declOfNum(treeInfo.checkedShops.size, ['магазин', 'магазина', 'магазинов'])}
							</p>
							<p>
								{sumValues(treeInfo.checkedAttemptUsers)} {declOfNum(sumValues(treeInfo.checkedAttemptUsers), ['сотрудник', 'сотрудника', 'сотрудников'])}
							</p>
						</div>
					)}
				</div>
				{!props.quizData.isSupervisor ? (
					<div className="filter">
						<ButtonGroup size="tiny">
							{buttons.map((b, i) => (
								<Button key={i} active={i === activeFilter} color={i === activeFilter ? 'grey' : undefined} onClick={() => filterChange(i)} content={b.title}/>
							))}
						</ButtonGroup>
					</div>
				) : null}
				<MainProgressStyle>
					<div className="main_progress">
						<ProgressStyle className="header">
							<Progress
								className="progress_bar"
								percent={percentForShops}
								color={identifyColorProgress(percentForShops)}
								style={{ marginBottom: 0 }}
							>
								{percentForShops} %
							</Progress>
						</ProgressStyle>
					</div>
					<ProgressCountStyle>
						{completedCountShops} / {allCountShops}
					</ProgressCountStyle>
				</MainProgressStyle>
			</ShopsHeaderStyle>
			{filteredUsers?.map((user) => {
				return (
					<UiBadge key={`user_${user.id}`}>
						{renderSubLevel(user, treeInfo, onCheckedChange, onCollapseChange, props.quizData, activeFilter)}
					</UiBadge>
				);
			})}
			{!filteredUsers?.length && (
				<TextFilterStyle>
					<UiBadge>
						{activeFilter === 1 && (
							<p>Отсутствуют выполненные опросы</p>
						)}
						{activeFilter === 2 && (
							<p>Отсутствуют невыполненные опросы</p>
						)}
					</UiBadge>
				</TextFilterStyle>
			)}
			{filteredShops?.map(shop => {
				return (
					<UiBadge key={`shop_${shop.id}`}>
						{renderSubShop(shop, treeInfo, onCheckedChange, onCollapseChange, props.quizData, activeFilter)}
					</UiBadge>
				);
			})}
		</div>
	);
}

const ItemLevelStyled = styled.div`
    display: flex;
    align-items: center;
    padding: 13px 8px;

    .info {
        flex: 1;
        overflow: hidden;
        padding: 0 16px;
    }

    .fio {
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .counter {
        font-size: 11px;
        color: #9696A6;
    }
`;

const ReportResultStyle = styled.div`
	margin-right: 24px;
  	user-select: none;

	p {
		color: #bbbbbb;
	}
`;

const CheckerImg = styled.img`
    width: 24px;
    height: 24px;
    background-image: ${p => !p.empty ? `url(/assets/img/checker-${p.checked ? 'checked' : p.notall ? 'notall' : 'empty'}.svg);` : 'none'};
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
`;

const CollapseImg = styled.img`
    width: 24px;
    height: 24px;
    background-image: url(/assets/img/collapse-${p => p.opened ? 'open' : 'close'}.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
	margin-right: ${p => p.isSupervisor && '24px'};
`;

const SubLevelStyled = styled.div`
    padding-left: 16px;
`;

const SelectAllManagersStyle = styled.button`
	margin: 0 5px 0 10px;
`;

const QuizResultStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 5px;
`;

function recountTree(subordinateItems, treeInfo: ICheckedSubordinatesValue2, activeFilter: number, isSupervisor?: boolean): void {
	const {
		checkedUsers,
		checkedShops,
		checkedNotallUsers,
		checkedAttemptUsers,
		branchSelectedCount,
	} = treeInfo;
	checkedUsers.clear();
	checkedAttemptUsers.clear();
	checkedNotallUsers.clear();
	branchSelectedCount.clear();

	if (isSupervisor) {
		const foo = (user) => {
			const managerCountRes = new Set<number>();
			if (treeInfo.checkedManagers.has(user.id)) {
				treeInfo.checkedUsers.add(user.id);
				checkedAttemptUsers.set(user.id, user.quizCount);
			} else {
				treeInfo.checkedUsers.delete(user.id);
			}
			user.subordinateUsers?.forEach(subUser => {
				foo(subUser);
				if (treeInfo.checkedUsers?.has(subUser.id)) managerCountRes.add(subUser.id);
			});
			if (managerCountRes.size > 0) {
				branchSelectedCount.set(user.id, managerCountRes.size);
			}
			return managerCountRes;
		};
		subordinateItems.subordinateUsers?.forEach(foo);
	} else {
		const foo = (user): Set<number> => {
			const res = new Set<number>();
			user.subordinateUsers?.forEach(subUser => {
				const subSet = foo(subUser);
				Array.from(subSet).forEach(n => res.add(n));
			});
			user.subordinateShops?.forEach(subShop => {
				const isShopSetForFilter = selectedFilter(subShop, activeFilter);
				if (checkedShops.has(subShop.id) && isShopSetForFilter) {
					res.add(subShop.id);
					if (activeFilter === 0) {
						checkedAttemptUsers.set(subShop.id, subShop.quizCount);
					} else if (activeFilter === 1) {
						checkedAttemptUsers.set(subShop.id, subShop.answeredCount);
					} else {
						checkedAttemptUsers.set(subShop.id, subShop.quizCount - subShop.answeredCount);
					}
				}
			});
			if (res.size > 0) {
				if (user.shopCount === res.size) {
					checkedUsers.add(user.id);
				} else {
					checkedUsers.delete(user.id);
					checkedNotallUsers.add(user.id);
				}
				branchSelectedCount.set(user.id, res.size);
			}
			return res;
		};
		subordinateItems.subordinateUsers?.forEach(foo);
	}
}

function selectAllManagers(user, treeInfo: ICheckedSubordinatesValue2, onChange: () => void) {
	const foo = (subUser) => {
		if (treeInfo.checkedManagers.has(subUser.id)) {
			treeInfo.checkedManagers.delete(subUser.id);
		} else {
			treeInfo.checkedManagers.add(subUser.id);
		}
		subUser.subordinateUsers?.forEach(foo);
	};
	foo(user);
	onChange();
}

/**
 * Меняется отметка чекера менеджера
 */
function checkUser(user, treeInfo: ICheckedSubordinatesValue2, onChange: () => void, isSupervisor?: boolean) {
	if (isSupervisor) {
		if (treeInfo.checkedManagers.has(user.id)) {
			treeInfo.checkedManagers.delete(user.id);
			treeInfo.checkedAttemptUsers.delete(user.id);
		} else {
			treeInfo.checkedManagers.add(user.id);
		}
		onChange();
	} else {
		if (!user.shopCount) return; // fool check
		const flag = !treeInfo.checkedUsers.has(user.id);
		// ставим или снимаем отметки в дереве для подчиненных магазинов
		const foo = subUser => {
			subUser.subordinateShops?.forEach(shop => {
				treeInfo.checkedShops[flag ? 'add' : 'delete'](shop.id);
			});
			subUser.subordinateUsers?.forEach(foo);
		};
		foo(user);
		onChange();
	}
}

/**
 * Меняется отметка чекера магазина
 */
function checkShop(shop, treeInfo: ICheckedSubordinatesValue2, onChange: () => void) {
	const { checkedShops } = treeInfo;
	checkedShops[checkedShops.has(shop.id) ? 'delete' : 'add'](shop.id);
	onChange();
}

function renderSubLevel(user, treeInfo: ICheckedSubordinatesValue2, onCheckedChange, onCollapseChange, quizData, activeFilter) {
	const { subordinateUsers, subordinateShops } = user;
	const isOpened = treeInfo.openedBranches.has(user.id);
	const percent = Math.floor(100 / user.quizCount * user.answeredCount);
	const filterUsers = subordinateUsers?.filter(subUser => selectedFilter(subUser, activeFilter));
	const filterShops = subordinateShops?.filter(subShop => selectedFilter(subShop, activeFilter));
	const serverUrl = getServerUrl();
	const status = user.answeredData?.status === QuestionnaireExecutionStatusEnum.FINISHED ? 1 : 0;

	const allQuestionCount = `${user.answeredCount} / ${user.quizCount}`;
	const answeredCount = `${user.answeredCount} / ${user.answeredCount}`;
	const notAnsweredCount = `0 / ${user.quizCount - user.answeredCount}`;

	let questionCount: string;
	if (activeFilter === 0) {
		questionCount = allQuestionCount;
	} else if (activeFilter === 1) {
		questionCount = answeredCount;
	} else {
		questionCount = notAnsweredCount;
	}

	let percentWithFilter: number;
	if (activeFilter === 0) {
		percentWithFilter = percent;
	} else if (activeFilter === 1) {
		percentWithFilter = 100;
	} else {
		percentWithFilter = 0;
	}

	return (
		<div key={user.id}>
			<ItemLevelStyled>
				<CheckerImg src={TRANSPARENT_1PX}
				            empty={!quizData.isSupervisor && !user.shopCount}
				            checked={treeInfo.checkedUsers.has(user.id)}
				            notall={treeInfo.checkedNotallUsers.has(user.id)}
				            onClick={() => checkUser(user, treeInfo, onCheckedChange, quizData.isSupervisor)} />
				<div className="info">
					<div className="fio">{user.shopManagerRole?.shortTitle || getRoleName(user.role)} {user.fullName}</div>
					{quizData.isSupervisor ? (
						<div className="counter">
							{treeInfo.branchSelectedCount.has(user.id) ? (
								<>
									Выбрано
									подчиненных: {treeInfo.branchSelectedCount.get(user.id)} из {user.userCount}
								</>
							) : (
								<>
									{user.userCount ? `Подчиненных: ${user.userCount}` : 'Нет подчиненных'}
								</>
							)}
							{/*{user.userCount ? (*/}
							{/*	<SelectAllManagersStyle onClick={() => selectAllManagers(user, treeInfo, onCheckedChange)}>*/}
							{/*		Выбрать всех*/}
							{/*	</SelectAllManagersStyle>*/}
							{/*) : null}*/}
						</div>
						) : (
						<>
							{treeInfo.branchSelectedCount.has(user.id) ? (
								<div className="counter">
									Выбрано
									магазинов: {treeInfo.branchSelectedCount.get(user.id)} из {user.shopCount}
								</div>
							) : (
								<div className="counter">
									{user.shopCount ? `Магазинов: ${user.shopCount}` : 'Нет подчиненных магазинов'}
								</div>
							)}
						</>
					)}
				</div>
				{quizData.isSupervisor ? (
					<>
						<ItemLevelStyled>
							<ReportResultStyle>
								{status > 0 ? (
									<a href={`${serverUrl}/noauth-user/quiz-reports/${user.answeredData.quizResultHashCode}`} target="_blank">
										Показать результаты
									</a>
								) : (
									<p>Показать результаты</p>
								)}
							</ReportResultStyle>
						</ItemLevelStyled>
					</>
				) : null}
				<QuizResultStyle>
					<ProgressStyle className="subordinates">
						<Progress
							className='progress_bar'
							percent={percentWithFilter}
							color={identifyColorProgress(percentWithFilter)}
							style={{ marginBottom: 0 }}
						>
							{percentWithFilter} %
						</Progress>
					</ProgressStyle>
				</QuizResultStyle>
				<ProgressCountStyle addMarginRight={!subordinateUsers?.length && quizData.isSupervisor || !subordinateShops?.length && quizData.isSupervisor}>
					{questionCount}
				</ProgressCountStyle>
				{subordinateUsers?.length || subordinateShops?.length && !quizData.isSupervisor ? (
					<CollapseImg src={TRANSPARENT_1PX} opened={isOpened} onClick={() => {
						if (isOpened) {
							treeInfo.openedBranches.delete(user.id);
						} else {
							treeInfo.openedBranches.add(user.id);
						}
						onCollapseChange();
					}} />
				) : null}
			</ItemLevelStyled>
			{isOpened && subordinateUsers?.length ? (
				<SubLevelStyled>
					{filterUsers.map(subUser =>
						renderSubLevel(
							subUser,
							treeInfo,
							onCheckedChange,
							onCollapseChange,
							quizData,
							activeFilter,
						)
					)}
				</SubLevelStyled>
			) : null}
			{isOpened && !quizData.isSupervisor && subordinateShops?.length ? (
				<SubLevelStyled>
					{filterShops.map(shop => {
						return renderSubShop(shop, treeInfo, onCheckedChange, onCollapseChange, quizData, activeFilter);
					})}
				</SubLevelStyled>
			) : null}
		</div>
	);
}

function renderSubShop(
		shop: ISubordinateShop2,
        treeInfo: ICheckedSubordinatesValue2,
		onCheckedChange: () => void,
		onCollapseChange: () => void,
		quizData: any,
		activeFilter: number
	) {
	const isChecked = treeInfo.checkedShops.has(shop.id);
	const isOpened = treeInfo.openedShops.has(shop.id);
	const percent = Math.floor(100 / shop.quizCount * shop.answeredCount);

	const allQuestionCount = `${shop.answeredCount} / ${shop.quizCount}`;
	const answeredCount = `${shop.answeredCount} / ${shop.answeredCount}`;
	const notAnsweredCount = `0 / ${shop.quizCount - shop.answeredCount}`;

	let questionCount: string;
	if (activeFilter === 0) {
		questionCount = allQuestionCount;
	} else if (activeFilter === 1) {
		questionCount = answeredCount;
	} else {
		questionCount = notAnsweredCount;
	}

	let percentWithFilter: number;
	if (activeFilter === 0) {
		percentWithFilter = percent;
	} else if (activeFilter === 1) {
		percentWithFilter = 100;
	} else {
		percentWithFilter = 0;
	}

	return (
		<div key={shop.id}>
			<ItemLevelStyled>
				<CheckerImg src={TRANSPARENT_1PX}
				            checked={isChecked}
				            onClick={() => checkShop(shop, treeInfo, onCheckedChange)} />
				<div className="info">
					<div className="fio">{shop.address || 'Адрес не указан'}</div>
					<div className="counter">{shop.city || 'Без города'}</div>
				</div>
				<QuizResultStyle>
					<ProgressStyle className="subordinates">
						<Progress
							className='progress_bar'
							percent={percentWithFilter}
							color={identifyColorProgress(percentWithFilter)}
							style={{ marginBottom: 0 }}
						>
							{percentWithFilter} %
						</Progress>
					</ProgressStyle>
				</QuizResultStyle>
				<ProgressCountStyle>
					{questionCount}
				</ProgressCountStyle>
				{quizData.repeatCount ? (
					<CollapseImg src={TRANSPARENT_1PX} opened={isOpened} onClick={() => {
						if (isOpened) {
							treeInfo.openedShops.delete(shop.id);
						} else {
							treeInfo.openedShops.add(shop.id);
						}
						onCollapseChange();
					}} />
				) : null}
			</ItemLevelStyled>
			{isOpened ? (
				<SubLevelStyled >
					{shop.answeredData
						.filter(attemptUser => filterShopUsers(attemptUser, activeFilter))
						.map((attemptUser, index)=> {
							const attemptIndex = index + (activeFilter === 2 ? shop.answeredCount : 0);
							return renderAttemptUser(attemptUser, attemptIndex + 1);
						})}
				</SubLevelStyled>
			) : null}
		</div>
	);
}

function renderAttemptUser(attemptUser: any, attemptIndex: number, user?, isSupervisor?: boolean) {
	const serverUrl = getServerUrl();
	const status = attemptUser.status === QuestionnaireExecutionStatusEnum.FINISHED ? 1 : 0;
	const percent = Math.floor(100 * status);

	return (
		<div key={attemptUser.quizResultHashCode}>
			<ItemLevelStyled>
				<div className="info">
					<div className="fio">{isSupervisor ? `${user.fullName} ${attemptIndex}` : `Сотрудник ${attemptIndex}`}</div>
				</div>
				<ReportResultStyle>
					{status > 0 ? (
						<a href={`${serverUrl}/noauth-user/quiz-reports/${attemptUser.quizResultHashCode}`} target="_blank">
							Показать результаты
						</a>
					) : (
						<p>Показать результаты</p>
					)}
				</ReportResultStyle>
				<ProgressStyle className="subordinates shop_user">
					<Progress
						className='progress_bar'
						percent={percent}
						color={identifyColorProgress(percent)}
						style={{ marginBottom: 0 }}
					>
						{percent} %
					</Progress>
				</ProgressStyle>
				<ProgressCountStyle>
					{status} / {1}
				</ProgressCountStyle>
			</ItemLevelStyled>
		</div>
	);
}

function selectedFilter(item: any, activeFilter: number): boolean {
	if (activeFilter === 1) {
		return item.answeredCount > 0;
	}
	if (activeFilter === 2) {
		return item.answeredCount < item.quizCount;
	}
	return true;
}

function filterShopUsers(item: any, activeFilter: number) {
	if (activeFilter === 1) {
		return item.status === QuestionnaireExecutionToShopStatusEnum.FINISHED;
	}
	if (activeFilter === 2) {
		return  item.status === QuestionnaireExecutionToShopStatusEnum.FAILED
			|| item.status === QuestionnaireExecutionToShopStatusEnum.CANCELED
			|| item.status === QuestionnaireExecutionToShopStatusEnum.RUNNING;
	}
	return item;
}

function sumValues(map: Map<number, number>) {
	const values = Array.from(map.values());
	return values.reduce((acc, value) => acc + value, 0);
}
