import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	DiagnosticsFileGalleryMeta,
	DiagnosticsFileGalleryMetaOptions,
	diagnosticsFileGalleryMetaSchema
} from '../meta';
import {
	DiagnosticsFileGalleryItem,
	DiagnosticsFileGalleryItemOptions,
	diagnosticsFileGalleryItemSchema
} from '../composite';

export const diagnosticsFileGalleryListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(diagnosticsFileGalleryItemSchema).optional(),
		meta: diagnosticsFileGalleryMetaSchema.optional(),
	}),
);

export interface DiagnosticsFileGalleryListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (DiagnosticsFileGalleryItem | DiagnosticsFileGalleryItemOptions)[];
	meta?: DiagnosticsFileGalleryMeta | DiagnosticsFileGalleryMetaOptions;
}

export class DiagnosticsFileGalleryListResponseDto extends BaseResponseDto {
	data?: DiagnosticsFileGalleryItem[];
	meta?: DiagnosticsFileGalleryMeta;

	constructor(options: DiagnosticsFileGalleryListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof DiagnosticsFileGalleryItem) {
					this.data.push(item);
				} else {
					this.data.push(new DiagnosticsFileGalleryItem(item));
				}
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof DiagnosticsFileGalleryMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new DiagnosticsFileGalleryMeta(options.meta);
			}
		}
		diagnosticsFileGalleryListResponseDtoSchema.parse(this);
	}
}