import React from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
  flex: 1;
  margin: 0 6px;
  height: 60px;
  border: 0;
  border-radius: 16px;
  background-color: ${p => p.theme.buttonBg};
  color: ${p => p.theme.buttonColor};
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  max-width: 400px;

  // для веба разрешаем менять цвет текста и иконок при наведении
  @media (min-width: 900px) {
    &:hover {
      background-color: ${p => p.theme.buttonHoverBg};
    }
  }

  &:disabled, &[disabled] {
    background-color: silver;
    cursor: default;
  }

  &.accept {
    background-color: ${p => p.theme.buttonAcceptBg};

    // для веба разрешаем менять цвет текста и иконок при наведении
    @media (min-width: 900px) {
      &:hover {
        background-color: ${p => p.theme.buttonAcceptHoverBg};
      }
    }

    &:disabled, &[disabled] {
      background-color: ${p => p.theme.buttonAcceptDisabledBg};
    }
  }

  &.decline {
    background-color: ${p => p.theme.buttonDeclineBg};

    // для веба разрешаем менять цвет текста и иконок при наведении
    @media (min-width: 900px) {
      &:hover {
        background-color: ${p => p.theme.buttonDeclineHoverBg};
      }
    }

    &:disabled, &[disabled] {
      background-color: ${p => p.theme.buttonDeclineDisabledBg};
    }
  }
`;

const MenuStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  background: #fff;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -2px 7px rgba(0, 0, 0, 0.1);
  z-index: 4;

  @media (max-width: 450px), (max-height: 450px) {
    ${ButtonStyled} {
      height: 55px;
    }
  }

  @media (max-width: 380px), (max-height: 380px) {
    ${ButtonStyled} {
      height: 40px;
      font-size: 15px;
    }
  }
`;

type Props = {
	children: any
}

export default function UiOverMenu(props: Props) {
	return (
		<MenuStyled>
			{props.children}
		</MenuStyled>
	);
}

type ButtonProps = {
	title: string
	onClick?: Function
	disabled?: boolean
}

UiOverMenu.Button = function Button(props: ButtonProps) {
	return <ButtonStyled onClick={props.onClick} disabled={props.disabled}>
		{props.title}
	</ButtonStyled>;
}

UiOverMenu.AcceptButton = function AcceptButton(props: ButtonProps) {
	return <ButtonStyled className="accept" onClick={props.onClick} disabled={props.disabled}>
		{props.title}
	</ButtonStyled>;
}

UiOverMenu.DeclineButton = function DeclineButton(props: ButtonProps) {
	return <ButtonStyled className="decline" onClick={props.onClick} disabled={props.disabled}>
		{props.title}
	</ButtonStyled>;
}
