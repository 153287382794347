import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import {
	QuestionnaireStatisticReportItem,
	QuestionnaireStatisticReportItemOptions,
	questionnaireStatisticReportItemSchema
} from '../composite';


export const questionnaireStatisticReportResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: questionnaireStatisticReportItemSchema.optional(),
	}),
);
export interface QuestionnaireStatisticReportResponseDtoOptions extends BaseResponseDtoOptions {
	data?: QuestionnaireStatisticReportItem | QuestionnaireStatisticReportItemOptions;
}

export class QuestionnaireStatisticReportResponseDto extends BaseResponseDto {
	readonly data?: QuestionnaireStatisticReportItem;

	constructor(options: QuestionnaireStatisticReportResponseDtoOptions) {
		super(options);
		if (options.data instanceof QuestionnaireStatisticReportItem) {
			this.data = options.data;
		} else if (options.data !== undefined) {
			this.data = new QuestionnaireStatisticReportItem(options.data);
		}
		questionnaireStatisticReportResponseDtoSchema.parse(this);
	}
}