import React from 'react';
import styled from 'styled-components';

const FaceStyled = styled.div`
	border-radius: 100%;
	border: 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	color: #FFF;
	text-align: center;
	line-height: calc(${({ size }) => size || Sizes.DEFAULT} * 1.05); 
	font-size: calc(${({ size }) => size || Sizes.DEFAULT} * 0.4); 
	width: ${({ size }) => size || Sizes.DEFAULT};
	height: ${({ size }) => size || Sizes.DEFAULT};
`;

enum Sizes {
	DEFAULT = '48px',
}

type Props = {
	faceFullName: string
	size?: Sizes
}

export default function FaceByText(props: Props) {
	const color = stringToColor(props.faceFullName);
	// из ФИО выбираем только первые буквы фамилии и имени и меняем местами
	// ex: 'Иванов Петр Михайлович' -> 'ПИ'
	const text = props.faceFullName
		.split(' ')
		.slice(0, 2)
		.map(s => s.substr(0, 1).toUpperCase())
		.filter(s => !!s)
		.join('') || '?';
	return (
		<FaceStyled
			size={props.size}
			style={{ backgroundColor: color }}
		>
			{text}
		</FaceStyled>
	);
}

/**
 * Преобразует строковое значение в цвет
 * @param s
 */
function stringToColor(s: string) {
	s = s.substr(0, 6);
	let hash = 0;
	for (let i = 0; i < s.length; i++) {
		hash = s.charCodeAt(i) + ((hash << 6) - hash);
	}
	let color = '#';
	for (let i = 0; i < 3; i++) {
		let value = (hash >> (i * 8)) & 0xDD;
		color += ('00' + value.toString(16)).substr(-2);
	}
	return color;
}
