import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { InfoMessagesData, InfoMessagesDataOptions, infoMessagesDataSchema } from '../composite';
import { InfoMessagesMeta, InfoMessagesMetaOptions, infoMessagesMetaSchema } from '../meta';

export const infoMessagesListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: infoMessagesDataSchema.optional(),
		meta: infoMessagesMetaSchema.optional(),
	}),
);

export interface InfoMessagesListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: InfoMessagesData | InfoMessagesDataOptions;
	meta?: InfoMessagesMeta | InfoMessagesMetaOptions;
}

export class InfoMessagesListResponseDto extends BaseResponseDto {
	readonly data?: InfoMessagesData;
	readonly meta?: InfoMessagesMeta;

	constructor(options: InfoMessagesListResponseDtoOptions) {
		super(options);
		if (options.data !== undefined) {
			if (options.data instanceof InfoMessagesData) {
				this.data = options.data;
			} else {
				this.data = new InfoMessagesData(options.data);
			}
		}
		if (options.meta !== undefined) {
			if (options.meta instanceof InfoMessagesMeta) {
				this.meta = options.meta;
			} else {
				this.meta = new InfoMessagesMeta(options.meta);
			}
		}
		infoMessagesListResponseDtoSchema.parse(this);
	}
}