import React, { useEffect, useRef, useState } from 'react';
import { ListIsEmpty, ManagerWithoutRole, Spinner, UiBadge, UiFaceProgressBadge } from '~components';
import * as QuizApi from '~api/quizApi';
import { UserContentWrapper } from '~containers';
import styled from 'styled-components';
import { getRoleName, getServerUrl, QuestionnaireTypeTitle } from 'common-lib';
import {  fillSubordinateMapForStatisticManager } from '../../../lib/subordinateTools';
import { Link, useParams } from 'react-router-dom';
import { UiContainerContentBreadCrumbs } from '~components/ui/UiContainer';
import { ResultStyle } from './UserQuizSubShopPage';

const TitleAndQuizTypeStyle = styled.div`
	display: flex;
	justify-content: space-between;

    .quiz_type {
        font-size: 10px;
        font-weight: bold;
        padding: 2px 8px;
        border-radius: 16px;
        color: gray;
        background-color: #ddd;
        text-transform: uppercase;
    }
`;

export const BreadCrumbsStyled = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 19px;
	overflow-y: hidden;
	overflow-x: auto;

	scrollbar-height: 5px; /* Firefox */

	&::-webkit-scrollbar {
		height: 5px;
	}

	// скрываем скроллбар (только на девайсах), но оставляем возможность скроллить контейнер
	@media only screen and (max-device-width: 736px) {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-height: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
	}

	div {
		padding: 5px 9px;
		text-align: center;
		background-color: ${p => p.theme.breadCrumbsBg};
		border-radius: 8px;
		font-size: 11px;
		line-height: 13px;
		white-space: nowrap;
		//text-overflow: ellipsis;
		//overflow: hidden;

		a {
			color: inherit;
		}

		&.active {
			background-color: ${p => p.theme.breadCrumbsActiveBg};
			color: ${p => p.theme.breadCrumbsActiveColor};
			overflow: visible;
		}
	}

	img {
		width: 8px;
		margin: 0 4px;
	}
`;

const ButtonStyled = styled.button`
    flex: 1;
    margin: 0 6px;
    height: 60px;
    border: 0;
    border-radius: 16px;
    background-color: ${p => p.active ? '#696969' : '#e0e1e2'};
    color: ${p => p.active ? '#fff' : '#333232'};
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    max-width: 400px;

    // для веба разрешаем менять цвет текста и иконок при наведении
    @media (min-width: 900px) {
        &:hover {
	        color: #ffffff;
            background-color: #80878bcc;
        }
    }
`;


const MenuManagerStyled = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 10px 10px;
	background: #fff;
	border-radius: 20px 20px 0 0;
	box-shadow: 0 -2px 7px rgba(0, 0, 0, 0.1);
	z-index: 4;

    a {
        display: contents;
    }

	@media (max-width: 450px), (max-height: 450px) {
		${ButtonStyled} {
  			height: 55px;
		}
	}
	@media (max-width: 380px), (max-height: 380px) {
		${ButtonStyled} {
	        height: 40px;
    	    font-size: 15px;
		}
	}
`;

export default function UserQuizManagersPage() {
	const [subordinateStat, setSubordinateStat] = useState<any | undefined>(undefined);
	const [managerQuests, setManagerQuests] = useState<any[] | undefined>(undefined);
	const [hasNoRole, setHasNoRole] = useState<boolean | undefined>(undefined);
	const serverUrl = getServerUrl();

	const { managerIds } = useParams<{managerIds: string | undefined}>();
	// хлебные крошки
	const breadCrumbs: UiContainerContentBreadCrumbs = [];
	const selectedManagerIds = managerIds?.split(',').map(Number);

	useEffect(() => {
		updateListForManager();
	}, []);

	// Получение опросов и тестов подчиненного менеджера
	// useEffect(() => {
	// 	if (selectedManagerIds?.length) {
	// 		updateQuizListForSubManager(selectedManagerIds[selectedManagerIds.length - 1]);
	// 	}
	// }, [location.pathname]);

	function updateQuizListForSubManager(subManagerId: number) {
		QuizApi.fetchQuizListForSelectedManager(subManagerId).then(res => {
			setManagerQuests(res.data);
		});
	}

	function updateListForManager() {
		QuizApi.fetchQuizCurrentManagerStats().then(res => {
			if (res.data?.questExecToShops) {
				setHasNoRole(false);
				const subs = fillSubordinateMapForStatisticManager(res.data, res.data.questExecToShops);
				setSubordinateStat(subs);
			}
			if (res.meta?.options?.isNotShopManager) {
				setHasNoRole(true);
			}
		});
	}

	function renderSublist(data) {
		let subTree = data;
		if (selectedManagerIds?.length) {
			for (const id of selectedManagerIds) {
				subTree = subTree.subordinateUsers.find(u => u.id === id);
			}
		}

		if (selectedManagerIds) {
			if (selectedManagerIds.length) {
				breadCrumbs.push({
					title: 'Все менеджеры',
					linkTo: '/quiz-managers'
				});
			}
			if (selectedManagerIds.length > 1) {
				const list: number[] = [];
				const foo = user => {
					if (selectedManagerIds.includes(user.id) && subTree.id !== user.id) {
						list.push(user.id);
						breadCrumbs.push({
							title: `${user.shopManagerRole?.shortTitle || getRoleName(user.role)} ${user.fullName}`,
							linkTo: `/quiz-managers/${list}`
						});
					}
					user.subordinateUsers?.forEach(foo);
				};
				const dataFiltered = data.subordinateUsers?.filter(i => selectedManagerIds.includes(i.id));
				dataFiltered.forEach(foo);
			}
			if (selectedManagerIds.length > 0) {
				breadCrumbs.push({
					title: `${subTree.shopManagerRole?.shortTitle || getRoleName(subTree.role)} ${subTree.fullName}`
				});
			}
		}

		return (
			<>
				{subTree.subordinateUsers?.map(item => {
					const list = [...selectedManagerIds || [], item.id].filter(Boolean);
					return (
						<UiFaceProgressBadge
							key={item.id}
							title={item.fullName}
							faceFullName={item.fullName}
							subTitle={item.shopManagerRole?.shortTitle || getRoleName(item.role)}
							progress={{ value: item.answeredCount, max: item.quizCount }}
							linkTo={`/quiz-managers/${list}`}
						/>
					);
				})}
				{subTree.subordinateShops?.map((shop: any) => {
					return (
						<UiFaceProgressBadge
							key={shop.id}
							faceFullName={shop.city}
							title={shop.address}
							subTitle={shop.city}
							progress={{ value: shop.answeredCount, max: shop.quizCount }}
							linkTo={`/quiz-subshop/${shop.id}`}
						/>
					);
				})}
				{/*{selectedManagerIds?.length*/}
				{/*	? (*/}
				{/*		<>*/}
				{/*			<h3>{subTree.fullName}</h3>*/}
				{/*			{managerQuests?.length ? managerQuests.map(item => {*/}
				{/*				const pathToQuiz = item.type.toLowerCase(); // quiz | testing*/}
				{/*				return (*/}
				{/*					<div key={item.id}>*/}
				{/*						<UiBadge bigPaddings>*/}
				{/*							<TitleAndQuizTypeStyle>*/}
				{/*								<h1>{item.title}</h1>*/}
				{/*								<div className="quiz_type">{QuestionnaireTypeTitle[item.type]}</div>*/}
				{/*							</TitleAndQuizTypeStyle>*/}
				{/*							<UiBadge.DateTimePeriod dateStart={new Date(item.startAt)} dateFinish={new Date(item.finishAt)} />*/}
				{/*							{item.repeatCount > 1 ? (*/}
				{/*								<UiBadge.QuizCount leftCount={item.repeatCount - item.finishedCount} repeatCount={item.repeatCount} />*/}
				{/*							) : null}*/}
				{/*							{item.quizResultHashCode ? (*/}
				{/*								<ResultStyle>*/}
				{/*									<div className="result_title">Результат:</div>*/}
				{/*										<a href={`${serverUrl}/noauth-user/${pathToQuiz}-reports/${item.quizResultHashCode}/manager/${[...selectedManagerIds]}`}>*/}
				{/*											Показать результаты*/}
				{/*										</a>*/}
				{/*								</ResultStyle>*/}
				{/*							) : null}*/}
				{/*						</UiBadge>*/}
				{/*					</div>*/}
				{/*				);*/}
				{/*			}) : (*/}
				{/*				<p>*/}
				{/*					Для выбранного менеджера опросов или тестов нет*/}
				{/*				</p>*/}
				{/*			)}*/}
				{/*		</>*/}
				{/*	)*/}
				{/*	: null}*/}
			</>
		);
	}

	if (!subordinateStat && hasNoRole === undefined) {
		return <Spinner onpage text={<div>Загрузка...</div>} />;
	}

	return (
		<UserContentWrapper title="Опросы и тесты" breadCrumbs={breadCrumbs}>
			{hasNoRole ? (
				<ManagerWithoutRole />
			) : (
					<>
						{subordinateStat.subordinateUsers || subordinateStat.subordinateShops
								? renderSublist(subordinateStat)
								: <ListIsEmpty />}
						<MenuManagerStyled>
							<ButtonStyled active>Опросы магазинов</ButtonStyled>
							<Link to="/quiz-list">
								<ButtonStyled>Мои опросы</ButtonStyled>
							</Link>
						</MenuManagerStyled>
					</>
			)}
		</UserContentWrapper>
	);
}
