import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ChecklistTaskStatusEnum, TaskStatusEnum } from 'common-lib';
import moment from 'moment';

const BadgeStyled = styled.div`
	display: block;
	padding: 5px 8px;
	margin-bottom: 8px;
	border-radius: 16px;
	color: inherit;
	text-decoration: none;
	background-color: white;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	cursor: inherit;

  	h1 {
    	color: #2C2E4E;
    	margin-bottom: 3px;
	  	max-width: 700px;
  	}

	&.big-paddings {
		padding: 13px 16px;
	}

	${p => p.color ? `border-left: 10px solid ${p.color};` : ''}
	&:hover {
		color: inherit;
		text-decoration: none;
	}

	&.clickable {
		cursor: pointer;

		// для веба разрешаем менять цвет текста и иконок при наведении
		@media (min-width: 900px) {
			&:hover {
				box-shadow: 0 1px 3px 1px rgba(20, 20, 20, 0.5);
			}
		}
	}

  	.title_task {
	  display: flex;
	  justify-content: space-between;
    }

	div.description {
		color: #9696A7;
	}
`;

interface Props {
	linkTo?: string;     // ссылка для перехода
	onClick?: Function;  // обработчик клика
	children: ReactNode;
	title: string;
	color?: string;      // цвет ярлыка
	bigPaddings?: boolean;
}

export default function UiBadge2(props: Props) {
	const cls = [
		props.onClick || props.linkTo ? 'clickable' : undefined,
		props.bigPaddings ? 'big-paddings' : undefined,
	].filter(i => !!i).join(' ') || undefined;
	return (
		<BadgeStyled as={props.linkTo ? Link : undefined}
		             to={props.linkTo}
		             onClick={props.onClick}
		             className={cls}
		             color={props.color}>
			{props.children}
		</BadgeStyled>
	);
}

const KeyValueStyled = styled.div`
  	display: flex;
  	margin-bottom: 3px;

	.key {
		margin-right: 5px;
		color: #9696A7;
		font-size: 13px;
		line-height: 16px;
	}

	.value {
		font-size: 13px;
		line-height: 16px;
	}
`;

const ValueStyled = styled.div`
  text-indent: ${p => p.keyLength ? p.keyLength : 0}px;
  margin-left: ${p => p.keyLength ? -p.keyLength : 0}px;
`;

type UiBadgeValuesValue = {
	key: string
	value: string
};

type UiBadgeValuesProps = {
	values: Array<UiBadgeValuesValue>
};

UiBadge2.Values = function UiBadgeValues(props: UiBadgeValuesProps) {
	return (
		<>
			{props.values.map((kv, index) => {
				return (
					<KeyValueStyled key={`${index}_${kv.key}`}>
						<div className="key">{kv.key}</div>
						<ValueStyled className="value" keyLength={kv.key.length * 7.5}>{kv.value}</ValueStyled>
					</KeyValueStyled>
				);}
			)}
		</>
	);
};

const DueDateStyle = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	color: #9696A7;

	.date, .time {
		&::before {
			content: ' ';
			display: inline-block;
			margin-right: 2px;
			margin-bottom: -5px;
			background-repeat: no-repeat;
			background-position: center;
			width: 18px;
			height: 18px;
		}
	}

	.date {
		display: inline-block;
		width: 90px;

		&::before {
			background-image: url(/assets/img/calendar.svg);
		}
	}

	.time {
		display: inline-block;

		&::before {
			background-image: url(/assets/img/clock.svg);
		}
	}
`;

const StatusStyle = styled.div`
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    margin-bottom: 0;
  	align-items: start;

	.title_block {
		margin-bottom: 3px;
	}

  	.dep_stat {
	    display: grid;
    	justify-content: left;
	    align-items: start;
	    gap: 10px;
        grid-template-columns: repeat(2, auto);
      	grid-column-gap: 10px;
		justify-items: end;
	  	margin-bottom: 3px;

		@media (max-width: 600px) {
            grid-row-gap: 5px;
		}
    }

    .checklist_name_container {
	    display: grid;
    }

  	.checklist_name {
		font-size: 10px;
		font-weight: bold;
		padding: 4px 8px;
	  	line-height: 1.2em;
		border-radius: 16px;
		color: gray;
		background-color: #ddd;
		text-transform: uppercase;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: center;
		max-width: 100%;
    }

  	.department {
      	font-size: 10px;
      	font-weight: bold;
	  	line-height: 1.2em;
      	padding: 4px 8px;
      	border-radius: 16px;
      	color: gray;
      	background-color: #ddd;
      	text-transform: uppercase;
      	overflow: hidden;
      	white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
	  	max-width: 100%;
    }

	.status {
		font-size: 10px;
		font-weight: bold;
	  	line-height: 1.2em;
      	padding: 4px 8px;
		border-radius: 16px;
		color: gray;
      	float: right;
		background-color: #ddd;
		text-transform: uppercase;
	  	// TODO не поддерживается на Safari
        text-wrap: nowrap;

		&:nth-child(2) {
			margin-right: 3px;
		}

		&.STARTED, &.PLANNED {
			color: #FEC679;
			background-color: #FFF5E7;
		}

		&.COMPLETED {
			color: #89BC96;
			background-color: #ECF5EF;
		}

		&.EXPIRED, &.REJECTED {
			color: #DC9091;
			background-color: #F6ECEC;
		}
	}
`;

type BadgeStatus = ChecklistTaskStatusEnum | TaskStatusEnum;

type UiBadgeDueDateProps = {
	taskCreatedAt: Date, // дата постановки задачи
	taskDeadlineAt: Date, // срок выполнения
};

UiBadge2.DueDate = function UiBadgeDueDate(props: UiBadgeDueDateProps) {
	const momTaskCreatedAt = moment(props.taskCreatedAt);
	const taskCreatedAtDate = momTaskCreatedAt.format('DD.MM.YYYY');
	const taskCreatedAtTime = momTaskCreatedAt.format('HH:mm');
	const dueMom = moment(props.taskDeadlineAt);
	const dueDateOver = dueMom.format('DD.MM.YYYY');
	const dueTimeOver = dueMom.format('HH:mm');
	return (
		<DueDateStyle>
			<div className="due-date">
				<div className="date">
					{taskCreatedAtDate}
				</div>
				<div className="time">
					{taskCreatedAtTime}
				</div>
			</div>
			<div className="due-date">
				<div className="date">
					{dueDateOver}
				</div>
				<div className="time">
					{dueTimeOver}
				</div>
			</div>
		</DueDateStyle>
	);
};

/**
 * UiBadge.DateTimeAndImportant
 */

const ImportantStyled = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	font-size: 12px;
	color: #9696A7;

	.date, .time, .is-important {
		&::before {
			content: ' ';
			display: inline-block;
			margin-right: 9px;
			margin-bottom: -5px;
			background-repeat: no-repeat;
			background-position: center;
			width: 18px;
			height: 18px;
		}
	}

	.date {
		display: inline-block;
		width: 105px;

		&::before {
			background-image: url(/assets/img/calendar.svg);
		}
	}

	.time {
		display: inline-block;

		&::before {
			background-image: url(/assets/img/clock.svg);
		}
	}

	.is-important {
		color: #F53737;
		font-weight: 500;

		&::before {
			background-image: url(/assets/img/red-important.svg);
		}
	}
`;

type UiBadgeDateTimeAndImportantProps = {
	date: Date
	isImportant?: boolean
};

UiBadge2.DateTimeAndImportant = function UiBadgeDateTimeAndImportant(props: UiBadgeDateTimeAndImportantProps) {
	const moment1 = moment(props.date);
	const date = moment1.format('DD.MM.YYYY');
	const time = moment1.format('HH:mm');
	return (
		<ImportantStyled>
			<div>
				<span className="date">{date}</span>
				<span className="time">{time}</span>
			</div>
			{props.isImportant ? (
				<div className="is-important">Важное</div>
			) : null}
		</ImportantStyled>
	);
};

type UiBadgeTextWithStatusProps = {
	text: string
	status?: BadgeStatus // статус
	statusText: string
};

UiBadge2.TextWithStatus = function UiBadgeTextWithStatus(props: UiBadgeTextWithStatusProps) {
	return (
		<StatusStyle>
			<div>{props.text}</div>
			<div className={`status ${props.status}`}>{props.statusText}</div>
		</StatusStyle>
	);
};

type UiBadgeTitleAndStatusProps = {
	title: string;
	checkListName: string;
	taskCreatedAt: Date;
	taskDeadlineAt: Date;
	status: BadgeStatus;
	expiredStatus?: BadgeStatus;
	expiredText?: string;
	statusText: string;
	department?: string;
};

UiBadge2.TitleAndStatus = function UiBadgeTitleAndStatus(props: UiBadgeTitleAndStatusProps) {
	const momTaskCreatedAt = moment(props.taskCreatedAt);
	const taskCreatedAtDate = momTaskCreatedAt.format('DD.MM.YYYY');
	const taskCreatedAtTime = momTaskCreatedAt.format('HH:mm');
	const dueMom = moment(props.taskDeadlineAt);
	const dueDateOver = dueMom.format('DD.MM.YYYY');
	const dueTimeOver = dueMom.format('HH:mm');
	return (
		<StatusStyle>
			<div className="title_checklist">
				<div className="title_block">
					{props.expiredStatus && props.status !== ChecklistTaskStatusEnum.COMPLETED ? (
						<div className={`status ${props.expiredStatus}`}>
							{props.expiredText}
						</div>
					) : null}
					<div className={`status ${props.status}`}>
						{props.statusText}
					</div>
					<h1>{props.title}</h1>
				</div>
			</div>
			<div className="dep_stat">
				{props.checkListName ? (
					<div className="checklist_name_container">
						<div className="checklist_name">{props.checkListName}</div>
					</div>
				) : null}
				{props.department ? (
					<div className="department">{props.department}</div>
				) : null}
			</div>
			<DueDateStyle>
				<div>
					<span className="date">{taskCreatedAtDate}</span>
					<span className="time">{taskCreatedAtTime}</span>
					{' - '}
					<span className="date">{dueDateOver}</span>
					<span className="time">{dueTimeOver}</span>
				</div>
			</DueDateStyle>
		</StatusStyle>
	);
};



const DateTimePeriodStyled = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	font-size: 12px;
	color: #9696A7;

	.date, .time, .is-important {
		&::before {
			content: ' ';
			display: inline-block;
			margin-right: 2px;
			margin-bottom: -5px;
			background-repeat: no-repeat;
			background-position: center;
			width: 18px;
			height: 18px;
		}
	}

	.date {
		display: inline-block;
		width: 90px;

		&::before {
			background-image: url(/assets/img/calendar.svg);
		}
	}

	.time {
		display: inline-block;

		&::before {
			background-image: url(/assets/img/clock.svg);
		}
	}

	.is-important {
		color: #F53737;
		font-weight: 500;

		&::before {
			background-image: url(/assets/img/red-important.svg);
		}
	}
`;

type UiBadgeDateTimePeriod = {
	dateStart: Date
	dateFinish: Date
};

UiBadge2.DateTimePeriod = function UiBadgeDateTimePeriod(props: UiBadgeDateTimePeriod) {
	const moment1 = moment(props.dateStart);
	const moment2 = moment(props.dateFinish);
	const dateStart = moment1.format('DD.MM.YYYY');
	const timeStart = moment1.format('HH:mm');
	const dateFinish = moment2.format('DD.MM.YYYY');
	const timeFinish = moment2.format('HH:mm');
	return (
		<DateTimePeriodStyled>
			<div>
				<span className="date">{dateStart}</span>
				<span className="time">{timeStart}</span>
				{' - '}
				<span className="date">{dateFinish}</span>
				<span className="time">{timeFinish}</span>
			</div>
		</DateTimePeriodStyled>
	);
};

type UiBadgeQuizCount = {
	leftCount: number;
	repeatCount: number;
};

UiBadge2.QuizCount = function UiBadgeQuizCount(props: UiBadgeQuizCount) {
	const { leftCount, repeatCount } = props;
	return (
		<ImportantStyled>
			<div>
				<span style={{ marginRight: '2rem' }}>Осталось попыток: {leftCount}</span>
				<span>Всего попыток: {repeatCount}</span>
			</div>
		</ImportantStyled>
	);
};
