import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Form, Header, Icon, Input, Loader, Segment, Table } from 'semantic-ui-react';
import { checkHumanDate, fromHumanDateToSqlDate, toHumanDate } from 'common-lib';
import { checkIncidentImportData, sendIncidentImportData } from '~store/actions/incidentImportActions';
import { HOC } from '~components';

type Props = {
	incidentImport: any
	navigate: any
	checkIncidentImportData: any
};

class AdminIncidentImportPage extends React.Component<Props>
{
	state = {
		isRequesting: true,
		isFormSendDisabled: false,
		formDate: toHumanDate(new Date()),
	};

	componentDidMount() {
		const { importData } = this.props.incidentImport;
		if (!importData) {
			return this.props.navigate('/'); // нет данных - выходим
		}
		// определимся, какие данные надо подтянуть с сервера
		const checkData = importData.map(({ shopExId, goodExId }) => ({ shopExId, goodExId }));
		this.props.checkIncidentImportData(checkData)
			.then(() => this.setState({ isRequesting: false }));
	}

	handleDateChange = (e, { value }) => {
		const isFormSendDisabled = !checkHumanDate(value);
		this.setState({ formDate: value, isFormSendDisabled });
	}

	handleFormSubmit = () => {
		const { importData } = this.props.incidentImport;
		const date = fromHumanDateToSqlDate(this.state.formDate);
		this.setState(
			{ isRequesting: true },
			() => {
				sendIncidentImportData(date, 'incident_type1', importData.map(item => ({
					shopExId: item.shopExId,
					goodExId: item.goodExId,
					goodName: item.goodName,
					upPrib: item.upPrib,
					ostatok: item.ostatok,
				})))
					.then(() => this.props.navigate('/'))
					.finally(() => this.setState({ isRequesting: false }));
			},
		);
	}

	render() {
		if (this.state.isRequesting) {
			return (
				<Dimmer active inverted>
					<Loader inverted>Загрузка данных об инцидентах...</Loader>
				</Dimmer>
			);
		}
		const { importData } = this.props.incidentImport;
		return (
			<div>
				<Header as="h3" icon="upload" content="Проверка загрузки инцидентов" />
				<p>
					Проверьте таблицу инцидентов.
					<br />
					Красным отмечены магазины, которые не заведены в БД. Эти записи с инцидентами
					не будут добавлены.
					<br />
					Зеленым отмечена номенклатура, которая еще не была заведена в БД. Эта
					номенклатура будет добавлена при
					создании задания инцидента.
				</p>
				<p>
					Нажмите кнопку
					&nbsp;
					<b>Сохранить</b>
					&nbsp;
					чтобы создать задания инцидентов.
				</p>
				<Table celled striped>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>#</Table.HeaderCell>
							<Table.HeaderCell colSpan={2}>Магазин</Table.HeaderCell>
							<Table.HeaderCell colSpan={2}>Номенклатура</Table.HeaderCell>
							<Table.HeaderCell>Упущ. прибыль, тыс.р.</Table.HeaderCell>
							<Table.HeaderCell>Остаток, шт</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{importData.map((item, index) => (
							<Table.Row key={`item-${index}`}>
								<Table.Cell>{index + 1}</Table.Cell>
								<Table.Cell collapsing
								            negative={item.shopNotExists}>{item.shopExId}</Table.Cell>
								<Table.Cell negative={item.shopNotExists}>
									{item.shopNotExists && <Icon name="attention" />}
									{item.shopName}
								</Table.Cell>
								<Table.Cell collapsing
								            positive={item.goodNotExists}>{item.goodExId}</Table.Cell>
								<Table.Cell
									positive={item.goodNotExists}>{item.goodName}</Table.Cell>
								<Table.Cell>{item.upPrib}</Table.Cell>
								<Table.Cell>{item.ostatok}</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
				<Segment>
					<Form onSubmit={this.handleFormSubmit}>
						<Form.Group inline>
							<Form.Field>
								<label>Укажите дату</label>
								<Input
									name="date"
									placeholder="ДД.ММ.ГГГГ"
									onChange={this.handleDateChange}
									defaultValue={this.state.formDate}
								/>
							</Form.Field>
							<Form.Field>
								<Form.Button positive disabled={this.state.isFormSendDisabled}
								             content="Сохранить" />
							</Form.Field>
						</Form.Group>
					</Form>
				</Segment>
			</div>
		);
	}
}

export default connect(
	(state: any) => ({ incidentImport: state.incidentImport }),
	{ checkIncidentImportData },
)(HOC(AdminIncidentImportPage));
