import { z } from 'zod';
import {
	DashboardMeta,
	DashboardMetaOptions,
	IncidentTypeDataItem,
	IncidentTypeDataItemOptions,
	incidentTypeDataItemSchema,
} from '../../../meta';

export const dashboardIncidentMetaSchema = z.object({
	incidentTypeMap: z.map(z.string(), incidentTypeDataItemSchema),
});

export interface DashboardIncidentMetaOptions extends DashboardMetaOptions {
	incidentTypeMap?: Record<string, IncidentTypeDataItem | IncidentTypeDataItemOptions>;
}

export class DashboardIncidentMeta extends DashboardMeta {
	readonly incidentTypeMap?: Record<string, IncidentTypeDataItem>;

	constructor(options: DashboardIncidentMetaOptions) {
		super(options);
		if (options.incidentTypeMap !== undefined) {
			this.incidentTypeMap = {};
			for(const [key, value] of Object.entries(options.incidentTypeMap)) {
				if (value instanceof IncidentTypeDataItem) {
					this.incidentTypeMap[key] = value;
				} else {
					this.incidentTypeMap[key] = new IncidentTypeDataItem(value);
				}
			}
		}
		dashboardIncidentMetaSchema.parse(this);
	}
}