import React from 'react';
import styled from 'styled-components';

const VersionSpan = styled.span`
  position: fixed;
  bottom: 0;
  left: 5px;
  z-index: 1;
  opacity: 0.5;
  font-size: .8rem;
`;

export default function VersionInfo() {
	return (
		<VersionSpan>
			{process.env.PACKAGE_NAME}
			&nbsp;v
			{process.env.PACKAGE_VERSION}
		</VersionSpan>
	);
}
