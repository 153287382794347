import { z } from 'zod';
import { BaseResponseDto, BaseResponseDtoOptions, baseResponseDtoSchema } from '../../../common';
import { TodoDataItem, TodoDataItemOptions, todoDataItemSchema } from '../composite';

export const todoListResponseDtoSchema = baseResponseDtoSchema.merge(
	z.object({
		data: z.array(todoDataItemSchema).optional(),
	}),
);

export interface TodoListResponseDtoOptions extends BaseResponseDtoOptions {
	data?: (TodoDataItem | TodoDataItemOptions)[];
}

export class TodoListResponseDto extends BaseResponseDto {
	data?: TodoDataItem[];

	constructor(options: TodoListResponseDtoOptions) {
		super(options);
		if (options.data?.length) {
			this.data = [];
			for (const item of options.data) {
				if (item instanceof TodoDataItem) {
					this.data.push(item);
				} else {
					this.data.push(new TodoDataItem(item));
				}
			}
		}
		todoListResponseDtoSchema.parse(this);
	}
}