import { createReducer } from 'redux-act';
import { CHECK_INCIDENT_IMPORT_DATA, SAVE_INCIDENT_IMPORT_DATA } from '../actions/incidentImportActions';

export default createReducer(
  {
    [SAVE_INCIDENT_IMPORT_DATA]: (state, importData) => {
      // все поля externalId должны быть типа VARCHAR
      if (importData) {
        importData.forEach((item) => {
          item.shopExId = String(item.shopExId);
          item.goodExId = String(item.goodExId);
        });
      }
      return { ...state, importData };
    },
    [CHECK_INCIDENT_IMPORT_DATA]: (state, { notExistShopExIds, notExistGoodExIds }) => {
      const { importData } = state;
      console.debug('%c*** checkData=', 'background: #eee; color: blue', { notExistShopExIds, notExistGoodExIds });
      // обогащаем данные
      importData.forEach((item) => {
        if (notExistShopExIds && notExistShopExIds.length && notExistShopExIds.includes(item.shopExId)) {
          item.shopNotExists = true;
        }
        if (notExistGoodExIds && notExistGoodExIds.length && notExistGoodExIds.includes(item.goodExId)) {
          item.goodNotExists = true;
        }
      });
      return { ...state, importData };
    },
  },
  {
    importData: null,
  },
);
