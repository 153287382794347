import React from 'react';
import styled from 'styled-components';

const TabSwitchStyled = styled.div`
  display: flex;
  margin-bottom: 16px;

  & > .item {
    padding: 6px 14px;
    border: 1px solid ${p => p.theme.badgeActionButtonBg};
    font-size: 14px;
    background-color: #FFF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    @media (max-width: 600px) {
      width: 100%;
    }

    &:first-child {
      border-radius: 16px 0 0 16px;
    }

    &:not(:first-child) {
      margin-left: -1px;
    }

    &:last-child {
      border-radius: 0 16px 16px 0;
    }

    &.selected {
      background-color: ${p => p.theme.badgeActionButtonBg};
      color: ${p => p.theme.badgeActionButtonColor};
      text-overflow: inherit;
      overflow: inherit;
    }
  }
`;

type Props = {
	tabs: string[]
	selectedIndex: number
	onChange: Function
}

export default function UiTabSwitch(props: Props) {
	return (
		<TabSwitchStyled>
			{props.tabs.map((title, index) => (
				<div key={`${index}_${title}`}
				     className={`item ${index === props.selectedIndex ? 'selected' : ''}`}
				     onClick={() => props.onChange(index)}>
					{title}
				</div>
			))}
		</TabSwitchStyled>
	);
}
